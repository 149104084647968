import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { addActivity } from 'scripts/common'

const Documents = (props) => {

  const path = `reports/report`

  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true
  })

  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectViewDocuments', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: props.filter.jobNumber,
        gradeId: props.filter.gradeId
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

        setFetchedData(result)

        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('Error: selectStatuses --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      window.open(`${path}${fetchedData[i].id}.pdf`)

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map((data, i) => {

    let description = data.description === null ? '' : data.description

    if (
      searchValue === '' ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  return (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>
          {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>

                  <Icon name='refresh' onClick={fetchData} />

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                {fetchedData.length > 0 ?

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          <th>Description</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div> :
                  <p style={{margin: 10}}>No documents found.</p>

                }

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )

}

export default Documents
