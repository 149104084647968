import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError, exportToExcel, filterData } from 'scripts/common'

const Billing = (props) => {

  const [fetchedDailies, setFetchedDailies] = useState([])
  const [fetchedMaterial, setFetchedMaterial] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true,
    sort: false, // false is by date, true is tech, date
    view: false // false is dailies and true is material
  })

  const [searchValue, setSearchValue] = useState('')

  const fileArray = useRef([])

  const fetchData = () => {

    fetch('/api/selectBillingDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        sort: isModal.sort
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedDailies(result)
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('Error: selectBillingDailies --> ' + error)
      }
    )

    fetch('/api/selectBillingMaterial', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedMaterial(result)

        // setFetchedBilling(result.map(data => (
        //   {...data,
        //     entrytime: formatDateTime(data.entrytime),
        //     modtime: formatDateTime(data.modtime),
        //     castDate: formatDateYMD(data.castDate)
        //   }
        // )))
      },
      (error) => {
        catchError(props.filter.jobNumber, '', props.component, 'selectBillingMaterial', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter, isModal.sort])

  // const validate = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value
  //
  //   setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  // }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else {

      window.open(`dailies/daily${fetchedDailies[i].id}.pdf`)

    }

  }

  const downloadDailies = () => {

    fetch('/api/mergeBilling', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fileName: `not used`,
        fileArray: fileArray.current
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        window.open(`billing.pdf`)

      },
      (error) => {
        alert('Error: could not merge PDFs for download. Contact and admin.')
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'mergeBilling', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  const toggleSort = () => setIsModal(prevState => ({...prevState, sort: isModal.sort ? false : true}))

  const toggleView = () => setIsModal(prevState => ({...prevState, view: isModal.view ? false : true}))

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const billMaterial = () => {

    if (fetchedMaterial.length === 0) {

      alert('Nothing to bill')

    } else if (window.confirm("Are you sure you want to mark as Billed? The search filter will be ignored and an excel sheet for your record will be downloaded.")) {

      exportToExcel()

      fetch('/api/updateBillingMaterial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          fetchData()
        },
        (error) => {
          catchError(props.filter.jobNumber, '', props.component, 'updateBillingMaterial', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }

  fileArray.current = [] // clear before pushing

  let listOfDailies = fetchedDailies.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let type = data.dailiestype === null ? '' : data.dailiestype
    let status = data.dailiesstatus === null ? '' : data.dailiesstatus
    let dailiesDate = data.dailiesdate === null ? '' : formatDateTime(data.dailiesdate, true)
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let task1 = data.taskcode1 === null ? '' : data.taskcode1
    let hour1 = data.hours1 === null ? '' : data.hours1
    let task2 = data.taskcode2 === null ? '' : data.taskcode2
    let hour2 = data.hours2 === null ? '' : data.hours2
    let task3 = data.taskcode3 === null ? '' : data.taskcode3
    let hour3 = data.hours3 === null ? '' : data.hours3
    let task4 = data.taskcode4 === null ? '' : data.taskcode4
    let hour4 = data.hours4 === null ? '' : data.hours4

    //  || entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    let filter = filterData(data, searchValue)

    if (
      (status === 'Signed' || status === 'Complete') &&
      filter
    ) {

      fileArray.current.push(`daily${data.id}.pdf`)

      return (
        <tr key={data.id.toString()} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td>{formatDateYMD(dailiesDate)}</td>
            <td>{entryBy}</td>
            <td>{jn} - {gd}</td>
            <td>{type}</td>
            <td>{task1}</td>
            <td><b>{hour1}</b></td>
            <td>{task2}</td>
            <td><b>{hour2}</b></td>
            <td>{task3}</td>
            <td><b>{hour3}</b></td>
            <td>{task4}</td>
            <td><b>{hour4}</b></td>
        </tr>
      )
    }

  })

  let listOfMaterial = fetchedMaterial.map((data, i) => {

    let filter = filterData(data, searchValue)

    if (filter) {

      return (
        <tr key={i.toString()} onClick={null}>
          <td>{data.jobNumber}</td>
          <td>{data.materialType}</td>
          <td>{data.specimenType}</td>
          <td>{data.sampleType}</td>
          <td><b>{data.count}</b></td>
        </tr>
      )

    }

  })

  let breakDays = (
    <>
      <option value=""></option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="7">7</option>
      <option value="14">14</option>
      <option value="28">28</option>
      <option value="0">Hold</option>
    </>
  )

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  return (
    <>
      {!isModal.loading ?
        <>

          <div style={{display: 'flex', width: '100%', height: '100%'}}>

            <div style={{margin: 10, flex: '1 0 auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                <div>
                  <Icon name='file_download' title='Export to Excel' onClick={exportToExcel} />
                  <Icon name='refresh' title='Refresh' onClick={fetchData} />

                  {!isModal.view ?

                    <div style={{display: 'inline-block', margin: 10}}>
                      <small style={{margin: 10}}>Sort by Date</small>
                      <label className='switch'>
                        <input type='checkbox' onClick={toggleSort} defaultChecked={isModal.sort} />
                        <span className='slider round'></span>
                      </label>
                      <small style={{margin: 10}}>Sort by Tech then Date</small>
                    </div> : null

                  }

                  <div style={{display: 'inline-block', margin: 10}}>
                    <small style={{margin: 10}}>Dailies</small>
                    <label className='switch'>
                      <input type='checkbox' onClick={toggleView} defaultChecked={isModal.view} />
                      <span className='slider round'></span>
                    </label>
                    <small style={{margin: 10}}>Material</small>
                  </div>

                </div>

                <SearchBar search={search} clearSearch={clearSearch} />

                {isModal.view ?

                  <div style={styleBtn} onClick={billMaterial}>
                    <Icon name='savings' outline={true} />
                    <span style={{marginRight: 10}}><b>Bill</b></span>
                  </div> :

                  <div style={styleBtn} onClick={downloadDailies}>
                    <Icon name='file_download' outline={true} />
                    <span style={{marginRight: 10}}><b>PDFs</b></span>
                  </div>

                }

                {fetchedDailies.length > 0 && !isModal.view ?

                  <>

                    <p style={{margin: 10}}>{fetchedDailies.length} Total Records. (If no filters, limit 500)</p>

                    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                      <table id="toExcel">

                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Tech</th>
                            <th>JN</th>
                            <th>Type</th>
                            <th>Task</th>
                            <th>Hours</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {listOfDailies}
                        </tbody>

                      </table>

                    </div>

                  </> :

                fetchedMaterial.length > 0 && isModal.view ?

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table id="toExcel">

                      <thead>
                        <tr>
                          <th>JN</th>
                          <th>Material</th>
                          <th>Specimen</th>
                          <th>Sample</th>
                          <th>Count</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfMaterial}
                      </tbody>

                    </table>

                  </div> :

                fetchedDailies.length === 0 && !isModal.view ?

                  <p>No dailies found.</p> :

                fetchedMaterial.length === 0 && isModal.view ?

                  <p>No material found.</p> : null

                }
              </div>

            </div>

          </div>

        </> :
        <p style={{margin: 10}}>Loading...</p>
      }
    </>
  )

}

export default Billing
