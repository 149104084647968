import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import CustomTools from 'components/utils/CustomTools'

import { formatDateYMD, formatDateTime, getLocation, filterData } from 'scripts/common'

const Presets = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [presetTypes, setPresetTypes] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    typeId: null,
    description: '',
    tool: '',
    color: '',
    width: '',
    line: '',
    lineName: 'solid', // this determines which line is selected, had issues comparing an array for the border
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    typeId: null,
    description: '',
    tool: '',
    color: '',
    width: '',
    line: '',
    lineName: 'solid', // this determines which line is selected, had issues comparing an array for the border
  })

  const fetchData = () => {

    fetch('/api/selectPresetTypes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setPresetTypes(result)
      },
      (error) => {
        console.log('Error: selectPresetTypes --> ' + error)
      }
    )

    fetch('/api/selectPresets', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectPresets --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      let lineName =
      fetchedData[i].line === '' ? 'solid' :
      fetchedData[i].line === '5, 5' ? 'dashS' :
      fetchedData[i].line === '20, 20' ? 'dashM' :
      fetchedData[i].line === '40, 20' ? 'dashL' :
      fetchedData[i].line === '30, 10, 6, 10' ? 'dashDot' :
      fetchedData[i].line === '40, 10, 6, 10, 6, 10, 6, 10' ? 'dashDot3' : 'solid'

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].presetId, // shared with plansdrawing, so presetId used and not id
        typeId: fetchedData[i].typeId,
        description: fetchedData[i].description,
        tool: fetchedData[i].tool,
        color: fetchedData[i].color,
        width: fetchedData[i].width,
        line: fetchedData[i].line,
        lineName: lineName
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addPreset = () => {

    if (props.user.preset < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.typeId === null || isValidated.typeId ==='') {
        alert("Please provide the type of preset")
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description")
    } else if (isValidated.tool === null || isValidated.tool ==='') {
        alert("Please select a tool")
    } else if (isValidated.color === null || isValidated.color ==='') {
        alert("Please select a color")
    } else  if (isValidated.width === null || isValidated.width ==='') {
        alert("Please select a thickness")
    // } else if (isValidated.line === null || isValidated.line ==='') {
    //     alert("Please select a line type")
    } else {

      getLocation(function(latlng){

        fetch('/api/addPreset', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            typeId: isValidated.typeId,
            description: isValidated.description,
            tool: isValidated.tool,
            color: isValidated.color,
            width: isValidated.width,
            line: isValidated.line
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Preset. Contact and admin.')
            console.log('Error: addPreset --> ' + error)
          }
        )

      })

    }

  }

  const editPreset = () => {

    if (props.user.preset < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.typeId === null || isValidated.typeId ==='') {
        alert("Please provide the type of preset")
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description")
    } else if (isValidated.tool === null || isValidated.tool ==='') {
        alert("Please select a tool")
    } else if (isValidated.color === null || isValidated.color ==='') {
        alert("Please select a color")
    } else  if (isValidated.width === null || isValidated.width ==='') {
        alert("Please select a thickness")
    // } else if (isValidated.line === null || isValidated.line ==='') {
    //     alert("Please select a line type")
    } else {

      getLocation(function(latlng){

        fetch('/api/editPreset', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            id: isValidated.id,
            typeId: isValidated.typeId,
            description: isValidated.description,
            tool: isValidated.tool,
            color: isValidated.color,
            width: isValidated.width,
            line: isValidated.line
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            let lineName =
            isValidated.line === '' ? 'solid' :
            isValidated.line === '5, 5' ? 'dashS' :
            isValidated.line === '20, 20' ? 'dashM' :
            isValidated.line === '40, 20' ? 'dashL' :
            isValidated.line === '30, 10, 6, 10' ? 'dashDot' :
            isValidated.line === '40, 10, 6, 10, 6, 10, 6, 10' ? 'dashDot3' : 'solid'

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                typeId: isValidated.typeId,
                description: isValidated.description,
                tool: isValidated.tool,
                color: isValidated.color,
                width: isValidated.width,
                line: isValidated.line,
                lineName: lineName
              } :
              data
            ))

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Preset. Contact and admin.')
            console.log('Error: editPreset --> ' + error)
          }
        )

      })

    }

  }

  // DO NOT DELETE PRESET..presetId saved in plansdraw table!!!!!!!!!!

  const deletePreset = () => {

    alert('The id of the preset record is saved in the drawings table. So if you delete, the style info will be lost. I can disable it alternatively... ill deal with this later')

    // if (props.user.preset < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    // } else {
    //
    //   if (window.confirm('If you proceed, this will be deleted. Proceed?')) {
    //
    //     fetch('/api/deletePreset', {
    //       method: 'post',
    //       headers: {
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify({
    //         id: isValidated.id
    //       })
    //     })
    //     .then(res=>res.json())
    //     .then(
    //       (result) => {
    //         //console.log('result: ' + JSON.stringify(result))
    //
    //         //fetchData()
    //         setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
    //         isChanged.current = false
    //         closeModal()
    //         //alert('Deleted.')
    //
    //       },
    //       (error) => {
    //
    //         alert('Error: could not delete Preset. Contact and admin.')
    //         console.log('Error: deletePreset --> ' + error)
    //       }
    //     )
    //
    //   }
    //
    // }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.preset < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const styleTool = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, tool: value}))
    changedData()
  }

  const styleColor = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, color: value}))
    changedData()
  }

  const styleColorOther = () => alert('under construction')

  const styleWidth = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, width: value}))
    changedData()
  }

  const styleLine = (e) => {

    let name = e.target.getAttribute('data-name') // i struggled with comparing an array for the border, so this was a quick fix
    let value = e.target.getAttribute('data-id')

    setIsValidated(prevState => ({...prevState, line: value, lineName: name}))
    changedData()
  }

  let listOfData = fetchedData.map((data, i) => {

    let type = data.type === null ? '' : data.type
    let description = data.description === null ? '' : data.description
    let tool = data.tool === null ? '' : data.tool
    let color = data.color === null ? '' : data.color
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let modTime = data.modtime === null ? '' : formatDateTime(data.modtime, true)

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.presetId.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{type}</td>
          <td>{description}</td>
          <td>{tool}</td>
          <td style={{backgroundColor: color}}></td>
          <td>{entryBy}</td>
          <td>{entryTime}</td>
          <td>{modBy}</td>
          <td>{modTime}</td>
        </tr>
      )
    }

  })

  let listOfPresetTypes = presetTypes.map(data => <option value={data.id}>{data.description}</option>)

  return (
    <>
      {isModal.add || isModal.edit ?
        <Modal
          add={isModal.add ? addPreset : isModal.edit ? editPreset : null}
          content={
            <CustomTools
              style={isValidated}
              styleTool={styleTool}
              styleColor={styleColor}
              styleColorOther={styleColorOther}
              styleWidth={styleWidth}
              styleLine={styleLine}
              validate={validate}
              listOfPresetTypes={listOfPresetTypes}
              who={'presets'}
            />
          }
          closeModal={closeModal}
        /> : null
      }

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div>
              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Descrip.</th>
                    <th>Tool</th>
                    <th>Color</th>
                    <th>Entry by</th>
                    <th>Entry time</th>
                    <th>Mod by</th>
                    <th>Mod time</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>
              </div> :
              <p style={{margin: 10}}>No presets found.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default Presets
