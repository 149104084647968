import React, {useState, useEffect, useRef} from 'react'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import Plans from 'components/main/Plans'
import Curve from 'components/menu/Curve'

import { formatDateTime, getLocation, catchError, addActivity, replaceStr } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const PresatInput = (props) => {

  const [isModal, setIsModal] = useState({
    locate: false,
    warning: 0,
    warningContent: '',
    saving: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    sampleDate: '',    
    location: '',
    represents: '',
    n: '',
    e: '',
    tareNo: '',
    tareWt: '',
    wetWt: '',
    dryWt: '',
    maxId: null,
    curve: '',
    optM: ''
  })  

  useEffect(() => {

    if (props.isModal.add) {            

      setIsValidated(prevState => ({...prevState,
        n: props.location === undefined ? '' : props.location.n,
        e: props.location === undefined ? '' : props.location.e      
      }))

    } else if (props.isModal.edit) {

      let i = props.isModal.i

      //console.log(`data: ${JSON.stringify(props.fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: props.fetchedData[i].entryby,
        entrytime: props.fetchedData[i].entrytime,
        entrylat: props.fetchedData[i].entrylat,
        entrylng: props.fetchedData[i].entrylng,
        entrydevice: props.fetchedData[i].entrydevice,
        modby: props.fetchedData[i].modby,
        modtime: props.fetchedData[i].modtime,
        modlat: props.fetchedData[i].modlat,
        modlng: props.fetchedData[i].modlng,
        moddevice: props.fetchedData[i].moddevice,
        id: props.fetchedData[i].id,
        sampleDate: props.fetchedData[i].sampleDate,        
        location: props.fetchedData[i].location,
        represents: props.fetchedData[i].represents,        
        n: props.fetchedData[i].n,
        e: props.fetchedData[i].e,        
        tareNo: props.fetchedData[i].tareNo,
        tareWt: props.fetchedData[i].tareWt,
        wetWt: props.fetchedData[i].wetWt,
        dryWt: props.fetchedData[i].dryWt,
        maxId: props.fetchedData[i].maxId,
        curve: props.fetchedData[i].curve,
        optM: props.fetchedData[i].optM
      }))

    }
    
  }, [])  

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const changedData = () => isChanged.current = true
  
  const selectCurve = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    let maxId = Number(td[0].textContent)
    //let optD = Number(td[3].textContent)
    let optM = Number(td[4].textContent)    

    if (isValidated.maxId !== maxId) {

      //updatePassFail.current.optD = optD

      // update in case
      //let relComp = Math.round((Number(updatePassFail.current.dryDens)/Number(updatePassFail.current.optD))*100)
      //let passFail = relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

      // console.log(
      //   `
      //   dryDens: ${updatePassFail.current.dryDens}
      //   optD: ${updatePassFail.current.optD}
      //   reqComp: ${updatePassFail.current.reqComp}
      //   relComp: ${relComp},
      //   passFail: ${passFail},
      //   `
      // )

      setIsValidated(prevState => ({...prevState,
        maxId: maxId,
        curve: td[2].textContent,        
        optM: optM        
      }))
      changedData()

    }

    closeCurve()

  }

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      n: n,
      e: e
    }))
  }

  const addData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addPresat', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,            
            sampleDate: isValidated.sampleDate,            
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),            
            n: isValidated.n,
            e: isValidated.e,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetWt: isValidated.wetWt,
            dryWt: isValidated.dryWt,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optM: isValidated.optM
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', '', props.user.username)

            props.updateFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                jobnumber: props.filter.jobNumber,                
                sampleDate: isValidated.sampleDate,                
                location: isValidated.location,
                represents: isValidated.represents,                
                n: isValidated.n,
                e: isValidated.e,
                tareNo: isValidated.tareNo,
                tareWt: isValidated.tareWt,
                wetWt: isValidated.wetWt,
                dryWt: isValidated.dryWt,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optM: isValidated.optM
              }, ...props.fetchedData]
            )            
            
            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add presat. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addPresat', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.filter.gradeId === 'RG') {
      alert('You cannot add a Presat to grading')
    } else if (props.user.presat < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");    
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please explain what this represents.");    
    } else if (isValidated.n === null || isValidated.n === '') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e === '') {
      alert("Please select an East.");
    } else  if (isValidated.tareWt === null || isValidated.tareWt === '') {
      alert("Please provide a tare number.")
    } else  if (isValidated.wetWt === null || isValidated.wetWt === '') {
      alert("Please provide a wet weight.");  
    } else {

      if (props.user.bufferData) {

        let id = Math.max(...props.fetchedData.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'addPresat',
          table: 'Presat',
          jobnumber: props.filter.jobNumber,          
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          sampledate: isValidated.sampleDate,          
          location: replaceStr(isValidated.location),
          represents: replaceStr(isValidated.represents),          
          n: isValidated.n,
          e: isValidated.e          
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(prevState =>
              [
                {
                  syncedID: false,
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  sampledate: isValidated.sampleDate,                  
                  location: replaceStr(isValidated.location),
                  represents: replaceStr(isValidated.represents),                  
                  n: isValidated.n,
                  e: isValidated.e
                },
                ...prevState
              ]
            )

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addPresatOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()        

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editPresat', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            sampleDate: isValidated.sampleDate,            
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),            
            n: isValidated.n,
            e: isValidated.e,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetWt: isValidated.wetWt,
            dryWt: isValidated.dryWt,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optM: isValidated.optM
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                sampleDate: isValidated.sampleDate,            
                location: replaceStr(isValidated.location),
                represents: replaceStr(isValidated.represents),            
                n: isValidated.n,
                e: isValidated.e,
                tareNo: isValidated.tareNo,
                tareWt: isValidated.tareWt,
                wetWt: isValidated.wetWt,
                dryWt: isValidated.dryWt,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optM: isValidated.optM
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', '', props.user.username)

            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit presat. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editPresat', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    // if (props.user.presat < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");    
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please explain what this represents.");    
    } else if (isValidated.n === null || isValidated.n === '') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e === '') {
      alert("Please select an East.");
    } else  if (isValidated.tareWt === null || isValidated.tareWt === '') {
      alert("Please provide a tare number.")
    } else  if (isValidated.wetWt === null || isValidated.wetWt === '') {
      alert("Please provide a wet weight.")
    } else  if (isValidated.drytwt === null || isValidated.dryWt === '') {
      alert("Please provide a dry weight.");  
    } else {

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editPresat',
          table: 'Presat',
          jobnumber: props.filter.jobNumber,          
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          sampledate: isValidated.sampleDate,          
          location: replaceStr(isValidated.location),
          represents: replaceStr(isValidated.represents),          
          n: isValidated.n,
          e: isValidated.e
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                moddevice: props.user.device,
                sampledate: isValidated.sampleDate,                
                location: isValidated.location,
                represents: isValidated.represents,                
                n: isValidated.n,
                e: isValidated.e
              } :
              data
            ))

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editPresatOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deletePresat', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', '', props.user.username)
          
          props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          close()          

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete presat. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deletePresat', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    // if (props.user.presat < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deletePresat',
            table: 'Presat',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              close()
              //alert('Deleted.')

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deletePresatOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const openCurve = () => setIsModal(prevState => ({...prevState, curve: true}))

  const closeCurve = () => setIsModal(prevState => ({...prevState, curve: false}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))  

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  let wtOfWater
  let wtOfDrySoil
  let moistureContent = ''
  let actualPresat = ''

  if (isValidated.wetWt !== '' && isValidated.dryWt !== '') {
    
    wtOfWater = Number(isValidated.wetWt) - Number(isValidated.dryWt)
    wtOfDrySoil = Number(isValidated.dryWt) - Number(isValidated.tareWt)
    moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  }
  
  if (moistureContent !== '' && isValidated.optM !== '') {

    actualPresat = Math.ceil((Number(moistureContent) / Number(isValidated.optM)) * 100)

  }

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {props.isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>Sample Date</label>
          <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : isValidated.sampleDate} required />
        </div>        

        <div>
          <label className='label'>Location</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='location' onInput={validate} onChange={changedData} value={isValidated.location} required />
        </div>

        <div>
          <label className='label'>Represents</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='represents' onInput={validate} onChange={changedData} placeholder={isValidated.sampleType === 'plantMax' ? 'Required' : 'Optional'} value={isValidated.represents} required />
        </div>

        <div>
          <label className='label'>Tare Wt (g)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,}(\.\d{1})?" name='tareWt' onInput={validate} onChange={changedData} value={isValidated.tareWt} required />              
        </div>

        <div>
          <label className='label'>Wet Soil/Tare Wt (g)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,}(\.\d{1})?" name='wetWt' onInput={validate} onChange={changedData} value={isValidated.wetWt} required />              
        </div>

        <div>
          <label className='label'>Dry Soil/Tare Wt (g)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,}(\.\d{1})?" name='dryWt' onInput={validate} onChange={changedData} value={isValidated.dryWt} required />              
        </div>

        <div>
          <label className='label'>Moisture Content (%)</label>
          <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
        </div>

        <div>
          <label className='label'>Curve</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.curve} onClick={openCurve} />          
        </div>

        <div>
          <label className='label'>Opt Moisture (%)</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.optM} disabled />
        </div>

        <div>
          <label className='label'>Required Presat (%)</label>
          <input style={{width: 75}} className='input' type="text" value={props.requiredPresat} disabled />
        </div>

        <div>
          <label className='label'>Actual Presat (%)</label>
          <input style={{width: 75}} className='input' type="text" value={actualPresat} disabled />
        </div>              
        
        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          {props.planMode !== undefined ? <div><CircleButton iconName='add_location' onClick={openLocate} /></div> : <small>Locate before input.</small>}

          {isValidated.n !== null && isValidated.n !== '' ? <p>N: {isValidated.n}</p> : null}
          {isValidated.e !== null && isValidated.e !== '' ? <p>E: {isValidated.e}</p> : null}

        </div>

      </div>

    </div>
  )
  
  let content = (    
    <>
        {props.isModal.add || props.isModal.edit ? 
          <Modal 
            add={props.isModal.add ? addData : props.isModal.edit ? editData : null}
            delete={props.isModal.edit ? deleteData : null} 
            content={modalContent} 
            closeModal={close} 
            isValidated={isValidated} 
            isModal={isModal}
            zIndex={props.zIndex} 
          /> : null
        }
        {isModal.curve ? <Curve user={props.user} filter={props.filter} selectCurve={selectCurve} closeModal={closeCurve} zIndex={props.zIndex} /> : null}        
        {isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} zIndex={props.zIndex} /> : null}
        
    </>     
    
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default PresatInput
