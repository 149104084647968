import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { filterData } from 'scripts/common'

const EquipDescription = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectEquipDescriptions', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectEquipDescriptions --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let description = data.description === null ? '' : data.description

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={props.select}>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let content = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      {fetchedData.length > 0 ?

        <div>

          <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

            <table>

              <thead>
                <tr>
                  <th>Descrip</th>
                </tr>
              </thead>

              <tbody>
                {listOfData}
              </tbody>

            </table>

          </div>

        </div> : <p style={{margin: 10}}>No descriptions added</p>

      }

    </div>
  )

  return <Modal content={content} closeModal={props.close} />
}

export default EquipDescription
