import React from "react";

import Home from './Home'
import Info from './Info'
import Issue from './Issue'
import Tests from './Tests'
import Lab from './Lab'
import Presat from './Presat'
import Plans from './Plans'
import Drawings from './Drawings'
import Dailies from './Dailies'
import Documents from './Documents'
import Concrete from './Concrete'
import Schedule from './Schedule'
import Manage from './Manage'

//{props.component === 'Drawings' ? <Drawings user={props.user} filter={props.filter} component={props.component} /> : null}
 
// selectNuke added here for modal tests in plansdraw

const Container = (props) => {

  return (
    <>
      {props.component === 'Home' ? <Home user={props.user} filter={props.filter} component={props.component} selectNuke={props.selectNuke} /> : null}
      {props.component === 'Info' ? <Info user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Issue' ? <Issue user={props.user} filter={props.filter} component={props.component} planMode={'plansIssue'} /> : null}
      {props.component === 'Tests' ? <Tests user={props.user} filter={props.filter} component={props.component} planMode={'plansTest'} selectNuke={props.selectNuke} /> : null}
      {props.component === 'Lab' ? <Lab user={props.user} filter={props.filter} component={props.component} planMode={'plansLab'} /> : null}
      {props.component === 'Presat' ? <Presat user={props.user} filter={props.filter} component={props.component} planMode={'plansPresat'} /> : null}
      {props.component === 'Plans' ? <Plans user={props.user} filter={props.filter} component={props.component} planMode={'plansdraw'} selectNuke={props.selectNuke} /> : null}
      {props.component === 'Dailies' ? <Dailies user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Documents' ? <Documents user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Concrete' ? <Concrete user={props.user} filter={props.filter} component={props.component} planMode={''} /> : null}
      {props.component === 'Schedule' ? <Schedule user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Manage' ? <Manage user={props.user} filter={props.filter} component={props.component} componentManage={props.componentManage} selectNuke={props.selectNuke} /> : null}
    </>
  )
}

export default Container
