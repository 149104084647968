import React, {useState, useEffect, useRef} from 'react'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import Plans from 'components/main/Plans'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity, replaceStr } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const LabInput = (props) => {

  const [isModal, setIsModal] = useState({
    locate: false,
    warning: 0,
    warningContent: '',
    saving: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    sampleDate: '',
    sampleNo: null,
    location: '',
    represents: '',
    purpose: '',
    sampleType: '',
    n: '',
    e: '',
    status: '',
    finalDialReading: '',
    initialDialReading: '',
    ei: '',
    dilution1000: '',
    dilution100: '',
    dilution10: '',
    dilution0: '',
    so4: '',
    curve: '',
    deliveredby: '',
    deliveredtime: null
  })  

  useEffect(() => {

    if (props.isModal.add) {

      // if adding, temporary: dynamically assigned on post
      let sampleNo = props.fetchedData.length < 1 ? 1 : Math.max(...props.fetchedData.map(o => o.sampleno)) + 1      

      setIsValidated(prevState => ({...prevState,        
        sampleNo: sampleNo,
        n: props.location === undefined ? '' : props.location.n,
        e: props.location === undefined ? '' : props.location.e      
      }))

    } else if (props.isModal.edit) {

      let i = props.isModal.i

      //console.log(`data: ${JSON.stringify(props.fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: props.fetchedData[i].entryby,
        entrytime: props.fetchedData[i].entrytime,
        entrylat: props.fetchedData[i].entrylat,
        entrylng: props.fetchedData[i].entrylng,
        entrydevice: props.fetchedData[i].entrydevice,
        modby: props.fetchedData[i].modby,
        modtime: props.fetchedData[i].modtime,
        modlat: props.fetchedData[i].modlat,
        modlng: props.fetchedData[i].modlng,
        moddevice: props.fetchedData[i].moddevice,
        id: props.fetchedData[i].id,
        sampleDate: props.fetchedData[i].sampledate,
        sampleNo: props.fetchedData[i].sampleno,
        location: props.fetchedData[i].location,
        represents: props.fetchedData[i].represents,
        purpose: props.fetchedData[i].purpose,
        sampleType: props.fetchedData[i].sampletype,
        n: props.fetchedData[i].n,
        e: props.fetchedData[i].e,
        status: props.fetchedData[i].status,
        finalDialReading: props.fetchedData[i].finalDialReading,
        initialDialReading: props.fetchedData[i].initialDialReading,
        ei: props.fetchedData[i].ei,
        dilution1000: props.fetchedData[i].dilution1000,
        dilution100: props.fetchedData[i].dilution100,
        dilution10: props.fetchedData[i].dilution10,
        dilution0: props.fetchedData[i].dilution0,
        so4: props.fetchedData[i].so4,
        curve: props.fetchedData[i].curve,
        deliveredby: props.fetchedData[i].deliveredby,
        deliveredtime: props.fetchedData[i].deliveredtime,
        description: props.fetchedData[i].description,
        optD: props.fetchedData[i].optd,
        optM: props.fetchedData[i].optm
      }))

    }
    
  }, [])  

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const changedData = () => isChanged.current = true  

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      n: n,
      e: e
    }))
  }

  const addData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            sampledate: isValidated.sampleDate,
            sampleno: isValidated.sampleNo,
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),
            purpose: replaceStr(isValidated.purpose),
            sampletype: isValidated.sampleType,
            n: isValidated.n,
            e: isValidated.e,
            status: isValidated.sampleType === 'plantMax' ? 'disposed' : 'sampled',
            plantDensity: isValidated.sampleType === 'plantMax' ? isValidated.plantDensity : null,
            plantMoisture: isValidated.sampleType === 'plantMax' && isValidated.description !== '' && isValidated.description !== 'Asphalt' ? isValidated.plantMoisture : null,
            description: isValidated.sampleType === 'plantMax' ? isValidated.description : ''
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', '', props.user.username)

            props.updateFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                jobnumber: props.filter.jobNumber,
                gradeid: props.filter.gradeId,
                sampledate: isValidated.sampleDate,
                sampleno: isValidated.sampleNo,
                location: isValidated.location,
                represents: isValidated.represents,
                purpose: isValidated.purpose,
                sampletype: isValidated.sampleType,
                n: isValidated.n,
                e: isValidated.e,
                status: 'sampled',
                finalDialReading: '',
                initialDialReading: '',
                ei: '',
                dilution1000: '',
                dilution100: '',
                dilution10: '',
                dilution0: '',
                so4: '',
                curve: '',
                deliveredby: '',
                deliveredtime: null
              }, ...props.fetchedData]
            )            
            
            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add lab. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addLab', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.lab < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");
    } else if (isValidated.sampleNo === null || isValidated.sampleNo ==='') {
      alert("Sample number not found.");
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.sampleType === 'plantMax' && (isValidated.represents === null || isValidated.represents === '')) {
      alert("Please explain what this represents.");
    } else if (isValidated.sampleType !== 'plantMax' && (isValidated.purpose === null || isValidated.purpose === '')) {
      alert("Please provide a purpose.");
    } else if (isValidated.sampleType === null || isValidated.sampleType ==='') {
      alert("Please select a sample type.");
    } else if (isValidated.sampleType !== 'plantMax' && (isValidated.n === null || isValidated.n === '')) {
      alert("Please select a North.");
    } else  if (isValidated.sampleType !== 'plantMax' && (isValidated.e === null || isValidated.e === '')) {
      alert("Please select an East.");
    } else  if (isValidated.sampleType === 'plantMax' && (isValidated.description === null || isValidated.description === '' || isValidated.description === undefined)) {
      alert("Please provide a description of the materials.");
    } else  if (isValidated.sampleType === 'plantMax' && (isValidated.plantDensity === null || isValidated.plantDensity === '' || isValidated.plantDensity === undefined)) {
      alert("Please provide a plant density.");
    } else  if (isValidated.sampleType === 'plantMax' && isValidated.description !== 'Asphalt' && (isValidated.plantMoisture === null || isValidated.plantMoisture === '' || isValidated.plantMoisture === undefined)) {
      alert("Please provide a plant moisture.");  
    } else {

      if (props.user.bufferData) {

        let id = Math.max(...props.fetchedData.map(o => o.gradeid === props.filter.gradeId ? o.id : 0)) + 1        

        addPending({
          actionId: 1,
          action: 'addLab',
          table: 'Lab',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          sampledate: isValidated.sampleDate,
          sampleno: isValidated.sampleNo,
          location: replaceStr(isValidated.location),
          represents: replaceStr(isValidated.represents),
          purpose: replaceStr(isValidated.purpose),
          sampletype: isValidated.sampleType,
          n: isValidated.n,
          e: isValidated.e,
          status: isValidated.status,
          finalDialReading: isValidated.finalDialReading,
          initialDialReading: isValidated.initialDialReading,
          ei: isValidated.ei,
          dilution1000: isValidated.dilution1000,
          dilution100: isValidated.dilution100,
          dilution10: isValidated.dilution10,
          dilution0: isValidated.dilution0,
          so4: isValidated.so4,
          curve: isValidated.curve,
          deliveredby: isValidated.deliveredby,
          deliveredtime: isValidated.deliveredtime
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(prevState =>
              [
                {
                  syncedID: false,
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  sampledate: isValidated.sampleDate,
                  sampleno: isValidated.sampleNo,
                  location: replaceStr(isValidated.location),
                  represents: replaceStr(isValidated.represents),
                  purpose: replaceStr(isValidated.purpose),
                  sampletype: isValidated.sampleType,
                  n: isValidated.n,
                  e: isValidated.e,
                  status: isValidated.status,
                  finalDialReading: isValidated.finalDialReading,
                  initialDialReading: isValidated.initialDialReading,
                  ei: isValidated.ei,
                  dilution1000: isValidated.dilution1000,
                  dilution100: isValidated.dilution100,
                  dilution10: isValidated.dilution10,
                  dilution0: isValidated.dilution0,
                  so4: isValidated.so4,
                  curve: isValidated.curve,
                  deliveredby: isValidated.deliveredby,
                  deliveredtime: isValidated.deliveredtime
                },
                ...prevState
              ]
            )

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addLabOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        if (isValidated.sampleType === 'plantMax' && window.confirm('I am a plant max? You cannot edit, so double check!. Proceed?')) {          

          addToServer()

        } else if (isValidated.sampleType !== 'plantMax') {

          addToServer()

        }

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            sampledate: isValidated.sampleDate,
            sampleno: isValidated.sampleNo,
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),
            purpose: replaceStr(isValidated.purpose),
            sampletype: isValidated.sampleType,
            n: isValidated.n,
            e: isValidated.e
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                sampledate: isValidated.sampleDate,
                sampleno: isValidated.sampleNo,
                location: isValidated.location,
                represents: isValidated.represents,
                purpose: isValidated.purpose,
                sampletype: isValidated.sampleType,
                n: isValidated.n,
                e: isValidated.e,
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', '', props.user.username)

            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit lab. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editLab', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    // if (props.user.lab < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");
    } else if (isValidated.sampleNo === null || isValidated.sampleNo ==='') {
      alert("Sample number not found.");
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.sampleType !== 'plantMax' && (isValidated.purpose === null || isValidated.purpose === '')) {
      alert("Please provide a purpose.");
    } else if (isValidated.sampleType === null || isValidated.sampleType ==='') {
      alert("Please select a sample type.");
    } else if (isValidated.sampleType !== 'plantMax' && (isValidated.n === null || isValidated.n === '')) {
      alert("Please select a North.");
    } else  if (isValidated.sampleType !== 'plantMax' && (isValidated.e === null || isValidated.e === '')) {
      alert("Please select an East.");
    } else {

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editLab',
          table: 'Lab',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          sampledate: isValidated.sampleDate,
          sampleno: isValidated.sampleNo,
          location: replaceStr(isValidated.location),
          represents: replaceStr(isValidated.represents),
          purpose: replaceStr(isValidated.purpose),
          sampletype: isValidated.sampleType,
          n: isValidated.n,
          e: isValidated.e,
          status: isValidated.status,
          finalDialReading: isValidated.finalDialReading,
          initialDialReading: isValidated.initialDialReading,
          ei: isValidated.ei,
          dilution1000: isValidated.dilution1000,
          dilution100: isValidated.dilution100,
          dilution10: isValidated.dilution10,
          dilution0: isValidated.dilution0,
          so4: isValidated.so4,
          curve: isValidated.curve,
          deliveredby: isValidated.deliveredby,
          deliveredtime: isValidated.deliveredtime
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                moddevice: props.user.device,
                sampledate: isValidated.sampleDate,
                sampleno: isValidated.sampleNo,
                location: isValidated.location,
                represents: isValidated.represents,
                purpose: isValidated.purpose,
                sampletype: isValidated.sampleType,
                n: isValidated.n,
                e: isValidated.e,
                status: isValidated.status,
                finalDialReading: isValidated.finalDialReading,
                initialDialReading: isValidated.initialDialReading,
                ei: isValidated.ei,
                dilution1000: isValidated.dilution1000,
                dilution100: isValidated.dilution100,
                dilution10: isValidated.dilution10,
                dilution0: isValidated.dilution0,
                so4: isValidated.so4,
                curve: isValidated.curve,
                deliveredby: isValidated.deliveredby,
                deliveredtime: isValidated.deliveredtime
              } :
              data
            ))

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editLabOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteLab', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', '', props.user.username)
          
          props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          close()          

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete lab. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteLab', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    // if (props.user.lab < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deleteLab',
            table: 'Lab',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              close()
              //alert('Deleted.')

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteLabOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))  

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {props.isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>Sample Date</label>
          <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : isValidated.sampleDate} required />
        </div>

        <div>
          <label className='label'>Sample No</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.sampleNo} disabled />
        </div>
        
        {isModal.edit && isValidated.sampleType === 'plantMax' ?

          <div>
            <label className='label'>Sample Type</label>
            <input style={{width: 75}} className='input' type="text" value={isValidated.sampleType} disabled />
          </div> :

          <div>
            <label className='label'>Sample Type</label>
            <select className='select' pattern=".{1,}" name='sampleType' onInput={validate} onChange={changedData} value={isValidated.sampleType} required >
              <option value=""></option>
              <option value="plantMax">Plant Max</option>
              <option value="smbag">Small Bag</option>
              <option value="bulk">Bulk</option>
              <option value="ac">Asphalt</option>
              <option value="ca">CA Rings</option>
              <option value="spt">SPT</option>
              <option value="shelby">Shelby Tube</option>
            </select>
          </div>

        }

        {isValidated.sampleType === 'plantMax' ?          

          <div>
            <label className='label'>Description</label>
            <select className='select' pattern=".{1,}" name='description' onInput={validate} onChange={changedData} value={isValidated.description} required >
              <option value=""></option>                
              <option value="Asphalt">Asphalt</option>
              <option value="Crushed Aggregate Base">Crushed Aggregate Base</option>
              <option value="Crushed Miscellaneous Base">Crushed Miscellaneous Base</option>
              <option value="Crushed Concrete">Crushed Concrete</option>
            </select>
          </div> : null

        }

        {isValidated.sampleType === 'plantMax' && isValidated.description !== '' ?

          <div>
            <label className='label'>Plant Density (pcf)</label>
            <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,3}(\.\d{1})?" name='plantDensity' onInput={validate} onChange={changedData} value={isValidated.optD} required />        
          </div> : null

        }

        {isValidated.sampleType === 'plantMax' && isValidated.description !== undefined && isValidated.description !== '' && isValidated.description !== 'Asphalt' ?

          <div>
            <label className='label'>Plant Moisutre (%)</label>
            <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1})?" name='plantMoisture' onInput={validate} onChange={changedData} value={isValidated.optM} required />        
          </div> : null

        }

        {isValidated.sampleType === 'plantMax' ?

          <div style={{textAlign: 'left'}}>
            <p>1. <u><b>Location</b></u> should say plant location, ie 'Irwindale Plant'</p>
            <p>2. <u><b>Represents</b></u> should explain where, ie 'Street subgrade'</p>
            <p>3. Have a <u><b>physical sample?</b></u> Log it separate!</p>
            <p>4. The physical sample '<u><b>Represents</b></u>' should match plant max '<u><b>Represents</b></u>'</p>
          </div> : null

        }

        <div>
          <label className='label'>Location</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='location' onInput={validate} onChange={changedData} value={isValidated.location} required />
        </div>

        <div>
          <label className='label'>Represents</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='represents' onInput={validate} onChange={changedData} placeholder={isValidated.sampleType === 'plantMax' ? 'Required' : 'Optional'} value={isValidated.represents} required />
        </div>

        {isValidated.sampleType !== 'plantMax' ?

          <div>
            <label className='label'>Purpose</label>
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='purpose' onInput={validate} onChange={changedData} value={isValidated.purpose} required />
          </div> : null
          
        }

        {isValidated.sampleType !== 'plantMax' ?
        
          <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

            {props.planMode !== undefined ? <div><CircleButton iconName='add_location' onClick={openLocate} /></div> : <small>Locate before input.</small>}

            {isValidated.n !== null && isValidated.n !== '' ? <p>N: {isValidated.n}</p> : null}
            {isValidated.e !== null && isValidated.e !== '' ? <p>E: {isValidated.e}</p> : null}

          </div> : <small>You selected Plant Max.</small>

        }

      </div>

    </div>
  )
  
  let content = (    
    <>
        {props.isModal.add || props.isModal.edit ? 
          <Modal 
            add={props.isModal.add ? addData : props.isModal.edit && isValidated.sampleType !== 'plantMax' ? editData : null}
            delete={props.isModal.edit && isValidated.sampleType !== 'plantMax' ? deleteData : null} 
            content={modalContent} 
            closeModal={close} 
            isValidated={isValidated} 
            isModal={isModal}
            zIndex={props.zIndex} 
          /> : null
        }        
        {isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} zIndex={props.zIndex} /> : null}
        
    </>     
    
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default LabInput
