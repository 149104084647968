import React, { useState} from 'react'

import Home from 'components/manage/Home'
import Errors from 'components/manage/AppErrors'
import Analytics from 'components/manage/Analytics'
import Permissions from 'components/manage/Permissions'
import Jobs from 'components/manage/Jobs'
import Maxes from 'components/manage/Maxes'
import Info from 'components/manage/Info'
import Issue from 'components/manage/Issue'
import Tests from 'components/manage/Tests'
import Lab from 'components/manage/Lab'
import Drawings from 'components/manage/Drawings'
import Plans from 'components/manage/Plans'
import Dailies from 'components/manage/Dailies'
import Concrete from 'components/manage/Concrete'
import Documents from 'components/manage/Documents'

import Users from 'components/manage/Users'
import Prefixes from 'components/manage/Prefixes'
import Tasks from 'components/manage/Tasks'
import Presets from 'components/manage/Presets'
import PresetTypes from 'components/manage/PresetTypes'
import Billing from 'components/manage/Billing'
import Nukes from 'components/manage/Nukes'
import DailyDescription from 'components/manage/DailyDescription'
import EquipDescription from 'components/manage/EquipDescription'

const Manage = (props) => {

  // the addition of component controls add in entry / manage

  return (
    <>
      {props.componentManage === 'Home' ? <Home  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Errors' ? <Errors  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Analytics' ? <Analytics  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Permissions' ? <Permissions  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Jobs' ? <Jobs user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Maxes' ? <Maxes user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Info' ? <Info user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Issue' ? <Issue user={props.user} filter={props.filter} component={props.component} planMode={'plansIssue'} /> : null}
      {props.componentManage === 'Tests' ? <Tests user={props.user} filter={props.filter} component={props.component} planMode={'plansTest'} selectNuke={props.selectNuke} /> : null}
      {props.componentManage === 'Lab' ? <Lab user={props.user} filter={props.filter} component={props.component} planMode={'plansLab'} /> : null}
      {props.componentManage === 'Drawings' ? <Drawings user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Dailies' ? <Dailies user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Documents' ? <Documents user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Concrete' ? <Concrete user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Users' ? <Users user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Plans' ? <Plans user={props.user} filter={props.filter} component={props.component} planMode={'plansmanage'} /> : null}
      {props.componentManage === 'Nukes' ? <Nukes user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'DailyDescription' ? <DailyDescription user={props.user} component={props.component} /> : null}
      {props.componentManage === 'EquipDescription' ? <EquipDescription user={props.user} component={props.component} /> : null}
      {props.componentManage === 'Billing' ? <Billing user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Prefixes' ? <Prefixes  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Tasks' ? <Tasks  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'Presets' ? <Presets  user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.componentManage === 'PresetTypes' ? <PresetTypes  user={props.user} filter={props.filter} component={props.component} /> : null}
    </>
  )

}

export default Manage
