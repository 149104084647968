import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError, exportToExcel, filterData, addActivity } from 'scripts/common'

const MyDailies = (props) => {

  const [fetchedDailies, setFetchedDailies] = useState([])
  const [fetchedMaterial, setFetchedMaterial] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true,
    sort: false, // false is by date, true is tech, date
    view: false // false is dailies and true is material
  })

  const [searchValue, setSearchValue] = useState('')

  const fileArray = useRef([])

  const fetchData = () => {

    fetch('/api/selectMyDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'My Dailies', props.user.username)

        setFetchedDailies(result)
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectMyDailies', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else {

      window.open(`dailies/daily${fetchedDailies[i].id}.pdf`)

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  //fileArray.current = [] // clear before pushing

  let listOfDailies = fetchedDailies.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let type = data.dailiestype === null ? '' : data.dailiestype
    let status = data.dailiesstatus === null ? '' : data.dailiesstatus
    let dailiesDate = data.dailiesdate === null ? '' : formatDateTime(data.dailiesdate, true)
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let task1 = data.taskcode1 === null ? '' : data.taskcode1
    let hour1 = data.hours1 === null ? '' : data.hours1
    let task2 = data.taskcode2 === null ? '' : data.taskcode2
    let hour2 = data.hours2 === null ? '' : data.hours2
    let task3 = data.taskcode3 === null ? '' : data.taskcode3
    let hour3 = data.hours3 === null ? '' : data.hours3
    let task4 = data.taskcode4 === null ? '' : data.taskcode4
    let hour4 = data.hours4 === null ? '' : data.hours4

    //  || entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    let filter = filterData(data, searchValue)

    if (filter) {

      //fileArray.current.push(`daily${data.id}.pdf`)

      return (
        <tr key={data.id.toString()} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td>{formatDateYMD(dailiesDate)}</td>
            {props.filter.jobNumber === '' ? <td>{jn} - {gd}</td> : null}
            <td>{status}</td>
            <td>{task1}</td>
            <td><b>{hour1}</b></td>
            <td>{task2}</td>
            <td><b>{hour2}</b></td>
            <td>{task3}</td>
            <td><b>{hour3}</b></td>
            <td>{task4}</td>
            <td><b>{hour4}</b></td>
        </tr>
      )
    }

  })

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  return (
    <>
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedDailies.length > 0 ?

              <>

                <small style={{margin: 10}}>{fetchedDailies.length} Total Records</small>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table id="toExcel">

                    <thead>
                      <tr>
                        <th>Date</th>
                        {props.filter.jobNumber === '' ? <th>JN</th> : null}
                        <th>Status</th>
                        <th>Task</th>
                        <th>Hours</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfDailies}
                    </tbody>

                  </table>

                </div>

              </> : null

            }

          </div>

        </div> : <p style={{margin: 10}}>Loading...</p>
      }
    </>
  )

}

export default MyDailies
