import React, {useState, useEffect, useRef} from 'react'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import Plans from 'components/main/Plans'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity, replaceStr } from 'scripts/common'

//import { addPending, selectData } from 'scripts/offline'

const IssueInput = (props) => {

  const [isModal, setIsModal] = useState({
    addAction: false,
    editAction: false,
    saving: false,
    warning: 0,
    warningContent: ''
  })

  const isChanged = useRef(false)
  const isChangedAction = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueDate: '',
    issueNo: null,
    description: '',
    resolved: 0,
    other: '',    
    n: '',
    e: ''
  })

  const [isValidatedAction, setIsValidatedAction] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueId: null,
    actionDate: '',
    description: ''
  })  

  useEffect(() => {

    if (props.isModal.add) {

      // if adding, temporary: dynamically assigned on post
      let issueNo = props.fetchedData.length < 1 ? 1 : Math.max(...props.fetchedData.map(o => o.issueNo)) + 1      

      setIsValidated(prevState => ({...prevState,        
        issueNo: issueNo,
        n: props.location === undefined ? '' : props.location.n,
        e: props.location === undefined ? '' : props.location.e      
      }))

    } else if (props.isModal.addAction) {

      

    } else if (props.isModal.edit) {

      let i = props.isModal.i

      //console.log(`data: ${JSON.stringify(props.fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: props.fetchedData[i].entryby,
        entrytime: props.fetchedData[i].entrytime,
        entrylat: props.fetchedData[i].entrylat,
        entrylng: props.fetchedData[i].entrylng,
        entrydevice: props.fetchedData[i].entrydevice,
        modby: props.fetchedData[i].modby,
        modtime: props.fetchedData[i].modtime,
        modlat: props.fetchedData[i].modlat,
        modlng: props.fetchedData[i].modlng,
        moddevice: props.fetchedData[i].moddevice,
        id: props.fetchedData[i].id,
        issueDate: props.fetchedData[i].issueDate,
        issueNo: props.fetchedData[i].issueNo,
        description: props.fetchedData[i].description,
        resolved: props.fetchedData[i].resolved,
        other: props.fetchedData[i].other,
        n: props.fetchedData[i].n,
        e: props.fetchedData[i].e
      }))

    } else if (props.isModal.editAction) {

      let i = props.isModal.i

      setIsValidatedAction(prevState => ({...prevState,
        entryby: props.fetchedAction[i].entryby,
        entrytime: props.fetchedAction[i].entrytime,
        entrylat: props.fetchedAction[i].entrylat,
        entrylng: props.fetchedAction[i].entrylng,
        entrydevice: props.fetchedAction[i].entrydevice,
        modby: props.fetchedAction[i].modby,
        modtime: props.fetchedAction[i].modtime,
        modlat: props.fetchedAction[i].modlat,
        modlng: props.fetchedAction[i].modlng,
        moddevice: props.fetchedAction[i].moddevice,
        id: props.fetchedAction[i].id,
        issueId: props.fetchedAction[i].issueId,
        actionDate: props.fetchedAction[i].actionDate,          
        description: props.fetchedAction[i].description          
      }))

    }
    
  }, [])

  //props.fetchedData, props.fetchedAction

  const clearIsValidatedAction = () => setIsValidatedAction({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueId: null,
    actionDate: '',
    description: ''
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'    

    setIsValidated(prevState => ({...prevState, [name]: state ? value : ''}))
  }

  const validateAction = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'    

    setIsValidatedAction(prevState => ({...prevState, [name]: state ? value : ''}))
  }

  const openActionAdd = () => setIsModal(prevState => ({...prevState, addAction: true}))

  const openActionEdit = (e) => {

    let tr = e.currentTarget
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')    

    setIsValidatedAction(prevState => ({...prevState,
      entryby: props.fetchedAction[i].entryby,
      entrytime: props.fetchedAction[i].entrytime,
      entrylat: props.fetchedAction[i].entrylat,
      entrylng: props.fetchedAction[i].entrylng,
      entrydevice: props.fetchedAction[i].entrydevice,
      modby: props.fetchedAction[i].modby,
      modtime: props.fetchedAction[i].modtime,
      modlat: props.fetchedAction[i].modlat,
      modlng: props.fetchedAction[i].modlng,
      moddevice: props.fetchedAction[i].moddevice,
      id: props.fetchedAction[i].id,
      issueId: props.fetchedAction[i].issueId,
      actionDate: props.fetchedAction[i].actionDate,          
      description: props.fetchedAction[i].description          
    }))

    setIsModal(prevState => ({...prevState, editAction: true}))

  }

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const closeAction = () => {

    if (isChangedAction.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        if (props.isModal.addAction || props.isModal.editAction) props.closeAction()
        
        if (isModal.addAction || isModal.editAction) {
          setIsModal(prevState => ({...prevState, addAction: false, editAction: false}))
        }
      }
    } else {
      if (props.isModal.addAction || props.isModal.editAction) props.closeAction()
      
      if (isModal.addAction || isModal.editAction) {
        setIsModal(prevState => ({...prevState, addAction: false, editAction: false}))
      }
    }

  }

  const changedData = () => isChanged.current = true
  
  const changedDataAction = () => isChangedAction.current = true  

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      n: n,
      e: e
    }))
  }

  const addData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addIssue', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,            
            issueDate: isValidated.issueDate,
            issueNo: isValidated.issueNo,
            description: replaceStr(isValidated.description),
            resolved: isValidated.resolved,
            other: replaceStr(isValidated.other),
            n: isValidated.n,
            e: isValidated.e
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

            props.updateFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                jobnumber: props.filter.jobNumber,
                gradeid: props.filter.gradeId,
                issueDate: isValidated.issueDate,
                issueNo: isValidated.issueNo,
                description: isValidated.description,
                resolved: isValidated.resolved,
                other: isValidated.other,
                n: isValidated.n,
                e: isValidated.e
              }, ...props.fetchedData]
            )
            
            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssue', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    if (props.user.issue < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
        alert("Please select an issue date.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please select a description.")
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");
    } else {

      // include optd and optm???

      if (props.user.bufferData) {

        // let id = Math.max(...fetchedData.map(o => o.id)) + 1

        // addPending({
        //   actionId: 1,
        //   action: 'addTest',
        //   table: 'Tests',
        //   jobnumber: props.filter.jobNumber,
        //   gradeid: props.filter.gradeId,
        //   entryby: props.user.username,
        //   entrytime: formatDateTime(new Date()),
        //   entrylat: '',
        //   entrylng: '',
        //   entrydevice: props.user.device,
        //   modby: '',
        //   modtime: null,
        //   modlat: '',
        //   modlng: '',
        //   moddevice: '',
        //   by: props.user.username,
        //   time: formatDateTime(new Date()),
        //   lat: '',
        //   lng: '',
        //   device: props.user.device,
        //   id: id,
        //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //   testdate: isValidated.testDate,
        //   pre: isValidated.pre,
        //   testno: isValidated.testNo,
        //   suf: isValidated.suf,
        //   testtype: isValidated.testType,
        //   north: isValidated.north,
        //   east: isValidated.east,
        //   elevd: isValidated.elevD,
        //   moistcont: isValidated.moistCont,
        //   drydens: isValidated.dryDens,
        //   maxId: isValidated.maxId,
        //   curve: isValidated.curve,
        //   optd: isValidated.optD,
        //   optm: isValidated.optM,
        //   reqcomp: isValidated.reqComp,
        //   relcomp: isValidated.relComp,
        //   passfail: isValidated.passFail,
        //   removed: isValidated.removed,
        //   showRemoved: isValidated.showRemoved
        // })
        // .then(
        //   (result) => {
        //     //console.log('result: ' + JSON.stringify(result))

        //     setFetchedData(prevState =>
        //       [
        //         {
        //           syncedID: false,
        //           entryby: props.user.username,
        //           entrytime: formatDateTime(new Date()),
        //           entrylat: '',
        //           entrylng: '',
        //           entrydevice: props.user.device,
        //           id: id,
        //           nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //           testdate: isValidated.testDate,
        //           testtype: isValidated.testType,
        //           pre: isValidated.pre,
        //           testno: isValidated.testNo,
        //           suf: isValidated.suf,
        //           testtype: isValidated.testType,
        //           north: isValidated.north,
        //           east: isValidated.east,
        //           elevd: isValidated.elevD,
        //           moistcont: isValidated.moistCont,
        //           drydens: isValidated.dryDens,
        //           maxId: isValidated.maxId,
        //           curve: isValidated.curve,
        //           optd: isValidated.optD,
        //           optm: isValidated.optM,
        //           reqcomp: isValidated.reqComp,
        //           relcomp: isValidated.relComp,
        //           passfail: isValidated.passFail,
        //           removed: isValidated.removed
        //         },
        //         ...prevState
        //       ]
        //     )

        //     isChanged.current = false
        //     closeModal()

        //   },
        //   (error) => {

        //     addToServer()
        //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestOffline', JSON.stringify(error), props.user.username, props.user.device)
        //   }
        // )

      } else {

        addToServer()

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editIssue', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            issueDate: isValidated.issueDate,
            issueNo: isValidated.issueNo,
            description: replaceStr(isValidated.description),
            resolved: isValidated.resolved,
            other: replaceStr(isValidated.other),
            n: isValidated.n,
            e: isValidated.e
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                issueDate: isValidated.issueDate,
                issueNo: isValidated.issueNo,
                description: isValidated.description,
                resolved: isValidated.resolved,
                other: isValidated.other,
                n: isValidated.n,
                e: isValidated.e
              } :
              data
            ))            

            
            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssue', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    // if (props.user.issue < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')      
    } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
        alert("Please select an issue date.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please select a description.")
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");    
    } else {

      if (props.user.bufferData) {

        // addPending({
        //   actionId: 2,
        //   action: 'editTest',
        //   table: 'Tests',
        //   jobnumber: props.filter.jobNumber,
        //   gradeid: props.filter.gradeId,
        //   entryby: props.user.username,
        //   entrytime: formatDateTime(new Date()),
        //   entrylat: '',
        //   entrylng: '',
        //   entrydevice: props.user.device,
        //   modby: '',
        //   modtime: null,
        //   modlat: '',
        //   modlng: '',
        //   moddevice: '',
        //   by: props.user.username,
        //   time: formatDateTime(new Date()),
        //   lat: '',
        //   lng: '',
        //   device: props.user.device,
        //   id: isValidated.id,
        //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //   testdate: isValidated.testDate,
        //   pre: isValidated.pre,
        //   testno: isValidated.testNo,
        //   suf: isValidated.suf,
        //   testtype: isValidated.testType,
        //   north: isValidated.north,
        //   east: isValidated.east,
        //   elevd: isValidated.elevD,
        //   moistcont: isValidated.moistCont,
        //   drydens: isValidated.dryDens,
        //   maxId: isValidated.maxId,
        //   curve: isValidated.curve,
        //   optd: isValidated.optD,
        //   optm: isValidated.optM,
        //   reqcomp: isValidated.reqComp,
        //   relcomp: isValidated.relComp,
        //   passfail: isValidated.passFail,
        //   removed: isValidated.removed,
        //   showRemoved: isValidated.showRemoved
        // })
        // .then(
        //   (result) => {
        //     //console.log('result: ' + JSON.stringify(result))

        //     setFetchedData(fetchedData.map(data =>
        //       data.id === isValidated.id ?
        //       {...data,
        //         modby: props.user.username,
        //         modtime: formatDateTime(new Date()),
        //         modlat: '',
        //         modlng: '',
        //         moddevice: props.user.device,
        //         testdate: isValidated.testDate,
        //         testtype: isValidated.testType,
        //         pre: isValidated.pre,
        //         testno: isValidated.testNo,
        //         suf: isValidated.suf,
        //         testtype: isValidated.testType,
        //         north: isValidated.north,
        //         east: isValidated.east,
        //         elevd: isValidated.elevD,
        //         moistcont: isValidated.moistCont,
        //         drydens: isValidated.dryDens,
        //         maxId: isValidated.maxId,
        //         curve: isValidated.curve,
        //         optd: isValidated.optD,
        //         optm: isValidated.optM,
        //         reqcomp: isValidated.reqComp,
        //         relcomp: isValidated.relComp,
        //         passfail: isValidated.passFail,
        //         removed: isValidated.removed
        //       } :
        //       data
        //     ))

        //     isChanged.current = false
        //     closeModal()

        //   },
        //   (error) => {

        //     addToServer()
        //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestOffline', JSON.stringify(error), props.user.username, props.user.device)
        //   }
        // )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteIssue', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))          

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)
          
          props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          close()

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssue', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          // addPending({
          //   actionId: 3,
          //   action: 'deleteTest',
          //   table: 'Tests',
          //   id: isValidated.id
          // })
          // .then(
          //   (result) => {
          //     //console.log('result: ' + JSON.stringify(result))

          //     //fetchData()
          //     setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          //     isChanged.current = false
          //     closeModal()
          //     //alert('Deleted.')

          //   },
          //   (error) => {

          //     addToServer()
          //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          //   }
          // )

        } else {

          addToServer()

        }

      }

    }

  }
  
  const addAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addIssueAction', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,                        
            actionDate: isValidatedAction.actionDate,
            issueId: isValidated.id,
            description: replaceStr(isValidatedAction.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

            //console.log(`actions: ${JSON.stringify(props.fetchedAction)}`)

            props.updateFetchedAction(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                actionDate: isValidatedAction.actionDate,
                issueId: isValidated.id,
                description: isValidatedAction.description
              }, ...props.fetchedAction]
            )
            
            clearIsValidatedAction()
            isChangedAction.current = false
            closeAction()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssueAction', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    if (props.user.issue < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
        alert("Please select an action date.")
    } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
      alert("Please select a description.")
    } else {

      addToServer()

    }

  }

  const editAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editIssueAction', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,            
            device: props.user.device,
            id: isValidatedAction.id,
            actionDate: isValidatedAction.actionDate,            
            description: replaceStr(isValidatedAction.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, `edit issue action`, `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)


            props.updateFetchedAction(props.fetchedAction.map(data =>
              data.id === isValidatedAction.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                actionDate: isValidatedAction.actionDate,                
                description: isValidatedAction.description
              } :
              data
            ))
            
            clearIsValidatedAction()
            isChangedAction.current = false
            closeAction()            

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssueAction', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }
    
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChangedAction.current === false) {
      alert('Nothing has been changed.')      
    } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
        alert("Please select an action date.")
    } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
      alert("Please select a description.")
    } else {

      addToServer()

    }

  }

  const deleteAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteIssueAction', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidatedAction.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))          

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

          //fetchData()
          props.updateFetchedAction(props.fetchedAction.filter(data => data.id !== isValidatedAction.id))
          clearIsValidatedAction()
          isChangedAction.current = false
          closeAction()

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssueAction', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) addToServer()        

    }

  } 

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))  

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  let listOfActions = null
  let actions = null

  if (isValidated.id !== null) {

    actions = props.fetchedAction.map((data, i) => {
        
      if (isValidated.id === data.issueId) {

        return (
          <>
            <tr key={data.id.toString()} data-i={i} onClick={openActionEdit}>            
              <td style={{textAlign: 'left'}}>{data.description} - <span style={{color: 'dodgerblue'}}>{data.entryby}</span> <span style={{color: 'gray'}}>{data.actionDate}</span></td>          
            </tr>                
          </>
        )

      }

    })

    listOfActions = (
      <table style={{display: 'inline-block'}}>

        <thead>
          <tr>            
            <th style={{textAlign: 'center'}}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {actions}
        </tbody>

      </table>
    )

  }

  let modalContent = (
    <div>

      {props.isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{textAlign: 'center'}}>

        {isValidated.resolved == 2 ?

          <div>
            <label className='label'>Resolved?</label>
            <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' value={isValidated.other} disabled />
          </div> :

          <div>
            <label className='label'>Resolved?</label>
            <select className='select' pattern=".{1,}" name='resolved' onInput={validate} onChange={changedData} value={isValidated.resolved} required>
              <option value={0}>No</option>
              <option value={1}>Yes</option>            
            </select>
          </div>

        }

        <div>
          <label className='label'>Issue Date</label>
          <input className='input' type="date" pattern=".{1,}" name='issueDate' onInput={validate} onChange={changedData} value={isValidated.issueDate === '' || isValidated.issueDate === null ? null : isValidated.issueDate} required />
        </div>

        <div>
          <label className='label'>Issue No</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.issueNo} disabled />
        </div>        

        <div>
          <label className='label'>Descrip</label>
          <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} value={isValidated.description} required />
        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          {props.planMode !== undefined ? <div><CircleButton iconName='add_location' onClick={openLocate} /></div> : <small>Locate before input.</small>}

          {isValidated.n !== null && isValidated.n !== '' ? <p>N: {isValidated.n}</p> : null}
          {isValidated.e !== null && isValidated.e !== '' ? <p>E: {isValidated.e}</p> : null}

        </div>

        {isValidated.resolved === 2 || props.isModal.add ? null : <CircleButton iconName='maps_ugc' onClick={props.openActionAdd} />}

        <div style={{textAlign: 'center'}}>          

          {listOfActions}

        </div>        

      </div>

    </div>
  )

  let actionContent = (
    <div>

      <div style={{textAlign: 'center'}}>        

        <div>
          <label className='label'>Action Date</label>
          <input className='input' type="date" pattern=".{1,}" name='actionDate' onInput={validateAction} onChange={changedDataAction} value={isValidatedAction.actionDate === '' || isValidatedAction.actionDate === null ? null : isValidatedAction.actionDate} required />
        </div>        

        <div>
          <label className='label'>Descrip</label>
          <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validateAction} onChange={changedDataAction} value={isValidatedAction.description} required />
        </div>        

      </div>

    </div>
  )

  let content = (    
    <>
        {props.isModal.add || props.isModal.edit ? 
          <Modal 
            add={isValidated.resolved === 2 ? null : props.isModal.add ? addData : props.isModal.edit ? editData : null}
            delete={isValidated.resolved === 2 ? null : props.isModal.edit ? deleteData : null} 
            content={modalContent} 
            closeModal={close} 
            isValidated={isValidated} 
            isModal={isModal}
            zIndex={props.zIndex} 
          /> : null
        }
        {props.isModal.addAction || props.isModal.editAction || isModal.addAction || isModal.editAction ? 
          <Modal 
            add={isValidated.resolved === 2 ? null : props.isModal.addAction || isModal.addAction ? addAction : props.isModal.editAction || isModal.editAction ? editAction : null} 
            delete={isValidated.resolved === 2 ? null  : props.isModal.editAction || isModal.editAction ? deleteAction : null} 
            content={actionContent} 
            closeModal={closeAction} 
            isValidated={isValidatedAction} 
            isModal={isModal}
            zIndex={props.zIndex} 
          /> : null
        }        
        {isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} zIndex={props.zIndex} /> : null}
        
    </>     
    
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default IssueInput
