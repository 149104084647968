import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import LabInput from 'components/input/LabInput'

import { formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline';

const Lab = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    loading: true,
    add: false,
    edit: false    
  })
  // warning: 0,
  // warningContent: '',
  // loading: true,
  // saving: false

  const [isDeliver, setIsDeliver] = useState(false)
  const [searchValue, setSearchValue] = useState('')  

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Lab').then(res => {
        setFetchedData(res.sort((a, b) => Number(b.sampleno) - Number(a.sampleno)))
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectLab', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          //setFetchedData(result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map(data => (
            {...data,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              sampledate: formatDateYMD(data.sampledate),
              deliveredtime: formatDateTime(data.deliveredtime)
            }
          )))

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectLab', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }  

  useEffect(() => {
    if (
      (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
      (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    ) fetchData()
  }, [props.filter])  

  const selectRow = (e) => {

    let tr = e.currentTarget
    //console.log(`target: ${target.nodeName}`)    
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    // let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent

    // if (fetchedData[i].syncedID === false) {
    //   alert('ID not synced. Please refresh the data.')
    // } else 
    if (isDeliver && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      updateDelivered(e)
    } else if (!isDeliver && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      showDeliveredInfo(e)
    } else if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      // // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      // let today = new Date()
      // let entry = new Date(fetchedData[i].entrytime)
      // let timeDiff = Math.abs(entry.getTime() - today.getTime())
      // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      // if (props.user.lab < 2) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      // } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username !== fetchedData[i].entryby) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      // } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      // } else if (props.user.lab < 5 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

      //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: Sample is ${fetchedData[i].status}. Contact an Admin.`}))

      // } else if (props.user.lab === 5 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

      //   alert(`Sample is ${fetchedData[i].status}. Sampled data will be lost if deleted!`)

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: `Sample is ${fetchedData[i].status}`}))

      // } else {

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      // }

      // //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      // setIsValidated(prevState => ({...prevState,
      //   entryby: fetchedData[i].entryby,
      //   entrytime: fetchedData[i].entrytime,
      //   entrylat: fetchedData[i].entrylat,
      //   entrylng: fetchedData[i].entrylng,
      //   entrydevice: fetchedData[i].entrydevice,
      //   modby: fetchedData[i].modby,
      //   modtime: fetchedData[i].modtime,
      //   modlat: fetchedData[i].modlat,
      //   modlng: fetchedData[i].modlng,
      //   moddevice: fetchedData[i].moddevice,
      //   id: fetchedData[i].id,
      //   sampleDate: fetchedData[i].sampledate,
      //   sampleNo: fetchedData[i].sampleno,
      //   location: fetchedData[i].location,
      //   represents: fetchedData[i].represents,
      //   purpose: fetchedData[i].purpose,
      //   sampleType: fetchedData[i].sampletype,
      //   n: fetchedData[i].n,
      //   e: fetchedData[i].e,
      //   status: fetchedData[i].status,
      //   finalDialReading: fetchedData[i].finalDialReading,
      //   initialDialReading: fetchedData[i].initialDialReading,
      //   ei: fetchedData[i].ei,
      //   dilution1000: fetchedData[i].dilution1000,
      //   dilution100: fetchedData[i].dilution100,
      //   dilution10: fetchedData[i].dilution10,
      //   dilution0: fetchedData[i].dilution0,
      //   so4: fetchedData[i].so4,
      //   curve: fetchedData[i].curve,
      //   deliveredby: fetchedData[i].deliveredby,
      //   deliveredtime: fetchedData[i].deliveredtime,
      // }))
      openEdit(i)

    }

  }

  const viewDelivered = () => setIsDeliver(isDeliver ? false : true)

  const updateDelivered = (e) => {

    let tr = e.currentTarget
    //console.log(`target: ${target.nodeName}`)    
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      let status = fetchedData[i].status
      let deliveredby = fetchedData[i].deliveredby
      let username = props.user.username
      let userlevel = props.user.userlevel

      const deliver = (update) => {

        const addToServer = () => {

          setIsModal(prevState => ({...prevState, saving: true}))

          getLocation(function(latlng){

            fetch('/api/updateDelivered', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                by: props.user.username,
                time: formatDateTime(new Date()),
                lat: latlng.lat,
                lng: latlng.lng,
                id: fetchedData[i].id,
                update: update
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + JSON.stringify(result))

                let description = `SN ${fetchedData[i].sampleno}, ${fetchedData[i].location}`

                addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delivered', description, props.user.username)

                fetchData()

                setIsModal(prevState => ({...prevState, saving: false}))

              },
              (error) => {
                setIsModal(prevState => ({...prevState, saving: false}))
                alert('Error: could not update delivered status. Contact and admin.')
                catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateDelivered', JSON.stringify(error), props.user.username, props.user.device)
              }
            )

          })

        }

        if (props.user.bufferData) {

          let status = ''
          let deliveredby = ''
          let deliveredtime = ''

          switch (update) {
            case 'delivered':
            case 'adddelivered':
              status = 'delivered'
              deliveredby = props.user.username
              deliveredtime = formatDateTime(new Date())
              break
            case 'sampled':
            case 'undelivered':
              status = 'sampled'
              deliveredby = ''
              deliveredtime = ''
              break
            default:
              console.log('not found')
          }

          addPending({
            actionId: 2,
            action: 'updateDelivered',
            table: 'Lab',
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            entryby: props.user.username,
            entrytime: formatDateTime(new Date()),
            entrylat: '',
            entrylng: '',
            entrydevice: props.user.device,
            modby: '',
            modtime: null,
            modlat: '',
            modlng: '',
            moddevice: '',
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: '',
            lng: '',
            device: props.user.device,
            id: fetchedData[i].id,
            sampledate: fetchedData[i].sampledate,
            sampleno: fetchedData[i].sampleno,
            location: fetchedData[i].location,
            represents: fetchedData[i].represents,
            purpose: fetchedData[i].purpose,
            sampletype: fetchedData[i].sampletype,
            n: fetchedData[i].n,
            e: fetchedData[i].e,
            status: fetchedData[i].status,
            finalDialReading: fetchedData[i].finalDialReading,
            initialDialReading: fetchedData[i].initialDialReading,
            ei: fetchedData[i].ei,
            dilution1000: fetchedData[i].dilution1000,
            dilution100: fetchedData[i].dilution100,
            dilution10: fetchedData[i].dilution10,
            dilution0: fetchedData[i].dilution0,
            so4: fetchedData[i].so4,
            curve: fetchedData[i].curve,
            deliveredby: fetchedData[i].deliveredby,
            deliveredtime: fetchedData[i].deliveredtime,
            update: update
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              setFetchedData(fetchedData.map(data =>
                data.id === fetchedData[i].id ?
                {...data,
                  modby: props.user.username,
                  modtime: formatDateTime(new Date()),
                  moddevice: props.user.device,
                  sampledate: fetchedData[i].sampledate,
                  sampleno: fetchedData[i].sampleno,
                  location: fetchedData[i].location,
                  represents: fetchedData[i].represents,
                  purpose: fetchedData[i].purpose,
                  sampletype: fetchedData[i].sampletype,
                  n: fetchedData[i].n,
                  e: fetchedData[i].e,
                  status: status,
                  finalDialReading: fetchedData[i].finalDialReading,
                  initialDialReading: fetchedData[i].initialDialReading,
                  ei: fetchedData[i].ei,
                  dilution1000: fetchedData[i].dilution1000,
                  dilution100: fetchedData[i].dilution100,
                  dilution10: fetchedData[i].dilution10,
                  dilution0: fetchedData[i].dilution0,
                  so4: fetchedData[i].so4,
                  curve: fetchedData[i].curve,
                  deliveredby: deliveredby,
                  deliveredtime: deliveredtime
                } :
                data
              ))

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateDeliveredOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

      if (status === 'sampled') {

          deliver('delivered');

      } else if (status === 'delivered' ) {

        if (username === deliveredby || userlevel === 'manager' || userlevel === 'admin') {

          if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
            deliver('sampled');
          }

        } else {

          alert('This sample was delivered by ' + deliveredby + '. Contact ' + deliveredby + ' to change.');

        }

      } else if (status === 'archive' || status === 'dispose') {

        if (deliveredby === null || deliveredby === '') {

          deliver('adddelivered');

        } else if (deliveredby !== null && deliveredby !== '') {

          if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
            deliver('undelivered');
          }

        } else {
          alert('Error: deliveredby? ' + deliveredby + '. Contact an admin to change.');
        }

      } else if (status === 'archived' || status === 'disposed') {

        if (userlevel !== 'admin') {

          alert('This sample has been marked ' + status + '. Contact an admin to change.');

        } else if (userlevel === 'admin') {

          if (deliveredby === null || deliveredby === '') {

            deliver('adddelivered');

          } else if (deliveredby !== null && deliveredby !== '') {

            if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
              deliver('undelivered');
            }

          } else {
            alert('Error: deliveredby? ' + deliveredby + '. Contact an admin to change.');
          }

        }

      } else {

        alert('This sample has been marked ' + status + '. Contact an admin to change.');

      }

    }

  }

  const showDeliveredInfo = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target : e.target.childNode
    let i = parseInt(tr.getAttribute('data-i'))

    console.log(`i: ${e.target.nodeName}`)

    alert(`
    ${fetchedData[i].entrytime !== null && fetchedData[i].entrytime !== '' ?
    'Sampled by: ' + fetchedData[i].entryby + ' @ ' + fetchedData[i].entrytime + '\n' : 'Not sampled\n'}

    ${fetchedData[i].deliveredtime !== null && fetchedData[i].deliveredtime !== '' ?
    'Delivered by: ' + fetchedData[i].deliveredby + ' @ ' + fetchedData[i].deliveredtime : 'Not delivered'}
    `)
  }  

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.lab < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))
  
  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))  

  const updateFetchedData = (data) => setFetchedData(data)  

  let listOfData = fetchedData.map((data, i) => {

    let sampleNo = data.sampleno === null ? '' : data.sampleno
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let purpose = data.purpose === null ? '' : data.purpose
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {      

      let curve, ei, so4, se

      let moisture = data.optm === null ? '' : ` @ ${data.optm}%`

      curve = data.curve === null || data.curve === '' ? '' : `${data.curve} - ${data.optd}${moisture}`

      if (data.ei !== null && data.ei !== '') {

        ei = data.ei < 21 ? `Very Low` : data.ei < 51 ? 'Low' : data.ei < 91 ? 'Medium' : data.ei < 131 ? 'High' : 'Very High'
        ei += `: ${data.ei}`

      } else if (data.finalDialReading !== null && data.finalDialReading !== '' && data.initialDialReading !== null && data.initialDialReading !== '') {

        let value = Math.round((data.finalDialReading - data.initialDialReading) * 1000)
        ei = value < 21 ? `Very Low` : value < 51 ? 'Low' : value < 91 ? 'Medium' : value < 131 ? 'High' : 'Very High'
        ei += `: ${value}`

      } else {

        ei = ''

      }

      if (data.dilution1000 !== null && data.dilution1000 !== '') {

        let value = data.dilution1000
        so4 = value < 0.1 ? 'Negligible' : value < 0.2 ? 'Moderate' : value < 2 ? 'Severe' : 'Very Severe'
        so4 += `: ${value}`

      } else if (data.dilution100 !== null && data.dilution100 !== '') {

        let value = data.dilution100/10
        so4 = value < 0.1 ? 'Negligible' : value < 0.2 ? 'Moderate' : value < 2 ? 'Severe' : 'Very Severe'
        so4 += `: ${value}`

      } else if (data.dilution10 !== null && data.dilution10 !== '') {

        let value = data.dilution10/100
        so4 = value < 0.1 ? 'Negligible' : value < 0.2 ? 'Moderate' : value < 2 ? 'Severe' : 'Very Severe'
        so4 += `: ${value}`

      } else if (data.dilution0 !== null && data.dilution0 !== '') {

        let value = data.dilution0/1000
        so4 = value < 0.1 ? 'Negligible' : value < 0.2 ? 'Moderate' : value < 2 ? 'Severe' : 'Very Severe'
        so4 += `: ${value}`

      } else if (data.so4 !== null && data.so4 !== '') {

        so4 = data.so4 < 0.1 ? 'Negligible' : data.so4 < 0.2 ? 'Moderate' : data.so4 < 2 ? 'Severe' : 'Very Severe'
        so4 += `: ${data.so4}`

      } else {

        so4 = ''

      }

      if (data.se !== null && data.se !== '') {

        se = data.se

      } else if (data.sand1 !== null && data.sand1 !== '' && data.clay1 !== null && data.clay1 !== '' && data.sand2 !== null && data.sand2 !== '' && data.clay2 !== null && data.clay2 !== '' && data.sand3 !== null && data.sand3 !== '' && data.clay3 !== null && data.clay3 !== '') {

        let se1 = Math.ceil((data.sand1 / data.clay1) * 100)
        let se2 = Math.ceil((data.sand2 / data.clay2) * 100)
        let se3 = Math.ceil((data.sand3 / data.clay3) * 100)

        se = Math.ceil((se1 + se2 + se3) / 3)

      } else {

        se = ''

      }

      return (
        <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={selectRow}>          
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          {isDeliver ?
            <td><Icon name={data.deliveredtime !== '' && data.deliveredtime !== null ? 'check_circle' : 'panorama_fish_eye'} color={data.deliveredtime !== '' && data.deliveredtime !== null ? 'dodgerblue' : 'tomato'} id={data.id} /></td> :
            <td><Icon name='local_shipping' color={data.deliveredtime !== '' && data.deliveredtime !== null ? 'dodgerblue' : 'tomato'} i={i} /></td>
          }
          <td>{data.sampledate}</td>
          <td>{entryBy}</td>
          <td>{sampleNo}</td>
          <td className='wrapText'>{location}</td>
          <td className='wrapText'>{represents}</td>
          <td className='wrapText'>{purpose}</td>
          <td>{sampleType}</td>
          {!isDeliver && !isModal.google ?
            <>
              <td className='wrapText'>{description}</td>
              <td className='wrapText'>{color}</td>
              <td>{curve}</td>
              <td>{ei}</td>
              <td>{so4}</td>
              <td>{se}</td>
            </> : null
          }
        </tr>
      )

    }

  })

  let content = (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>
          {isModal.add || isModal.edit ?
            <LabInput                
              close={closeModal} 
              fetchedData={fetchedData}
              updateFetchedData={updateFetchedData}              
              isModal={isModal}
              user={props.user}
              filter={props.filter}
              planMode={props.planMode}
              component={props.component}
            /> : null
          }          
          {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>

                  {props.user.userlevel === 'guest' || props.user.lab < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                  {props.user.userlevel === 'guest' || props.user.lab < 2 ? null : <Icon name='local_shipping' color={isDeliver ? 'dodgerblue' : 'gray'} onClick={viewDelivered} />}
                  <Icon name='refresh' onClick={fetchData} />

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                {fetchedData.length > 0 ?

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          {isModal.google ? <th></th> : null}
                          <th>{isDeliver ? 'Delivered?' : ''}</th>
                          {(props.filter.jobNumber === null || props.filter.jobNumber === '') && props.component === 'Manage' ? <th>JN</th> : null}
                          <th>Date</th>
                          <th>Tech</th>
                          <th>SN</th>
                          <th>Location</th>
                          <th>Represents</th>
                          <th>Purpose</th>
                          <th>Type</th>
                          {!isDeliver && !isModal.google ?
                            <>
                              <th>Desc.</th>
                              <th>Color</th>
                              <th>Max</th>
                              <th>EI</th>
                              <th>SO4</th>
                              <th>SE</th>
                            </> : null
                          }
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div> :
                  <p style={{margin: 10}}>No lab found.</p>

                }

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )

  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content

}

export default Lab
