import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Locate from 'components/menu/Locate'
import Calendar from 'components/menu/Calendar'
import MaterialBreaks from 'components/input/MaterialBreaks'

import { formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, exportToExcel, addActivity } from 'scripts/common'

import { breakResultCyl } from 'scripts/material';

import { pdfMaterial } from 'scripts/pdf'

import { addPending, selectData } from 'scripts/offline';

const Concrete = (props) => {

  const forDownload = useRef([])
  // const googleRef = useRef(null)
  // const mapRef = useRef(null)
  // const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])

  const [reportDetails, setReportDetails] = useState({
    reportDate: '',
    tableName: '',
    page: ''
  })

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    locate: false,
    warning: 0,
    warningContent: '',
    loading: true,
    calendar: false,
    delivered: false,
    preview: false
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    castDate: '',
    timeCast: '',
    fieldMarking: '',
    represents: '',
    location: '',
    structureType: '',
    otherStructure: '',
    reinforcementType: '',
    materialType: '',
    specimenType: '',
    sampleType: '',
    mixNo: '',
    concreteSupplier: '',
    batchPlantLocation: '',
    deliveryTicketNo: '',
    slumpMeasured: '',
    waterAdded: '',
    airContent: '',
    concreteTemp: '',
    airTemp: '',
    designStrength: '',
    designStrengthDay: '28',
    age1: '',
    age2: '',
    age3: '',
    age4: '',
    age5: '',
    age6: '',
    latitude: '',
    longitude: '',
    notes: '',
    maxLoad1: '',
    maxLoad2: '',
    maxLoad3: '',
    maxLoad4: '',
    maxLoad5: '',
    maxLoad6: '',
    breakType1: '',
    breakType2: '',
    breakType3: '',
    breakType4: '',
    breakType5: '',
    breakType6: '',
    length1: '',
    length2: '',
    length3: '',
    length4: '',
    length5: '',
    length6: '',
    diameter: '',
    status: '',
    sentOutCompany: '',
    sentOutDate: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    castDate: '',
    timeCast: '',
    fieldMarking: '',
    represents: '',
    location: '',
    structureType: '',
    otherStructure: '',
    reinforcementType: '',
    materialType: '',
    specimenType: '',
    sampleType: '',
    mixNo: '',
    concreteSupplier: '',
    batchPlantLocation: '',
    deliveryTicketNo: '',
    slumpMeasured: '',
    waterAdded: '',
    airContent: '',
    concreteTemp: '',
    airTemp: '',
    designStrength: '',
    designStrengthDay: '',
    age1: '',
    age2: '',
    age3: '',
    age4: '',
    age5: '',
    age6: '',
    latitude: '',
    longitude: '',
    notes: '',
    maxLoad1: '',
    maxLoad2: '',
    maxLoad3: '',
    maxLoad4: '',
    maxLoad5: '',
    maxLoad6: '',
    breakType1: '',
    breakType2: '',
    breakType3: '',
    breakType4: '',
    breakType5: '',
    breakType6: '',
    length1: '',
    length2: '',
    length3: '',
    length4: '',
    length5: '',
    length6: '',
    diameter: '',
    status: '',
    sentOutCompany: '',
    sentOutDate: ''
  })

  const fetchData = () => {

    if (props.filter.offline && props.user.offline === props.filter.jobNumber) {

      selectData('Concrete').then(res => {
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectManageConcrete', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          //setFetchedData(result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'Material', props.user.username)

          setFetchedData(result.map(data => {

            let material = breakResultCyl(data)

            //console.log(`material: ${JSON.stringify(material)}`)

            return {...data,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              castDate: formatDateYMD(data.castDate),
              strength1: material.strength1,
              strength2: material.strength2,
              strength3: material.strength3,
              strength4: material.strength4,
              strength5: material.strength5,
              strength6: material.strength6,
              percent1: material.percent1,
              percent2: material.percent2,
              percent3: material.percent3,
              percent4: material.percent4,
              percent5: material.percent5,
              percent6: material.percent6
            }
          }))

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, '', props.component, 'selectManageConcrete', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : ''}))
  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (isModal.delivered  && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      updateDelivered(e)
    } else if (!isModal.delivered  && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      showDeliveredInfo(e)
    } else if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      let today = new Date()
      let entry = new Date(fetchedData[i].entrytime)
      let timeDiff = Math.abs(entry.getTime() - today.getTime())
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      if (props.user.concrete < 2) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username !== fetchedData[i].entryby) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      } else if (props.user.concrete === 4) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      } else {

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      }

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(fetchedData[i])

      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const updateReportDetails = (event) => {
    let name = event.target.getAttribute('name')
    let value = event.target.value

    setReportDetails(prevState => ({...prevState, [name]: value}))
  }

  const updateDelivered = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target : e.target.childNode
    let i = parseInt(tr.getAttribute('data-i'))
    let status = (fetchedData[i].deliveredtime !== '' && fetchedData[i].deliveredtime !== null) || fetchedData[i].entrytime === '' || fetchedData[i].entrytime === null ? true : false

    const deliver = () => {

      getLocation(function(latlng){

        fetch('/api/deliverMaterial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: fetchedData[i].id,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            status: status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', fetchedData[i].jobNumber, '', props.component, 'deliver', `Material Cast: ${fetchedData[i].castDate}`, props.user.username)

            fetchData() // i need the id if edited

          },
          (error) => {

            alert('Error: could not add Cconcrete. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'addConcrete', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else if (status && window.confirm('Mark the sample as Un-Delivered?')) {
      deliver()
    } else {
      deliver()
    }

  }

  const showDeliveredInfo = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target : e.target.childNode
    let i = parseInt(tr.getAttribute('data-i'))

    alert(`
    ${fetchedData[i].entrytime !== null && fetchedData[i].entrytime !== '' ?
    'Sampled by: ' + fetchedData[i].entryby + ' @ ' + fetchedData[i].entrytime + '\n' : 'Not sampled\n'}

    ${fetchedData[i].deliveredtime !== null && fetchedData[i].deliveredtime !== '' ?
    'Delivered by: ' + fetchedData[i].deliveredby + ' @ ' + fetchedData[i].deliveredtime : 'Not delivered'}
    `)
  }

  const selectLocate = (lat,lng) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      latitude: lat,
      longitude: lng
    }))

    closeLocate()
  }

  const addData = () => {

    // OFFLINE not done

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/addConcrete', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: props.filter.jobNumber,
            castDate: isValidated.castDate,
            timeCast: isValidated.timeCast,
            fieldMarking: isValidated.fieldMarking,
            represents: replaceStr(isValidated.represents),
            location: replaceStr(isValidated.location),
            structureType: isValidated.structureType,
            otherStructure: replaceStr(isValidated.otherStructure),
            reinforcementType: isValidated.reinforcementType,
            materialType: isValidated.materialType,
            specimenType: isValidated.specimenType,
            sampleType: isValidated.sampleType,
            mixNo: isValidated.mixNo,
            concreteSupplier: replaceStr(isValidated.concreteSupplier),
            batchPlantLocation: isValidated.batchPlantLocation,
            deliveryTicketNo: isValidated.deliveryTicketNo,
            slumpMeasured: isValidated.slumpMeasured,
            waterAdded: isValidated.waterAdded,
            airContent: isValidated.airContent,
            concreteTemp: isValidated.concreteTemp,
            airTemp: isValidated.airTemp,
            designStrength: isValidated.designStrength,
            designStrengthDay: isValidated.designStrengthDay,
            age1: isValidated.age1,
            age2: isValidated.age2,
            age3: isValidated.age3,
            age4: isValidated.age4,
            age5: isValidated.age5,
            age6: isValidated.age6,
            notes: replaceStr(isValidated.notes),
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            status: isValidated.status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, '', props.component, 'add', `Material Cast: ${isValidated.castDate}`, props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add Cconcrete. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'addConcrete', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.concrete < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (props.filter.jobNumber !== null && props.filter.jobNumber !== '') {
      alert("Please select a Job Number.");
    } else if (isValidated.castDate === null || isValidated.castDate === '') {
      alert("Please select a Cast Date.");
    } else if (isValidated.timeCast === null || isValidated.timeCast === '') {
      alert("Please select a Cast Time.");
    } else if (isValidated.fieldMarking === null || isValidated.fieldMarking === '') {
      alert("Please provide a Set No.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please provide an Area of Work.");
    } else if (isValidated.location === null || isValidated.location === '') {
      alert("Please provide a Sample Location.");
    } else if (isValidated.structureType === null || isValidated.structureType === '') {
      alert("Please provide a Structure Type.");
    } else if (isValidated.structureType === 'other' && (isValidated.otherStructure === null || isValidated.otherStructure === '')) {
      alert("Please specify other structure.");
    } else if (isValidated.reinforcementType === null || isValidated.reinforcementType === '') {
      alert("Please provide a Reinforcement Type.");
    } else if (isValidated.materialType === null || isValidated.materialType === '') {
      alert("Please provide a Material Type.");
    } else if (isValidated.specimenType === null || isValidated.specimenType === '') {
      alert("Please provide a Specimen Type.");
    } else if (isValidated.sampleType === null || isValidated.sampleType === '') {
      alert("Please provide a Sample Type.");
    } else if (isValidated.concreteSupplier === null || isValidated.concreteSupplier === '') {
      alert("Please provide a Concrete Supplier.");
    } else if (isValidated.batchPlantLocation === null || isValidated.batchPlantLocation === '') {
      alert("Please provide a Batch Plant Location.");
    } else if (isValidated.deliveryTicketNo === null || isValidated.deliveryTicketNo === '') {
      alert("Please provide a Delivery Ticket No.");
    } else if (isValidated.mixNo === null || isValidated.mixNo === '') {
      alert("Please provide a Mix No.");
    } else if (isValidated.slumpMeasured === null || isValidated.slumpMeasured === '') {
      alert("Please provide a Measured Slump.");
    } else if (isValidated.waterAdded === null || isValidated.waterAdded === '') {
      alert("Please provide Water Added.");
    // } else if (isValidated.airContent === null || isValidated.airContent === '') {
    //   alert("Please provide an Air Content.");
    } else if (isValidated.concreteTemp === null || isValidated.concreteTemp === '') {
      alert("Please provide a Concrete Temp.");
    } else if (isValidated.airTemp === null || isValidated.airTemp === '') {
      alert("Please provide an Air Temp.");
    } else if (isValidated.designStrength === null || isValidated.designStrength === '') {
      alert("Please provide a Design Strength.");
    } else if (isValidated.designStrengthDay === null || isValidated.designStrengthDay === '') {
      alert("Please provide a Design Strenth @ Day.");
    } else if (isValidated.age1 === null || isValidated.age1 === '') {
      alert("Please provide at least one age for breaking.");
    } else {

      if (props.filter.offline) {

        let id = Math.max(...fetchedData.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'addConcrete',
          table: 'Concrete',
          jobnumber: props.filter.jobNumber,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(prevState =>
              [
                {
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  jobNumber: props.filter.jobNumber,
                  castDate: isValidated.castDate,
                  location: replaceStr(isValidated.location),
                  materialType: isValidated.materialType,
                  specimenType: isValidated.specimenType,
                  sampleType: isValidated.sampleType,
                  diameter: isValidated.diameter,
                  designStrength: isValidated.designStrength,
                  slumpMeasured: isValidated.slumpMeasured,
                  mixNo: isValidated.mixNo,
                  concreteSupplier: isValidated.concreteSupplier,
                  batchPlantLocation: isValidated.batchPlantLocation,
                  deliveryTicketNo: isValidated.deliveryTicketNo,
                  timeCast: isValidated.timeCast,
                  waterAdded: isValidated.waterAdded,
                  airContent: isValidated.airContent,
                  concreteTemp: isValidated.concreteTemp,
                  airTemp: isValidated.airTemp,
                  notes: isValidated.notes
                },
                ...prevState
              ]
            )

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/editConcreteManage', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            castDate: isValidated.castDate,
            timeCast: isValidated.timeCast,
            fieldMarking: isValidated.fieldMarking,
            represents: replaceStr(isValidated.represents),
            location: replaceStr(isValidated.location),
            structureType: isValidated.structureType,
            otherStructure: replaceStr(isValidated.otherStructure),
            reinforcementType: isValidated.reinforcementType,
            materialType: isValidated.materialType,
            specimenType: isValidated.specimenType,
            sampleType: isValidated.sampleType,
            mixNo: isValidated.mixNo,
            concreteSupplier: replaceStr(isValidated.concreteSupplier),
            batchPlantLocation: isValidated.batchPlantLocation,
            deliveryTicketNo: isValidated.deliveryTicketNo,
            slumpMeasured: isValidated.slumpMeasured,
            waterAdded: isValidated.waterAdded,
            airContent: isValidated.airContent,
            concreteTemp: isValidated.concreteTemp,
            airTemp: isValidated.airTemp,
            designStrength: isValidated.designStrength,
            designStrengthDay: isValidated.designStrengthDay,
            age1: isValidated.age1,
            age2: isValidated.age2,
            age3: isValidated.age3,
            age4: isValidated.age4,
            age5: isValidated.age5,
            age6: isValidated.age6,
            notes: replaceStr(isValidated.notes),
            maxLoad1: isValidated.maxLoad1,
            maxLoad2: isValidated.maxLoad2,
            maxLoad3: isValidated.maxLoad3,
            maxLoad4: isValidated.maxLoad4,
            maxLoad5: isValidated.maxLoad5,
            maxLoad6: isValidated.maxLoad6,
            breakType1: isValidated.breakType1,
            breakType2: isValidated.breakType2,
            breakType3: isValidated.breakType3,
            breakType4: isValidated.breakType4,
            breakType5: isValidated.breakType5,
            breakType6: isValidated.breakType6,
            length1: isValidated.length1,
            length2: isValidated.length2,
            length3: isValidated.length3,
            length4: isValidated.length4,
            length5: isValidated.length5,
            length6: isValidated.length6,
            diameter: isValidated.diameter,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            status: isValidated.status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            let material = breakResultCyl(isValidated)

            //console.log(`material: ${JSON.stringify(material)}`)

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: isValidated.jobNumber,
                castDate: isValidated.castDate,
                timeCast: isValidated.timeCast,
                fieldMarking: isValidated.fieldMarking,
                represents: replaceStr(isValidated.represents),
                location: replaceStr(isValidated.location),
                structureType: isValidated.structureType,
                otherStructure: replaceStr(isValidated.otherStructure),
                reinforcementType: isValidated.reinforcementType,
                materialType: isValidated.materialType,
                specimenType: isValidated.specimenType,
                sampleType: isValidated.sampleType,
                mixNo: isValidated.mixNo,
                concreteSupplier: replaceStr(isValidated.concreteSupplier),
                batchPlantLocation: isValidated.batchPlantLocation,
                deliveryTicketNo: isValidated.deliveryTicketNo,
                slumpMeasured: isValidated.slumpMeasured,
                waterAdded: isValidated.waterAdded,
                airContent: isValidated.airContent,
                concreteTemp: isValidated.concreteTemp,
                airTemp: isValidated.airTemp,
                designStrength: isValidated.designStrength,
                designStrengthDay: isValidated.designStrengthDay,
                age1: isValidated.age1,
                age2: isValidated.age2,
                age3: isValidated.age3,
                age4: isValidated.age4,
                age5: isValidated.age5,
                age6: isValidated.age6,
                notes: replaceStr(isValidated.notes),
                maxLoad1: isValidated.maxLoad1,
                maxLoad2: isValidated.maxLoad2,
                maxLoad3: isValidated.maxLoad3,
                maxLoad4: isValidated.maxLoad4,
                maxLoad5: isValidated.maxLoad5,
                maxLoad6: isValidated.maxLoad6,
                breakType1: isValidated.breakType1,
                breakType2: isValidated.breakType2,
                breakType3: isValidated.breakType3,
                breakType4: isValidated.breakType4,
                breakType5: isValidated.breakType5,
                breakType6: isValidated.breakType6,
                length1: isValidated.length1,
                length2: isValidated.length2,
                length3: isValidated.length3,
                length4: isValidated.length4,
                length5: isValidated.length5,
                length6: isValidated.length6,
                diameter: isValidated.diameter,
                latitude: isValidated.latitude,
                longitude: isValidated.longitude,
                strength1: material.strength1,
                strength2: material.strength2,
                strength3: material.strength3,
                strength4: material.strength4,
                strength5: material.strength5,
                strength6: material.strength6,
                percent1: material.percent1,
                percent2: material.percent2,
                percent3: material.percent3,
                percent4: material.percent4,
                percent5: material.percent5,
                percent6: material.percent6,
                status: isValidated.status
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, '', props.component, 'edit', `Material Cast: ${isValidated.castDate}`, props.user.username)

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit material. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'editMaterial', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.concrete < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.castDate === null || isValidated.castDate === '') {
      alert("Please select a Cast Date.");
    } else if (isValidated.timeCast === null || isValidated.timeCast === '') {
      alert("Please select a Cast Time.");
    } else if (isValidated.fieldMarking === null || isValidated.fieldMarking === '') {
      alert("Please provide a Set No.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please provide an Area of Work.");
    } else if (isValidated.location === null || isValidated.location === '') {
      alert("Please provide a Sample Location.");
    } else if (isValidated.structureType === null || isValidated.structureType === '') {
      alert("Please provide a Structure Type.");
    } else if (isValidated.structureType === 'other' && (isValidated.otherStructure === null || isValidated.otherStructure === '')) {
      alert("Please specify other structure.");
    } else if (isValidated.reinforcementType === null || isValidated.reinforcementType === '') {
      alert("Please provide a Reinforcement Type.");
    } else if (isValidated.materialType === null || isValidated.materialType === '') {
      alert("Please provide a Material Type.");
    } else if (isValidated.specimenType === null || isValidated.specimenType === '') {
      alert("Please provide a Specimen Type.");
    } else if (isValidated.sampleType === null || isValidated.sampleType === '') {
      alert("Please provide a Sample Type.");
    } else if (isValidated.concreteSupplier === null || isValidated.concreteSupplier === '') {
      alert("Please provide a Concrete Supplier.");
    } else if (isValidated.batchPlantLocation === null || isValidated.batchPlantLocation === '') {
      alert("Please provide a Batch Plant Location.");
    } else if (isValidated.deliveryTicketNo === null || isValidated.deliveryTicketNo === '') {
      alert("Please provide a Delivery Ticket No.");
    } else if (isValidated.mixNo === null || isValidated.mixNo === '') {
      alert("Please provide a Mix No.");
    } else if (isValidated.slumpMeasured === null || isValidated.slumpMeasured === '') {
      alert("Please provide a Measured Slump.");
    } else if (isValidated.waterAdded === null || isValidated.waterAdded === '') {
      alert("Please provide Water Added.");
    // } else if (isValidated.airContent === null || isValidated.airContent === '') {
    //   alert("Please provide an Air Content.");
    } else if (isValidated.concreteTemp === null || isValidated.concreteTemp === '') {
      alert("Please provide a Concrete Temp.");
    } else if (isValidated.airTemp === null || isValidated.airTemp === '') {
      alert("Please provide an Air Temp.");
    } else if (isValidated.designStrength === null || isValidated.designStrength === '') {
      alert("Please provide a Design Strength.");
    } else if (isValidated.designStrengthDay === null || isValidated.designStrengthDay === '') {
      alert("Please provide a Design Strenth @ Day.");
    } else if (isValidated.age1 === null || isValidated.age1 === '') {
      alert("Please provide at least one age for breaking.");
    } else {

      if (props.filter.offline) {

        addPending({
          actionId: 2,
          action: 'editConcreteManage',
          table: 'Concrete',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                moddevice: props.user.device
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, '', props.component, 'editConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      fetch('/api/deleteConcrete', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, '', props.component, 'delete', `Material Cast: ${isValidated.castDate}`, props.user.username)

          //fetchData()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, '', props.component, 'deleteConcrete', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    // if (props.user.concrete < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.filter.offline) {

          addPending({
            actionId: 3,
            action: 'deleteConcrete',
            table: 'Lab',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              closeModal()
              //alert('Deleted.')

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, '', props.component, 'deleteConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.concrete < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const toggleDelivered = () => setIsModal(prevState => ({...prevState, delivered: isModal.delivered ? false : true}))

  const togglePreview = () => setIsModal(prevState => ({...prevState, preview: isModal.preview ? false : true}))

  const openCalendar = () => setIsModal(prevState => ({...prevState, calendar: true}))

  const closeCalendar = () => setIsModal(prevState => ({...prevState, calendar: false}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  forDownload.current = [] // clear before pushing

  let listOfData = fetchedData.map((data, i) => {

    let castDate = data.castDate === null ? '' : data.castDate
    let fieldMarking = data.fieldMarking === null ? '' : data.fieldMarking
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let materialType = data.materialType === null ? '' : data.materialType
    let specimenType = data.specimenType === null ? '' : data.specimenType
    let sampleType = data.sampleType === null ? '' : data.sampleType.toLowerCase()
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let delivered = (data.deliveredtime !== '' && data.deliveredtime !== null) || data.entrytime === '' || data.entrytime === null ? true : false

    let filter = filterData(data, searchValue)

    if (filter) {

      forDownload.current.push(data)

      let count = 0
      let percentSum = 0
      let passFail = ''
      let error = 0 // track cored if < 75% per C41?

      if(data.age1 === '28' && data.maxLoad1 !== '') {
        count += 1
        percentSum += data.percent1
        if (sampleType === 'cored' && data.percent1 < 75) error = 1
      }

      if(data.age2 === '28' && data.maxLoad2 !== '') {
        count += 1
        percentSum += data.percent2
        if (sampleType === 'cored' && data.percent2 < 75) error = 1
      }

      if(data.age3 === '28' && data.maxLoad3 !== '') {
        count += 1
        percentSum += data.percent3
        if (sampleType === 'cored' && data.percent3 < 75) error = 1
      }

      if(data.age4 === '28' && data.maxLoad4 !== '') {
        count += 1
        percentSum += data.percent4
        if (sampleType === 'cored' && data.percent4 < 75) error = 1
      }

      if(data.age5 === '28' && data.maxLoad5 !== '') {
        count += 1
        percentSum += data.percent5
        if (sampleType === 'cored' && data.percent5 < 75) error = 1
      }

      if(data.age6 === '28' && data.maxLoad6 !== '') {
        count += 1
        percentSum += data.percent6
        if (sampleType === 'cored' && data.percent6 < 75) error = 1
      }

      if (count > 0 && percentSum > 0) {
        let percent = Math.round(percentSum / count * 10) / 10
        passFail =  error > 0 ? 'Fail' : percent < 100 ? 'Fail' : 'Pass'
      }

      let today = new Date(formatDateYMD(new Date()))
      let breakDate1 = new Date(data.breakDate1)
      let breakDate2 = new Date(data.breakDate2)
      let breakDate3 = new Date(data.breakDate3)
      let breakDate4 = new Date(data.breakDate4)
      let breakDate5 = new Date(data.breakDate5)
      let breakDate6 = new Date(data.breakDate6)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          {isModal.delivered ?
            <td>
              <Icon
                name={delivered ? 'check_circle' : 'panorama_fish_eye'}
                color={delivered ? 'dodgerblue' : 'tomato'}
                i={i}
              />
            </td> :
            data.status !== '' ?
            <td>
              <Icon
                name={data.status === 'bathtub' ? 'bathtub' : data.status === 'sentOut' ? 'electric_moped' : 'wrong_location'}
                color={data.status === 'missing' ? 'tomato' : 'dodgerblue'}
                outline={true}
                i={i}
              />
            </td> :
            <td>
              <Icon
                name='local_shipping'
                color={delivered ? 'dodgerblue' : 'tomato'}
                outline={delivered ? false : true}
                i={i}
              />
            </td>
          }
          <td>{data.jobNumber}</td>
          <td>{castDate}</td>
          <td>{fieldMarking}</td>
          <td className='wrapText'>{location}</td>
          {isModal.preview ? null :
            <>
              <td>{materialType}</td>
              <td>{specimenType}</td>
              <td>{entryBy}</td>
              <td><b>{data.age1 === '0' ? 'Hold' : data.age1}</b></td>
              <td style={{display: 'none'}}>{data.strength1}</td>
              <td style={{background: data.age1 !== '' && data.age1 !== '0' && data.maxLoad1 === '' && breakDate1.getTime() < today.getTime() ? 'orange' : data.percent1 === '' ? 'white' : (data.age1 === '7' && Number(data.percent1) < 62) || (data.age1 === '28' && Number(data.percent1) < 100) ? 'tomato' : data.age1 === '7' && Number(data.percent1) < 70 ? 'yellow' : 'white'}}>{data.percent1 !== '' ? `${data.percent1}%` : ''}</td>
              <td><b>{data.age2 === '0' ? 'Hold' : data.age2}</b></td>
              <td style={{display: 'none'}}>{data.strength2}</td>
              <td style={{background: data.age2 !== '' && data.age2 !== '0' && data.maxLoad2 === '' && breakDate2.getTime() < today.getTime() ? 'orange' : data.percent2 === '' ? 'white' : (data.age2 === '7' && Number(data.percent2) < 62) || (data.age2 === '28' && Number(data.percent2) < 100) ? 'tomato' : data.age2 === '7' && Number(data.percent2) < 70 ? 'yellow' : 'white'}}>{data.percent2 !== '' ? `${data.percent2}%` : ''}</td>
              <td><b>{data.age3 === '0' ? 'Hold' : data.age3}</b></td>
              <td style={{display: 'none'}}>{data.strength3}</td>
              <td style={{background: data.age3 !== '' && data.age3 !== '0' && data.maxLoad3 === '' && breakDate3.getTime() < today.getTime() ? 'orange' : data.percent3 === '' ? 'white' : (data.age3 === '7' && Number(data.percent3) < 62) || (data.age3 === '28' && Number(data.percent3) < 100) ? 'tomato' : data.age3 === '7' && Number(data.percent3) < 70 ? 'yellow' : 'white'}}>{data.percent3 !== '' ? `${data.percent3}%` : ''}</td>
              <td><b>{data.age4 === '0' ? 'Hold' : data.age4}</b></td>
              <td style={{display: 'none'}}>{data.strength4}</td>
              <td style={{background: data.age4 !== '' && data.age4 !== '0' && data.maxLoad4 === '' && breakDate4.getTime() < today.getTime() ? 'orange' : data.percent4 === '' ? 'white' : (data.age4 === '7' && Number(data.percent4) < 62) || (data.age4 === '28' && Number(data.percent4) < 100) ? 'tomato' : data.age4 === '7' && Number(data.percent4) < 70 ? 'yellow' : 'white'}}>{data.percent4 !== '' ? `${data.percent4}%` : ''}</td>
              <td><b>{data.age5 === '0' ? 'Hold' : data.age5}</b></td>
              <td style={{display: 'none'}}>{data.strength5}</td>
              <td style={{background: data.age5 !== '' && data.age5 !== '0' && data.maxLoad5 === '' && breakDate5.getTime() < today.getTime() ? 'orange' : data.percent5 === '' ? 'white' : (data.age5 === '7' && Number(data.percent5) < 62) || (data.age5 === '28' && Number(data.percent5) < 100) ? 'tomato' : data.age5 === '7' && Number(data.percent5) < 70 ? 'yellow' : 'white'}}>{data.percent5 !== '' ? `${data.percent5}%` : ''}</td>
              <td><b>{data.age6 === '0' ? 'Hold' : data.age6}</b></td>
              <td style={{display: 'none'}}>{data.strength6}</td>
              <td style={{background: data.age6 !== '' && data.age6 !== '0' && data.maxLoad6 === '' && breakDate6.getTime() < today.getTime() ? 'orange' : data.percent6 === '' ? 'white' : (data.age6 === '7' && Number(data.percent6) < 62) || (data.age6 === '28' && Number(data.percent6) < 100) ? 'tomato' : data.age6 === '7' && Number(data.percent6) < 70 ? 'yellow' : 'white'}}>{data.percent6 !== '' ? `${data.percent6}%` : ''}</td>
              <td style={{display: 'none', background: passFail === 'Fail' ? 'tomato' : passFail === 'Pass' ? 'dodgerblue' : 'white'}}>{passFail}</td>
            </>
          }
        </tr>
      )

    }

    else {

      console.log('hide me?')
      //if (isModal.google) updateMap(data.id, false)
    }

  })

  let breakDays = (
    <>
      <option value=""></option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="7">7</option>
      <option value="14">14</option>
      <option value="28">28</option>
      <option value="56">56</option>
      <option value="0">Hold</option>
    </>
  )

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>Status</label>
          <select className='select' pattern=".{1,}" name='status' onInput={validate} onChange={changedData} defaultValue={isValidated.status} required>
            <option value=""></option>
            <option value="bathtub">Bathtub</option>
            <option value="sentOut">Sent Out</option>
            <option value="missing">Missing</option>
          </select>
        </div>

        <div>
          <label className='label'>Cast Date</label>
          <input className='input' type="date" pattern=".{1,}" name='castDate' onInput={validate} onChange={changedData} defaultValue={isValidated.castDate === '' || isValidated.castDate === null ? null : isValidated.castDate} required />
        </div>

        <div>
          <label className='label'>Cast Time</label>
          <input style={{width: 100}} className='input' type="time" pattern=".{1,}" name='timeCast' onInput={validate} onChange={changedData} defaultValue={isValidated.timeCast} required />
        </div>

        <div>
          <label className='label'>Set No</label>
          <input style={{width: 75}} className='input' type="text" pattern="[0-9]{1,}" name='fieldMarking' onInput={validate} onChange={changedData} defaultValue={isValidated.fieldMarking} required />
        </div>

        <div>
          <label className='label'>Area of Work</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Gridlines, etc.' name='represents' onInput={validate} onChange={changedData} required>{isValidated.represents}</textarea>
        </div>

        <div>
          <label className='label'>Sample Location</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Location within Area of Work specimen was sampled' name='location' onInput={validate} onChange={changedData} required>{isValidated.location}</textarea>
        </div>

        <div>
          <label className='label'>Structure Type</label>
          <select className='select' pattern=".{1,}" name='structureType' onInput={validate} onChange={changedData} defaultValue={isValidated.structureType} required>
            <option value=""></option>
            <option value="slabOnGrade">Slab on Grade</option>
            <option value="elevatedSlab">Elevated Slab</option>
            <option value="footing">Footing</option>
            <option value="pile">Pile</option>
            <option value="pier">Pier</option>
            <option value="column">Column</option>
            <option value="wallPanel">Wall Panel</option>
            <option value="beam">Beam</option>
            <option value="sidewalk">Sidewalk</option>
            <option value="curbAndGutter">Curb and Gutter</option>
            <option value="pavement">Pavement</option>
            <option value="masonry">Masonry</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isValidated.structureType === 'other' ?
          <div>
            <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='otherStructure' onInput={validate} onChange={changedData} required>{isValidated.otherStructure}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Reinforcement Type</label>
          <select className='select' pattern=".{1,}" name='reinforcementType' onInput={validate} onChange={changedData} defaultValue={isValidated.reinforcementType} required>
            <option value=""></option>
            <option value="non">Non-Reinforced</option>
            <option value="conv">Conv. Reinforced</option>
            <option value="pt">Post-tension</option>
            <option value="pre">Pre-tension</option>
          </select>
        </div>

        <div>
          <label className='label'>Material Type</label>
          <select className='select' pattern=".{1,}" name='materialType' onInput={validate} onChange={changedData} defaultValue={isValidated.materialType} required>
            <option value=""></option>
            <option value="Concrete">Concrete Reg</option>
            <option value="lightWeight">Concrete LW</option>
            <option value="shotcrete">Shotcrete</option>
            <option value="Grout">Grout</option>
            <option value="nsGrout">Non-Shrink Grout</option>
            <option value="Mortar">Mortar</option>
            <option value="Prism">Prism</option>
          </select>
        </div>

        <div>
          <label className='label'>Specimen Type</label>
          <select className='select' pattern=".{1,}" name='specimenType' onInput={validate} onChange={changedData} defaultValue={isValidated.specimenType} required>
            <option value=""></option>
            <option value="Cylinder">Cylinder</option>
            <option value="Cube">Cube</option>
            <option value="Prism">Prism</option>
            <option value="Beam">Beam</option>
          </select>
        </div>

        <div>
          <label className='label'>Sample Type</label>
          <select className='select' pattern=".{1,}" name='sampleType' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
            <option value=""></option>
            <option value="cast">Cast</option>
            <option value="cored">Cored</option>
          </select>
        </div>

        <div>
          <label className='label'>Supplier</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='concreteSupplier' onInput={validate} onChange={changedData} required>{isValidated.concreteSupplier}</textarea>
        </div>

        <div>
          <label className='label'>Batch</label>
          <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='batchPlantLocation' onInput={validate} onChange={changedData} defaultValue={isValidated.batchPlantLocation} required />
        </div>

        <div>
          <label className='label'>Ticket No</label>
          <input style={{width: 100}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='deliveryTicketNo' onInput={validate} onChange={changedData} defaultValue={isValidated.deliveryTicketNo} required />
        </div>

        <div>
          <label className='label'>Mix No</label>
          <input style={{width: 100}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='mixNo' onInput={validate} onChange={changedData} defaultValue={isValidated.mixNo} required />
        </div>

        <div>
          <label className='label'>Slump Measured (in)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='slumpMeasured' onInput={validate} onChange={changedData} defaultValue={isValidated.slumpMeasured} required />
        </div>

        <div>
          <label className='label'>Water Added (gal/load)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='waterAdded' onInput={validate} onChange={changedData} defaultValue={isValidated.waterAdded} required />
        </div>

        <div>
          <label className='label'>Air Content (%)</label>
          <input style={{width: 75}} className='input' type="text" placeholder='Optional' pattern="\d{1,3}(\.\d{1})?" name='airContent' onInput={validate} onChange={changedData} defaultValue={isValidated.airContent} />
        </div>

        <div>
          <label className='label'>Conc. Temp (F)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='concreteTemp' onInput={validate} onChange={changedData} defaultValue={isValidated.concreteTemp} required />
        </div>

        <div>
          <label className='label'>Air Temp (F)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='airTemp' onInput={validate} onChange={changedData} defaultValue={isValidated.airTemp} required />
        </div>

        <div>
          <label className='label'>Specified Strength (psi)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}" name='designStrength' onInput={validate} onChange={changedData} defaultValue={isValidated.designStrength} required />
        </div>

        <div>
          <label className='label'>@ (days)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}" name='designStrengthDay' onInput={validate} onChange={changedData} defaultValue={isValidated.designStrengthDay} required />
        </div>

        <div>
          <label className='label'>Break (days)</label>
          <select className='select' pattern=".{1,}" name='age1' onInput={validate} onChange={changedData} defaultValue={isValidated.age1} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age2' onInput={validate} onChange={changedData} defaultValue={isValidated.age2}>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age3' onInput={validate} onChange={changedData} defaultValue={isValidated.age3}>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age4' onInput={validate} onChange={changedData} defaultValue={isValidated.age4}>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age5' onInput={validate} onChange={changedData} defaultValue={isValidated.age5}>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age6' onInput={validate} onChange={changedData} defaultValue={isValidated.age6}>
            {breakDays}
          </select>
        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center', borderTop: '1px solid black'}}>

          <small>Optional</small>
          <p><b>Specify an onsite location for pickup by clicking the <span style={{color: 'dodgerblue'}}>Blue</span> button and/or add Notes below</b></p>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {isValidated.latitude !== null && isValidated.latitude !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: isValidated.latitude !== null && isValidated.latitude !== '' ? 'dodgerblue' : 'tomato'}}>Lat/Lng</span>
          </div>

          <div>
            <label className='label'>Notes</label>
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Where did you leave the specimen?' name='notes' onInput={validate} onChange={changedData}>{isValidated.notes}</textarea>
          </div>

        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center', borderTop: '1px solid black'}}>

          <MaterialBreaks data={isValidated} validate={validate} changedData={changedData} />

        </div>

      </div>

    </div>

  )

  // let styleBtn = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   borderRadius: 10,
  //   border: '2px solid dodgerblue',
  //   margin: 10,
  //   width: 100
  // }

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} /> : null}
      {isModal.calendar ? <Calendar user={props.user} filter={props.filter} component={props.component} close={closeCalendar} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{flex: isModal.google || isModal.plan || isModal.preview ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <div style={{width: '100%'}}>

                {props.user.device === 'desktop' ? <Icon name='add_circle' title='Add' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                <Icon name='local_shipping' title='Delivery Status' color={isModal.delivered ? 'dodgerblue' : 'gray'} onClick={toggleDelivered} />
                <Icon name='file_download' onClick={exportToExcel} />
                <Icon name='calendar_month' title='Break Date Calendar' onClick={openCalendar} />
                <Icon name='article' title='View Report' color={isModal.preview ? 'dodgerblue' : 'gray'} onClick={togglePreview} />
                <Icon name='refresh' title='Refresh' onClick={fetchData} />

              </div>

              <SearchBar search={search} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <small style={{marginLeft: 10, marginTop: 10}}>{fetchedData.length} Total Records. (If no filters, limit 100). *Cored samples require 85% f'c.</small><br/>

                  <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: 5}}>
                    <Icon name='circle' color='tomato' /><span style={{marginRight: 10}}>Problem</span>
                    <Icon name='circle' color='yellow' /><span style={{marginRight: 10}}>Concern</span>
                    <Icon name='circle' color='orange' /><span style={{marginRight: 10}}>Missing</span>
                  </div>

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table id="toExcel">

                      <>

                        <thead>
                          <tr>
                            <th style={{display: 'none'}}>id</th>
                            {isModal.delivered ? <th>Delivered?</th> : <th></th>}
                            <th>JN</th>
                            <th>Cast</th>
                            <th>Set</th>
                            <th>Location</th>
                            {isModal.preview ? null :
                              <>
                                <th>Material</th>
                                <th>Specimen</th>
                                <th>By</th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th>Age</th>
                                <th style={{display: 'none'}}>Strength</th>
                                <th></th>
                                <th style={{display: 'none'}}>Pass/Fail</th>
                              </>
                            }
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>

                      </>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No data found.</p>

              }

            </div>

          </div>

          {isModal.preview ?

            <div style={{flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
                <span>
                  <input className='input' style={{width: 100}} placeholder='Report Date' className='input' type="text" name='reportDate' onChange={updateReportDetails} />
                  <input className='input' style={{width: 100}} placeholder='TABLE ?' className='input' type="text" name='tableName' onChange={updateReportDetails} />
                  <input className='input' style={{width: 100}} placeholder='Start Page' className='input' type="text" name='page' onChange={updateReportDetails} />
                </span>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
                  <iframe style={{height: 'calc(100% - 10px)', width: 'calc(100% - 10px)'}} src={pdfMaterial(forDownload.current, props.filter, reportDetails)}></iframe>
                </div>
              </div>
            </div> : null

          }

        </div>:
        <p style={{margin: 10}}>Loading...</p>

      }

    </>

  )

}

export default Concrete
