import React, { useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'

import { formatDateTime, formatDateYMD } from 'scripts/common'

const AssignLab = (props) => {

  let sample = props.sample

  const useArchive = () => alert('Please use Archive.')

  let notRun = 'orange'
  let unassigned = 'whitesmoke'
  let assigned = props.view === 'status' || props.view === 'dueDate' ? 'skyblue' : 'crimson'
  let reviewed = 'black'
  let completed = 'gray'
  let started = 'yellow'

  //console.log('assignLab: ' + JSON.stringify(sample))

  let testDetails = [
    {
      id: sample.labid,
      name: 'md',
      field: 'statusmds',
      status: sample.statusmds,
      priority: sample.prioritymds,
      dueDate: sample.duedatemds,
      colorStatus:
      sample.statusmds === 'notRun' ? notRun :
      sample.statusmds === 'reviewed' ? reviewed :
      sample.statusmds === 'completed' ? completed :
      sample.statusmds === 'started' ? started :
      sample.statusmds === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusmds === null || sample.statusmds === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'max',
      field: 'statusmax',
      status: sample.statusmax,
      priority: sample.prioritymax,
      dueDate: sample.duedatemax,
      colorStatus:
      sample.statusmax === 'notRun' ? notRun :
      sample.statusmax === 'reviewed' ? reviewed :
      sample.statusmax === 'completed' ? completed :
      sample.statusmax === 'started' ? started :
      sample.statusmax === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusmax === null || sample.statusmax === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'ei',
      field: 'statusei',
      status: sample.statusei,
      priority: sample.priorityei,
      dueDate: sample.duedateei,
      colorStatus:
      sample.statusei === 'notRun' ? notRun :
      sample.statusei === 'reviewed' ? reviewed :
      sample.statusei === 'completed' ? completed :
      sample.statusei === 'started' ? started :
      sample.statusei === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusei === null || sample.statusei === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'so4',
      field: 'statusso4',
      status: sample.statusso4,
      priority: sample.priorityso4,
      dueDate: sample.duedateso4,
      colorStatus:
      sample.statusso4 === 'notRun' ? notRun :
      sample.statusso4 === 'reviewed' ? reviewed :
      sample.statusso4 === 'completed' ? completed :
      sample.statusso4 === 'started' ? started :
      sample.statusso4 === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusso4 === null || sample.statusso4 === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'att',
      field: 'statusatt',
      status: sample.statusatt,
      priority: sample.priorityatt,
      dueDate: sample.duedateatt,
      colorStatus:
      sample.statusatt === 'notRun' ? notRun :
      sample.statusatt === 'reviewed' ? reviewed :
      sample.statusatt === 'completed' ? completed :
      sample.statusatt === 'started' ? started :
      sample.statusatt === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusatt === null || sample.statusatt === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'rval',
      field: 'statusrval',
      status: sample.statusrval,
      priority: sample.priorityrval,
      dueDate: sample.duedaterval,
      colorStatus:
      sample.statusrval === 'notRun' ? notRun :
      sample.statusrval === 'reviewed' ? reviewed :
      sample.statusrval === 'completed' ? completed :
      sample.statusrval === 'started' ? started :
      sample.statusrval === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusrval === null || sample.statusrval === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'se',
      field: 'statusse',
      status: sample.statusse,
      priority: sample.priorityse,
      dueDate: sample.duedatese,
      colorStatus:
      sample.statusse === 'notRun' ? notRun :
      sample.statusse === 'reviewed' ? reviewed :
      sample.statusse === 'completed' ? completed :
      sample.statusse === 'started' ? started :
      sample.statusse === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusse === null || sample.statusse === '' ? unassigned : assigned
    },
    {
      id: sample.labid,
      name: 'consol',
      field: 'statusconsol',
      status: sample.statusconsol,
      priority: sample.priorityconsol,
      dueDate: sample.duedateconsol,
      colorStatus:
      sample.statusconsol === 'notRun' ? notRun :
      sample.statusconsol === 'reviewed' ? reviewed :
      sample.statusconsol === 'completed' ? completed :
      sample.statusconsol === 'started' ? started :
      sample.statusconsol === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusconsol === null || sample.statusconsol === '' ? unassigned : 'skyblue'
    },
    {
      id: sample.labid,
      name: 'ds',
      field: 'statusds',
      status: sample.statusds,
      priority: sample.priorityds,
      dueDate: sample.duedateds,
      colorStatus:
      sample.statusds === 'notRun' ? notRun :
      sample.statusds === 'reviewed' ? reviewed :
      sample.statusds === 'completed' ? completed :
      sample.statusds === 'started' ? started :
      sample.statusds === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusds === null || sample.statusds === '' ? unassigned : 'aquamarine'
    },
    {
      id: sample.labid,
      name: 'sa',
      field: 'statussa',
      status: sample.statussa,
      priority: sample.prioritysa,
      dueDate: sample.duedatesa,
      colorStatus:
      sample.statussa === 'notRun' ? notRun :
      sample.statussa === 'reviewed' ? reviewed :
      sample.statussa === 'completed' ? completed :
      sample.statussa === 'started' ? started :
      sample.statussa === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statussa === null || sample.statussa === '' ? unassigned : 'mediumpurple' //purple
    },
    {
      id: sample.labid,
      name: 'Hydro',
      field: 'statushydro',
      status: sample.statushydro,
      priority: sample.priorityhydro,
      dueDate: sample.duedatehydro,
      colorStatus:
      sample.statushydro === 'notRun' ? notRun :
      sample.statushydro === 'reviewed' ? reviewed :
      sample.statushydro === 'completed' ? completed :
      sample.statushydro === 'started' ? started :
      sample.statushydro === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statushydro === null || sample.statushydro === '' ? unassigned : 'mediumpurple' //purple
    },
    {
      id: sample.labid,
      name: '200',
      field: 'status200',
      status: sample.status200,
      priority: sample.priority200,
      dueDate: sample.duedate200,
      colorStatus:
      sample.status200 === 'notRun' ? notRun :
      sample.status200 === 'reviewed' ? reviewed :
      sample.status200 === 'completed' ? completed :
      sample.status200 === 'started' ? started :
      sample.status200 === 'assigned' ? assigned : unassigned,
      colorNormal: sample.status200 === null || sample.status200 === '' ? unassigned : 'mediumpurple' //purple
    },
    {
      id: sample.labid,
      name: 'ph',
      field: 'statusph',
      status: sample.statusph,
      priority: sample.priorityph,
      dueDate: sample.duedateph,
      colorStatus:
      sample.statusph === 'notRun' ? notRun :
      sample.statusph === 'reviewed' ? reviewed :
      sample.statusph === 'completed' ? completed :
      sample.statusph === 'started' ? started :
      sample.statusph === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusph === null || sample.statusph === '' ? unassigned : 'fuchsia' //spicy pink
    },
    {
      id: sample.labid,
      name: 'ch',
      field: 'statusch',
      status: sample.statusch,
      priority: sample.prioritych,
      dueDate: sample.duedatech,
      colorStatus:
      sample.statusch === 'notRun' ? notRun :
      sample.statusch === 'reviewed' ? reviewed :
      sample.statusch === 'completed' ? completed :
      sample.statusch === 'started' ? started :
      sample.statusch === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusch === null || sample.statusch === '' ? unassigned : 'fuchsia' //spicy pink
    },
    {
      id: sample.labid,
      name: 'resist',
      field: 'statusresist',
      status: sample.statusresist,
      priority: sample.priorityresist,
      dueDate: sample.duedateresist,
      colorStatus:
      sample.statusresist === 'notRun' ? notRun :
      sample.statusresist === 'reviewed' ? reviewed :
      sample.statusresist === 'completed' ? completed :
      sample.statusresist === 'started' ? started :
      sample.statusresist === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusresist === null || sample.statusresist === '' ? unassigned : 'fuchsia' //spicy pink
    },
    {
      id: sample.labid,
      name: 'sval',
      field: 'statussval',
      status: sample.statussval,
      priority: sample.prioritysval,
      dueDate: sample.duedatesval,
      colorStatus:
      sample.statusrval === 'notRun' ? notRun :
      sample.statussval === 'reviewed' ? reviewed :
      sample.statussval === 'completed' ? completed :
      sample.statussval === 'started' ? started :
      sample.statussval === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statussval === null || sample.statussval === '' ? unassigned : 'sandybrown' //orange
    },
    {
      id: sample.labid,
      name: 'oc',
      field: 'statusoc',
      status: sample.statusoc,
      priority: sample.priorityoc,
      dueDate: sample.duedateoc,
      colorStatus:
      sample.statusoc === 'notRun' ? notRun :
      sample.statusoc === 'reviewed' ? reviewed :
      sample.statusoc === 'completed' ? completed :
      sample.statusoc === 'started' ? started :
      sample.statusoc === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusoc === null || sample.statusoc === '' ? unassigned : 'sandybrown' //orange
    },
    {
      id: sample.labid,
      name: 'acsa',
      field: 'statusacsa',
      status: sample.statusacsa,
      priority: sample.priorityacsa,
      dueDate: sample.duedateacsa,
      colorStatus:
      sample.statusacsa === 'notRun' ? notRun :
      sample.statusacsa === 'reviewed' ? reviewed :
      sample.statusacsa === 'completed' ? completed :
      sample.statusacsa === 'started' ? started :
      sample.statusacsa === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusacsa === null || sample.statusacsa === '' ? unassigned : 'sandybrown' //orange
    },
    {
      id: sample.labid,
      name: 'av',
      field: 'statusav',
      status: sample.statusav,
      priority: sample.priorityav,
      dueDate: sample.duedateav,
      colorStatus:
      sample.statusav === 'notRun' ? notRun :
      sample.statusav === 'reviewed' ? reviewed :
      sample.statusav === 'completed' ? completed :
      sample.statusav === 'started' ? started :
      sample.statusav === 'assigned' ? assigned : unassigned,
      colorNormal: sample.statusav === null || sample.statusav === '' ? unassigned : 'sandybrown' //orange
    }
  ]

  // determine which tests are visible

  //let dueDate = formatDateYMD(new Date(props.dueDate))
  let tests = testDetails.map(test => {

    // (props.view === 'dueDate' && ((props.dueDate === 'priority' && test.priority === 1) || (test.priority !== 1 && dueDate === formatDateYMD(new Date(test.dueDate)))) && props.who === 'todoTech' && (test.status === 'assigned' || test.status === 'started')) ||
    // (props.view === 'dueDate' && ((props.dueDate === 'priority' && test.priority === 1) || (test.priority !== 1 && dueDate === formatDateYMD(new Date(test.dueDate)))) && props.who === 'todoManager' && test.status === 'completed') ||

    if (
    (props.view === 'dueDate' && props.who === 'todoTech' && (test.status === 'assigned' || test.status === 'started')) ||
    (props.view === 'dueDate' && props.who === 'todoManager' && test.status === 'completed') ||
    (props.view !== 'dueDate' && props.who === 'todoTech' && (test.status === 'assigned' || test.status === 'started')) ||
    (props.view !== 'dueDate' && props.who === 'todoManager' && test.status === 'completed') ||
    (props.who === 'assign' || props.who === 'results')
    ) {

      return (
        <button
          type='button'
          onClick={
            props.who === 'assign' && (test.field === 'statusconsol' || test.field === 'statusds') ?
            props.onClickShearOrConsol :
            props.onClick
          }
          style={{
            backgroundColor: test.priority ? 'tomato' : props.view === 'normal' ? test.colorNormal : test.colorStatus,
            border: test.priority ? '2px solid black' : '1px solid black',
            borderRadius: 5,
            color: test.priority || (props.view === 'status' || props.view === 'dueDate') && (test.status === 'completed ' || test.status === 'reviewed') ? 'white' : 'black',
            display: 'inline-block',
            margin: 5
          }}
          data-id={test.id}
          data-field={test.field}
          data-status={test.status}
          data-samplestatus={sample.status}
          title={test.dueDate === '' || test.dueDate === null ? 'Not assigned' : `Due Date: ${formatDateYMD(new Date(test.dueDate))}`}
        >{test.name}</button>
      )

    } else {
      // console.log(`
      //   view: ${props.view}
      //   dueDate: ${props.dueDate}
      //   who: ${props.who}
      //   status: ${test.status}
      //   `)
    }

  })
  //{test.id === 20607 && test.name === 'av' ? test.colorNormal : test.name}</button>
  let addIcon =
    props.who === 'todoTech' && sample.status === 'archive' ?
    <Icon name='archive' color='dodgerblue' id={sample.labid} status={sample.status} iconClass='material-icons-outlined' onClick={useArchive} /> :
    props.who === 'todoTech' && sample.status === 'dispose' ?
    <Icon name='delete' color='tomato' id={sample.labid} status={sample.status} iconClass='material-icons-outlined' onClick={props.updateDisposed} /> :
    props.who === 'assign' ?
    null :
    null

  return (
    <span>
      {tests}
      {addIcon}
    </span>
  )
}
// leave here until you develop it for addIcon above
//<Icon name='add_circle' onClick={() => alert('under development')} />
export default AssignLab
