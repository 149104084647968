import React, {useState, useEffect, useRef} from 'react';
import LeftPanel from 'components/utils/LeftPanel'
import Login from 'components/Login'
import NavBar from 'components/utils/NavBar'
import JobAndGrade from './components/menu/JobAndGrade'
import User from 'components/menu/User'
import DateRange from 'components/menu/DateRange'
import Container from 'components/main/Container.js'
import Icon from 'components/utils/Icon'
import Sign from 'components/utils/Sign'
import Modal from 'components/utils/Modal'
import PushNotifications from "components/utils/PushNotifications"

import 'styles/sign.css'
import 'styles/app.css';
//import '../node_modules/material-icons/iconfont/material-icons.css';
import { formatDateTime, formatDateYMD, determineDate, getLocation, addActivity, getDeviceType } from 'scripts/common'
import { selectData, downloadData, selectDataToSync, destroyDatabase, fetchWithTimeout, syncPending, updatePending, deletePending, updateData } from 'scripts/offline'
//import { showNotification, test } from 'utils/notification'

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [stayLoggedIn, setStayLoggedIn] = useState(false)
  const toggleStayLoggedIn = () => setStayLoggedIn(prevState => (stayLoggedIn ? false : true))

  // holds data from buffer until fetch done
  const pendingData = useRef([])

  const [user, setUser] = useState({offline: null})
  const [isError, setIsError] = useState(false)

  const [offline, setOffline] = useState({
    available: true,
    status: '', // handles icon for various statuses
    manualSync: ''
  })

  const [isModal, setIsModal] = useState({
    job: false,
    user: false,
    date: false,
    sign: false, // for adding signature if new user and not guest
    tutorial: false,
    notify: false
  })

  // phaseID and view are needed for lab. they will help transition to a single platform until i decide how to merge everything smoothly

  const [filter, setFilter] = useState({
    jobNumber: '',
    gradeId: 'PG',
    client: '',
    user: '',
    startDate: '',
    endDate: '',
    dateName: '', // for display purposes below
    lat: '',
    lng: '',
    phaseId: 'All',
    view: 'dueDate',
    siteLocation: ''
  })

  const [component, setComponent] = useState('Home')
  const [componentManage, setComponentManage] = useState('Home')

  const selectComponent = (o) => {
    //console.log(`o: ${o.getAttribute('data-name')}`);
    setComponent(o.getAttribute('data-name'))
  }

  const selectComponentManage = (e) => {
    //console.log(`derp: ${e.currentTarget.getAttribute('data-id')}`)
    setComponentManage(e.currentTarget.getAttribute('data-id'))
  }

  const syncData = async () => {

    // need to resolve fails
    // if fail id on actionId = 1 and an edit on the record occurs? how to resolve

    for (let i=pendingData.current.length - 1; i >= 0; i--) {
      console.log(`before ${i}`)
      //console.log(`pendingData: ${JSON.stringify(pendingData.current[i])}`)
      //let promise1 = await Promise.resolve(i);

      let value = pendingData.current[i].value

      let promise = await syncPending(value)
      .then(res => {

        // update ids for edits on records before temp id was sync'd with real id
        // IMPORTANT!!! this update occurs on pendingData here and under deletePending to update pending table
        // more expanation in syncPending

        //console.log(`res: ${JSON.stringify(res)}`)

        // Start by updating pendingData, then update indexedDB below

        //console.log(`pendingData: ${JSON.stringify(pendingData.current[i])}`)

        if (value.actionId === 1) {

          let oldId = null
          let newId = res[0].lastId

          //console.log('actionID=1 => update pendingData.current')

          pendingData.current = pendingData.current.map(data => {

            switch(data.table) {
              case 'PlansDrawDrawings':
                oldId = value.drawId
                return value.drawId === data.drawId ? {...data, drawId: newId} : data
                break
              case 'PlansDrawTests':
                oldId = value.testId
                return value.testId === data.testId ? {...data, testId: newId} : data
                break
              case 'PlansDrawLab':
                oldId = value.labId
                return value.labId === data.labId ? {...data, labId: newId} : data
                break
              default:
                //console.log('updating pendingData.current')
                oldId = value.id
                return value.id === data.id ? {...data, id: newId} : data
            }

          })

          value = {...value, oldId: oldId, newId: newId}

          if (oldId !== null && value.table) {

            return updatePending(value)
            .then(res => updateData(value))
            .then(res => deletePending(pendingData.current[i].primaryKey))
            .then(res => {
              pendingData.current.splice(i, 1)
              console.log(`sliced1 i: ${i}`)
            })
            .catch(err => console.log(`syncPending1 Err: ${JSON.stringify(err)}`))

            //may still have loop hole if section is open with old data, fetchedData and isValidated
            //maybe use component to check?
          } else {

            return deletePending(pendingData.current[i].primaryKey)
            .then(res => {
              pendingData.current.splice(i, 1)
              console.log(`sliced2 i: ${i}`)
            })
            .catch(err => console.log(`syncPending2 Err: ${JSON.stringify(err)}`))

          }

        } else {

          return deletePending(pendingData.current[i].primaryKey)
          .then(res => {
            pendingData.current.splice(i, 1)
            console.log(`sliced3 i: ${i}`)
          })
          .catch(err => console.log(`syncPending3 Err: ${JSON.stringify(err)}`))

        }

        //deletePending before splice ;) or i will no longer exist

        // return deletePending(pendingData.current[i].primaryKey)
        // .then(res => pendingData.current.splice(i, 1))
        // .catch(err => console.log('deletePending failed'))

      })
      .catch(error => {
        console.log(`Error: syncPending ${error}`)
        // end loop to prevent syncing out of order
        // also if process started, possibly creates duplicates once connnection works
        return 'stopLoop'
      })

      if (promise === 'stopLoop') {
        console.log(`stop ${i}`)
        break
      }
      //console.log(`promise: ${promise}`)

      console.log(`after ${i}`)

    }

  }

  // const syncData = async () => {
  //
  //   let promises = []
  //
  //   for (let i=pendingData.current.length - 1; i >= 0; i--) {
  //     console.log(`before ${i}`)
  //     console.log(`pendingData: ${JSON.stringify(pendingData.current[i])}`)
  //     let result = await Promise.resolve(i);
  //     let value = pendingData.current[i].value
  //
  //     promises.push(syncPending(value))
  //     .then(res => {
  //
  //       // update ids for edits on records before temp id was sync'd with real id
  //       // IMPORTANT!!! this update occurs on pendingData here and under deletePending to update pending table
  //       // more expanation in syncPending
  //
  //       //console.log(`res: ${JSON.stringify(res)}`)
  //
  //       // Start by updating pendingData, then update indexedDB below
  //
  //       if (value.actionId === 1) {
  //
  //         let oldId = null
  //         let newId = res[0].lastId
  //
  //         //console.log('actionID=1 => update pendingData.current')
  //
  //         pendingData.current = pendingData.current.map(data => {
  //
  //           switch(data.table) {
  //             case 'PlansDrawDrawings':
  //               oldId = value.drawId
  //               return value.drawId === data.drawId ? {...data, drawId: newId} : data
  //               break
  //             case 'PlansDrawTests':
  //               oldId = value.testId
  //               return value.testId === data.testId ? {...data, testId: newId} : data
  //               break
  //             case 'PlansDrawLab':
  //               oldId = value.labId
  //               return value.labId === data.labId ? {...data, labId: newId} : data
  //               break
  //             default:
  //               //console.log('updating pendingData.current')
  //               oldId = value.id
  //               return value.id === data.id ? {...data, id: newId} : data
  //           }
  //
  //         })
  //
  //         value = {...value, oldId: oldId, newId: newId}
  //
  //         if (oldId !== null && value.table) {
  //           updatePending(value)
  //           updateData(value)
  //           //may still have loop hole if section is open with old data, fetchedData and isValidated
  //           //maybe use component to check?
  //         }
  //
  //       }
  //
  //       //deletePending before splice ;) or i will no longer exist
  //
  //       console.log(`
  //         key: ${pendingData.current[i].primaryKey}
  //         data: ${pendingData.current[i]}
  //       `)
  //
  //       deletePending(pendingData.current[i].primaryKey)
  //       .then(res => pendingData.current.splice(i, 1))
  //       .catch(err => console.log('deletePending failed'))
  //
  //     })
  //     .catch(error => {
  //       console.log(`Error: syncPending ${error}`)
  //       console.log(`pendingData: ${JSON.stringify(pendingData.current[i])}`)
  //     })
  //     console.log(`after ${i}`)
  //
  //   }
  //
  // }

  useEffect(() => {
    const interval = setInterval( async () => {
      if (window.navigator.onLine) {

        if (pendingData.current.length > 0) {
          syncData()
        } else {

          console.log('no pending')

          selectDataToSync()
          .then(data => {

            // data is {"primaryKey":97,"value":{"actionId":2,"action":"updateDelivered","table":"Lab","jobnumber":"1002.00","gradeid":"RG","entryby":"ddalbus","entrytime":"2022/10/19
            //REFERENCE VALUE?!
            //sort it z-a because for loop in syncData starts at back and i want the first pending added to start
            pendingData.current = data.sort((a, b) => Number(b.primaryKey) - Number(a.primaryKey))
            syncData()
          })
          .catch(error => console.log(`Error selectDataToSync: ${error}`))

        }

      }
    }, 5000);
    return () => clearInterval(interval);

    //window.navigator.onLine ? selectDataToSync() : console.log('not online')
  });

  // useEffect(() => {
  //   const interval = setInterval( async () => {
  //     if (offline.available && offline.status !== '' && offline.manualSync === '') {
  //       existsDataToSync()
  //     }
  //   }, 5000);
  //   return () => clearInterval(interval);
  //
  //   //window.navigator.onLine ? selectDataToSync() : console.log('not online')
  // });

  useEffect(() => {

    if (offline.available && user.offline) {

      if (filter.jobNumber === '') {
        setOffline(prevState => ({...prevState, status: ''}))
      } else if (user.offlineJob !== filter.jobNumber) {
        setOffline(prevState => ({...prevState, status: 'download_for_offline'}))
      } else if (user.offlineJob === filter.jobNumber) {
        setOffline(prevState => ({...prevState, status: 'offline_pin'}))
      } else {
        setOffline(prevState => ({...prevState, status: ''}))
      }

    }

  }, [filter])

  useEffect(() => {

    if (localStorage.getItem('albusgeo') !== null) {

      let user = JSON.parse(localStorage.getItem('albusgeo'))
      login(user.user.trim(), user.pw.trim())

    }

  }, [])

  const login = async (user, pw) => {

    // prompt for notifications

    // const result = await Notification.requestPermission()

    // if (result === "granted") {

    //   const registration = await navigator.serviceWorker.getRegistration()
    //   const subscribed = await registration.pushManager.getSubscription()
    //   if (!subscribed) {
    //     setIsModal(prevState => ({...prevState, notify: true}))
    //   }
    // }  

    getLocation(function(latlng){

      fetch('/api/login', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user, pw: pw})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log(JSON.stringify(result))
          if (result.length === 0) {
            setIsError(true)
          } else {

            if (stayLoggedIn) {

              let info = {
                user: user,
                pw: pw
              }

              localStorage.setItem('albusgeo', JSON.stringify(info))

            }

            //console.log(`user: ${JSON.stringify(result[0])}`)            

            setUser({...result[0],
              device: getDeviceType(),
              nukeId: '',
              info: result[0].userlevel === 'inspector' ? 3 : result[0].info,
              daily: result[0].userlevel === 'inspector' ? 3 : result[0].daily,
              document: result[0].userlevel === 'inspector' ? 3 : result[0].document,
              concrete: result[0].userlevel === 'inspector' ? 3 : result[0].concrete,
              offline: result[0].offline === 1 ? true : false,
              localData: result[0].localData === 1 ? true : false,
              bufferData: result[0].bufferData === 1 ? true : false
            })

            setIsLoggedIn(true)
            setIsError(false)

            if (result[0].userlevel !== 'guest' && (result[0].signature === null || result[0].signature === '')) {
              if (window.confirm("No signature was found on file. This is required for dailies. Would you like to create a signature now?")) {
                setIsModal(prevState => ({...prevState, sign: true}))
              }
            }

            addActivity('field', '', '', '', 'login', '', user)

            fetch('/api/updateLastLogin', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: result[0].id,
                time: formatDateTime(new Date())
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                console.log('Error: updateLastLogin --> ' + error)
              }
            )

          }

        }
      )
      .catch(err => {

        selectData('Users')
        .then(result => {

          if (result.length > 0) {

            setUser({...result[0],
              device: getDeviceType(),
              nukeId: '',
              info: result[0].userlevel === 'inspector' ? 3 : result[0].info,
              daily: result[0].userlevel === 'inspector' ? 3 : result[0].daily,
              document: result[0].userlevel === 'inspector' ? 3 : result[0].document,
              concrete: result[0].userlevel === 'inspector' ? 3 : result[0].concrete,
              offline: result[0].offline === 1 ? true : false,
              localData: result[0].localData === 1 ? true : false,
              bufferData: result[0].bufferData === 1 ? true : false
            })
            setIsLoggedIn(true)
            setIsError(false)

          }

        })
        .catch(err => console.log(`Cannot selectData(Users) Error: ${err}`))

      })

    })

  }

  const updateSignature = (signature) => setUser(prevState => ({...prevState, signature: signature}))

  // const validate = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value
  //
  //   setFilter(prevState => ({...prevState, [name]: state ? value : null}))
  //
  // }

  const selectOffline = () => {

    const editOffline = (job) => {

      fetch(`/api/editOffline`, {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: user.id,
          jobNumber: job,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log(JSON.stringify(result))
          setUser(prevState => ({...prevState, offline: true, offlineJob: job, offlineTime: formatDateTime(new Date())}))
        },
        (error) => {
          //console.log('error: ' + error)
          setUser(prevState => ({...prevState, offline: true, offlineJob: job, offlineTime: formatDateTime(new Date())}))
        }
      )

    }

    if (offline.status === 'offline_pin') {

      destroyDatabase()
      .then(res => {
        editOffline('')
        setOffline(prevState => ({...prevState, status: 'download_for_offline'}))
      })
      .catch(res => {
        editOffline('')
        setOffline(prevState => ({...prevState, status: 'download_for_offline'}))
      })

    } else if (filter.jobNumber === '') {
      alert('Please select a JN')
    } else {

      let data = {
        jobNumber: filter.jobNumber,
        gradeId: filter.gradeId,
        userName: user.username,
        userLevel: user.userlevel,
        guestAccess: user.guestaccess,
        startDate: '', // for selectDailies
        endDate: ''
      }

      setOffline(prevState => ({...prevState, status: 'sync'}))

      downloadData(data)
      .then(res => {

        editOffline(filter.jobNumber)
        setOffline(prevState => ({...prevState, status: 'offline_pin'}))

      })
      .catch(err => {

        setOffline(prevState => ({...prevState, status: 'sync_problem'}))

        alert('Failed to download. Please try again or contact an admin.')
      })

    }

  }

  const manualSync = () => {
    console.log('start manual sync')
    setOffline(prevState => ({...prevState, manualSync: ''}))
    selectDataToSync()
    //setOffline(prevState => ({...prevState, manualSync: status}))
    console.log('start manual sync')
  }

  function existsDataToSync() {

    let request = window.indexedDB.open('Pending', 1)

    request.onerror = (event) => {
      //db = event.target.result
      console.log("Error: existsDataToSync");

    }

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onsuccess = (event) => {

      let db = event.target.result

      //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
      if (db.objectStoreNames.contains('Pending')) {

        let txn = db.transaction('Pending', 'readwrite');
        let objectStore = txn.objectStore('Pending');
        let request = objectStore.openCursor()

        request.onerror = function(event) {
          console.log(`Error: existsDataToSync-->txn`)
        }

        request.onsuccess = function(event) {

          let cursor = event.target.result
          if (cursor) {
            console.log( 'found pending')
            setOffline(prevState => ({...prevState, manualSync: 'pending'}))
            return
          } else {
            console.log( 'no pending')
            setOffline(prevState => ({...prevState, manualSync: ''}))

          }

        }

      }

    }

  }

  const selectJob = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let lat, lng

    if (td[4].textContent === '' || td[4].textContent === null) { // Clear JN should be excluded
      alert('Info has not been setup. Contact a manager.')
    } else {

      if (td[1].textContent === '' || td[2].textContent === '' || td[1].textContent === null || td[2].textContent === null) {
        lat = 33.84789303492371 // albus office
        lng = -117.86229291534426
      } else {
        lat = td[1].textContent
        lng = td[2].textContent
      }

      // gradeId: td[4].textContent === 'Grading' ? 'RG' : td[4].textContent === 'Post' ? 'PG' : '',

      setFilter(prevState =>
        ({...prevState,
          jobNumber: td[3].textContent,          
          client: td[5].textContent,
          lat: lat,
          lng: lng,
          siteLocation: td[7].textContent,
          siteArea: td[8].textContent
        })
      )
      closeModal()
    }

  }

  const clearJob = () => {

    let lat = 33.84789303492371 // albus office
    let lng = -117.86229291534426

    setFilter(prevState =>
      ({...prevState,
        jobNumber: '',
        gradeId: '',
        lat: lat,
        lng: lng
      })
    )
    closeModal()

  }

  const selectUser = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setFilter(prevState => ({...prevState, user: td[0].textContent}))
    closeModal()

  }

  const selectDate = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let filter = determineDate(td[0].textContent)

    setFilter(prevState => ({...prevState, startDate: filter.startDate, endDate: filter.endDate, dateName: td[0].textContent}))
    closeModal()

  }

  const selectDateAlt = (start, end) => { // og the date picker had validate, which setFilter onInput. so chart, management tools, etc updated on input :/ better to confirm

    setFilter(prevState => ({...prevState, startDate: start, endDate: end, dateName: ''}))
    closeModal()

  }

  const addDate = () => {

    let startDate = new Date(filter.startDate)
    let endDate = new Date(filter.endDate)
    let day = 60 * 60 * 24 * 1000 * 2 // * 2 is to account for -3 hrs for PST vs GMT

    startDate = new Date(startDate.getTime() + day)
    endDate = new Date(endDate.getTime() + day)

    setFilter(prevState => ({...prevState, startDate: formatDateYMD(startDate), endDate: formatDateYMD(endDate), dateName: ''}))
  }

  const subtractDate = () => {

    let startDate = new Date(filter.startDate)
    let endDate = new Date(filter.endDate)
    //let day = 60 * 60 * 24 * 1000 * 2 // * 2 is to account for -3 hrs for PST vs GMT

    startDate = new Date(startDate.getTime())
    endDate = new Date(endDate.getTime())

    setFilter(prevState => ({...prevState, startDate: formatDateYMD(startDate), endDate: formatDateYMD(endDate), dateName: ''}))
  }

  // const toggleOffline = () => setFilter(prevState =>({...prevState,
  //   offline: filter.offline ? false : true
  // }))

  const toggleGradeId = () => setFilter(prevState =>({...prevState,
    gradeId: filter.gradeId === 'PG' ? 'RG' : 'PG'
  }))

  const selectNuke = (nukeId) => setUser(prevState => ({...prevState, nukeId: nukeId}))

  const closeModal = () => setIsModal(prevState => ({...prevState, job: false, user: false, date: false}))
  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))
  const openUser = () => setIsModal(prevState => ({...prevState, user: true}))
  const openDate = () => setIsModal(prevState => ({...prevState, date: true}))

  const closeSign = () => setIsModal(prevState => ({...prevState, sign: false}))

  const closeNotify = () => setIsModal(prevState => ({...prevState, notify: false}))

  const logOut = () => {

    localStorage.removeItem('albusgeo');
    setIsLoggedIn(false)
    setUser({offline: null})

  }

  let tutorialContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'left', margin: 10, fontFamily: 'Arial'}}>

        <h3>Welcome to our Field App!</h3>
        <h3>This is a quick 'How-To' of our app features.</h3>

        <p style={{fontSize: 16}}>This is a quick 'How-To' of our app features.</p>

        <p style={{display: 'flex', alignItems: 'center'}}>Start by selecting your Job with the <Icon name='search' /> in the upper left corner</p>

        <p>Our projects are divided into Grading and Post Grading (utilities, pavement,..). So you need to pick one or the other depending on the type of work you are interested in.</p>

        <p style={{display: 'flex', alignItems: 'center'}}>Use the menu <Icon name='apps' /> to view Plans and Dailies</p>

        <p>Under Plans, you can select a sheet to view drawings.</p>

        <p style={{display: 'flex', alignItems: 'center'}}>Use the <Icon name='zoom_in' /> and <Icon name='zoom_out' /> to zoom in and out</p>

        <p style={{display: 'flex', alignItems: 'center'}}>The <Icon name='layers' /> button allows you to search and filter drawings</p>

        <p>Under Dailies, you can select a Daily to review</p>

        <p style={{display: 'flex', alignItems: 'center'}}>From a desktop, you can use the <Icon name='preview' /> button to view dailies split screen</p>

        <p style={{display: 'flex', alignItems: 'center'}}>The <Icon name='file_download' outline={true} /> button allows downloading dailies as a batch. Be sure to use either the Search bar or ‘Select a Date or Date Range’ when batching. Otherwise, all dailies will be downloaded from the list and may take a while</p>

        <p style={{display: 'flex', alignItems: 'center'}}>To close a menu, simply click <Icon name='close' color='tomato' /> usually located in the upper left</p>

        <p>If you can any questions, please contact (Danny) ddalbus@albusgeo.net</p>

      </div>

    </div>
  )

    // {filter.gradeId === 'RG' ? 'Grading' : filter.gradeId === 'PG' ? 'Post' : ''}

  return (
    <div style={{height: '100vh', width: '100vw'}}>

      {isLoggedIn ?

        <div style={{display: 'flex', flexFlow: 'column', height: '100%', width: '100%'}}>

          {isModal.job ? <JobAndGrade selectJob={selectJob} clearJob={clearJob} closeModal={closeModal} filter={filter} user={user} /> : null}
          {isModal.user ? <User selectUser={selectUser} closeModal={closeModal} /> : null}
          {isModal.date ? <DateRange selectDate={selectDate} selectDateAlt={selectDateAlt} closeModal={closeModal} filter={filter} /> : null}
          {isModal.sign ? <Sign parent='login' user={user} update={updateSignature} close={closeSign} /> : null}
          {isModal.tutorial ? <Modal content={tutorialContent} isModal={isModal} /> : null}
          {isModal.notify ? <PushNotifications close={closeNotify} /> : null}
                

          <NavBar component={component} user={user} filter={filter} onClick={selectComponent} logOut={logOut} />

          <div style={{flex: '1', overflow: 'auto'}}>

            <div style={{display: 'flex', height: '100%', maxWidth: '100%', overflow: 'auto'}}>

              {component === 'Manage' ?

                <div style={{flex: '0 1 auto', height: '100%'}}>
                  <LeftPanel selectComponent={selectComponentManage} componentManage={componentManage} user={user} />
                </div> : null

              }

              <div style={{flex: '1 1 auto', overflow: 'auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div style={{display: 'flex'}}>

                    {offline.available && offline.status !== '' ?
                      <div className={offline.status === 'sync' ? 'sync' : ''} onClick={selectOffline}>
                        <Icon
                          name={offline.status}
                          color={offline.status === 'sync_problem' ? 'tomato' : 'dodgerblue'}
                          outline={offline.status === 'offline_pin' ? false : true}
                        />
                      </div> : null
                    }

                    <div style={{display: 'flex', alignItems: 'center'}}>
                      
                      <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', border: '2px solid dodgerblue', borderRadius: 10, margin: 10, paddingRight: 10}} onClick={openJob}><Icon name='search' />
                      {filter.jobNumber !== '' ?                        
                        <span style={{verticalAlign: 'base-line'}}><b>{filter.jobNumber}</b></span> : <span style={{verticalAlign: 'base-line'}}><b>Select a Job</b></span>
                      }
                      </div>
                      {filter.jobNumber !== '' ?
                        <div style={{display: 'inline-block', cursor: 'pointer', border: `2px solid ${filter.gradeId === 'PG' ? 'dodgerblue' : 'orange'}`, borderRadius: 10, margin: 10, padding: 10}} onClick={toggleGradeId}>
                          <span style={{margin: 10}}><b>Grading</b></span>
                          <label className='switch'>
                            <input type='checkbox' checked={filter.gradeId === 'PG' ? true : false} />
                            <span className='slider round'></span>
                          </label>
                          <span style={{margin: 10}}><b>Post</b></span>
                        </div> : null
                      }
                    </div>

                    {filter.offline === 'derp' && offline.available && offline.status !== '' && offline.manualSync !== '' ?
                      <div className={offline.manualSync === 'sync' ? 'sync' : ''} onClick={manualSync}>
                        <Icon name='sync' />
                      </div> : null
                    }

                    {component === 'Manage' ?

                      <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openUser}>
                        <Icon name='person_search' />
                        {
                          filter.user !== '' ?
                          <span style={{verticalAlign: 'base-line'}}>{filter.user}</span> :
                          'Select a user'
                        }
                      </div> : null

                    }

                    {component === 'Manage' || user.userlevel === 'guest'?

                      <>

                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openDate}>
                          <Icon name='calendar_today' />
                          {
                            filter.startDate !== '' || filter.endDate !== '' ?
                            <span style={{verticalAlign: 'base-line'}}>
                              {
                                filter.dateName !== '' ?
                                `${filter.dateName}` :
                                filter.startDate === filter.endDate ?
                                `${filter.startDate}` :
                                `${filter.startDate} - ${filter.endDate}`
                              }
                            </span> :
                            'Select a Date or Date Range'
                          }

                        </div>

                        {filter.startDate !== '' || filter.endDate !== '' ?

                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Icon name='arrow_circle_left' onClick={subtractDate} outline={true} />
                            <Icon name='arrow_circle_right' onClick={addDate} outline={true} />
                          </div> : null

                        }

                      </> : null

                    }

                  </div>

                  <div style={{flex: '1', overflow: 'auto', width: '100%'}}>

                    <Container component={component} componentManage={componentManage} user={user} filter={filter} selectNuke={selectNuke} />

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div> : <Login isError={isError} stayLoggedIn={stayLoggedIn} toggleStayLoggedIn={toggleStayLoggedIn} login={login} />
      }
    </div>
  )
}

export default App;
