import React from 'react'

const Icon = (props) => {

  const style = {

    backgroundColor: 'transparent',
    border: props.border != undefined ? props.border : 'none',
    color: props.color != undefined ? props.color : 'dodgerblue',
    cursor: 'pointer',
    display: 'inline-block',
    height: 30,
    margin: '3px 6px 3px 3px',
    outline: 'none',
    padding: 3,
    width: 30,
    verticalAlign: 'top'

  }

  return (
    <button className={props.class} style={style} >
      <i
        className={props.outline ? 'material-symbols-outlined' : props.iconClass !== undefined ? props.iconClass : 'material-symbols-outlined'}
        title={props.title}
        onClick={props.onClick}
        data-id={props.id}
        data-i={props.i}
        data-lat={props.lat}
        data-lng={props.lng}
      >
        {props.name}
      </i>
    </button>
  )

}

export default Icon
