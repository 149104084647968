import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData } from 'scripts/common'

const AppErrors = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    google: false,
    loading: true
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,

  })

  const fetchData = () => {

    fetch('/api/selectErrors', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedData(result.map((data, i) => {

          return {...data,
            entrytime: data.entrytime !== null ? formatDateTime(data.entrytime) : data.entrytime
          }

        }))

        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectErrors', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {

        let jn = data.jobNumber === null ? '' : data.jobNumber
        let gd = data.gradeId === null ? '' : data.gradeId
        let component = data.component === null ? '' : data.component
        let func = data.func === null ? '' : data.func
        let error = data.error === null ? '' : data.error
        let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
        let entryTime = data.entrytime === null ? '' : data.entrytime

        return true

        //{testNo: testNo, lat: data.entrylat, lng: data.entrylng}

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, jn, gd, component, func, error, entryBy, entryTime

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      gd = data[i].gradeId === null ? '' : data[i].gradeId
      component = data[i].component === null ? '' : data[i].component
      func = data[i].func === null ? '' : data[i].func
      error = data[i].error === null ? '' : data[i].error
      entryBy = data[i].entryby !== null && data[i].entryby !== '' ? data[i].entryby : ''
      entryTime = data[i].entrytime === null ? '' : data[i].entrytime

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: error,
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i], searchValue)
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  // const selectRow = (e) => {
  //
  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let i = td[0].textContent
  //
  //   if (i === '' || i === null) {
  //     alert('Error: data index not found. Contact an admin.')
  //   } else {
  //
  //     // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
  //     let today = new Date()
  //     let entry = new Date(fetchedData[i].entrytime)
  //     let timeDiff = Math.abs(entry.getTime() - today.getTime())
  //     let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day
  //
  //     if (props.user.test < 2) {
  //
  //       setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))
  //
  //     } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username !== fetchedData[i].entryby) {
  //
  //       setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))
  //
  //     } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {
  //
  //       setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))
  //
  //     } else if (props.user.test === 4) {
  //
  //       setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))
  //
  //     } else {
  //
  //       setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))
  //
  //     }
  //
  //     // this is for removed, if returns undefined, then not removed
  //     let removed =  fetchedData.find(test => test.testno === fetchedData[i].testno && test.suf === 'R')
  //     let reTest = false
  //
  //     if (!removed && fetchedData[i].passfail === 'F') {
  //
  //       // this is for retest, if returns undefined, then not found, so retest needed
  //       reTest = fetchedData.find(test => test.testno === fetchedData[i].testno && test.passfail === 'P')
  //       //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
  //
  //     }
  //
  //     // used to dynamically update relComp and passFail during input
  //     updatePassFail.current = {
  //       dryDens: fetchedData[i].drydens,
  //       optD: fetchedData[i].optd,
  //       reqComp: fetchedData[i].reqcomp
  //     }
  //
  //     setIsValidated(prevState => ({...prevState,
  //       entryby: fetchedData[i].entryby,
  //       entrytime: fetchedData[i].entrytime,
  //       entrylat: fetchedData[i].entrylat,
  //       entrylng: fetchedData[i].entrylng,
  //       entrydevice: fetchedData[i].entrydevice,
  //       modby: fetchedData[i].modby,
  //       modtime: fetchedData[i].modtime,
  //       modlat: fetchedData[i].modlat,
  //       modlng: fetchedData[i].modlng,
  //       moddevice: fetchedData[i].moddevice,
  //       id: fetchedData[i].id,
  //       reTest: reTest === undefined ? true : false,
  //       showRemoved: removed === undefined ? true : false,
  //       removed: removed === undefined ? false : true,
  //       nukeId: fetchedData[i].nukeId,
  //       testDate: fetchedData[i].testdate,
  //       pre: fetchedData[i].pre,
  //       testNo: fetchedData[i].testno,
  //       suf: fetchedData[i].suf,
  //       testType: fetchedData[i].testtype,
  //       north: fetchedData[i].north,
  //       east: fetchedData[i].east,
  //       elevD: fetchedData[i].elevd,
  //       moistCont: fetchedData[i].moistcont,
  //       dryDens: fetchedData[i].drydens,
  //       maxId: fetchedData[i].maxId,
  //       curve: fetchedData[i].curve,
  //       optD: fetchedData[i].optd,
  //       optM: fetchedData[i].optm,
  //       reqComp: fetchedData[i].reqcomp,
  //       relComp: fetchedData[i].relcomp,
  //       passFail: fetchedData[i].passfail
  //     }))
  //     openEdit()
  //
  //   }
  //
  // }

  const changedData = () => isChanged.current = true

  // const addTest = () => {
  //
  //   if (props.user.test < 2) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isValidated.testDate === null || isValidated.testDate ==='') {
  //       alert("Please select a Test Date.");
  //   } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
  //        alert("Please select a Prefix.");
  //   } else if (isValidated.testType === null || isValidated.testType ==='') {
  //       alert("Please select a Test Type.");
  //   } else if (isValidated.testType === 'N' && (props.user.nukeId === '' || props.user.nukeId === null)) {
  //     alert('Please select a Nuke from the Home page.')
  //   } else if (isValidated.north === null || isValidated.north ==='') {
  //       alert("Please select a North.");
  //   } else  if (isValidated.east === null || isValidated.east ==='') {
  //       alert("Please select an East.");
  //   } else if (isValidated.elevD === null || isValidated.elevD ==='') {
  //       alert("Please select an Elev/Depth.");
  //   } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
  //       alert("Please select a Moisture Content.");
  //   } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
  //       alert("Please select a Dry Density.");
  //   } else if (isValidated.curve === null || isValidated.curve ==='') {
  //       alert("Please select a curve.");
  //   // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
  //   //     alert("Please select a Relative Compaction.");
  //   } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
  //       alert("Please select a Required Compaction.");
  //   } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
  //       alert("Relative compaction cannot be greater than 100%.");
  //   } else {
  //
  //     // include optd and optm???
  //
  //     getLocation(function(latlng){
  //
  //       fetch('/api/addTest', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: props.user.device,
  //           jobnumber: props.filter.jobNumber,
  //           gradeid: props.filter.gradeId,
  //           nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
  //           testdate: isValidated.testDate,
  //           pre: isValidated.pre,
  //           testno: isValidated.testNo,
  //           suf: isValidated.suf,
  //           testtype: isValidated.testType,
  //           north: isValidated.north,
  //           east: isValidated.east,
  //           elevd: isValidated.elevD,
  //           moistcont: isValidated.moistCont,
  //           drydens: isValidated.dryDens,
  //           maxId: isValidated.maxId,
  //           curve: isValidated.curve,
  //           optd: isValidated.optD,
  //           optm: isValidated.optM,
  //           reqcomp: isValidated.reqComp,
  //           relcomp: isValidated.relComp,
  //           passfail: isValidated.passFail,
  //           removed: isValidated.removed,
  //           showRemoved: isValidated.showRemoved
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))
  //
  //           fetchData() // i need the id if edited
  //           isChanged.current = false
  //           closeModal()
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not add test. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTest', JSON.stringify(error), props.user.username, props.user.device)
  //
  //         }
  //       )
  //
  //     })
  //
  //   }
  //
  // }
  //
  // const editTest = () => {
  //
  //   // if (props.user.test < 2) {
  //   //   alert('You do not have the required permission. Contact an admin.')
  //   if (isModal.warning > 1) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else if (isChanged.current === false) {
  //     alert('Nothing has been changed.')
  //   } else if (isValidated.testDate === null || isValidated.testDate ==='') {
  //       alert("Please select a Test Date.");
  //   } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
  //        alert("Please select a Prefix.");
  //   } else if (isValidated.testType === null || isValidated.testType ==='') {
  //       alert("Please select a Test Type.");
  //   } else if (isValidated.north === null || isValidated.north ==='') {
  //       alert("Please select a North.");
  //   } else  if (isValidated.east === null || isValidated.east ==='') {
  //       alert("Please select an East.");
  //   } else if (isValidated.elevD === null || isValidated.elevD ==='') {
  //       alert("Please select an Elev/Depth.");
  //   } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
  //       alert("Please select a Moisture Content.");
  //   } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
  //       alert("Please select a Dry Density.");
  //   } else if (isValidated.curve === null || isValidated.curve ==='') {
  //       alert("Please select a curve.");
  //   // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
  //   //     alert("Please select a Relative Compaction.");
  //   } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
  //       alert("Please select a Required Compaction.");
  //   } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
  //       alert("Relative compaction cannot be greater than 100%.");
  //   } else {
  //
  //     getLocation(function(latlng){
  //
  //       fetch('/api/editTest', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           id: isValidated.id,
  //           device: props.user.device,
  //           testdate: isValidated.testDate,
  //           pre: isValidated.pre,
  //           testno: isValidated.testNo,
  //           suf: isValidated.suf,
  //           testtype: isValidated.testType,
  //           north: isValidated.north,
  //           east: isValidated.east,
  //           elevd: isValidated.elevD,
  //           moistcont: isValidated.moistCont,
  //           drydens: isValidated.dryDens,
  //           maxId: isValidated.maxId,
  //           curve: isValidated.curve,
  //           optd: isValidated.optD,
  //           optm: isValidated.optM,
  //           reqcomp: isValidated.reqComp,
  //           relcomp: isValidated.relComp,
  //           passfail: isValidated.passFail,
  //           removed: isValidated.removed
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))
  //
  //           setFetchedData(fetchedData.map(data =>
  //             data.id === isValidated.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: props.user.device,
  //               testdate: isValidated.testDate,
  //               testtype: isValidated.testType,
  //               pre: isValidated.pre,
  //               testno: isValidated.testNo,
  //               suf: isValidated.suf,
  //               testtype: isValidated.testType,
  //               north: isValidated.north,
  //               east: isValidated.east,
  //               elevd: isValidated.elevD,
  //               moistcont: isValidated.moistCont,
  //               drydens: isValidated.dryDens,
  //               maxId: isValidated.maxId,
  //               curve: isValidated.curve,
  //               optd: isValidated.optD,
  //               optm: isValidated.optM,
  //               reqcomp: isValidated.reqComp,
  //               relcomp: isValidated.relComp,
  //               passfail: isValidated.passFail,
  //               removed: isValidated.removed
  //             } :
  //             data
  //           ))
  //
  //           isChanged.current = false
  //           closeModal()
  //           //alert('Updated')
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not edit test. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTest', JSON.stringify(error), props.user.username, props.user.device)
  //
  //         }
  //       )
  //
  //     })
  //
  //   }
  //
  // }
  //
  // const deleteTest = () => {
  //
  //   if (isModal.warning > 0) {
  //       alert('You do not have the required permission. Contact an admin.')
  //   } else {
  //
  //     if (window.confirm('If you proceed, this will be deleted. Proceed?')) {
  //
  //       fetch('/api/deleteTest', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           id: isValidated.id
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))
  //
  //           //fetchData()
  //           setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
  //           isChanged.current = false
  //           closeModal()
  //           //alert('Deleted.')
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not delete test. Contact and admin.')
  //           catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTest', JSON.stringify(error), props.user.username, props.user.device)
  //
  //         }
  //       )
  //
  //     }
  //
  //   }
  //
  // }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  // const openAdd = () => {
  //
  //   if (props.user.test < 2) {
  //
  //     alert('You do not have the required permission. Contact an admin.')
  //
  //   // if (isModal.warning) {
  //   //
  //   //   alert('You do not have the required permission. Contact an admin.')
  //
  //   } else if (props.filter.jobNumber === '') {
  //
  //     alert('Please select a JN.')
  //
  //   } else {
  //
  //     let testNo = fetchedData.length < 1 ? 1 : Math.max(...fetchedData.map(o => o.testno)) + 1
  //
  //     setIsValidated(prevState => ({...prevState, testNo: testNo}))
  //     setIsModal(prevState => ({...prevState, add: true}))
  //
  //     // fetch('/api/selectNextTestNo', {
  //     //   method: 'post',
  //     //   headers: {
  //     //     'Accept': 'application/json, text/plain, */*',
  //     //     'Content-Type': 'application/json'
  //     //   },
  //     //   body: JSON.stringify({
  //     //     jobNumber: props.filter.jobNumber,
  //     //     gradeId: props.filter.gradeId
  //     //   })
  //     // })
  //     // .then(res=>res.json())
  //     // .then(
  //     //   (result) => {
  //     //     //console.log('result: ' + result)
  //     //
  //     //     setIsValidated(prevState => ({...prevState, testNo: result[0].testNo === null ? 1 : result[0].testNo + 1}))
  //     //     setIsModal(prevState => ({...prevState, add: true}))
  //     //
  //     //   },
  //     //   (error) => {
  //     //
  //     //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectNextTestNo', JSON.stringify(error), props.user.username, props.user.device)
  //     //
  //     //   }
  //     // )
  //
  //   }
  //
  // }
  //
  // const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let gd = data.gradeId === null ? '' : data.gradeId === 'RG' ? 'Grading' : data.gradeId === 'PG' ? 'Post' : ''
    let component = data.component === null ? '' : data.component
    let func = data.func === null ? '' : data.func
    let error = data.error === null ? '' : data.error
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={null} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>{entryTime}</td>
          <td>{entryBy}</td>
          {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
            <>
              <td>{jn}</td>
              <td>{gd}</td>
            </> : null
          }
          <td>{component}</td>
          <td>{func}</td>
          <td>{error}</td>

        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  return (
    <>
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: isModal.google || isModal.plan ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <div style={{width: '100%'}}>

                <Icon name='refresh' onClick={fetchData} />
                <Icon name='location_on' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
                
              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <p style={{margin: 10}}>{fetchedData.length} Total Records. (If no filters, limit 500)</p>

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          {isModal.google ? <th></th> : null}
                          <th>Time</th>
                          <th>By</th>
                          {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <><th>JN</th><th></th></> : null}
                          <th>Component</th>
                          <th>Function</th>
                          <th>Error</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No errors found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>
  )

}

export default AppErrors
