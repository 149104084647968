import React, { useState, useEffect, useRef} from 'react'

import { formatDateYMD, formatDateTime, filterData, groupBy } from 'scripts/common'

const Charts = (props) => {

  const canvasStatusRef = useRef(null)
  const chartStatusRef = useRef(null)

  const canvasNukeRef = useRef(null)
  const chartNukeRef = useRef(null)

  const canvasTestTimeRef = useRef(null)
  const chartTestTimeRef = useRef(null)

  const canvasTestAvgRef = useRef(null)
  const chartTestAvgRef = useRef(null)

  const canvasDailiesTimeRef = useRef(null)
  const chartDailiesTimeRef = useRef(null)

  const canvasLabTimeRef = useRef(null)
  const chartLabTimeRef = useRef(null)

  //const [fetchedData, setFetchedData] = useState([])

  //const [fetchedUsers, setFetchedUsers] = useState([])
  const [fetchedStatuses, setFetchedStatuses] = useState([])
  const [fetchedNukes, setFetchedNukes] = useState([])

  //const [fetchedTests, setFetchedTests] = useState([])
  const [fetchedTests, setFetchedTests] = useState([])

  const [fetchedLab, setFetchedLab] = useState([])

  const [fetchedDailies, setFetchedDailies] = useState([])
  const [fetchedDailyTesting, setFetchedDailyTesting] = useState([])
  const [fetchedDistinctDailyDays, setFetchedDistinctDailyDays] = useState([])

  const [fetchedDrawings, setFetchedDrawings] = useState([])

  const [fetchedDevices, setFetchedDevices] = useState([])

  // async function fetchStatus() {

  //   let response = await fetch('/api/selectDailies', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       filter: props.filter,
  //       userLevel: props.user.userlevel
  //     })
  //   })
  //   return response.json();

  // }

  const fetchData = () => {

    fetch('/api/selectChartStatuses', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedStatuses(result)
      },
      (error) => {
        console.log('Error: selectStatsStatuses --> ' + error)
      }
    )

    fetch('/api/selectChartNukes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedNukes(result)
      },
      (error) => {
        console.log('Error: selectStatsNukes --> ' + error)
      }
    )

    fetch('/api/selectChartTests', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedTests(result)
      },
      (error) => {
        console.log('Error: selectChartTests --> ' + error)
      }
    )

    fetch('/api/selectChartDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDailies(result)
      },
      (error) => {
        console.log('Error: selectChartDailies --> ' + error)
      }
    )

    fetch('/api/selectChartLab', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedLab(result)
      },
      (error) => {
        console.log('Error: selectChartLab --> ' + error)
      }
    )    

    // fetch('/api/selectChartsDistinctDailyDays', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter,
    //     display: props.display
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + JSON.stringify(result))
    //     setFetchedDistinctDailyDays(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsDistinctDailyDays --> ' + error)
    //   }
    // )

    // fetch('/api/selectStatsDailyTesting', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     console.log('result: ' + JSON.stringify(result))
    //     setFetchedDailyTesting(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsDailies --> ' + error)
    //   }
    // )

    // fetch('/api/selectAnalyzeDevices', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + JSON.stringify(result))
    //     setFetchedDevices(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectAnalyzeDevices --> ' + error)
    //   }
    // )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter, props.display])

  useEffect(() => {

    if (props.isModal.chart) {      
      buildChartStatus()
      buildChartNuke()
      buildChartTest()
      buildChartDailies()
      buildChartLab()
    }
  })

  useEffect(() => {

    if (props.isModal.chart) {      
      buildChartStatus()
      buildChartNuke()
      buildChartTest()
      buildChartDailies()
      buildChartLab()
    }
  }, [props.filter, props.searchValue])

  // useEffect(() => {

  //   if (props.isModal.chart) {      
  //     buildChartStatus()
  //     buildChartNuke()
  //     buildChartTest()
  //   }
  // }, [props.display])
  
  // ========= STATUSES =========

  function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getDateOfWeek(w, y) {
    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

    return new Date(y, 0, d);
  }

  const awaitChartDataStatus = async () => {    

    let style = {
      borderWidth: 2,
      tension: 0.1,
      showLine: true,
    }

    let chart = {
      labels: [],
      data: []
    }

    //console.log(`chart0: ${JSON.stringify(fetchedStatuses)}`)

    // for (let i = 0; i < fetchedStatuses.length; i++) {

    //   let result = await Promise.resolve(i)

    //   if (filterData(fetchedStatuses[i], props.searchValue)) {

    //     let data = fetchedStatuses[i]
    //     //let total = data.countStart + data.countEnd
    //     //let dailies = fetchedDistinctDailyDays.find(res => res.entryby === data.entryby)
    //     //let jobs = fetchedDailies.find(res => res.entryby === data.entryby)    
    
    //     let ratio = data.countStart > data.countEnd ? data.countEnd / data.countStart : data.countStart / data.countEnd
    //     ratio = Math.round(10*ratio)/10
    //     //console.log(`data: ${JSON.stringify(data)}`)
    //     // console.log(`
    //     // start: ${data.countStart}
    //     // days: ${dailies.countDays}
    //     // `)
    //     //let ratioStart = dailies === undefined ? null : Math.round(data.countStart / dailies.countDays * 10) / 10
    //     //let ratioEnd = dailies === undefined ? null : Math.round(data.countEnd / dailies.countDays * 10) / 10
    //     //let ratioArrive = jobs === undefined ? null : Math.round(data.countArrive / (jobs.countIncomplete + jobs.countComplete + jobs.countSigned) * 10) / 10
    //     //let ratioDepart = jobs === undefined ? null : Math.round(data.countDepart / (jobs.countIncomplete + jobs.countComplete + jobs.countSigned) * 10) / 10

    //     arr.data.push({y: ratio, x: fetchedStatuses[i].yearMonth, entryby: fetchedStatuses[i].entryby})
    //     // arrStart.data.push(ratioStart)
    //     // arrEnd.data.push(ratioEnd)
    //     // arrArrive.data.push(ratioArrive)
    //     // arrDepart.data.push(ratioDepart)
    //     //chart.labels.push(fetchedStatuses[i].yearMonth)

        

    //   }

    // }

    // let chartData = fetchedStatuses.filter(data => {

    //   if (filterData(data, props.searchValue)) {
        
    //     let ratio = data.countStart > data.countEnd ? data.countEnd / data.countStart : data.countStart / data.countEnd
    //     ratio = Math.round(10*ratio)/10

    //     return {y: ratio, x: data.yearMonth, entryby: data.entryby}

    //   }

    // })

    let chartData = []

    for (let i = 0; i < fetchedStatuses.length; i++) {

      let result = await Promise.resolve(i)

      let data = fetchedStatuses[i]

      if (filterData(data, props.searchValue)) {

        //console.log('here2')
        
        let ratio = data.countEnd / data.countStart
        ratio = Math.round(10*ratio)/10

        let newDate

        if (props.display.week) {

          let oldDate = String(data.yearMonth)

          let year = Number(oldDate.substring(0,4))
          let week = Number(oldDate.substring(4))

          newDate = getDateOfWeek(week, year)

        } else {

          newDate = data.yearMonth

        }

        chartData.push({y: ratio, x: newDate, entryby: data.entryby})

      }

    }
    
    //console.log(`chart: ${JSON.stringify(arrStatus)}`)
    //console.log(`chart2: ${JSON.stringify(arrStart)}`)
    chartData = groupBy(chartData, 'entryby')
    console.log(`chart0: ${JSON.stringify(chartData)}`)
    //console.log(`chart1: ${JSON.stringify(statuses)}`)

    for (let i = 0; i < chartData.length; i++) {

      let result = await Promise.resolve(i)

      //if (filterData(statuses[i], props.searchValue)) {

        // let data = statuses[i]
        // let ratio = data.countStart > data.countEnd ? data.countEnd / data.countStart : data.countStart / data.countEnd
        // ratio = Math.round(10*ratio)/10

        let color = getRandomColor()

        chart.data.push({
          ...style,
          label: chartData[i][0].entryby,
          fill: false,
          data: chartData[i],
          borderColor: color,
          pointBackgroundColor: color
        })

      //}


    }

    //chart.data = [arr]

    //console.log(`chart2: ${JSON.stringify(chart.data)}`)

    return chart

  }

  const buildChartStatus = () => {

    awaitChartDataStatus()
    .then(chart => {

      let canvas, ctx      
      
      //let minTime = Math.min(...fetchedData.map(data => data.entrytime))

      //console.log(`minTime: ${minTime}`)

      canvas = canvasStatusRef.current
      ctx = canvas.getContext('2d')

      if (chartStatusRef.current) chartStatusRef.current.destroy()

      chartStatusRef.current = new window.Chart(ctx, {
        // The type of chart we want to create
        type: 'scatter',

        // The data for our dataset
        data: {
          //labels: chart.labels,
          datasets: chart.data
        },
        options: {
          title: {
            display: true,
            text: 'Status Start vs End Ratio'
          },
          animation: 0,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: props.display.month ? 'month' : 'week',
                unitStepSize: 1
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{              
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {          
            labels: {
                usePointStyle: true,
            },
            fullWidth: true,
          },
          hover: {
              mode: 'nearest',
              intersect: true
          },
          tooltips: {            
            mode: 'nearest',
            callbacks: {
              label: function(tooltipItem, data) {
                let result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let newDate = props.display.week ? formatDateYMD(result.x) : result.x
                return result.entryby + ': ' + result.y + ' @ ' + newDate
              },              
            }
          }

        }

      });

    })
    .catch(err => console.log("Error: " + err ))

  }

  // ========= NUKES =========

  // let listOfNukes = fetchedNukes.map(data => {

  //   let data = fetchedNukes[i]
  //   let total = data.countCheckOut
  //   let testing = fetchedDailyTesting.find(res => res.entryby === data.entryby)
  //   let ratioOut = testing === undefined ? 'no days' : Math.round(data.countCheckOut / testing.countDays * 10) / 10
  //   let ratioIn = testing === undefined ? 'no days' : Math.round(data.countCheckIn / testing.countDays * 10) / 10

  //   let ratio = data.countCheckIn / data.countCheckOut
  //   ratio = Math.round(10*ratio)/10

  //   let filter = filterData(data, props.searchValue)
  //   //console.log(`filter ${filter}`)

  //   if (filter) {

  //     return (
  //       <tr onClick={null} onMouseOver={null}>              
  //         <td>{data.entryby}</td>
  //         <td>{total}</td>
  //         <td>{testing === undefined ? 'no days' : testing.countDays}</td>
  //         <td>{ratio}</td>          
  //         <td>{ratioOut}</td>
  //         <td>{ratioIn}</td>
  //       </tr>
  //     )

  //   }
  // })

  const awaitChartDataNuke = async () => {    

    let style = {
      borderWidth: 2,
      tension: 0.1,
      showLine: true,
    }  

    let chart = {
      labels: [],
      data: []
    }

    //console.log(`nukes: ${JSON.stringify(fetchedNukes)}`)

    // for (let i = 0; i < fetchedNukes.length; i++) {

    //   let result = await Promise.resolve(i)

    //   if (filterData(fetchedNukes[i], props.searchValue)) {

    //     let data = fetchedNukes[i]
    //     let total = data.countCheckOut
    //     //let testing = fetchedDailyTesting.find(res => res.entryby === data.entryby)
    //     //let ratioOut = testing === undefined ? 'no days' : Math.round(data.countCheckOut / testing.countDays * 10) / 10
    //     //let ratioIn = testing === undefined ? 'no days' : Math.round(data.countCheckIn / testing.countDays * 10) / 10

    //     let ratio = data.countCheckIn / data.countCheckOut
    //     ratio = Math.round(10*ratio)/10
    //     //console.log(`data: ${JSON.stringify(data)}`)
        
    //     arr.data.push(ratio)
    //     // arrStart.data.push(ratioStart)
    //     // arrEnd.data.push(ratioEnd)
    //     // arrArrive.data.push(ratioArrive)
    //     // arrDepart.data.push(ratioDepart)
    //     chart.labels.push(fetchedStatuses[i].yearMonth)

    //     //console.log(`data: ${JSON.stringify(data)}`)

    //   }

    // }    

    // chart.data = [arr]

    // return chart

    // let chartData = fetchedNukes.filter(data => {

    //   //console.log('here1')

    //   if (filterData(data, props.searchValue)) {

    //     //console.log('here2')
        
    //     let ratio = data.countCheckIn / data.countCheckOut
    //     ratio = Math.round(10*ratio)/10

    //     return {y: ratio, x: data.yearMonth, entryby: data.entryby}

    //   }

    // })

    let chartData = []

    for (let i = 0; i < fetchedNukes.length; i++) {

      let result = await Promise.resolve(i)

      let data = fetchedNukes[i]

      if (filterData(data, props.searchValue)) {

        //console.log('here2')
        
        let ratio = data.countCheckIn / data.countCheckOut
        ratio = Math.round(10*ratio)/10
        
        let newDate

        if (props.display.week) {

          let oldDate = String(data.yearMonth)

          let year = Number(oldDate.substring(0,4))
          let week = Number(oldDate.substring(4))

          newDate = getDateOfWeek(week, year)

        } else {

          newDate = data.yearMonth

        }        

        chartData.push({y: ratio, x: newDate, entryby: data.entryby})

      }


    }
    
    //console.log(`chart: ${JSON.stringify(arrStatus)}`)
    //console.log(`chart2: ${JSON.stringify(arrStart)}`)
    //console.log('test1')
    //console.log(`chart0: ${JSON.stringify(chartData)}`)
    chartData = groupBy(chartData, 'entryby')
    //console.log('test2')
    //console.log(`chart1: ${JSON.stringify(chartData)}`)

    for (let i = 0; i < chartData.length; i++) {

      let result = await Promise.resolve(i)

      //if (filterData(statuses[i], props.searchValue)) {

        // let data = statuses[i]
        // let ratio = data.countStart > data.countEnd ? data.countEnd / data.countStart : data.countStart / data.countEnd
        // ratio = Math.round(10*ratio)/10

        let color = getRandomColor()

        chart.data.push({
          ...style,
          label: chartData[i][0].entryby,
          fill: false,
          data: chartData[i],
          borderColor: color,
          pointBackgroundColor: color
        })

      //}


    }

    //chart.data = [arr]

    //console.log(`chart2: ${JSON.stringify(chart.data)}`)

    return chart

  }

  const buildChartNuke = () => {

    awaitChartDataNuke()
    .then(chart => {

      console.log(`chart2: ${JSON.stringify(chart)}`)

      let canvas, ctx 

      canvas = canvasNukeRef.current
      ctx = canvas.getContext('2d')

      if (chartNukeRef.current) chartNukeRef.current.destroy()

      chartNukeRef.current = new window.Chart(ctx, {
        // The type of chart we want to create
        type: 'scatter',

        // The data for our dataset
        data: {
          labels: chart.labels,
          datasets: chart.data
        },
        options: {
          title: {
            display: true,
            text: 'Nuke Out vs In Ratio'
          },
          animation: 0,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: props.display.month ? 'month' : 'week',
                unitStepSize: 1
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{              
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {          
            labels: {
                usePointStyle: true,
            },
            fullWidth: true,
          },
          hover: {
              mode: 'nearest',
              intersect: true
          },
          tooltips: {            
            mode: 'nearest',
            callbacks: {
              label: function(tooltipItem, data) {
                let result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let newDate = props.display.week ? formatDateYMD(result.x) : result.x
                return result.entryby + ': ' + result.y + ' @ ' + newDate
              },              
            }
          }

        }

      });

    })
    .catch(err => console.log("Error: " + err ))

  }

  

  // ========= HANDLE TESTS =========  

  // let styleS, styleN, styleD, avgTestPerHour

  // let listOfTests = fetchedTests.map(data => {
  //   let total = data.countS + data.countN + data.countD
  //   let sPer = Math.round(10*data.countS/total*100)/10
  //   //let nPer = Math.round(10*data.countN/total*100)/10
  //   //let dPer = Math.round(10*data.countD/total*100)/10
  //   let testing = fetchedDailyTesting.find(res => res.entryby === data.entryby)    

  //   //console.log(`testing: ${JSON.stringify(sumHours)}`)
  //   let avgTestPerHour = testing === undefined ? 'no tests' : Math.round(total / testing.sumHours * 10) / 10
  //   //console.log(`avgTestPerHour: ${avgTestPerHour}`)

  //   let filter = filterData(data, props.searchValue)
  //   //console.log(`filter ${filter}`)

  //   if (filter) {
    
  //     return (
  //       <tr onClick={null} onMouseOver={null}>              
  //         <td>{data.entryby}</td>
  //         <td>{total}</td>
  //         <td>{Math.round(data.dateDiff * 100) / 100}</td>
  //         <td>{testing === undefined ? 'no hours' : testing.sumHours}</td>
  //         <td>{avgTestPerHour}</td>
  //         <td>{sPer}</td>
  //       </tr>
  //     )

  //   }
  // })


  const awaitChartDataTest = async () => {    

    let style = {
      borderWidth: 2,
      tension: 0.1,
      showLine: true,
    }  

    let chart = {
      labels: [],
      data: []
    } 

    let chartData = []

    for (let i = 0; i < fetchedTests.length; i++) {

      let result = await Promise.resolve(i)

      let data = fetchedTests[i]

      if (filterData(data, props.searchValue)) {

        //console.log('here2')
        
        let dateDiff = Math.round(data.dateDiff * 100) / 100

        let newDate

        if (props.display.week) {

          let oldDate = String(data.yearMonth)

          let year = Number(oldDate.substring(0,4))
          let week = Number(oldDate.substring(4))

          newDate = getDateOfWeek(week, year)

        } else {

          newDate = data.yearMonth

        }

        chartData.push({y: dateDiff, x: newDate, entryby: data.entryby})

      }

    }
    
    chartData = groupBy(chartData, 'entryby')
    
    //console.log(`chart1: ${JSON.stringify(chartData)}`)

    for (let i = 0; i < chartData.length; i++) {

      let result = await Promise.resolve(i)      

      let color = getRandomColor()

      chart.data.push({
        ...style,
        label: chartData[i][0].entryby,
        fill: false,
        data: chartData[i],
        borderColor: color,
        pointBackgroundColor: color
      })

    }    

    //console.log(`chart2: ${JSON.stringify(chart.data)}`)

    return chart

  }

  const buildChartTest = () => {

    awaitChartDataTest()
    .then(chart => {

      let canvas, ctx 

      canvas = canvasTestTimeRef.current
      ctx = canvas.getContext('2d')

      if (chartTestTimeRef.current) chartTestTimeRef.current.destroy()

      chartTestTimeRef.current = new window.Chart(ctx, {
        // The type of chart we want to create
        type: 'scatter',

        // The data for our dataset
        data: {
          labels: chart.labels,
          datasets: chart.data
        },
        options: {
          title: {
            display: true,
            text: 'Time to Enter Test'
          },
          animation: 0,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: props.display.month ? 'month' : 'week',
                unitStepSize: 1
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{              
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {          
            labels: {
                usePointStyle: true,
            },
            fullWidth: true,
          },
          hover: {
              mode: 'nearest',
              intersect: true
          },
          tooltips: {            
            mode: 'nearest',
            callbacks: {
              label: function(tooltipItem, data) {
                let result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let newDate = props.display.week ? formatDateYMD(result.x) : result.x
                return result.entryby + ': ' + result.y + ' @ ' + newDate
              },              
            }
          }

        }

      });

    })
    .catch(err => console.log("Error: " + err ))

  }

  // ========= HANDLE Lab =========    

  let listOfLab = fetchedLab.map(data => {
    //let total = fetchedLab.length    

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {
    
      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{data.countSamples}</td>
          <td>{Math.round(data.dateDiff * 100) / 100}</td>
        </tr>
      )

    }
  })

  const awaitChartDataLab = async () => {    

    let style = {
      borderWidth: 2,
      tension: 0.1,
      showLine: true,
    }  

    let chart = {
      labels: [],
      data: []
    } 

    let chartData = []

    for (let i = 0; i < fetchedLab.length; i++) {

      let result = await Promise.resolve(i)

      let data = fetchedLab[i]

      if (filterData(data, props.searchValue)) {

        //console.log('here2')
        
        let dateDiff = Math.round(data.dateDiff * 100) / 100

        let newDate

        if (props.display.week) {

          let oldDate = String(data.yearMonth)

          let year = Number(oldDate.substring(0,4))
          let week = Number(oldDate.substring(4))

          newDate = getDateOfWeek(week, year)

        } else {

          newDate = data.yearMonth

        }

        chartData.push({y: dateDiff, x: newDate, entryby: data.entryby})

      }

    }
    
    chartData = groupBy(chartData, 'entryby')
    
    //console.log(`chart1: ${JSON.stringify(chartData)}`)

    for (let i = 0; i < chartData.length; i++) {

      let result = await Promise.resolve(i)      

      let color = getRandomColor()

      chart.data.push({
        ...style,
        label: chartData[i][0].entryby,
        fill: false,
        data: chartData[i],
        borderColor: color,
        pointBackgroundColor: color
      })

    }    

    //console.log(`chart2: ${JSON.stringify(chart.data)}`)

    return chart

  }

  const buildChartLab = () => {

    awaitChartDataLab()
    .then(chart => {

      let canvas, ctx 

      canvas = canvasLabTimeRef.current
      ctx = canvas.getContext('2d')

      if (chartLabTimeRef.current) chartLabTimeRef.current.destroy()

      chartLabTimeRef.current = new window.Chart(ctx, {
        // The type of chart we want to create
        type: 'scatter',

        // The data for our dataset
        data: {
          labels: chart.labels,
          datasets: chart.data
        },
        options: {
          title: {
            display: true,
            text: 'Time to Enter Lab'
          },
          animation: 0,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: props.display.month ? 'month' : 'week',
                unitStepSize: 1
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{              
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {          
            labels: {
                usePointStyle: true,
            },
            fullWidth: true,
          },
          hover: {
              mode: 'nearest',
              intersect: true
          },
          tooltips: {            
            mode: 'nearest',
            callbacks: {
              label: function(tooltipItem, data) {
                let result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let newDate = props.display.week ? formatDateYMD(result.x) : result.x
                return result.entryby + ': ' + result.y + ' @ ' + newDate
              },              
            }
          }

        }

      });

    })
    .catch(err => console.log("Error: " + err ))

  }

  // ========= HANDLE DAILIES =========

  let listOfDailies = fetchedDailies.map(data => {
    let total = data.countIncomplete + data.countComplete + data.countSigned
    let incPer = Math.round(10*data.countIncomplete/total*100)/10
    let comPer = Math.round(10*data.countComplete/total*100)/10
    let signPer = Math.round(10*data.countSigned/total*100)/10

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{total}</td>
          <td>{Math.round(data.dateDiff * 100) / 100}</td>
          <td>{Math.round(10*data.sumHours/total)/10}</td>
          <td>{data.sumHours}</td>
          <td>{incPer}</td>
          <td>{signPer}</td>
        </tr>
      )

    }
  })


  const awaitChartDataDailies = async () => {    

    let style = {
      borderWidth: 2,
      tension: 0.1,
      showLine: true,
    }  

    let chart = {
      labels: [],
      data: []
    } 

    let chartData = []

    for (let i = 0; i < fetchedDailies.length; i++) {

      let result = await Promise.resolve(i)

      let data = fetchedDailies[i]

      if (filterData(data, props.searchValue)) {

        //console.log('here2')
        
        let dateDiff = Math.round(data.dateDiff * 100) / 100

        let newDate

        if (props.display.week) {

          let oldDate = String(data.yearMonth)

          let year = Number(oldDate.substring(0,4))
          let week = Number(oldDate.substring(4))

          newDate = getDateOfWeek(week, year)

        } else {

          newDate = data.yearMonth

        }

        chartData.push({y: dateDiff, x: newDate, entryby: data.entryby})

      }

    }
    
    chartData = groupBy(chartData, 'entryby')
    
    //console.log(`chart1: ${JSON.stringify(chartData)}`)

    for (let i = 0; i < chartData.length; i++) {

      let result = await Promise.resolve(i)      

      let color = getRandomColor()

      chart.data.push({
        ...style,
        label: chartData[i][0].entryby,
        fill: false,
        data: chartData[i],
        borderColor: color,
        pointBackgroundColor: color
      })

    }    

    //console.log(`chart2: ${JSON.stringify(chart.data)}`)

    return chart

  }

  const buildChartDailies = () => {

    awaitChartDataDailies()
    .then(chart => {

      let canvas, ctx 

      canvas = canvasDailiesTimeRef.current
      ctx = canvas.getContext('2d')

      if (chartDailiesTimeRef.current) chartDailiesTimeRef.current.destroy()

      chartDailiesTimeRef.current = new window.Chart(ctx, {
        // The type of chart we want to create
        type: 'scatter',

        // The data for our dataset
        data: {
          labels: chart.labels,
          datasets: chart.data
        },
        options: {
          title: {
            display: true,
            text: 'Time to Enter Dailies'
          },
          animation: 0,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: props.display.month ? 'month' : 'week',
                unitStepSize: 1
              },
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{              
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {          
            labels: {
                usePointStyle: true,
            },
            fullWidth: true,
          },
          hover: {
              mode: 'nearest',
              intersect: true
          },
          tooltips: {            
            mode: 'nearest',
            callbacks: {
              label: function(tooltipItem, data) {
                let result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let newDate = props.display.week ? formatDateYMD(result.x) : result.x
                return result.entryby + ': ' + result.y + ' @ ' + newDate
              },              
            }
          }

        }

      });

    })
    .catch(err => console.log("Error: " + err ))

  }

  // ====================================

  let styleChart = {
    margin: 10, 
    flex: '0 1 auto', 
    overflow: 'auto', 
    maxWidth: '800px',
    minHeight: '400px'
  }

  // <div style={styleChart}>
  //   <canvas ref={canvasTestAvgRef}></canvas>
  // </div>

  return (    

    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%', overflow: 'auto'}}>        

        <div style={styleChart}>
          <canvas ref={canvasStatusRef}></canvas>
        </div>

        <div style={styleChart}>
          <canvas ref={canvasNukeRef}></canvas>
        </div>

        <div style={styleChart}>
          <canvas ref={canvasTestTimeRef}></canvas>
        </div>        

        <div style={styleChart}>
          <canvas ref={canvasDailiesTimeRef}></canvas>
        </div>

        <div style={styleChart}>
          <canvas ref={canvasLabTimeRef}></canvas>
        </div>

      </div>

    </div>

  )
}

export default Charts
