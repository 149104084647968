import { formatDateTime, formatDateYMD, catchError, getLocation, getDeviceType } from 'scripts/common'

import { createPDF } from 'scripts/pdf'

// formats plans for offline use

export const getBase64OfPlan = (url, id, modtime) => {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.onload = function () {
      //console.log(`status ${this.status}`)
      if (this.status >= 200 && this.status < 300) {

        // Create a Uint8Array from ArrayBuffer
        let bytes = new Uint8Array(xhr.response)

        // Get binary string from UTF-16 code units
        //let binary = String.fromCharCode.apply(null, codes)
        let binary = ''
        for (let i = 0; i < bytes.length; i++) {
          binary += String.fromCharCode(bytes[i])
        }

        // Convert binary to Base64
        let base64 = btoa(binary)
        //console.log(b64)

        let localPlan = {
          id: id,
          modtime: modtime,
          base64: base64
        }

        localStorage.setItem('localPlan', JSON.stringify(localPlan))

        resolve(base64);

      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }

    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    // Send HTTP request and fetch file as ArrayBuffer
    xhr.responseType = 'arraybuffer'
    xhr.send()
  });
}

// const connectToDatabase = (resolve, reject) => {
//   return new Promise((resolve, reject) => {
//
//     let req = window.indexedDB.open('albusData', 1);
//     req.onsuccess = (e) => {
//
//       //dbDef.dbCon = e.target.result;
//       resolve(e.target.result);
//     }
//     req.onupgradeneeded = (e) => {
//
//       //dbDef.dbCon = e.target.result;
//       //dbDef.dbInit = 1;
//       resolve(e.target.result);
//     }
//     req.onerror = (e) => {
//     // Returns error event
//       reject(console.log('failed to connect'));
//     }
//   });
// }

// async function connectToDatabase() {
//
//   if (window.indexedDB) {
//
//     let request = window.indexedDB.open('albusData', 1)
//
//     request.onerror = function(event) {
//       console.log("Error: connectToDatabase");
//     }
//
//     request.onsuccess = (event) => {
//        //db = event.target.result
//     }
//
//     return request.result
//
//   } else {
//     return null
//   }
//
// }

export async function fetchWithTimeout(path, options) {
  let timeout = 3000
  let controller = new AbortController()
  let id = setTimeout(() => controller.abort(), timeout)

  let response = await fetch(path, {
    ...options,
    timeout: timeout,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}

// const syncData = (db, data, key) => {
//   //This promise will resolve when the network call succeeds
//   //Feel free to make a REST fetch using promises and assign it to networkPromise
//   let networkPromise = fetchWithTimeout(`/api/${data.action}`, {
//     method: 'post',
//     headers: {
//      'Accept': 'application/json, text/plain, */*',
//      'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data)
//   })
//   .then(res=>res.json())
//   .then(
//     (result) => {
//       //console.log(JSON.stringify(result))
//       console.log('success')
//       console.log(`key2: ${key}`)
//       let txn = db.transaction('Pending', 'readwrite');
//       let request = txn.objectStore('Pending').delete(key)
//
//       request.onerror = function() {
//        // DEAL WITH THIS!!! OR LOOP AND POSSIBLE INFINITE INSERTS!!!!!
//        console.log('not Deleted.')
//       };
//
//       request.onsuccess = function() {
//        console.log('Deleted.')
//       };
//
//     },
//     (error) => {
//      console.log('Not Deleted - error: ' + error)
//     }
//   )
//
//
//   //This promise will resolve when 2 seconds have passed
//   let timeOutPromise = new Promise(function(resolve, reject) {
//     // 2 Second delay
//     setTimeout(resolve, 2000, 'Timeout Done');
//   });
//
//   Promise.all(
//   [networkPromise, timeOutPromise]).then(function(values) {
//     console.log("At least 2 secs + TTL (Network/server)");
//     //Repeat
//     syncData(db, data, key)
//   });
// }


export async function selectDataToSync() {

  return new Promise((resolve, reject) => {

    let request = window.indexedDB.open('Pending', 1)

    request.onerror = (event) => {
      console.log("Error: selectDataToSync")
      reject(false)
    }

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onsuccess = (event) => {

      let db = event.target.result

      //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
      if (db.objectStoreNames.contains('Pending')) {

        let txn = db.transaction('Pending', 'readwrite');
        let objectStore = txn.objectStore('Pending');
        let request = objectStore.openCursor()
        let result = []

        request.onerror = (event) => {
          console.log(`Error: selectDataToSync-->txn`)
          reject(false)
        }

        request.onsuccess = (event) => {

          let cursor = event.target.result
          if (cursor) {

            // let key = cursor.primaryKey
            // let value = cursor.value

            result.push({primaryKey: cursor.primaryKey, value: cursor.value})
            cursor.continue()

          } else {
             // no more results
             resolve(result) // synced
          }

        }

      }

    }

  })

}

export const syncPending = async (value) => {

  return new Promise((resolve, reject) => {

    getLocation(function(latlng){

      let data = {...value, lat: latlng.lat, lng: latlng.lng}

      fetchWithTimeout(`/api/${data.action}`, {
        method: 'post',
        headers: {
         'Accept': 'application/json, text/plain, */*',
         'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(res=>res.json())
      .then(result => {
        //console.log(JSON.stringify(result))

        //Create PDF for dailies

        switch (data.action) {
         case 'addDaily':
           createPDF(result[0].lastId, '', 'add', data.user, '')
           break
         case 'editDaily':
           createPDF(data.id, '', 'edit', data.user, '')
           break
         case 'signDaily':
           createPDF(data.id, '', 'sign', data.user, data.signature)
           break
         default:
           console.log('')
        }

        resolve(result)
      })
      .catch(error => {
        console.log(`Error selectDataToSync => fetchWithTimeout: ${error}`)
        reject(error)
      })

    })

  })

}

export const updatePending = async (data) => {

  return new Promise((resolve, reject) => {

    // IMPORTANT!!!
    // Temporary ids are assigned to new records for indexedDB
    // These must be updated with the real ids assigned after fetch
    // However, if an edit occurs before update, then the wrong id will be used
    // Therefore, result[0].lastId below updates new records after fetch
    // id is used to update the temporary id to the real id for edits on new records before the sync of ids
    //

    //Update ids for new records

    let request = window.indexedDB.open('Pending', 1)

    request.onerror = (event) => {
      console.log("Error: updatePending")
      reject(false)
    }

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onsuccess = (event) => {

      let db = event.target.result

      //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
      if (db.objectStoreNames.contains('Pending')) {

        let txn = db.transaction('Pending', 'readwrite');
        let objectStore = txn.objectStore('Pending');
        let request = objectStore.openCursor()
        let result = []

        request.onerror = (event) => {
          console.log(`Error: selectDataToSync-->txn`)
          reject(false)
        }

        request.onsuccess = (event) => {

          console.log('update pendingData => indexedDB')

          let cursor = event.target.result
          if (cursor) {

            switch(data.table) {
              case 'PlansDrawDrawings':

                if (cursor.value.drawId === data.oldId) {
                  let updateData = cursor.value
                  updateData.drawId = data.newId
                  cursor.update(updateData)
                  console.log('update pendingData => indexedDB => drawings')
                }

                break
              case 'PlansDrawTests':

                if (cursor.value.testId === data.oldId) {
                  let updateData = cursor.value
                  updateData.testId = data.newId
                  cursor.update(updateData)
                  console.log('update pendingData => indexedDB => tests')
                }

                break
              case 'PlansDrawLab':

                if (cursor.value.labId === data.oldId) {
                  let updateData = cursor.value
                  updateData.labId = data.newId
                  cursor.update(updateData)
                  console.log('update pendingData => indexedDB => lab')
                }

                break
              default:

                if (cursor.value.id === data.oldId) {

                  // console.log(`
                  //   cursor.value.id: ${cursor.value.id}
                  //   data.oldId: ${data.oldId}
                  //   data.newId: ${data.newId}
                  // `)

                  let updateData = cursor.value
                  updateData.id = data.newId
                  cursor.update(updateData)
                  console.log('update pendingData => indexedDB => other')
                }

            }

            cursor.continue()

          } else {
             // no more results
             resolve(result) // synced
          }

        }

      }

    }

  })

}

export const deletePending = async (key) => {

  return new Promise((resolve, reject) => {

    let request = window.indexedDB.open('Pending', 1)

    request.onerror = (event) => {
      console.log("Error: deletePending")
      reject(false)
    }

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onsuccess = (event) => {

      let db = event.target.result

      //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
      if (db.objectStoreNames.contains('Pending')) {

        let txn = db.transaction('Pending', 'readwrite');
        let objectStore = txn.objectStore('Pending');
        let request = objectStore.openCursor()

        request.onerror = (event) => {
          console.log(`Error: deletePending-->txn`)
          reject(event)
        }

        request.onsuccess = (event) => {

          let cursor = event.target.result
          if (cursor) {

            //console.log(`key: ${key}`)
            let txn = db.transaction('Pending', 'readwrite');
            let request = txn.objectStore('Pending').delete(key)

            request.onerror = (event) => {
             // DEAL WITH THIS!!! OR LOOP AND POSSIBLE INFINITE INSERTS!!!!!
             console.log('not Deleted.')
             reject(event)
            }

            request.onsuccess = (event) => {
             console.log('Deleted.')
             resolve(event)
            }

          } else {
             console.log('nothing to delete.')
             resolve(true)
          }

        }

      } else {
        reject(false)
      }

    }

  })

}

export const addPending = async (data) => {

  return new Promise((resolve, reject) => {

    let request = window.indexedDB.open('Pending', 1)

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onerror = (event) => {
      reject(event)
    }

    request.onsuccess = (event) => {

      let db = event.target.result
      let txn = db.transaction(['Pending'], 'readwrite')
      let store = txn.objectStore('Pending')
      let req = store.put(data)

      req.onerror = function(event) {
        reject(event)
      }
      req.onsuccess = function(event) {

        console.log('added pending')

        // for now just use buffer

        resolve(event.target.result)

        if (data.table !== '') {

          let request = window.indexedDB.open('albusData', 1)

          request.onupgradeneeded = (event) => {
            let db = event.target.result

            let id

            switch(data.table) {
              case 'PlansDrawDrawings':
                id = {keyPath: 'drawId'}
                break
              case 'PlansDrawTests':
                id = {keyPath: 'testId'}
                break
              case 'PlansDrawLab':
                id = {keyPath: 'labId'}
                break
              default:
                id = {keyPath: 'id'}
            }

            db.createObjectStore(data.table, id)

            // db.createObjectStore(data.table, {
            //    autoIncrement: true
            // })
          }

          request.onerror = (event) => {
            reject(event)
          }

          request.onsuccess = (event) => {

            console.log(`added pending-->connected to albusData-->table ${data.table}`)

            let db = event.target.result

            if (!db.objectStoreNames.contains(data.table)) {

              console.log(`table ${data.table} not found`)

              reject(false)

            } else {

              console.log(`table ${data.table} found`)

              //if (data.table ==='MyNuke') console.log(`data ${JSON.stringify(data)}`)

              let txn = db.transaction(data.table, 'readwrite')
              let store = txn.objectStore(data.table)

// IMPORTANT!!!! DOUBLE put below!?!?!?

              let req = store.put(data)

              txn.onerror = function(event) {
                console.log(`table ${data.table} found-->txn error`)
                reject(false)
              }

              switch(data.actionId) {
                case 1:
                  store.put(data)
                  break
                case 2:

                  switch(data.table) {
                    case 'PlansDrawDrawings':
                      store.put(data, data.drawId)
                      break
                    case 'PlansDrawTests':
                      store.put(data, data.testId)
                      break
                    case 'PlansDrawLab':
                      store.put(data, data.labId)
                      break
                    default:
                      store.put(data)
                  }

                  break
                case 3:

                  switch(data.table) {
                    case 'PlansDrawDrawings':
                      store.delete(data.drawId)
                      break
                    case 'PlansDrawTests':
                      store.delete(data.testId)
                      break
                    case 'PlansDrawLab':
                      store.delete(data.labId)
                      break
                    default:
                      store.delete(data.id)
                  }

                  break
                default:
                  alert('Error: actionId not recognized')
                  catchError(data.jobNumber, data.gradeId, 'offline', 'addPending', 'actionId not recognized', '', '')
              }

              req.onerror = function(event) {
                console.log(`table ${data.table} found-->action error`)
                reject(event)
              }
              req.onsuccess = function(event) {
                console.log(`added to table`)
                resolve(event.target.result)
              }

            }

          }

        }

      }

    }

  })

}

export function selectData(table) {

  return new Promise((resolve, reject) => {

    let database = table === 'Pending' ? 'Pending' : 'albusData'

    let request = window.indexedDB.open(database, 1)

    request.onupgradeneeded = (event) => {
      let db = request.result
      createTable(db)
    }

    request.onerror = (event) => {
      reject(false)
    }

    request.onsuccess = (event) => {

      //console.log(`table: ${JSON.stringify(table)}`)
      let db = event.target.result;

      if (!db.objectStoreNames.contains(table)) {

        reject(false)

      } else {

        let txn = db.transaction(table);
        let objectStore = txn.objectStore(table);
        let req = objectStore.getAll();

        txn.onerror = function(event) {
          reject(false)
          //db.close()
        }

        req.onerror = function(event) {
          reject(false)
        }
        req.onsuccess = function(event) {
          resolve(event.target.result)
        }

      }

      db.close()

    }

  })

}

export async function destroyDatabase() {
  return new Promise(function (resolve, reject) {

    let req = indexedDB.deleteDatabase('albusData')

    req.onerror = function () {
      alert('Error: Could not download, refresh the app, and try again.')
      console.log("Couldn't delete database")
      //reject(false)
    }
    req.onblocked = function () {

      window.indexedDB.open('albusData', 1).onsuccess = (e) => e.target.result.close
      //alert('Blocked: Could not download, refresh the app, and try again.')
      //console.log("Couldn't delete database due to the operation being blocked")
      //reject(false)
      resolve(true)
    }
    req.onsuccess = function () {
      console.log("Deleted database")
      resolve(true)
    }

  })
}

async function createTable(db) {

  let arrTable = [
    'JobAndGrade',
    'Info',
    'DrawingPresets',
    'PresetTypes',
    'PlansDrawDrawings',
    'PlansDrawTests',
    'PlansDrawLab',
    'DistinctPresets',
    'Tests',
    'TestPrefixes',
    'TestCurves',
    'Plans',
    'Lab',
    'Dailies',
    'DailyTasks',
    'Users',
    'MyStatus',
    'MyNuke',
    'AvailableNukes',
    'Statuses'
  ]

  let id

  for (let i=0; i < arrTable.length; i++) {
    //console.log(`before ${i}`)
    let result = await Promise.resolve(i);
    //db.deleteObjectStore(tbl)

    switch(arrTable[i]) {
      case 'PlansDrawDrawings':
        id = {keyPath: 'drawId'}
        break
      case 'PlansDrawTests':
        id = {keyPath: 'testId'}
        break
      case 'PlansDrawLab':
        id = {keyPath: 'labId'}
        break
      case 'DistinctPresets':
        id = {autoIncrement: true} // SQl returns null, so fails with keypath: id
        break
      case 'DrawingPresets':
        id = {keyPath: 'presetId'}
        break
      // case 'Users':
      //   id = {autoIncrement: true} // throws error with keypath: id, even though it appears to work with id.. simple fix for now
      //   break
      default:
        id = {keyPath: 'id'}
    }

    db.createObjectStore(arrTable[i], id)
    //console.log(`after ${i}`)
  }

  return true

}

// IF YOU DELETE DB, THEN NO PENDING AND YOU LOSE RESULTS!!!!

// export async function downloadData(jn,gd){
//   destroyDatabase()
//   .then(res => connectToDatabase())
//   .then(res => createTable(res))
//   .then(res => fetchDrawings(res, jn,gd))
// }

export function downloadData(filter) {
  return new Promise(function (resolve, reject) {

    destroyDatabase()
    .then(res => {

      let request = window.indexedDB.open('albusData', 1);

      request.onerror = function() {
        reject(request.result)
      }

      request.onupgradeneeded = function() {
        let db = request.result
        createTable(db)
      }

      request.onsuccess = function() {
        let db = request.result
        fetchData(db, filter)
        resolve(db)
      }

    })
    .catch(res => {
      console.log('downloadData --> destroyDatabase --> failed')
      reject(res)
    })

  })
}

// download the table instead of all data

export function downloadTable(table, filter) {
  return new Promise(function (resolve, reject) {

    let request = window.indexedDB.open('albusData', 1);

    request.onerror = function() {
      reject(request.result)
    }

    request.onupgradeneeded = function() {
      let db = request.result
      createTable(db)
    }

    // export async function downloadData(jn,gd){
    //   destroyDatabase()
    //   .then(res => connectToDatabase())
    //   .then(res => createTable(res))
    //   .then(res => fetchDrawings(res, jn,gd))
    // }

    request.onsuccess = function() {
      let db = request.result







      // clearData(db, table)
      // .then(res => fetchData(db, filter, table))
      //.then(res => createTable(res))
      //.then(res => fetchDrawings(res, jn,gd))

      .catch(res => {
        console.log('downloadData --> destroyDatabase --> failed')
        reject(res)
      })

    }

  })
}

const addData = (db, table, data) => {

  //console.log(`table: ${table}`)
  //if (table === 'MyStatus') console.log(`data: ${JSON.stringify(data)}`)

  let txn = db.transaction([table], 'readwrite')
  let store = txn.objectStore(table)
  let res = store.put(data)

  // Handle the success case
  res.onsuccess = function (event) {
    //console.log(event);
  };

  // Handle the error case
  res.onerror = function (event) {
    //console.log(event.target.errorCode);
  }

  // Close the database once the transaction completes
  txn.oncomplete = function () {
    //db.close();
  }

}

export const updateData = async (data) => {

  return new Promise((resolve, reject) => {

    // IMPORTANT!!!
    // Temporary ids are assigned to new records for indexedDB
    // These must be updated with the real ids assigned after fetch
    // However, if an edit occurs before update, then the wrong id will be used
    // Therefore, result[0].lastId below updates new records after fetch
    // id is used to update the temporary id to the real id for edits on new records before the sync of ids
    //

    //Update ids for new records

    if (data.table !== '') {

      let request = window.indexedDB.open('albusData', 1)

      request.onupgradeneeded = (event) => {
        let db = event.target.result

        let id

        switch(data.table) {
          case 'PlansDrawDrawings':
            id = {keyPath: 'drawId'}
            break
          case 'PlansDrawTests':
            id = {keyPath: 'testId'}
            break
          case 'PlansDrawLab':
            id = {keyPath: 'labId'}
            break
          default:
            id = {keyPath: 'id'}
        }

        db.createObjectStore(data.table, id)
      }

      request.onerror = (event) => {
        reject(event)
      }

      request.onsuccess = (event) => {

        console.log(`update table ${data.table}`)

        let db = event.target.result

        if (!db.objectStoreNames.contains(data.table)) {

          console.log(`table ${data.table} not found`)

          reject(false)

        } else {

          console.log(`table ${data.table} found`)

          let txn = db.transaction(data.table, 'readwrite')
          let store = txn.objectStore(data.table)

          switch(data.table) {
            case 'PlansDrawDrawings':
              store.delete(data.drawId)
              break
            case 'PlansDrawTests':
              store.delete(data.testId)
              break
            case 'PlansDrawLab':
              store.delete(data.labId)
              break
            default:
              store.delete(data.id)
          }

          let req = store.put(data)

          txn.onerror = function(event) {
            console.log(`table ${data.table} found-->txn error`)
            reject(false)
          }

          req.onerror = function(event) {
            console.log(`table ${data.table} found-->action error`)
            reject(event)
          }
          req.onsuccess = function(event) {
            console.log(`table id updated`)
            resolve(event.target.result)
          }

        }

      }

    }

  })

}

const clearData = (db, table, data) => {

  let txn = db.transaction([table], 'readwrite')
  let store = txn.objectStore(table)
  let res = store.put(data)

  // Handle the success case
  res.onsuccess = function (event) {
    //console.log(event);
  };

  // Handle the error case
  res.onerror = function (event) {
    //console.log(event.target.errorCode);
  }

  // Close the database once the transaction completes
  txn.oncomplete = function () {
    //db.close();
  }

}

async function fetchData (db, filter) {

  // User

  fetch('/api/selectUserOffline', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({userName: filter.userName})
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log(JSON.stringify(result))

      result = result.map(data => ({...data, device: getDeviceType(), nukeId: ''}))
      result.forEach(data => addData(db, 'Users', data))

    },
    (error) => catchError('', '', 'offline', 'login', JSON.stringify(error), '', '')
  )

  // Jobs and Grades

  fetchJobs(db, filter)

  // Drawings

  fetchDrawings(db, filter)
  fetchDrawingUtilities(db, filter)

  // Tests

  fetchTests(db, filter)
  fetchTestCurves(db, filter)
  fetchTestPrefixes(db, filter)

  // Plans

  fetchPlans(db, filter)

  // Lab

  fetchLab(db, filter)

  // Dailies

  fetchDailies(db, filter)
  fetchDailyTasks(db, filter)

  // Info

  fetchInfo(db, filter)

  // Status

  fetch('/api/myStatus', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'MyStatus', data))
    },
    (error) => catchError('', '', 'offline', 'myStatus', JSON.stringify(error), '', '')
  )

  fetch('/api/selectStatuses', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'Statuses', data))
    },
    (error) => catchError('', '', 'offline', 'selectStatuses', JSON.stringify(error), '', '')

  )

  // Nukes

  fetch('/api/myNuke', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'MyNuke', data))
    },
    (error) => catchError('', '', 'offline', 'myNuke', JSON.stringify(error), '', '')
  )

  fetch('/api/selectAvailableNukes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'AvailableNukes', data))
    },
    (error) => catchError('', '', 'offline', 'selectAvailableNukes', JSON.stringify(error), '', '')
  )

}

const fetchPlans = (db, filter) => {

  fetch('/api/selectPlans', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('Plan result: ' + JSON.stringify(result))

      result.forEach(data => {

        getBase64OfPlan(`/plans/plan${data.id}.pdf`)
        .then(result => {
          //console.log('Plan 64: ' + JSON.stringify(result))
          addData(db, 'Plans', {...data,
            base64: result
          })
        })
        .catch(error => {
          catchError('', '', 'offline', 'getBase64OfPlan', JSON.stringify(error), '', '')
        })

      })
    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectPlans', JSON.stringify(error), '', '')
    }
  )

}

const fetchDrawings = (db, filter) => {

  fetch('/api/selectPlansDrawDrawings', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      let arrayDraw = result.map(data => ({...data, x: data.x.split(","), y: data.y.split(","), n: data.n.split(","), e: data.e.split(","), visible: true, entrytime: formatDateTime(data.entrytime), modtime: formatDateTime(data.modtime)}))

      arrayDraw.forEach(data => addData(db, 'PlansDrawDrawings', data))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawDrawings', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPlansDrawLab', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      let arrayLab = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))

      arrayLab.forEach(data => addData(db, 'PlansDrawLab', data))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawLab', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPlansDrawTests', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      let arrayTest = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
      arrayTest.forEach(data => addData(db, 'PlansDrawTests', data))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawTests', JSON.stringify(error), '', '')

  )

}

const fetchDrawingUtilities = (db, filter) => {

  fetch('/api/distinctPresets', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      result = result.map(data => ({...data, status: true}))

      result.push(
        {
          id: -1,
          presetName: 'Tests',
          color: '#000000FF',
          status: true
        },
        {
          id: -2,
          presetName: 'Lab',
          color: '#964B00FF',
          status: true
        },
        {
          id: -3,
          presetName: 'Hide All',
          color: '#000000FF',
          status: false
        }
      )

      result.forEach(data => addData(db, 'DistinctPresets', data))

      //setDistinctPresets(result.map(preset => ({...preset, status: preset.presetName === 'Hide All' ? false : true})))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'distinctPresets', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPresetTypes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'PresetTypes', data))
    },
    (error) => catchError('', '', 'offline', 'selectPresetTypes', JSON.stringify(error), '', '')
  )

  fetch('/api/selectPresets', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'DrawingPresets', data))
    },
    (error) => catchError('', '', 'offline', 'selectPresets', JSON.stringify(error), '', '')
  )

}

const fetchInfo = (db, filter) => {

  fetch('/api/selectInfo', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'Info', data))
    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectInfo', JSON.stringify(error), '', '')
  )

}

const fetchDailies = (db, filter) => {

  fetch('/api/selectDailies', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter,
      userLevel: filter.userlevel
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('fetching data...')

      result = result.map(data => ({...data,
        entrytime: formatDateTime(data.entrytime),
        modtime: formatDateTime(data.modtime),
        dailiesdate: formatDateYMD(data.dailiesdate)
      }))

      result.forEach(data => addData(db, 'Dailies', data))

    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectDailies', JSON.stringify(error), '', '')
    }
  )

}

const fetchDailyTasks = (db, filter) => {

  fetch('/api/selectDailyTasks', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      gradeId: filter.gradeId
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result.forEach(data => addData(db, 'DailyTasks', data))

    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectDailyTasks', JSON.stringify(error), '', '')
  )

}

const fetchLab = (db, filter) => {

  fetch('/api/selectLab', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result = result.map(data => ({...data,
        entrytime: formatDateTime(data.entrytime),
        modtime: formatDateTime(data.modtime),
        sampledate: formatDateYMD(data.sampledate)
      }))

      result.forEach(data => addData(db, 'Lab', data))

    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectLab', JSON.stringify(error), '', '')
    }
  )

}

const fetchTests = (db, filter) => {

  fetch('/api/selectTests', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result = result.map((data, i) => {

        let relComp = Math.round((data.drydens/data.optd)*100)

        let passFail = relComp >= data.reqcomp ? 'P' : 'F'

        return {...data,
          relcomp: relComp,
          passfail: passFail,
          entrytime: formatDateTime(data.entrytime),
          modtime: formatDateTime(data.modtime),
          testdate: formatDateYMD(data.testdate)
        }

      })

      result.forEach(data => addData(db, 'Tests', data))

    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectTests', JSON.stringify(error), '', '')

  )

}

const fetchTestCurves = (db, filter) => {

  fetch('/api/selectMenuCurves', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      jobNumber: filter.jobNumber
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'TestCurves', data))
    },
    (error) => {
      catchError(filter.jobNumber, '', 'offline', 'selectMenuCurves', JSON.stringify(error), '', '')
    }
  )

}

const fetchTestPrefixes = (db, filter) => {

  fetch('/api/selectMenuPrefixes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      gradeId: filter.gradeId
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'TestPrefixes', data))
    },
    (error) => {
      catchError('', filter.gradeId, 'offline', 'selectMenuPrefixes', JSON.stringify(error), '', '')
    }
  )

}

const fetchJobs = (db, filter) => {

  fetch('/api/selectMenuJobAndGrade', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userLevel: filter.userLevel,
      guestAccess: filter.guestAccess
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'JobAndGrade', data))
    },
    (error) => catchError('', '', 'offline', 'selectMenuJobAndGrade', JSON.stringify(error), '', '')

  )

}
