import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import SearchBar from 'components/utils/SearchBar'

import { formatDateTime, formatDateYMD, formatTime, getLocation, catchError } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Nukes = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [nukes, setNukes] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    history: false
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: '',
    description: '',
    available: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: '',
    description: '',
    available: ''
  })

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // naming convention is a bit odd, maybe update
      // available Nukes

      selectData('AvailableNukes').then(res => {
        setNukes(res.sort((a, b) => Number(a.nukeId) - Number(b.nukeId)))
      })

    } else {

      fetch('/api/selectAvailableNukes', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
          setNukes(result)
        },
        (error) => {
          console.log('Error: selectAvailableNukes --> ' + error)
        }
      )

      // fetch('/api/selectNukes', {
      //   method: 'post',
      //   headers: {
      //     'Accept': 'application/json, text/plain, */*',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     by: props.user.username,
      //     time: formatDateTime(new Date())
      //   })
      // })
      // .then(res=>res.json())
      // .then(
      //   (result) => {
      //     //console.log('result: ' + JSON.stringify(result))
      //     setFetchedData(result)
      //   },
      //   (error) => {
      //     console.log('Error: selectNukes --> ' + error)
      //   }
      // )

    }

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    // let tr = e.target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent

    let tr = e.currentTarget        
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        nukeId: fetchedData[i].nukeId,
        description: fetchedData[i].description,
        available: fetchedData[i].available
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const checkOutNuke = (e) => {

    // let tr = e.target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let nukeId = td[0].textContent
    // let location = td[1].textContent

    let tr = e.currentTarget        
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    
    } else {

      let nukeId = nukes[i].nukeId
      let location = nukes[i].location  

    // if (nukeId === '' || nukeId === null) {
    //   alert('The Nuke ID was not found. Contact an admin.')
    // } else {

      if (props.user.bufferData) {

        let id = Math.max(...nukes.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'checkOutNuke',
          table: 'MyNuke',
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          nukeId: nukeId,
          location: location
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateNuke([{checkIn: '', nukeId: nukeId, entrytime: formatDateTime(new Date())}])
            //props.updateParent(nukeId)
            props.closeModal()

          },
          (error) => {

            alert('Error: could not check out nuke. Contact and admin.')
            catchError('', '', '', 'checkOutNuke', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        getLocation(function(latlng){

          fetch('/api/checkOutNuke', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              nukeId: nukeId,
              location: location
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {

              props.updateParent(nukeId)
              props.closeModal()

            },
            (error) => {

              alert('Error: could not add myNuke. Contact and admin.')
              console.log('Error: myNuke --> ' + error)
            }
          )

        })

      }

    }

  }

  const checkInNuke = (e) => {

    if (props.myNuke.length > 0) {

      if (props.user.bufferData) {

        //console.log(`checkin? ${JSON.stringify(props.myNuke)}`)

        addPending({
          actionId: 2,
          action: 'checkInNuke',
          table: 'MyNuke',
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: props.myNuke[0].id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateNuke([{checkIn: true}])
            //props.updateParent('')
            props.closeModal()

          },
          (error) => {

            alert('Error: could not check in nuke. Contact and admin.')
            catchError('', '', '', 'checkInNuke', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        getLocation(function(latlng){

          fetch('/api/checkInNuke', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              id: props.myNuke[0].id
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {              

              props.updateParent('')
              props.closeModal()

            },
            (error) => {

              alert('Error: could not edit myNuke. Contact and admin.')
              console.log('Error: editmyNuke --> ' + error)
            }
          )

        })

      }

    } else {
      alert('Error: Nuke Id not found. Contact an Admin.')
    }

    closeNuke()

  }

  const addNuke = (e) => {

    //let nuke = e.target.getAttribute('data-id')

    if (isValidated.nuke === '' || isValidated.nuke === null) {
      alert('Please select a nuke')
    } else if ((isValidated.nuke === 'Arrive Job' || isValidated.nuke === 'Depart Job') && ((props.filter.jobNumber === '' || props.filter.jobNumber === null) && (isValidated.jobNumber === '' || isValidated.jobNumber === null))) {
      alert("Please select a Job Number")
    } else {

      getLocation(function(latlng){

        fetch('/api/addNuke', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            nuke: isValidated.nuke,
            jobNumber: isValidated.jobNumber !== '' && isValidated.jobNumber !== null ? isValidated.jobNumber : props.filter.jobNumber,
            gradeId: isValidated.gradeId !== '' && isValidated.gradeId !== null ? isValidated.gradeId : props.filter.gradeId
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            props.updateParent()
            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Nuke. Contact and admin.')
            console.log('Error: addNuke --> ' + error)
          }
        )

      })

    }

  }

  const editNuke = () => {

    if ((isValidated.nuke === 'Arrive Job' || isValidated.nuke === 'Depart Job') && isValidated.jobNumber === '') {
        alert("Please select a Job Number")
    } else {

      //console.log(`isValidated: ${JSON.stringify(isValidated)}`)

      getLocation(function(latlng){

        fetch('/api/editNuke', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            nuke: isValidated.nuke,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //if (props.myNuke.length > 0) if (isValidated.nuke === 'End Day' && (props.myNuke[0].checkIn === '' || props.myNuke[0].checkIn === null)) props.checkInNuke()

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                nuke: isValidated.nuke,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId
              } :
              data
            ))

            props.updateParent()
            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Nuke. Contact and admin.')
            console.log('Error: editNuke --> ' + error)
          }
        )

      })

    }

  }

  const deleteNuke = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteNuke', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          props.updateParent()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()
          //alert('Deleted.')

        },
        (error) => {

          alert('Error: could not delete Nuke. Contact and admin.')
          console.log('Error: deleteNuke --> ' + error)
        }
      )

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const closeNuke = () => setIsModal(prevState => ({...prevState, nuke: false}))

  const toggleHistory = () => setIsModal(prevState => ({...prevState, history: isModal.history ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[1].textContent === '' || td[1].textContent === null) {
      alert('Info has not been setup. Contact a manager.')
    } else {
      setIsValidated(prevState =>
        ({...prevState,
          jobNumber: td[2].textContent,
          gradeId: td[3].textContent === 'Grading' ? 'RG' : td[1].textContent === 'Post' ? 'PG' : ''
        })
      )
      closeJob()
    }

  }

  const selectNuke = (e) => {

    let td = e.target
    //let td = tr.getElementsByTagName('td')

    setIsValidated(prevState =>
      ({...prevState,
        nuke: td[0].textContent
      })
    )

  }

  let listOfData = fetchedData.map((data, i) => {

    let nuke = data.nuke === null ? '' : data.nuke
    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let entryTime = data.entrytime === null ? '' : isModal.history ? formatDateTime(data.entrytime) : formatTime(data.entrytime)

    if (
      (isModal.history || (!isModal.history && formatDateYMD(data.entrytime) === formatDateYMD(new Date()))) &&
      (searchValue === '' ||
      nuke.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{nuke}</td>
          <td>{jn} {gd}</td>
          <td>{entryTime}</td>
        </tr>
      )
    }

  })

  const checkedOut = (e) => {

    let tr = e.currentTarget
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    
    } else {      

      alert(`Nuke #${nukes[i].nukeId} has already been checked out by ${nukes[i].user}`)

    }

  }

  let listOfNukes = nukes.map((data, i) => {

    let available = data.checkOut !== '' && data.checkOut !== null && (data.checkIn === '' || data.checkIn === null) ? false : true
    //let nukeId = available ? data.nukeId : `${data.nukeId} ${data.user}`    

    return (
      <tr style={{height: 75}} data-i={i} data-id={data.nukeId} onClick={available ? checkOutNuke : checkedOut}>
        <td style={{fontSize: 20, textDecoration: available ? 'none' : 'line-through', color: available ? 'dodgerblue' : 'tomato'}}>{data.nukeId}</td>
        <td style={{display: 'none'}}>{data.location}</td>        
      </tr>
    )
  })

  //<div style={{display: 'flex', justifyContent: 'center'}}>

  let showListofNukes = (nukes.length > 0 ?

    <div>      

      <div style={{display: 'flex', justifyContent: 'center'}}>

        <table>
          <thead>
            <tr>
              <th style={{fontSize: 20}}>Check Out a Nuke</th>              
            </tr>
          </thead>

          <tbody>
            {listOfNukes}
          </tbody>
        </table>

      </div>

    </div> :
    <p style={{margin: 10}}>No nukes added. Contact management.</p>

  )

  let content = (

    <div>

      {isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{textAlign: 'center'}}>

        <div style={{display: 'inline-block'}}>

          {
            props.myNuke.length > 0 ?
            props.myNuke[0].checkIn !== '' && props.myNuke[0].checkIn !== null ?
            showListofNukes :
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <h1>Check In Nuke {props.myNuke[0].nukeId}?</h1>
              <div><CircleButton iconName='check_circle' onClick={checkInNuke} /></div>
            </div> :
            showListofNukes
          }

        </div>

      </div>

    </div>

  )  

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addNuke : isModal.edit ? editNuke : null} delete={isModal.edit ? deleteNuke : null} content={content} closeModal={closeModal} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div style={{textAlign: 'center'}}>

              <div style={{display: 'inline-block'}}>

                {
                  props.myNuke.length > 0 ?
                  props.myNuke[0].checkIn !== '' && props.myNuke[0].checkIn !== null ?
                  showListofNukes :
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <h1>Check In Nuke {props.myNuke[0].nukeId}?</h1>
                    <div><CircleButton iconName='check_circle' onClick={checkInNuke} /></div>
                  </div> :
                  showListofNukes
                }

              </div>

            </div>

        </div>

      </div>

    </div>

  </>
  )

}

export default Nukes
