import React, {useState, useRef, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import MaterialBreaks from 'components/input/MaterialBreaks'

import { formatDateYMD, formatDateTime, catchError, getLocation, addActivity } from 'scripts/common'

const Calendar = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    edit: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState(null)

  const clearIsValidated = () => setIsValidated(null)

  const fetchData = () => {

    fetch('/api/selectConcreteCalendar', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(`result: ${JSON.stringify(result)}`)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'Material Calendar', props.user.username)

        setFetchedData(result.map(data => (
          {...data,
            castDate: formatDateYMD(data.castDate),
            breakDate: formatDateYMD(data.breakDate)
          }
        )))

      },
      (error) => {
        console.log('Error: selectConcreteCalendar --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, edit: false}))
      clearIsValidated()
    }

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let i = tr.getAttribute('data-i')

    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      let today = new Date()
      let entry = new Date(fetchedData[i].entrytime)
      let timeDiff = Math.abs(entry.getTime() - today.getTime())
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      if (props.user.concrete < 2) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username !== fetchedData[i].entryby) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      } else if (props.user.concrete === 4) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      } else {

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      }

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(fetchedData[i])

      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const editData = () => {

    if (props.user.concrete < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      getLocation(function(latlng){

        fetch('/api/editMaterialBreaks', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            maxLoad1: isValidated.maxLoad1,
            maxLoad2: isValidated.maxLoad2,
            maxLoad3: isValidated.maxLoad3,
            maxLoad4: isValidated.maxLoad4,
            maxLoad5: isValidated.maxLoad5,
            maxLoad6: isValidated.maxLoad6,
            breakType1: isValidated.breakType1,
            breakType2: isValidated.breakType2,
            breakType3: isValidated.breakType3,
            breakType4: isValidated.breakType4,
            breakType5: isValidated.breakType5,
            breakType6: isValidated.breakType6,
            length1: isValidated.length1,
            length2: isValidated.length2,
            length3: isValidated.length3,
            length4: isValidated.length4,
            length5: isValidated.length5,
            length6: isValidated.length6,
            diameter: isValidated.diameter
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                maxLoad1: isValidated.maxLoad1,
                maxLoad2: isValidated.maxLoad2,
                maxLoad3: isValidated.maxLoad3,
                maxLoad4: isValidated.maxLoad4,
                maxLoad5: isValidated.maxLoad5,
                maxLoad6: isValidated.maxLoad6,
                breakType1: isValidated.breakType1,
                breakType2: isValidated.breakType2,
                breakType3: isValidated.breakType3,
                breakType4: isValidated.breakType4,
                breakType5: isValidated.breakType5,
                breakType6: isValidated.breakType6,
                length1: isValidated.length1,
                length2: isValidated.length2,
                length3: isValidated.length3,
                length4: isValidated.length4,
                length5: isValidated.length5,
                length6: isValidated.length6,
                diameter: isValidated.diameter
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, '', props.component, 'edit break', `Material Cast: ${isValidated.castDate}`, props.user.username)

            isChanged.current = false
            closeModal()

          },
          (error) => {
            alert('Error: could not edit calendar. Contact and admin.')
            catchError(props.filter.jobNumber, '', '', 'editMaterialCalendar', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let set = data.fieldMarking === null ? '' : data.fieldMarking
    let age = data.age === null ? '' : data.age
    let castDate = data.castDate === null ? '' : data.castDate
    let breakDate = data.breakDate === null ? '' : data.breakDate

    let today = new Date(formatDateYMD(new Date()))
    let breakDay = new Date(breakDate)

    if (
      searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      age.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      castDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      breakDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} data-i={i} onClick={selectRow} style={{background: breakDay.getTime() < today.getTime() ? 'tomato' : breakDay.getTime() === today.getTime() ? 'dodgerblue' : 'white'}}>
          <td>{jn}</td>
          <td>{set}</td>
          <td>{age}</td>
          <td>{castDate}</td>
          <td>{breakDate}</td>
        </tr>
      )
    }

  })

  let content = (

    <>

      {isModal.add || isModal.edit ? <Modal add={editData} content={<MaterialBreaks data={isValidated} validate={validate} changedData={changedData} />} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

        <>

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <div style={{textAlign: 'center'}}>

              <p>Displays breaks starting -15 days ago</p>

              <div style={{display: 'inline-block'}}>

                <table>

                  <thead>
                    <tr>
                      <th>JN</th>
                      <th>Set</th>
                      <th>Age</th>
                      <th>Cast</th>
                      <th>Break</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div>

            </div>

          </div>

        </>

      </div>

    </>

  )

  return <Modal content={content} closeModal={props.close} />
}

export default Calendar
