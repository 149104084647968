import React from "react";
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'

function PushNotifications(props) {

  // Push notification logic.

  const VAPID_PUBLIC_KEY = 'BMxyNxZ5VV1NfjtR4qmalC2lnOzxEBbs7SAwAUO84YuXUfe_vMUEKuxwPUNzP03TQLQWtPIuCiFDIagD87KK72E';  

  async function subscribeButtonHandler() {
    // Prevent the user from clicking the subscribe button multiple times.
    //subscribeButton.disabled = true;
    const result = await Notification.requestPermission();
    if (result === "denied") {
      console.error("The user explicitly denied the permission request.");
      return;
    }
    if (result === "granted") {
      console.info("The user accepted the permission request.");
    }
    const registration = await navigator.serviceWorker.getRegistration();

    if (registration) {
      console.log('registered')
    } else {
      console.log('not registered')
      //console.log(`reg: ${registration}`)
    }

    const subscribed = await registration.pushManager.getSubscription();
    if (subscribed) {
      console.info("User is already subscribed.");
      // notifyMeButton.disabled = false;
      // unsubscribeButton.disabled = false;
      return;
    } else {
      console.log('subscribing')
    }
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(VAPID_PUBLIC_KEY),
    });

    fetch('/api/add-subscription', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(subscription)
    })    
    .then(res => console.log('add-subscript worked?'))
    .catch(err => console.log('add-subscription no worky'))    

  }

  // async function unsubscribeButtonHandler() {
  //   const registration = await navigator.serviceWorker.getRegistration();
  //   const subscription = await registration.pushManager.getSubscription();
  //   fetch("/api/remove-subscription", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ endpoint: subscription.endpoint }),
  //   });
  //   const unsubscribed = await subscription.unsubscribe();
  //   if (unsubscribed) {
  //     console.info("Successfully unsubscribed from push notifications.");
  //     // unsubscribeButton.disabled = true;
  //     // subscribeButton.disabled = false;
  //     // notifyMeButton.disabled = true;
  //   }
  // }

  // Convert a base64 string to Uint8Array.
  // Must do this so the server can understand the VAPID_PUBLIC_KEY.
  function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // const notifyMe = async () => {
    
  //   const registration = await navigator.serviceWorker.getRegistration();
    
  //   if (!registration) {
  //         console.log(`
  //           No service worker has been registered yet.
  //           Push subscription on this client isn't possible until a service worker is registered.
  //           Push notification to this client isn't possible until a service worker is registered.
  //         `);
  //         //registrationStatus.textContent = 'No service worker has been registered yet.';
  //         //subscriptionStatus.textContent = "Push subscription on this client isn't possible until a service worker is registered.";
  //         //notificationStatus.textContent = "Push notification to this client isn't possible until a service worker is registered.";
  //         return;
  //       }
  //       console.log(`Service worker registered. Scope: ${registration.scope}`);
    
  //   const subscription = await registration.pushManager.getSubscription();
    
    
  //   // Service worker is registered and now we need to subscribe for push
  //   // or unregister the existing service worker.
  //   if (!subscription) {
  //     console.log(`
  //       Ready to subscribe this client to push.';
  //       Push notification to this client will be possible once subscribed.
  //     `);
  //     return;
  //   }
  //   // Service worker is registered and subscribed for push and now we need
  //   // to unregister service worker, unsubscribe to push, or send notifications.
  //   console.log(`
  //     Service worker subscribed to push. Endpoint: ${subscription.endpoint}
  //     Ready to send a push notification to this client!
  //   `);    
    

  //   fetch("/notify-me", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ 
  //       endpoint: subscription.endpoint,
  //       info: {
  //         title: "test",
  //         body: `ID: ${Math.floor(Math.random() * 100)}`
  //       }                
  //     }),
  //   })        
  //   .then((res) => {
  //     console.log("worked?");      

      
  //   })
  //   .catch((err) => {
  //     console.log(`err: ${err}`);
  //   });    

  // }

  // const notifyAll = async () => {

  //   const response = await fetch("/notify-all", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({        
  //       info: {
  //         title: 'test all',
  //         icon: 'logo192.png',
  //         body: Date.now()
  //       }                
  //     }),
  //   });
  //   if (response.status === 409) {
  //     //window.document.getElementById("notification-status-message").textContent =
  //       console.log("There are no subscribed endpoints to send messages to, yet.")
  //   }

  // }

  // async function postToServer(url, data) {
  //   let response = await fetch('/api/'+url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(data)
  //   })
  //   .then((res) => {
  //     console.log("worked?");
  //   })
  //   .catch((err) => {
  //     console.log(`err: ${err}`);
  //   });
  // }

  let styleCard = {
    flex: '1 1 120px',
    margin: 5,
    border: '1px solid gray',
    borderRadius: 5,
    height: 100,
    textAlign: 'center',
    cursor: 'pointer',
    maxWidth: '300px'
  }

  return (

    <Modal      
      content={
        <div>

          <div style={{display: 'flex', flexWrap: 'wrap', margin: 10}}>          

            <div style={styleCard} onClick={subscribeButtonHandler}>
              <h3>Subscribe!</h3>              
              <Icon name='self_improvement' outline={true} />              
            </div>

          </div>

        </div>
      }
      closeModal={props.close}
      height='none'
    />

  )
  
}

export default PushNotifications