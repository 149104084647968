import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
// import Prefix from 'components/menu/Prefix'
// import Curve from 'components/menu/Curve'
import Plans from 'components/main/Plans'
// import Nukes from 'components/main/Nukes'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity, replaceStr } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Issue = (props) => {

  // const googleRef = useRef(null)
  // const mapRef = useRef(null)
  // const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedAction, setFetchedAction] = useState([])

  const listOfActions = useRef(null)

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    loading: true,
    saving: false,
    warning: 0,
    warningContent: '',
    unresolved: false,
    action: false,
    addAction: false,
    editAction: false
  })

  const isChanged = useRef(false)
  const isChangedAction = useRef(false) 

  const [searchValue, setSearchValue] = useState('')  

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueDate: '',
    issueNo: null,
    description: '',
    resolved: 0,
    other: '',    
    n: '',
    e: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueDate: '',
    issueNo: null,
    description: '',
    resolved: 0,
    other: '',   
    n: '',
    e: ''
  })

  const [isValidatedAction, setIsValidatedAction] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueId: null,
    actionDate: '',
    description: ''
  })

  const clearIsValidatedAction = () => setIsValidatedAction({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    issueId: null,
    actionDate: '',
    description: ''
  })

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // selectData('Tests').then(res => {
      //   setFetchedData(res.sort((a, b) => Number(b.testno) - Number(a.testno)))
      //   setIsModal(prevState => ({...prevState, loading: false}))
      // })

    } else {

      fetch('/api/selectManageIssues', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map((data, i) => {

            return {...data,              
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              issueDate: formatDateYMD(data.issueDate)
            }

          }))          

          //setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectIssues', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

      fetch('/api/selectManageIssueActions', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          setFetchedAction(result.map((data, i) => {

            return {...data,              
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              actionDate: formatDateYMD(data.actionDate)
            }

          }))

          setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectIssueActions', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }  

  // useEffect(() => {
  //   if (isModal.google) initMap()
  // }, [props.filter, isModal.google, fetchedData])

  // useEffect(() => {
  //   if (isModal.google) updateMap()
  // }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()    
  }, [props.filter])  

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    //console.log(`value: ${isNaN(value)} : ${value}`)

    event.target.style.backgroundColor = state ? 'white' : 'yellow'    

    setIsValidated(prevState => ({...prevState, [name]: state ? value : ''}))
  }

  const validateAction = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'    

    setIsValidatedAction(prevState => ({...prevState, [name]: state ? value : ''}))
  }

  const selectRow = (e) => {

    let tr = e.currentTarget
    //console.log(`target: ${target.nodeName}`)
    //let tr = target.nodeName === 'I' && target.nodeName === 'SPAN' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' && target.nodeName === 'UL' ? target.parentNode.parentNode : target.parentNode
    //let tr = target.parent
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')
    let table = tr.getAttribute('data-table')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (table === 'issue') {

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        let today = new Date()
        let entry = new Date(fetchedData[i].entrytime)
        let timeDiff = Math.abs(entry.getTime() - today.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        if (props.user.issue < 2) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username !== fetchedData[i].entryby) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

        } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        } else if (props.user.issue === 4) {

          setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        } else {

          setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        }

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          issueDate: fetchedData[i].issueDate,
          issueNo: fetchedData[i].issueNo,
          description: fetchedData[i].description,
          resolved: fetchedData[i].resolved,
          other: fetchedData[i].other,
          n: fetchedData[i].n,
          e: fetchedData[i].e
        }))
        openEdit()

      } else if (table === 'listOfActions') {

        setIsValidated(prevState => ({...prevState,          
          id: fetchedData[i].id
        }))

        openAction(fetchedData[i].id)
  
      } else if (table === 'action') {         

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        let today = new Date()
        let entry = new Date(fetchedAction[i].entrytime)
        let timeDiff = Math.abs(entry.getTime() - today.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        if (props.user.issue < 2) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username !== fetchedAction[i].entryby) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedAction[i].entryby} is the owner.`}))

        } else if ((props.user.issue === 2 || props.user.issue === 3) && props.user.username === fetchedAction[i].entryby && diffDays > 1) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        } else if (props.user.issue === 4) {

          setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        } else {

          setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        }

        setIsValidatedAction(prevState => ({...prevState,
          entryby: fetchedAction[i].entryby,
          entrytime: fetchedAction[i].entrytime,
          entrylat: fetchedAction[i].entrylat,
          entrylng: fetchedAction[i].entrylng,
          entrydevice: fetchedAction[i].entrydevice,
          modby: fetchedAction[i].modby,
          modtime: fetchedAction[i].modtime,
          modlat: fetchedAction[i].modlat,
          modlng: fetchedAction[i].modlng,
          moddevice: fetchedAction[i].moddevice,
          id: fetchedAction[i].id,
          issueId: fetchedAction[i].issueId,
          actionDate: fetchedAction[i].actionDate,          
          description: fetchedAction[i].description          
        }))
        openActionEdit()

      }

    }

  }

  const changedData = () => isChanged.current = true
  
  const changedDataAction = () => isChangedAction.current = true

  const addData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addIssue', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,            
            issueDate: isValidated.issueDate,
            issueNo: isValidated.issueNo,
            description: replaceStr(isValidated.description),
            resolved: isValidated.resolved,
            other: replaceStr(isValidated.other),
            n: isValidated.n,
            e: isValidated.e
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssue', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    if (props.user.issue < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
        alert("Please select an issue date.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please select a description.")
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");
    } else if (isValidated.resolved == 2 && isValidated.other ==='') {
      alert("Please specify other.")
    } else {

      // include optd and optm???

      if (props.user.bufferData) {

        // let id = Math.max(...fetchedData.map(o => o.id)) + 1

        // addPending({
        //   actionId: 1,
        //   action: 'addTest',
        //   table: 'Tests',
        //   jobnumber: props.filter.jobNumber,
        //   gradeid: props.filter.gradeId,
        //   entryby: props.user.username,
        //   entrytime: formatDateTime(new Date()),
        //   entrylat: '',
        //   entrylng: '',
        //   entrydevice: props.user.device,
        //   modby: '',
        //   modtime: null,
        //   modlat: '',
        //   modlng: '',
        //   moddevice: '',
        //   by: props.user.username,
        //   time: formatDateTime(new Date()),
        //   lat: '',
        //   lng: '',
        //   device: props.user.device,
        //   id: id,
        //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //   testdate: isValidated.testDate,
        //   pre: isValidated.pre,
        //   testno: isValidated.testNo,
        //   suf: isValidated.suf,
        //   testtype: isValidated.testType,
        //   north: isValidated.north,
        //   east: isValidated.east,
        //   elevd: isValidated.elevD,
        //   moistcont: isValidated.moistCont,
        //   drydens: isValidated.dryDens,
        //   maxId: isValidated.maxId,
        //   curve: isValidated.curve,
        //   optd: isValidated.optD,
        //   optm: isValidated.optM,
        //   reqcomp: isValidated.reqComp,
        //   relcomp: isValidated.relComp,
        //   passfail: isValidated.passFail,
        //   removed: isValidated.removed,
        //   showRemoved: isValidated.showRemoved
        // })
        // .then(
        //   (result) => {
        //     //console.log('result: ' + JSON.stringify(result))

        //     setFetchedData(prevState =>
        //       [
        //         {
        //           syncedID: false,
        //           entryby: props.user.username,
        //           entrytime: formatDateTime(new Date()),
        //           entrylat: '',
        //           entrylng: '',
        //           entrydevice: props.user.device,
        //           id: id,
        //           nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //           testdate: isValidated.testDate,
        //           testtype: isValidated.testType,
        //           pre: isValidated.pre,
        //           testno: isValidated.testNo,
        //           suf: isValidated.suf,
        //           testtype: isValidated.testType,
        //           north: isValidated.north,
        //           east: isValidated.east,
        //           elevd: isValidated.elevD,
        //           moistcont: isValidated.moistCont,
        //           drydens: isValidated.dryDens,
        //           maxId: isValidated.maxId,
        //           curve: isValidated.curve,
        //           optd: isValidated.optD,
        //           optm: isValidated.optM,
        //           reqcomp: isValidated.reqComp,
        //           relcomp: isValidated.relComp,
        //           passfail: isValidated.passFail,
        //           removed: isValidated.removed
        //         },
        //         ...prevState
        //       ]
        //     )

        //     isChanged.current = false
        //     closeModal()

        //   },
        //   (error) => {

        //     addToServer()
        //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestOffline', JSON.stringify(error), props.user.username, props.user.device)
        //   }
        // )

      } else {

        addToServer()

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editIssue', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            issueDate: isValidated.issueDate,
            issueNo: isValidated.issueNo,
            description: replaceStr(isValidated.description),
            resolved: isValidated.resolved,
            other: replaceStr(isValidated.other),
            n: isValidated.n,
            e: isValidated.e
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                issueDate: isValidated.issueDate,
                issueNo: isValidated.issueNo,
                description: isValidated.description,
                resolved: isValidated.resolved,
                other: isValidated.other,
                n: isValidated.n,
                e: isValidated.e
              } :
              data
            ))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssue', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    // if (props.user.issue < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')      
    } else if (isValidated.issueDate === null || isValidated.issueDate ==='') {
        alert("Please select an issue date.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please select a description.")
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");
    } else if (isValidated.resolved == 2 && isValidated.other ==='') {
      alert("Please specify other.")
    } else {

      if (props.user.bufferData) {

        // addPending({
        //   actionId: 2,
        //   action: 'editTest',
        //   table: 'Tests',
        //   jobnumber: props.filter.jobNumber,
        //   gradeid: props.filter.gradeId,
        //   entryby: props.user.username,
        //   entrytime: formatDateTime(new Date()),
        //   entrylat: '',
        //   entrylng: '',
        //   entrydevice: props.user.device,
        //   modby: '',
        //   modtime: null,
        //   modlat: '',
        //   modlng: '',
        //   moddevice: '',
        //   by: props.user.username,
        //   time: formatDateTime(new Date()),
        //   lat: '',
        //   lng: '',
        //   device: props.user.device,
        //   id: isValidated.id,
        //   nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
        //   testdate: isValidated.testDate,
        //   pre: isValidated.pre,
        //   testno: isValidated.testNo,
        //   suf: isValidated.suf,
        //   testtype: isValidated.testType,
        //   north: isValidated.north,
        //   east: isValidated.east,
        //   elevd: isValidated.elevD,
        //   moistcont: isValidated.moistCont,
        //   drydens: isValidated.dryDens,
        //   maxId: isValidated.maxId,
        //   curve: isValidated.curve,
        //   optd: isValidated.optD,
        //   optm: isValidated.optM,
        //   reqcomp: isValidated.reqComp,
        //   relcomp: isValidated.relComp,
        //   passfail: isValidated.passFail,
        //   removed: isValidated.removed,
        //   showRemoved: isValidated.showRemoved
        // })
        // .then(
        //   (result) => {
        //     //console.log('result: ' + JSON.stringify(result))

        //     setFetchedData(fetchedData.map(data =>
        //       data.id === isValidated.id ?
        //       {...data,
        //         modby: props.user.username,
        //         modtime: formatDateTime(new Date()),
        //         modlat: '',
        //         modlng: '',
        //         moddevice: props.user.device,
        //         testdate: isValidated.testDate,
        //         testtype: isValidated.testType,
        //         pre: isValidated.pre,
        //         testno: isValidated.testNo,
        //         suf: isValidated.suf,
        //         testtype: isValidated.testType,
        //         north: isValidated.north,
        //         east: isValidated.east,
        //         elevd: isValidated.elevD,
        //         moistcont: isValidated.moistCont,
        //         drydens: isValidated.dryDens,
        //         maxId: isValidated.maxId,
        //         curve: isValidated.curve,
        //         optd: isValidated.optD,
        //         optm: isValidated.optM,
        //         reqcomp: isValidated.reqComp,
        //         relcomp: isValidated.relComp,
        //         passfail: isValidated.passFail,
        //         removed: isValidated.removed
        //       } :
        //       data
        //     ))

        //     isChanged.current = false
        //     closeModal()

        //   },
        //   (error) => {

        //     addToServer()
        //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestOffline', JSON.stringify(error), props.user.username, props.user.device)
        //   }
        // )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteIssue', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))          

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue', `issue id: ${isValidated.id}: ${isValidated.description}`, props.user.username)

          //fetchData()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssue', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          // addPending({
          //   actionId: 3,
          //   action: 'deleteTest',
          //   table: 'Tests',
          //   id: isValidated.id
          // })
          // .then(
          //   (result) => {
          //     //console.log('result: ' + JSON.stringify(result))

          //     //fetchData()
          //     setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          //     isChanged.current = false
          //     closeModal()
          //     //alert('Deleted.')

          //   },
          //   (error) => {

          //     addToServer()
          //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          //   }
          // )

        } else {

          addToServer()

        }

      }

    }

  }
  
  const addAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addIssueAction', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,                        
            actionDate: isValidatedAction.actionDate,
            issueId: isValidated.id,
            description: replaceStr(isValidatedAction.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

            fetchData() // i need the id if edited
            isChangedAction.current = false
            closeAction()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addIssueAction', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    if (props.user.issue < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
        alert("Please select an action date.")
    } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
      alert("Please select a description.")
    } else {

      addToServer()

    }

  }

  const editAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editIssueAction', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,            
            device: props.user.device,
            id: isValidatedAction.id,
            actionDate: isValidatedAction.actionDate,            
            description: replaceStr(isValidatedAction.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedAction(fetchedAction.map(data =>
              data.id === isValidatedAction.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                actionDate: isValidatedAction.actionDate,                
                description: isValidatedAction.description
              } :
              data
            ))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, `edit issue action`, `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

            isChangedAction.current = false
            closeAction()            

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editIssueAction', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }
    
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChangedAction.current === false) {
      alert('Nothing has been changed.')      
    } else if (isValidatedAction.actionDate === null || isValidatedAction.actionDate ==='') {
        alert("Please select an action date.")
    } else if (isValidatedAction.description === null || isValidatedAction.description ==='') {
      alert("Please select a description.")
    } else {

      addToServer()

    }

  }

  const deleteAction = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteIssueAction', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidatedAction.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))          

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete issue action', `issue id: ${isValidated.id}: ${isValidatedAction.description}`, props.user.username)

          //fetchData()
          setFetchedAction(fetchedAction.filter(data => data.id !== isValidatedAction.id))
          isChangedAction.current = false
          closeModal()

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteIssueAction', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) addToServer()        

    }

  } 

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.issue < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      let maxIssueNo = Math.max(...fetchedData.map(data => data.issueNo))

      setIsValidated(prevState => ({...prevState, issueNo: fetchedData.length === 0 ? 1 : maxIssueNo + 1}))
      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))  

  const toggleUnresolved = () => setIsModal(prevState => ({...prevState, unresolved: isModal.unresolved ? false : true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const openAction = (id) => {

    let actions = fetchedAction.map((data, i) => {
      
      if (id === data.issueId) {

        return (
          <>
            <tr key={data.id.toString()} data-table='action' data-i={i} data-id={data.id} onClick={selectRow}>            
              <td style={{textAlign: 'left'}}>{data.description} - <span style={{color: 'dodgerblue'}}>{data.entryby}</span> <span style={{color: 'gray'}}>{data.actionDate}</span></td>          
            </tr>                
          </>
        )

      }
  
    })

    listOfActions.current = (
      <table>

        <thead>
          <tr>
            <th></th>
            <th style={{textAlign: 'left'}}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {actions}
        </tbody>

      </table>
    )
    
    setIsModal(prevState => ({...prevState, action: true}))

  }

  const openActionAdd = () => setIsModal(prevState => ({...prevState, addAction: true}))

  const openActionEdit = () => setIsModal(prevState => ({...prevState, editAction: true}))

  const closeAction = () => {

    if (isChangedAction.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, action: false, addAction: false, editAction: false, saving: false}))
        clearIsValidatedAction()
        isChangedAction.current = false

        listOfActions.current = null

        closeModal()
      }
    } else {
      setIsModal(prevState => ({...prevState, action: false, addAction: false, editAction: false, saving: false}))
      clearIsValidatedAction()

      listOfActions.current = null

      closeModal()
    }

  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))
      clearIsValidated()
    }

  }

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      n: n,
      e: e
    }))
  }  

  let listOfData = fetchedData.map((data, i) => {    

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    // <td>{data.issueDate}</td>
    // <td>{data.entryby}</td>
    // <td>{data.description}</td>

    if ((isModal.unresolved && data.resolved == 0 && filter) || !isModal.unresolved && filter) {

      let jn = data.jobnumber === null ? '' : data.jobnumber
      let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''

      let count = 0

      let actionList = fetchedAction.map((actionData, j) => {

        if (actionData.issueId === data.id && j === 0) {
          count += 1
          return ( 
            <li style={{marginBottom: 10}}>{actionData.description} - <span style={{color: 'dodgerblue'}}>{actionData.entryby}</span> <span style={{color: 'gray'}}>{actionData.actionDate}</span></li>
          )
        } else if (actionData.issueId === data.id) {
          count += 1
          return ( 
            <li>{actionData.description} - <span style={{color: 'dodgerblue'}}>{actionData.entryby}</span> <span style={{color: 'gray'}}>{actionData.actionDate}</span></li>
          )
        }

      })     

      
      return (
        <>
          <tr key={data.id.toString()} data-table='issue' data-i={i} data-id={data.id} onClick={selectRow}>
            <td>{data.resolved == 0 ? <Icon name='error_outline' color='tomato' /> : data.resolved == 2 ? <Icon name='sentiment_dissatisfied' color='orange' /> : <Icon name='task_alt' />}</td>
            {props.filter.jobNumber === '' ? <td>{jn} {gd}</td> : null}
            <td>{data.issueNo}</td>
            <td style={{textAlign: 'left'}}>{data.description} - <span style={{color: 'dodgerblue'}}>{data.entryby}</span> <span style={{color: 'gray'}}>{data.issueDate}</span></td>          
          </tr>
          {count > 0 ?
            <tr data-table='listOfActions' data-i={i} data-id={data.id} onClick={selectRow}>
              <td style={{textAlign: 'left', fontSize: 14}} colSpan={props.filter.jobNumber === '' ? '4' : '3'}>
                <ul>
                  {actionList}
                </ul>                
              </td>
            </tr> :
            null
          }      
        </>
      )

    }

  })

  //console.log(`other: ${isValidated.resolved}`)

  let modalContent = (
    <div>

      <div style={{textAlign: 'center'}}>

        <div>
          <label className='label'>Resolved?</label>
          <select className='select' pattern=".{1,}" name='resolved' onInput={validate} onChange={changedData} defaultValue={isValidated.resolved} required>
            <option value={0}>No</option>
            <option value={1}>Yes</option>
            <option value={2}>Other</option>
          </select>
        </div>

        {isValidated.resolved == 2 ?
          <div>            
            <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='other' placeholder='Specify other' onInput={validate} onChange={changedData} required>{isValidated.other}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Issue Date</label>
          <input className='input' type="date" pattern=".{1,}" name='issueDate' onInput={validate} onChange={changedData} defaultValue={isValidated.issueDate === '' || isValidated.issueDate === null ? null : isValidated.issueDate} required />
        </div>

        <div>
          <label className='label'>Issue No</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.issueNo} disabled />
        </div>        

        <div>
          <label className='label'>Descrip</label>
          <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} required>{isValidated.description}</textarea>
        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          {isValidated.n !== null && isValidated.n !== '' ? <p>N: {isValidated.n}</p> : null}
          {isValidated.e !== null && isValidated.e !== '' ? <p>E: {isValidated.e}</p> : null}

        </div>        

        <CircleButton iconName='maps_ugc' onClick={openActionAdd} />        

      </div>

    </div>
  )

  let actionContent = (
    <div>

      <div style={{textAlign: 'center'}}>        

        <div>
          <label className='label'>Action Date</label>
          <input className='input' type="date" pattern=".{1,}" name='actionDate' onInput={validateAction} onChange={changedDataAction} defaultValue={isValidatedAction.actionDate === '' || isValidatedAction.actionDate === null ? null : isValidatedAction.actionDate} required />
        </div>        

        <div>
          <label className='label'>Descrip</label>
          <textarea style={{maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validateAction} onChange={changedDataAction} required>{isValidatedAction.description}</textarea>
        </div>        

      </div>

    </div>
  )

  // <th>Date</th>
  // <th>Tech</th>
  // <th>Descrip</th>

  return (
    <>      
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.action ? <Modal add={openActionAdd} content={listOfActions.current} closeModal={closeAction} /> : null}
      {isModal.addAction || isModal.editAction ? <Modal add={isModal.addAction ? addAction : isModal.editAction ? editAction : null} delete={deleteAction} content={actionContent} closeModal={closeAction} isValidated={isValidatedAction} isModal={isModal} /> : null}
      {isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} /> : null}
      {!isModal.loading ?
        
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{flex: '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <div style={{width: '100%'}}>

                {props.user.issue < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                <Icon name='error_outline' color={isModal.unresolved ? 'tomato' : 'gray'} title='View unresolved issues' onClick={toggleUnresolved} />                  
                <Icon name='refresh' title='Refresh' onClick={fetchData} />

              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <small style={{margin: 10}}>{listOfData.length} Record{listOfData.length === 1 ? '' : 's'}</small>

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          <th></th>
                          {props.filter.jobNumber === '' ? <th>JN</th> : null}
                          <th>#</th>
                          <th style={{textAlign: 'left'}}>Issue</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> : <p style={{margin: 10}}>No issues found.</p>

              }

            </div>

          </div>
          
        </div> : <p style={{margin: 10}}>Loading...</p>

      }        
    </>
  )

}

export default Issue
