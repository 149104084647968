import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
import Job from 'components/menu/Job'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity, replaceStr } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const ScheduleInput = (props) => {

  const [users, setUsers] = useState([])

  const [others, setOthers] = useState([])

  const [isModal, setIsModal] = useState({
    locate: false,
    warning: 0,
    warningContent: '',
    saving: false,
    personal: false,
    other: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    status: 0,
    description: '',
    tag: '',
    startDate: props.isModal.startDate,
    startTime: null,
    duration: null,
    user: '',
    requestedBy: '',
    company: ''
  })
  
  const fetchData = () => {    

    fetch('/api/selectScheduleTechs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setUsers(result)
      },
      (error) => {
        console.log('Error: selectScheduleTechs --> ' + error)
      }
    )

    fetch('/api/selectScheduleOthers', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setOthers(result)
      },
      (error) => {
        console.log('Error: selectScheduleOthers --> ' + error)
      }
    )

  }

  useEffect(() => {

    if (props.isModal.add) {

      // if adding, temporary: dynamically assigned on post
      // let sampleNo = props.fetchedData.length < 1 ? 1 : Math.max(...props.fetchedData.map(o => o.sampleno)) + 1      

      setIsValidated(prevState => ({...prevState,        
        user: props.isModal.user === undefined ? '' : props.isModal.user,
        startTime: props.isModal.startTime === undefined ? null : props.isModal.startTime      
      }))

    } else if (props.isModal.edit) {

      let i = props.isModal.i

      //console.log(`data: ${JSON.stringify(props.fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: props.fetchedData[i].entryby,
        entrytime: props.fetchedData[i].entrytime,
        entrylat: props.fetchedData[i].entrylat,
        entrylng: props.fetchedData[i].entrylng,
        entrydevice: props.fetchedData[i].entrydevice,
        modby: props.fetchedData[i].modby,
        modtime: props.fetchedData[i].modtime,
        modlat: props.fetchedData[i].modlat,
        modlng: props.fetchedData[i].modlng,
        moddevice: props.fetchedData[i].moddevice,
        id: props.fetchedData[i].id,
        jobNumber: props.fetchedData[i].jobNumber,
        gradeId: props.fetchedData[i].gradeId,
        status: props.fetchedData[i].status,
        description: props.fetchedData[i].description,
        tag: props.fetchedData[i].tag,
        startDate: props.fetchedData[i].startDate,
        startTime: props.fetchedData[i].startTime,
        duration: props.fetchedData[i].duration,
        user: props.fetchedData[i].user,
        requestedBy: props.fetchedData[i].requestedBy,
        company: props.fetchedData[i].company
      }))

    }

    fetchData()
    
  }, [])  

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    console.log(`value: ${value}`)

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const changedData = () => isChanged.current = true  

  // const selectLocate = (n,e) => {
  //   isChanged.current = true
  //   setIsValidated(prevState => ({...prevState,
  //     n: n,
  //     e: e
  //   }))
  // }

  const addData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addSchedule', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            status: isValidated.status,
            description: replaceStr(isValidated.description),
            tag: isValidated.tag,
            startDate: isValidated.startDate,
            startTime: isValidated.startTime,
            duration: isValidated.duration,
            user: isValidated.user,
            requestedBy: isValidated.requestedBy,
            company: isValidated.company
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', '', props.user.username)

            props.updateFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                status: isValidated.status,
                description: isValidated.description,
                tag: isValidated.tag,
                startDate: isValidated.startDate,
                startTime: isValidated.startTime,
                time: isValidated.startTime.slice(0,2),
                duration: isValidated.duration,
                user: isValidated.user,
                requestedBy: isValidated.requestedBy,
                company: isValidated.company
              }, ...props.fetchedData]
            )            
            
            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add schedule. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addSchedule', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.schedule < 2) {
      alert('You do not have the required permission. Contact an admin.')
    // } else if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
    //   alert("Please select a Job Number.");
    // } else if (isValidated.gradeId === null || isValidated.gradeId === '') {
    //   alert("Please select Grading or Post");
    } else if (isValidated.status === null || isValidated.status === '') {
      alert("Please provide a status.");
    } else if (isValidated.description === '') {
      alert("Please describe the request.");
    } else if (isValidated.startDate === null || isValidated.startDate === '') {
      alert("Please provide a start date.");
    } else if (isValidated.startTime === null || isValidated.startTime === '') {
      alert("Please select a start time.");
    } else if (isValidated.duration === null || isValidated.duration === '') {
      alert("Please estimate hours.");
    // } else if (isValidated.user === null || isValidated.user === '') {
    //   alert("Please estimate hours.");
    // } else if (isValidated.requestedBy === null || isValidated.requestedBy === '') {
    //   alert("Who requested this?");
    // } else if (isValidated.company === null || isValidated.company === '') {
    //   alert("What company requested this?");    
    } else {        

      addToServer()      

    }

  }

  const editData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editSchedule', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            status: isValidated.status,
            description: replaceStr(isValidated.description),
            tag: isValidated.tag,
            startDate: isValidated.startDate,
            startTime: isValidated.startTime,
            duration: isValidated.duration,
            user: isValidated.user,
            requestedBy: isValidated.requestedBy,
            company: isValidated.company
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                id: isValidated.id,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                status: isValidated.status,
                description: isValidated.description,
                tag: isValidated.tag,
                startDate: isValidated.startDate,
                startTime: isValidated.startTime,
                time: isValidated.startTime.slice(0,2),
                duration: isValidated.duration,
                user: isValidated.user,
                requestedBy: isValidated.requestedBy,
                company: isValidated.company
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', '', props.user.username)

            isChanged.current = false
            close()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit schedule. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editSchedule', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.schedule < 2) {
      alert('You do not have the required permission. Contact an admin.')
    // } else if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
    //   alert("Please select a Job Number.");
    // } else if (isValidated.gradeId === null || isValidated.gradeId === '') {
    //   alert("Please select Grading or Post");
    } else if (isValidated.status === null || isValidated.status === '') {
      alert("Please provide a status.");
    } else if (isValidated.description === '') {
      alert("Please describe the request.");
    } else if (isValidated.startDate === null || isValidated.startDate === '') {
      alert("Please provide a start date.");
    } else if (isValidated.startTime === null || isValidated.startTime === '') {
      alert("Please select a start time.");
    } else if (isValidated.duration === null || isValidated.duration === '') {
      alert("Please estimate hours.");
    // } else if (isValidated.user === null || isValidated.user === '') {
    //   alert("Please estimate hours.");
    // } else if (isValidated.requestedBy === null || isValidated.requestedBy === '') {
    //   alert("Who requested this?");
    // } else if (isValidated.company === null || isValidated.company === '') {
    //   alert("What company requested this?");    
    } else {        

      addToServer()      

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteSchedule', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', '', props.user.username)
          
          props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          close()          

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete lab. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteSchedule', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    if (props.user.schedule < 3) {
      alert('You do not have the required permission. Contact an admin.')    
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        addToServer()

      }

    }

  }

  const togglePersonal = () => setIsModal(prevState => ({...prevState, personal: isModal.personal ? false : true}))

  const toggleOther = () => setIsModal(prevState => ({...prevState, other: isModal.other ? false : true}))

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))
  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (isValidated.jobNumber !== td[2].textContent) changedData()
    setIsValidated(prevState => ({...prevState, jobNumber: td[2].textContent}))
    closeJob()

  }

  let listOfUsers = users.map(data => {

    return <option value={data.username}>{data.fullname}</option>

  })

  let listOfOthers = others.map(data => {

    return <option value={data.username}>{data.fullname}</option>

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div 
          style={{
            outline: '2px solid dodgerblue', 
            borderRadius: 5, 
            padding: 10, 
            margin: 10, 
            cursor: 'pointer', 
            textAlign: 'center', 
            backgroundColor: isModal.personal ? 'dodgerblue' : 'white'
          }}
          onClick={togglePersonal}
        >
          <b>Vacation or Personal?</b>
        </div>

        {!isModal.personal ?

          <>

            <div>
              <label className='label'>JN</label>
              <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} onClick={openJob} />
            </div>

            <div>
              <label className='label'>Grading</label>
              <select style={{width: 75}} className='select' pattern=".{1,}" name='gradeId' onInput={validate} onChange={changedData} value={isValidated.gradeId} required>
                <option value=''></option>
                <option value='RG'>Grading</option>
                <option value='PG'>Post</option>
              </select>
            </div>

          </> : null

        }

        <div>
          <label className='label'>Start Date</label>
          <input className='input' type="date" pattern=".{1,}" name='startDate' onInput={validate} onChange={changedData} defaultValue={isValidated.startDate === '' || isValidated.startDate === null ? null : isValidated.startDate} required />
        </div>

        <div>
          <label className='label'>Start Time</label>
          <input className='input' type="time" pattern="/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/" name='startTime' onInput={validate} onChange={changedData} defaultValue={isValidated.startTime === '' || isValidated.startTime === null ? null : isValidated.startTime} required />
        </div>

        <div>
          <label className='label'>Expected Hours</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,3}(\.\d{1})?" name='duration' onInput={validate} onChange={changedData} value={isValidated.duration} required />        
        </div>

        <div>
          <label className='label'>Description</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} value={isValidated.description} required />
        </div>        

        {!isModal.personal ?

          <div>
            <label className='label'>Status</label>
            <select className='select' pattern=".{1,}" name='status' onInput={validate} onChange={changedData} value={isValidated.status} required>
              <option value={0}>Confirmed</option>
              <option value={1}>Pending</option>
              <option value={2}>Cancelled</option>
            </select>
          </div> : null

        }

        <div 
          style={{
            outline: '2px solid dodgerblue', 
            borderRadius: 5, 
            padding: 10, 
            margin: 10, 
            cursor: 'pointer', 
            textAlign: 'center', 
            backgroundColor: isModal.other ? 'dodgerblue' : 'white'
          }}
          onClick={toggleOther}
        >
          <b>Other User?</b>
        </div>

        {!isModal.other ?

          <div>
            <label className='label'>Tech</label>
            <select className='select' pattern=".{1,}" name='user' onInput={validate} onChange={changedData} value={isValidated.user} required>
              <option value=''></option>
              {listOfUsers}
            </select>
          </div> :

          <div>
            <label className='label'>Other User</label>
            <select className='select' pattern=".{1,}" name='user' onInput={validate} onChange={changedData} value={isValidated.user} required>
              <option value=''></option>
              {listOfOthers}
            </select>
          </div>

        }

        {!isModal.personal ?

          <>

            <div>
              <label className='label'>Requested By</label>
              <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='requestedBy' onInput={validate} onChange={changedData} value={isValidated.requestedBy} placeholder='Optional' required />
            </div>

            <div>
              <label className='label'>Requested Company</label>
              <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='company' onInput={validate} onChange={changedData} value={isValidated.company} placeholder='Optional' required />
            </div>

          </> : null

        }

      </div>

    </div>
  )
  
  let content = (    
    <>
      {isModal.job ? <Job selectJob={selectJob} closeModal={closeJob} /> : null}
      {props.isModal.add || props.isModal.edit ? 
        <Modal 
          add={props.isModal.add ? addData : props.isModal.edit ? editData : null}
          delete={props.isModal.edit ? deleteData : null} 
          content={modalContent} 
          closeModal={close} 
          isValidated={isValidated} 
          isModal={isModal}
          zIndex={props.zIndex} 
        /> : null
      }
        
    </>     
    
  )
  
  return content  

}

export default ScheduleInput
