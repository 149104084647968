import React, { useState, useEffect, useRef } from 'react'
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'

const SiteLocation = (props) => {  

  const googleRef = useRef(null)

  const siteInfo = useRef({
    latitude: '',
    longitude: '',
    siteLocation: '',
    siteArea: ''
  })


  const polygons = useRef([])

  useEffect(() => {

    let info = props.latlng

    //console.log(`info: ${JSON.stringify(info)}`)

    siteInfo.current = {
      latitude: info.lat,
      longitude: info.lng,
      siteLocation: info.siteLocation,
      siteArea: info.siteArea
    }

    initMap()
  }, [])

  let siteLocation

  function initMap() {

    //console.log(`site? ${siteInfo.current.siteLocation}`)

    let lat, lng

    if (siteInfo.current.latitude === null || siteInfo.current.latitude === '' || siteInfo.current.longitude === null || siteInfo.current.longitude === '') {

      lat = 33.847827
      lng = -117.862286

      //lat = Number(props.filter.latitude)
      //lng = Number(props.filter.longitude)
    } else {
      lat = Number(siteInfo.current.latitude)
      lng = Number(siteInfo.current.longitude)
    }

    let google = googleRef.current
    const myLatLng = { lat: lat, lng: lng };

    let map = new window.google.maps.Map(google, {
      mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      zoom: 17,
      center: myLatLng,
      tilt: 0
    })

    // now handle the siteLocation if exists

    if (siteInfo.current.siteLocation !== '' && siteInfo.current.siteLocation !== null) {

      if (siteInfo.current.siteLocation.length > 0) {

        let id = polygons.current.length

        polygons.current.push({id: id, latlng: siteInfo.current.siteLocation, area: siteInfo.current.siteArea})

        let coords = []

        let siteCoords = siteInfo.current.siteLocation.split(" ")

        for (let i=0; i < siteCoords.length; i++) {
          let latlng = siteCoords[i].split(",")
          coords.push(new window.google.maps.LatLng(latlng[0], latlng[1]))
        }

        // Construct the polygon
        siteLocation = new window.google.maps.Polygon({
          id: id,
          paths: coords,
          fillColor: "#59FF33",
          fillOpacity: 0.3,
          strokeColor: "#59FF33",
          strokeWeight: 1,
          clickable: true,
          editable: false,
          draggable: false,
          zIndex: 1
        })

        //siteLocation.set("id", id)

        siteLocation.setMap(map)

        // Create the initial InfoWindow.
        // let infoWindow = new window.google.maps.InfoWindow({
        //   content: "<p>Drag and drop</p><p>or </p><p>Click the map to position</p>"
        // });
        //
        // infoWindow.open(map, marker)

        window.google.maps.event.addListener(siteLocation, 'click', function (event) {



          // Close the current InfoWindow.
          //infoWindow.close();
          // Create a new InfoWindow.

          let site = Math.round(Number(siteInfo.current.siteArea) * 100) / 100

          let infoWindow = new window.google.maps.InfoWindow({
            content: `<p><b>Lat:</b> ${siteInfo.current.latitude}</p><p><b>Lng:</b> ${siteInfo.current.longitude}</p><p><b>Area:</b> ${site} acres</p>`
          });

          // Replace the info window's content and position.
          //infoWindow.setContent(contentString);
          infoWindow.setPosition(event.latLng);

          infoWindow.open(map);


          //infoWindow.open(map, siteLocation);

        })

      }

    }

  }

  let content = (
    <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
  )

  return <Modal closeModal={props.closeModal} content={content} maxWidth='none' />
}

export default SiteLocation
