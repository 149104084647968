import React, {useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'

import { getDeviceType } from 'scripts/common'

const SearchBar = (props) => {

  let searchContainer = {

    border: '1px solid #d3d3d3',
    borderRadius: 5,
    display: 'flex',
    height: 45,
     margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
    justifyContent: 'space-between'

  }

  let searchInput = {

    background: 'transparent',
    border: 'transparent',
    borderRadius: 10,
    display: 'inline-block',
    height: 40,
    outline: 'none',
    width: 'calc(100% - 100px)'

  }

  let device = getDeviceType()

  return (
    <div>
      <div style={{width: '100%', display: 'inline-block', textAlign: 'center', maxWidth: 600}}>
        <div style={searchContainer}>

          <Icon name='search' color='gray' onClick={null} />
          <input type="text" id='searchInput' style={searchInput} onInput={props.search} placeholder='Search...' defaultValue={props.searchValue} autoFocus={device === 'desktop' ? true : false} autoComplete='off' />
          <Icon name='clear' color='gray' onClick={props.clearSearch} />

        </div>
      </div>
    </div>
  )
}

export default SearchBar
