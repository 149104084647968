import React, {useState, useEffect, useRef} from 'react'
//import Icon from 'components/utils/Icon'
//import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
// import SearchBar from 'components/utils/SearchBar'

// import ScheduleInput from 'components/input/ScheduleInput'

import { filterData } from 'scripts/common'

import 'styles/scheduleTimeline.css'

const ScheduleTimeline = (props) => {  

  let requestedJobs = props.fetchedData  
  
  const refTimeSlot = useRef([])
  const refRequestedWork = useRef([])

  const positionJob = useRef({
    moving: false,
    x: null,
    y: null,
    left: null,
    top: null,
    width: null,
    height: null
  })

  const [fetchedUsers, setFetchedUsers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  //const [listOfRequestedWork, setListOfRequestedWork] = useState(null)

  //let listOfRequestedWork = null
  
  const fetchData = () => {    

    fetch('/api/selectScheduleUsers', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        calendarDate: props.calendarDate
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(`result: ${JSON.stringify(result)}`)

        //addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

        result.unshift({username: 'Unassigned'})

        //console.log(`final: ${JSON.stringify(result)}`)

        setFetchedUsers(result)

        //setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        //catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTests', JSON.stringify(error), props.user.username, props.user.device)

      }
    )    

  }

  const positionJobs = () => {

    let totalHeightOfUnassigned = 0

    // position jobs

    for (let i=0; i < refRequestedWork.current.length; i++) {
      
      let user = refRequestedWork.current[i].user
      let time = refRequestedWork.current[i].time
      let index = refRequestedWork.current[i].index
      //console.log(`index: ${JSON.stringify(refRequestedWork.current[i])}`)
      //console.log(`useEffect: ${refTimeSlot.current[index].getBoundingClientRect().left}`)
      //console.log(`useEffect2: ${document.getElementById(`${user}${time}`).style.left}`)
      // console.log(`
      // index: ${index}
      // user: ${user}
      // time: ${time}
      // `)      
      
      //let left = refTimeSlot.current[index].getBoundingClientRect().left - document.getElementById(`user`).getBoundingClientRect().width
      let left = refTimeSlot.current[index].offsetLeft
      let width = refTimeSlot.current[index].getBoundingClientRect().width * Number(refRequestedWork.current[i].hours)
      
      document.getElementById(`${user}${time}`).style.left = (left + 5) + 'px'
      document.getElementById(`${user}${time}`).style.width = (width - 10) + 'px'      

      //console.log(`unassigned: ${document.getElementById(`${user}${time}`).offsetHeight}`)

      if (user === 'Unassigned') {

        document.getElementById(`${user}${time}`).style.top = totalHeightOfUnassigned + 'px'
        
        totalHeightOfUnassigned += document.getElementById(`${user}${time}`).offsetHeight + 5        

      }
      
    }

    //make room for unassigned    

    if (totalHeightOfUnassigned !== 0) {
      //console.log('yep')
      let els = document.getElementsByClassName("UnassignedHeight")

      Array.from(els).forEach((el) => {
        // Do stuff here
        
        el.style.height = totalHeightOfUnassigned + 'px'
        
      });

    } else {
      //console.log('nope')
    }

    // whats this again???

    // define container boundaries

    let bd = document.getElementById(`containerTimeline`).getBoundingClientRect()

    positionJob.current.left = bd.left
    positionJob.current.top = bd.top
    positionJob.current.width = bd.width
    positionJob.current.height = bd.height

  }

  useEffect(() => {

    window.onresize = positionJobs

    fetchData()     
  
    requestedJobs = props.fetchedData
    
  }, [props.display, props.calendarDate, props.fetchedData])  

  useEffect(() => {
    
    positionJobs()

    //console.log(`calendar: ${JSON.stringify(props.calendarDate)}`)
    
  })
  
  // useEffect(() => {
    
  //   console.log(`timeSlot: ${JSON.stringify(refTimeSlot.current)}`)
  // }, [fetchedUsers])

  const selectRow = (e) => {

    let td = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let data = td.getAttribute('data-date')

    //let i = td.getAttribute('data-i')

    // if (i === '' || i === null) {
    //   alert('Error: data index not found. Contact an admin.')
    // // } else if (fetchedData[i].syncedID === false) {
    // //   alert('ID not synced. Please refresh the data.')
    // } else {
      
      // openEdit(i)

      alert(`date: ${data}`)

    //}

  }  

  const openAdd = (e) => {

    e.stopPropagation()
    let div = e.currentTarget
    //let i = div.getAttribute('data-i')    

    props.openAdd(div.getAttribute('data-user'), `${div.getAttribute('data-time')}:00:00`)

    // const giveInfo = (e) => {

    //   e.stopPropagation()
    //   let div = e.currentTarget
    //   let i = div.getAttribute('data-i')
  
    //   let user = div.getAttribute('data-user')
  
    //   let time = div.getAttribute('data-time')
  
    //   alert(`Hi ${user} @ ${time}`)
  
    // }

    // let td = e.currentTarget
    // let day = `${td.getAttribute('data-date')}`    

    // console.log(day < 10 ? true : false)

    // props.openAdd(`${props.calendarDate.year}-${props.calendarDate.month + 1 < 10 ? `0${props.calendarDate.month + 1}` : props.calendarDate.month}-${day < 10 ? `0${day}` : day}T00:00:00`)

    

  }

  const openEdit = (e) => {

    e.stopPropagation()
    let div = e.currentTarget
    let i = div.getAttribute('data-i')    

    props.openEdit(i)    

  }
  
  const highlightMarker = (e) => {

    // e.stopPropagation()
    // let div = e.currentTarget
    // let i = div.getAttribute('data-i')    

    props.highlightMarker(e)    

  }

  let listOfUsers = fetchedUsers.map((data, i) => {   

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    //if (data.username === 'Unassigned') console.log(`rowUser: ${data.username}`)

    if (filter) { 
      
      return (
        <div id={data.username} className={`rowUser ${data.username}Height`}>
          {data.username}          
        </div>
      )

    }

  })

  //=========== MOVE WORK ===========  

  const mouseDown = (e) => {

    let target = e.currentTarget

    let container = document.getElementById(`containerTimeline`).getBoundingClientRect()
    let x = parseInt(e.pageX - container.left)
    let y = parseInt(e.pageY - container.top)

    let user = target.getAttribute('data-user')
    let time = target.getAttribute('data-time')

    let top = document.getElementById(`${user}${time}`).getBoundingClientRect().top

    e.preventDefault()

    positionJob.current.moving = true
    positionJob.current.initialX = x
    positionJob.current.initialY = y
    positionJob.current.currentX = x
    positionJob.current.currentY = y
    positionJob.current.elementTop = top

  }

  const mouseMove = (e) => {

    let obj = positionJob.current

    let container = document.getElementById(`containerTimeline`).getBoundingClientRect()
    let x = parseInt(e.pageX - container.left)
    let y = parseInt(e.pageY - container.top)

    let target = e.currentTarget 
    let user = target.getAttribute('data-user')
    let time = target.getAttribute('data-time')

    let top = parseInt(obj.elementTop - container.top)

    

    //let previousX = positionJob.current.currentX
    //let previousY = positionJob.current.currentY
    //let deltaX = positionJob.current.currentX - obj.initialX
    let deltaY = y - top

    // console.log(`
    //   left1: ${x}
    //   top1: ${y}
    //   `)

    if (obj.moving) {

      positionJob.current.currentX = x
      positionJob.current.currentY = y

    }

    if (obj.moving && (Math.abs(obj.initialX - obj.currentX) > 10 || Math.abs(obj.initialY - obj.currentY) > 10) ) {

      

      // console.log(`
      // left2: ${x}
      // top2: ${y}
      // moving: ${obj.moving}
      // left?: ${document.getElementById(`${user}${time}`).style.left}
      // top?: ${deltaY}
      // `)

      

      //document.getElementById(`${user}${time}`).style.left = (x-10) + 'px'
      document.getElementById(`${user}${time}`).style.top = (deltaY-10) + 'px'



    }
    
  }

  const mouseUp = (e) => {

    positionJob.current.moving = false
    positionJob.current.initialX = null
    positionJob.current.initialY = null
    positionJob.current.currentX = null
    positionJob.current.currentY = null
    positionJob.current.elementTop = null

    //console.log('false')
    
  }

  // const mstart = (e) => {

  //   //console.log('mstart');

  //   let canvas = props.canvasDraw.current
  //   let bd = canvas.getBoundingClientRect();
  //   let mx = parseInt(e.pageX - bd.x);
  //   let my = parseInt(e.pageY - bd.y);

  //   if (props.mode === 'plansmanage') {

  //     start(mx,my)

  //   } else if (tool.draw) { //  || props.mode === 'planscoords' // causing issues with scrool

  //     if (style.tool === 'planspencil' || style.tool === 'plansmarker') e.preventDefault()
  //     start(mx,my)

  //   } else if (tool.edit) {

  //     events(mx,my)

  //   } else {

  //     displayPosition(mx,my)

  //   }

  // }

  // const mmove = (e) => { //  || props.mode === 'planscoords' // causing issues with scrool

  //   let canvas = props.canvasDraw.current
  //   let bd = canvas.getBoundingClientRect();
  //   let mx = parseInt(e.pageX - bd.x);
  //   let my = parseInt(e.pageY - bd.y);

  //   if (tool.draw || props.mode === 'plansmanage') {

  //     e.preventDefault()
  //     move(mx,my)

  //   } else if (tool.edit) {

  //     e.preventDefault()
  //     moveEdit(mx,my)

  //   } else {

  //     displayPosition(mx,my)

  //   }

  // }

  // const mend = (e) => {

  //   //console.log('mend');
  //   let canvas = props.canvasDraw.current
  //   let bd = canvas.getBoundingClientRect();
  //   let px = e.pageX;
  //   let py = e.pageY;
  //   let mx = parseInt(px - bd.x);
  //   let my = parseInt(py - bd.y);

  //   if (tool.draw || props.mode === 'plansmanage') { //  || props.mode === 'planscoords' // causing issues with scrool

  //     end(mx,my,px,py);

  //   } else if (tool.edit) {

  //     endEdit()

  //   } else {

  //     displayPosition(mx,my)

  //   }

  // }

  //=================================

  let min = Math.min(...props.fetchedData.map(data => {
    
    //console.log(`mins? ${data.time}`)
    return Math.round(Number(data.time))
  }));

  let max = Math.max(...props.fetchedData.map(data => Math.round(Number(data.time)) + Number(data.duration)));
  //console.log("min: " + min);
  //console.log("max: " + max);
  
  let timeRange = []

  for (let i = 0; min + i < max; i++) {
    
    let time = min + i
    time = time.toString()
    time = time.length < 2 ? `0${time}` : time
    timeRange.push(time)
    //console.log(`i: ${i} => time: ${time}`)
  }  

  const RowsByTime = (props) => {
    let rows = timeRange.map((data, j) => {      
      
      //id={`${props.user}${data}`}

      if (props.header) {

        return (
          <div          
          className='headerTime'                     
          >
            {data}
          </div>
        )

      } else {

        //console.log(`test: ${refTimeSlot.current}`)
        //console.log(`test: ${refTimeSlot.current[refTimeSlot.current.length - 1]}`)

        let ref = refTimeSlot.current.length + 1

        //console.log(`ref0 ${refTimeSlot.current[refTimeSlot.current.length - 1]}`)

        refTimeSlot.current.push(ref)
      //ref={el => refTimeSlot.current[refTimeSlot.current.length - 1] = el}

    //   ref={el => {
    //     refTimeSlot.current[refTimeSlot.current.length - 1] = el
    //     console.log(`el: ${el !== null ? el.getBoundingClientRect().left : null}`)
    //   }
    // }

    //if (props.user === 'Unassigned') console.log(`rowTime: ${props.user}`)

        return (
          <div       
            className={`rowTime ${props.user}Height`}
            data-user={props.user} 
            data-time={data} 
            onDoubleClick={openAdd}
            onMouseOver={null}
            ref={el => refTimeSlot.current[ref - 1] = el}          
          >{`.`}           
          </div>
        )

      }

    })
    // ref={el => refTimeSlot.current[refTimeSlot.current.length] = el}
    return rows
  }

  //canvasPlan.current.style.transformOrigin = `${mx}px ${my}px`

  //scheduleContainer.current.height

  const RequestedWork = (props) => {
    let result = requestedJobs.map((data, i) => {
      
      //console.log(`users?: ${data.user} =? ${props.user}`)

      if (data.user === props.user || (data.user === '' && props.user === 'Unassigned')) {

        let user = data.user === '' && props.user === 'Unassigned' ? 'Unassigned' : data.user
        
        // UNDEFINED ERROR IN USEEFFECT IF TIME OUTSIDE RANGE

        // data.time undefined if adding a record. time is formatted in Schedule with select

        let time = data.time === undefined ? data.startTime.slice(0,2) : data.time

        let indexTime = timeRange.indexOf(data.time)
        //let indexRef = (props.i + 1) * (indexTime + 1) - 1
        let indexRef = (props.i * timeRange.length) + indexTime

        // console.log(`
        // workTime: ${data.time}
        // time: ${time}
        // indexTime ${indexTime}
        // indexRef: ${indexRef}
        // `)

        //console.log(`index: ${props.i} > ${timeRange.length} > ${indexTime} > ${time}`)

        //console.log(`indexes: time = ${time} time = ${indexTime} ref = ${indexRef} i = ${props.i}`)
        refRequestedWork.current.push({user: user, time: data.time, index : indexRef, hours: data.duration})
        
        let status = props.user === 'Unassigned' ? 'unassigned' : requestedJobs[i].jobNumber === '' ? 'personal' : requestedJobs[i].status === 0 ? 'confirmed' : requestedJobs[i].status === 1 ? 'pending' : 'cancelled'
        
        //console.log(`status: ${status}`)

        //data-id is used for highlightMarker

        return (
          <div 
            className={`requestedWork ${status}`}
            id={`${user}${data.time}`}
            data-id={data.id}
            data-i={i}
            data-user={user} 
            data-time={data.time}
            title={`
            ${requestedJobs[i].jobNumber} ${requestedJobs[i].client} (${requestedJobs[i].city})
            start: ${requestedJobs[i].startTime} => ${requestedJobs[i].duration} hrs
            Desc: ${requestedJobs[i].description}
            `}
            onDoubleClick={openEdit}
            onMouseDown={mouseDown}
            onMouseMove={mouseMove}  
            onMouseUp={mouseUp}
            onMouseOver={highlightMarker}
            onClick={highlightMarker}                 
          >
            {props.header ? data : requestedJobs[i].jobNumber === '' ? requestedJobs[i].description : `${requestedJobs[i].jobNumber} ${requestedJobs[i].description}`}
          </div>
        )

      }

    })

    return result
  }

  // reset ref
  refTimeSlot.current = []
  refRequestedWork.current = []

  let listOfRowsByUser = fetchedUsers.map((data, i) => {   

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    // <RequestedWork user={data.username} i={i} /> 

    //console.log(`user: ${data.username}`)

    if (filter) { 

      return (
        <div style={{flex: '1 1 auto'}}>
            
          <div style={{position: 'relative', display: 'flex', width: '100%', height: '100%'}}>

            <RowsByTime header={false} user={data.username} i={i} />

            <RequestedWork user={data.username} i={i} />           

          </div>

        </div>       
      )

    }

  })

  // build row of Days
  
  let calendar = props.calendarDate
  let date = new Date(calendar.year, calendar.month, 1)  
  let day
  let dayNo
  let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];  
  let dayName
  let rowOfDays = []

  while (Number(date.getMonth()) === Number(calendar.month)) {
    
    day = new Date(date).getDay()
    dayNo = new Date(date).getDate()      
    dayName = dayNames[day]    

    rowOfDays.push(
      <div className={`headerDay ${Number(calendar.day) === Number(dayNo) ? 'selectedDay' : dayName === 'Sat' || dayName === 'Sun' ? 'weekend' : 'weekDay'}`} data-id={dayNo} onClick={props.selectDay}>
        {dayName} <br />
        <b>{dayNo}</b>          
      </div>
    )

    date.setDate(date.getDate() + 1)      

  }  

  let content = (
    <div style={{margin: 10, flex: '1', overflow: 'auto'}} onMouseUp={mouseUp}>

      <div className='flexContainer'>
      
        <div style={{flex: '0 1 auto'}}>
              
          <div style={{display: 'flex', width: '100%', height: '100%'}}>

            {rowOfDays}                  

          </div>

        </div>

        <div style={{flex: '1 1 auto'}}>      

          <div style={{display: 'flex', width: '100%', height: '100%'}}>

            <div style={{flex: '0 1 auto', position: 'sticky', top: 0, left: 0, zIndex: 3}}>
            
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>

                <div id='user' className='headerUser'>User</div>

                {listOfUsers}

              </div>

            </div>

            <div style={{flex: '1 1 auto'}}>
            
              <div id='containerTimeline' style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>

                <div style={{flex: '1 1 auto', position: 'sticky', top: 0, zIndex: 2}}>
                
                  <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    
                    <RowsByTime header={true} />                

                  </div>

                </div>            

                {listOfRowsByUser}                   

              </div>

            </div>      

          </div>

        </div>

      </div>

    </div>
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default ScheduleTimeline
