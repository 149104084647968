import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import PresatInput from 'components/input/PresatInput'

import { formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline';

const Presat = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedProjectInfo, setFetchedProjectInfo] = useState([{
    requiredPresat: 0
  }])
  const [isModal, setIsModal] = useState({
    loading: true,
    add: false,
    edit: false    
  })
  // warning: 0,
  // warningContent: '',
  // loading: true,
  // saving: false
  
  const [searchValue, setSearchValue] = useState('')  

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // SEE sampleno

      // selectData('Presat').then(res => {
      //   setFetchedData(res.sort((a, b) => Number(b.sampleno) - Number(a.sampleno)))
      //   setIsModal(prevState => ({...prevState, loading: false}))
      // })

    } else {

      fetch('/api/selectPresat', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          //setFetchedData(result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map(data => (
            {...data,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              sampleDate: formatDateYMD(data.sampleDate)              
            }
          )))

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, '', props.component, 'selectPresat', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

      fetch('/api/selectProjectRequirements', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          //setFetchedData(result)          

          setFetchedProjectInfo(result.length > 0 ? result[0] : {requiredPresat: 0})
          
        },
        (error) => {
          catchError(props.filter.jobNumber, '', props.component, 'selectProjectRequirements', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }  

  useEffect(() => {
    if (
      (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
      (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    ) fetchData()
  }, [props.filter])  

  const selectRow = (e) => {

    let tr = e.currentTarget
    //console.log(`target: ${target.nodeName}`)    
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    // let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent

    // if (fetchedData[i].syncedID === false) {
    //   alert('ID not synced. Please refresh the data.')
    // } else 
    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      // // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      // let today = new Date()
      // let entry = new Date(fetchedData[i].entrytime)
      // let timeDiff = Math.abs(entry.getTime() - today.getTime())
      // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      // if (props.user.lab < 2) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      // } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username !== fetchedData[i].entryby) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      // } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      // } else if (props.user.lab < 5 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

      //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: Sample is ${fetchedData[i].status}. Contact an Admin.`}))

      // } else if (props.user.lab === 5 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

      //   alert(`Sample is ${fetchedData[i].status}. Sampled data will be lost if deleted!`)

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: `Sample is ${fetchedData[i].status}`}))

      // } else {

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      // }

      // //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      // setIsValidated(prevState => ({...prevState,
      //   entryby: fetchedData[i].entryby,
      //   entrytime: fetchedData[i].entrytime,
      //   entrylat: fetchedData[i].entrylat,
      //   entrylng: fetchedData[i].entrylng,
      //   entrydevice: fetchedData[i].entrydevice,
      //   modby: fetchedData[i].modby,
      //   modtime: fetchedData[i].modtime,
      //   modlat: fetchedData[i].modlat,
      //   modlng: fetchedData[i].modlng,
      //   moddevice: fetchedData[i].moddevice,
      //   id: fetchedData[i].id,
      //   sampleDate: fetchedData[i].sampledate,
      //   sampleNo: fetchedData[i].sampleno,
      //   location: fetchedData[i].location,
      //   represents: fetchedData[i].represents,
      //   purpose: fetchedData[i].purpose,
      //   sampleType: fetchedData[i].sampletype,
      //   n: fetchedData[i].n,
      //   e: fetchedData[i].e,
      //   status: fetchedData[i].status,
      //   finalDialReading: fetchedData[i].finalDialReading,
      //   initialDialReading: fetchedData[i].initialDialReading,
      //   ei: fetchedData[i].ei,
      //   dilution1000: fetchedData[i].dilution1000,
      //   dilution100: fetchedData[i].dilution100,
      //   dilution10: fetchedData[i].dilution10,
      //   dilution0: fetchedData[i].dilution0,
      //   so4: fetchedData[i].so4,
      //   curve: fetchedData[i].curve,
      //   deliveredby: fetchedData[i].deliveredby,
      //   deliveredtime: fetchedData[i].deliveredtime,
      // }))
      openEdit(i)

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.filter.gradeId === 'RG') {
      
      alert('You cannot add a Presat to grading')

    } else if (props.user.presat < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))

  //const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  //const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))
  
  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))  

  const updateFetchedData = (data) => setFetchedData(data)  

  let listOfData = fetchedData.map((data, i) => {    

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {      

      return (
        <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={selectRow}>
          <td>{data.sampleDate}</td>
          <td>{data.entryby}</td>
          <td className='wrapText'>{data.location}</td>
          <td className='wrapText'>{data.represents}</td>
        </tr>
      )

    }

  })

  let content = (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>
          {isModal.add || isModal.edit ?
            <PresatInput                
              close={closeModal} 
              fetchedData={fetchedData}
              updateFetchedData={updateFetchedData}              
              isModal={isModal}
              user={props.user}
              filter={props.filter}
              planMode={props.planMode}
              component={props.component}
              requiredPresat={fetchedProjectInfo.requiredPresat}
            /> : null
          }          
          {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>

                  {props.user.userlevel === 'guest' || props.user.presat < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                  
                  <Icon name='refresh' onClick={fetchData} />

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                {fetchedData.length > 0 ?

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Tech</th>                          
                          <th>Location</th>
                          <th>Represents</th>                          
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div> :
                  <p style={{margin: 10}}>No presats found.</p>

                }

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )

  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content

}

export default Presat
