import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import IconAndText from 'components/utils/IconAndText'
import CircleButton from 'components/utils/CircleButton'
import SquareButton from 'components/utils/SquareButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import DailyDescription from 'components/menu/DailyDescription'
import EquipDescription from 'components/menu/EquipDescription'
import Sign from 'components/utils/Sign'

import { getDayName, formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, addActivity } from 'scripts/common'

import { createPDF } from 'scripts/pdf'

import { addPending, selectData } from 'scripts/offline'

//import { jsPDF } from "jspdf";

const Dailies = (props) => {

  const path = `dailies/daily`

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedTasks, setFetchedTasks] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    menu: false,
    sign: false,
    google: false,
    preview: false,
    table: false,
    feed: true,
    task: false,
    warning: 0,
    warningContent: '',
    description: false,
    equipment: false,
    loading: true,
    saving: false // to prevent duplicates in modal
  })

  const [isTask, setIsTask] = useState({
    second: false,
    third: false,
    fourth: false
  })

  const isChanged = useRef(false)
  const [isUnsignedDailies, setIsUnsignedDailies] = useState(false)
  const [isGoogleMap, setIsGoogleMap] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const fileArray = useRef([])
  const canvasSign = useRef(null)
  const signaturePad = useRef(null) // old. created separate component
  //const receivedBySignature = useRef(null)
  const descriptionPosition = useRef(null)
  const equipmentPosition = useRef(null)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    completeby: '',
    completetime: null,
    signby: '',
    signtime: null,
    signlat: '',
    signlng: '',
    id: null,
    dailiesType: '',
    memoNo: null,
    dailiesDate: '',
    contractor: '',
    foreman: '',
    equipment: '',
    description: '',
    weather: '',
    yardageToday: '',
    yardageToDate: '',
    permitNo: '',
    jurisdiction: '',
    inspectorId: '',
    receivedBy: '',
    distribution: '',
    taskCode1: '',
    hours1: '',
    taskCode2: '',
    hours2: '',
    taskCode3: '',
    hours3: '',
    taskCode4: '',
    hours4: '',
    dailiesStatus: 'Incomplete'
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    completeby: '',
    completetime: null,
    signby: '',
    signtime: null,
    signlat: '',
    signlng: '',
    id: null,
    dailiesType: '',
    memoNo: null,
    dailiesDate: '',
    contractor: '',
    foreman: '',
    equipment: '',
    description: '',
    weather: '',
    yardageToday: '',
    yardageToDate: '',
    permitNo: '',
    jurisdiction: '',
    inspectorId: '',
    receivedBy: '',
    distribution: '',
    taskCode1: '',
    hours1: '',
    taskCode2: '',
    hours2: '',
    taskCode3: '',
    hours3: '',
    taskCode4: '',
    hours4: '',
    dailiesStatus: 'Incomplete'
  })

  async function fetchDailies() {

    let response = await fetch('/api/selectDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        userLevel: props.user.userlevel,
        userName: props.user.username
      })
    })
    return response.json();

  }

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Dailies').then(res => {
        setFetchedData(res.sort((a, b) => new Date(b.dailiesdate) - new Date(a.dailiesdate)))
        setIsModal(prevState => ({...prevState, loading: false}))
      })

      selectData('DailyTasks').then(res => {
        setFetchedTasks(res.sort((a, b) => Number(a.task) - Number(b.task)))
      })

    } else {

      fetchDailies()
      .then(
        (result) => {
          //console.log('fetching data...')
          //console.log('result: ' + JSON.stringify(result))
          //setFetchedData(result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result.map(data => (
            {...data,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              dailiesdate: formatDateYMD(data.dailiesdate)
            }
          )))

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectDailies', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

      fetch('/api/selectDailyTasks', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gradeId: props.filter.gradeId
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setFetchedTasks(result)
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectDailyTasks', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }

  useEffect(() => {
    if (props.filter.jobNumber !== '') fetchData()
  }, [props.filter])

  // useEffect(() => {
  //
  //   // On mobile devices it might make more sense to listen to orientation change,
  //   // rather than window resize events.
  //
  //   if (isModal.sign) {
  //
  //     signaturePad.current = new SignaturePad(canvasSign.current, {
  //       backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
  //     })
  //
  //     window.onresize = resizecanvasSign;
  //     resizecanvasSign() // necessary to allow signing
  //
  //   }
  //
  // }, [isModal.sign])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
     let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else if (fetchedData[i].syncedID === false) {
      alert('ID not synced. Please refresh the data.')
    } else {

      console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      //console.log(`status: ${fetchedData[i].dailiesstatus} and node: ${e.target.nodeName}`)

      if (fetchedData[i].dailiesstatus === 'Complete' && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {

        selectSign(i)

      } else if (isModal.preview && fetchedData[i].dailiesstatus !== 'Incomplete') {

        // added i to help with listOfDailyTasks

        setIsValidated(prevState => ({...prevState,
          i: i,
          id: fetchedData[i].id,
          dailiesStatus: fetchedData[i].dailiesstatus
        }))

      } else if (!isModal.preview && fetchedData[i].dailiesstatus !== 'Incomplete') {

        window.open(`dailies/daily${fetchedData[i].id}.pdf`)

      } else {

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        let today = new Date()
        let entry = new Date(fetchedData[i].entrytime)
        let timeDiff = Math.abs(entry.getTime() - today.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        if (props.user.daily < 2) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username !== fetchedData[i].entryby) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

        // } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {
        //
        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username !== fetchedData[i].entryby && fetchedData[i].dailiesstatus === 'Complete') {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: This daily is Complete. Contact a Manager.'}))

        } else if (props.user.daily < 5 && fetchedData[i].dailiesstatus === 'Signed') {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: This daily is signed. Contact a Manager.'}))

        // allow managers with add/edit permission to delete incompete/complete dailies

        // } else if (props.user.daily === 4) {
        //
        //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        } else {

          setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        }        

        // can probably remove memoNo and dailiesType

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          dailiesType: fetchedData[i].dailiestype,
          memoNo: fetchedData[i].memono,
          dailiesDate: fetchedData[i].dailiesdate,
          contractor: fetchedData[i].contractor,
          foreman: fetchedData[i].foreman,
          equipment: fetchedData[i].equipment,
          description: fetchedData[i].description,
          weather: fetchedData[i].weather,
          yardageToday: fetchedData[i].yardagetoday,
          yardageToDate: fetchedData[i].yardagetodate,
          permitNo: fetchedData[i].permitNo,
          jurisdiction: fetchedData[i].jurisdiction,
          inspectorId: fetchedData[i].inspectorId,
          receivedBy: fetchedData[i].receivedby,
          taskCode1: fetchedData[i].taskcode1,
          hours1: fetchedData[i].hours1,
          taskCode2: fetchedData[i].taskcode2,
          hours2: fetchedData[i].hours2,
          taskCode3: fetchedData[i].taskcode3,
          hours3: fetchedData[i].hours3,
          taskCode4: fetchedData[i].taskcode4,
          hours4: fetchedData[i].hours4,
          dailiesStatus: fetchedData[i].dailiesstatus,
          completeBy: fetchedData[i].completeby,
          completeTime: fetchedData[i].completetime
        }))

        openEdit()

      }

    }

  }

  const selectFeed = (e) => {

    let div = e.currentTarget    
    let i = div.getAttribute('data-i')
    
    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else if (fetchedData[i].syncedID === false) {
      alert('ID not synced. Please refresh the data.')
    } else {

      //console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      //console.log(`status: ${fetchedData[i].dailiesstatus} and node: ${e.target.nodeName}`)

      // if (fetchedData[i].dailiesstatus === 'Complete' && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {

      //   selectSign(i)

      // } else 
      
      if (isModal.preview && fetchedData[i].dailiesstatus !== 'Incomplete') {

        // added i to help with listOfDailyTasks

        setIsValidated(prevState => ({...prevState,
          i: i,
          id: fetchedData[i].id,
          dailiesStatus: fetchedData[i].dailiesstatus
        }))

      } else if (!isModal.preview && fetchedData[i].dailiesstatus !== 'Incomplete') {

        window.open(`dailies/daily${fetchedData[i].id}.pdf`)

      } else {

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        let today = new Date()
        let entry = new Date(fetchedData[i].entrytime)
        let timeDiff = Math.abs(entry.getTime() - today.getTime())
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        if (props.user.daily < 2) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username !== fetchedData[i].entryby) {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

        // } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {
        //
        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        } else if ((props.user.daily === 2 || props.user.daily === 3) && props.user.username !== fetchedData[i].entryby && fetchedData[i].dailiesstatus === 'Complete') {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: This daily is Complete. Contact a Manager.'}))

        } else if (props.user.daily < 5 && fetchedData[i].dailiesstatus === 'Signed') {

          setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: This daily is signed. Contact a Manager.'}))

        // allow managers with add/edit permission to delete incompete/complete dailies

        // } else if (props.user.daily === 4) {
        //
        //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        } else {

          setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        }        

        // can probably remove memoNo and dailiesType

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          dailiesType: fetchedData[i].dailiestype,
          memoNo: fetchedData[i].memono,
          dailiesDate: fetchedData[i].dailiesdate,
          contractor: fetchedData[i].contractor,
          foreman: fetchedData[i].foreman,
          equipment: fetchedData[i].equipment,
          description: fetchedData[i].description,
          weather: fetchedData[i].weather,
          yardageToday: fetchedData[i].yardagetoday,
          yardageToDate: fetchedData[i].yardagetodate,
          permitNo: fetchedData[i].permitNo,
          jurisdiction: fetchedData[i].jurisdiction,
          inspectorId: fetchedData[i].inspectorId,
          receivedBy: fetchedData[i].receivedby,
          taskCode1: fetchedData[i].taskcode1,
          hours1: fetchedData[i].hours1,
          taskCode2: fetchedData[i].taskcode2,
          hours2: fetchedData[i].hours2,
          taskCode3: fetchedData[i].taskcode3,
          hours3: fetchedData[i].hours3,
          taskCode4: fetchedData[i].taskcode4,
          hours4: fetchedData[i].hours4,
          dailiesStatus: fetchedData[i].dailiesstatus,
          completeBy: fetchedData[i].completeby,
          completeTime: fetchedData[i].completetime
        }))

        openEdit()

      }

    }

  }

  const selectSign = (i) => {

    setIsValidated(prevState => ({...prevState,
      id: fetchedData[i].id,
      dailiesStatus: fetchedData[i].dailiesstatus
    }))

    openSign()

  }

  const selectDailyDescription = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let description
    let addDescription = td[0].textContent

    if (descriptionPosition.current !== null) {

      if (isValidated.description !== '' && isValidated.description !== null) {

        description = [isValidated.description.slice(0, descriptionPosition.current), addDescription, isValidated.description.slice(descriptionPosition.current)].join('')

      } else {

        description = addDescription

      }

      setIsValidated(prevState => ({...prevState,
        description: description
      }))

      changedData()

    } else {
      alert('Select a position in your description to add new text.')
    }

    closeDescription()

  }

  const selectEquipDescription = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let equipment
    let addEquipment = td[0].textContent

    if (equipmentPosition.current !== null) {

      if (isValidated.equipment !== '' && isValidated.equipment !== null) {

        equipment = [isValidated.equipment.slice(0, equipmentPosition.current), addEquipment, isValidated.equipment.slice(equipmentPosition.current)].join('')

      } else {

        equipment = addEquipment

      }

      setIsValidated(prevState => ({...prevState,
        equipment: equipment
      }))

      changedData()

    } else {
      alert('Select a position in your equipment to add new text.')
    }

    closeEquipment()

  }

  const addDaily = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addDaily', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            dailiesdate: isValidated.dailiesDate,
            dailiestype: isValidated.dailiesType,
            contractor: replaceStr(isValidated.contractor),
            foreman: replaceStr(isValidated.foreman),
            weather: isValidated.weather,
            yardagetoday: isValidated.yardageToday,
            yardagetodate: isValidated.yardageToDate,
            permitNo: isValidated.permitNo,
            jurisdiction: isValidated.jurisdiction,
            inspectorId: isValidated.inspectorId,
            equipment: replaceStr(isValidated.equipment),
            description: replaceStr(isValidated.description),
            taskcode1: isValidated.taskCode1,
            taskcode2: isValidated.taskCode2,
            taskcode3: isValidated.taskCode3,
            taskcode4: isValidated.taskCode4,
            hours1: isValidated.hours1,
            hours2: isValidated.hours2,
            hours3: isValidated.hours3,
            hours4: isValidated.hours4,
            dailiesstatus: isValidated.dailiesStatus            
          })
        })
        .then(res=>res.json())
        .then(result => {
            //console.log('result: ' + JSON.stringify(result[0].lastId))

            setFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                id: result[0].lastId,
                dailiesdate: isValidated.dailiesDate,
                dailiestype: isValidated.dailiesType,
                contractor: isValidated.contractor,
                foreman: isValidated.foreman,
                weather: isValidated.weather,
                yardagetoday: isValidated.yardageToday,
                yardagetodate: isValidated.yardageToDate,
                permitNo: isValidated.permitNo,
                jurisdiction: isValidated.jurisdiction,
                inspectorId: isValidated.inspectorId,
                equipment: isValidated.equipment,
                description: isValidated.description,
                taskcode1: isValidated.taskCode1,
                taskcode2: isValidated.taskCode2,
                taskcode3: isValidated.taskCode3,
                taskcode4: isValidated.taskCode4,
                hours1: isValidated.hours1,
                hours2: isValidated.hours2,
                hours3: isValidated.hours3,
                hours4: isValidated.hours4,
                dailiesstatus: isValidated.dailiesStatus
              }, ...fetchedData]
            )

            createPDF(result[0].lastId, '', 'add', props.user, '')

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', '', props.user.username)

            isChanged.current = false
            closeModal()

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add daily. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addDaily', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.daily < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.dailiesDate === null || isValidated.dailiesDate ==='') {
      alert("Please select a Date.");
    } else if (isValidated.dailiesType === null || isValidated.dailiesType ==='') {
      alert("Please select a Type.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.permitNo === null || isValidated.permitNo ==='')) {
      alert("Please provide a Permit No.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.jurisdiction === null || isValidated.jurisdiction ==='')) {
      alert("Please provide a Jurisdiction.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.inspectorId === null || isValidated.inspectorId ==='')) {
      alert("Please provide a Inspector ID.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.contractor === null || isValidated.contractor ==='')) {
      alert("Please provide a Contractor.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.foreman === null || isValidated.foreman ==='')) {
      alert("Please provide a Foreman.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.weather === null || isValidated.weather ==='')) {
      alert("Please select the Weather.");
    } else  if (isValidated.dailiesStatus === 'Complete' && (isValidated.equipment === null || isValidated.equipment ==='')) {
      alert("Please provide Equipment used.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.description === null || isValidated.description ==='')) {
      alert("Please provide a Description of the work.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.taskCode1 === null || isValidated.taskCode1 ==='')) {
      alert("Please select at least 1 task.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.hours1 === null || isValidated.hours1 ==='')) {
      alert("Please provide your hours.");
    } else {

      // include optd and optm???

      if (props.user.bufferData) {

        let id = Math.max(...fetchedData.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'addDaily',
          table: 'Dailies',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          dailiesdate: isValidated.dailiesDate,
          dailiestype: isValidated.dailiesType,
          contractor: replaceStr(isValidated.contractor),
          foreman: replaceStr(isValidated.foreman),
          weather: isValidated.weather,
          yardagetoday: isValidated.yardageToday,
          yardagetodate: isValidated.yardageToDate,
          permitNo: isValidated.permitNo,
          jurisdiction: isValidated.jurisdiction,
          inspectorId: isValidated.inspectorId,
          equipment: replaceStr(isValidated.equipment),
          description: replaceStr(isValidated.description),
          taskcode1: isValidated.taskCode1,
          taskcode2: isValidated.taskCode2,
          taskcode3: isValidated.taskCode3,
          taskcode4: isValidated.taskCode4,
          hours1: isValidated.hours1,
          hours2: isValidated.hours2,
          hours3: isValidated.hours3,
          hours4: isValidated.hours4,
          dailiesstatus: isValidated.dailiesStatus,
          user: props.user
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(prevState =>
              [
                {
                  syncedID: false,
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  dailiesdate: isValidated.dailiesDate,
                  dailiestype: isValidated.dailiesType,
                  contractor: isValidated.contractor,
                  foreman: isValidated.foreman,
                  weather: isValidated.weather,
                  yardagetoday: isValidated.yardageToday,
                  yardagetodate: isValidated.yardageToDate,
                  permitNo: isValidated.permitNo,
                  jurisdiction: isValidated.jurisdiction,
                  inspectorId: isValidated.inspectorId,
                  equipment: isValidated.equipment,
                  description: isValidated.description,
                  taskcode1: isValidated.taskCode1,
                  taskcode2: isValidated.taskCode2,
                  taskcode3: isValidated.taskCode3,
                  taskcode4: isValidated.taskCode4,
                  hours1: isValidated.hours1,
                  hours2: isValidated.hours2,
                  hours3: isValidated.hours3,
                  hours4: isValidated.hours4,
                  dailiesstatus: isValidated.dailiesStatus
                },
                ...prevState
              ]
            )

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addDailyOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const editDaily = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editDaily', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: props.filter.jobNumber,
            gradeId: props.filter.gradeId,
            dailiesdate: isValidated.dailiesDate,
            dailiestype: isValidated.dailiesType,
            contractor: replaceStr(isValidated.contractor),
            foreman: replaceStr(isValidated.foreman),
            weather: isValidated.weather,
            yardagetoday: isValidated.yardageToday,
            yardagetodate: isValidated.yardageToDate,
            permitNo: isValidated.permitNo,
            jurisdiction: isValidated.jurisdiction,
            inspectorId: isValidated.inspectorId,
            equipment: replaceStr(isValidated.equipment),
            description: replaceStr(isValidated.description),
            taskcode1: isValidated.taskCode1,
            taskcode2: isValidated.taskCode2,
            taskcode3: isValidated.taskCode3,
            taskcode4: isValidated.taskCode4,
            hours1: isValidated.hours1,
            hours2: isValidated.hours2,
            hours3: isValidated.hours3,
            hours4: isValidated.hours4,
            dailiesstatus: isValidated.dailiesStatus,
            completeby: isValidated.completeby
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                dailiesdate: isValidated.dailiesDate,
                dailiestype: isValidated.dailiesType,
                contractor: isValidated.contractor,
                foreman: isValidated.foreman,
                weather: isValidated.weather,
                yardagetoday: isValidated.yardageToday,
                yardagetodate: isValidated.yardageToDate,
                permitNo: isValidated.permitNo,
                jurisdiction: isValidated.jurisdiction,
                inspectorId: isValidated.inspectorId,
                equipment: isValidated.equipment,
                description: isValidated.description,
                taskcode1: isValidated.taskCode1,
                taskcode2: isValidated.taskCode2,
                taskcode3: isValidated.taskCode3,
                taskcode4: isValidated.taskCode4,
                hours1: isValidated.hours1,
                hours2: isValidated.hours2,
                hours3: isValidated.hours3,
                hours4: isValidated.hours4,
                dailiesstatus: isValidated.dailiesStatus,
                completeby: isValidated.completeBy !== '' ? isValidated.completeBy : isValidated.completeBy === '' && isValidated.dailiesStatus === 'Complete' ? props.user.username : '',
                completetime: isValidated.completeTime !== '' ? isValidated.completeTime : isValidated.completeTime === '' && isValidated.dailiesStatus === 'Complete' ? formatDateTime(new Date()) : ''
              } :
              data
            ))

            createPDF(isValidated.id, '', 'edit', props.user, '')

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', '', props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit daily. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editDaily', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    // if (props.user.daily < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.dailiesDate === null || isValidated.dailiesDate ==='') {
      alert("Please select a Date.");
    } else if (isValidated.dailiesType === null || isValidated.dailiesType ==='') {
      alert("Please select a Type.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.permitNo === null || isValidated.permitNo ==='')) {
      alert("Please provide a Permit No.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.jurisdiction === null || isValidated.jurisdiction ==='')) {
      alert("Please provide a Jurisdiction.");
    } else if ((isValidated.dailiesStatus === 'Complete' && isValidated.dailiesType === 'inspector') && (isValidated.inspectorId === null || isValidated.inspectorId ==='')) {
      alert("Please provide a Inspector ID.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.contractor === null || isValidated.contractor ==='')) {
        alert("Please provide a Contractor.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.foreman === null || isValidated.foreman ==='')) {
      alert("Please provide a Foreman.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.weather === null || isValidated.weather ==='')) {
      alert("Please select the Weather.");
    } else  if (isValidated.dailiesStatus === 'Complete' && (isValidated.equipment === null || isValidated.equipment ==='')) {
      alert("Please provide Equipment used.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.description === null || isValidated.description ==='')) {
      alert("Please provide a Description of the work.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.taskCode1 === null || isValidated.taskCode1 ==='')) {
      alert("Please select at least 1 task.");
    } else if (isValidated.dailiesStatus === 'Complete' && (isValidated.hours1 === null || isValidated.hours1 ==='')) {
      alert("Please provide your hours.");
    } else {

      // include optd and optm???

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editDaily',
          table: 'Dailies',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          dailiesdate: isValidated.dailiesDate,
          dailiestype: isValidated.dailiesType,
          contractor: replaceStr(isValidated.contractor),
          foreman: replaceStr(isValidated.foreman),
          weather: isValidated.weather,
          yardagetoday: isValidated.yardageToday,
          yardagetodate: isValidated.yardageToDate,
          permitNo: isValidated.permitNo,
          jurisdiction: isValidated.jurisdiction,
          inspectorId: isValidated.inspectorId,
          equipment: replaceStr(isValidated.equipment),
          description: replaceStr(isValidated.description),
          taskcode1: isValidated.taskCode1,
          taskcode2: isValidated.taskCode2,
          taskcode3: isValidated.taskCode3,
          taskcode4: isValidated.taskCode4,
          hours1: isValidated.hours1,
          hours2: isValidated.hours2,
          hours3: isValidated.hours3,
          hours4: isValidated.hours4,
          dailiesstatus: isValidated.dailiesStatus,
          user: props.user
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                dailiesdate: isValidated.dailiesDate,
                dailiestype: isValidated.dailiesType,
                contractor: isValidated.contractor,
                foreman: isValidated.foreman,
                weather: isValidated.weather,
                yardagetoday: isValidated.yardageToday,
                yardagetodate: isValidated.yardageToDate,
                permitNo: isValidated.permitNo,
                jurisdiction: isValidated.jurisdiction,
                inspectorId: isValidated.inspectorId,
                equipment: isValidated.equipment,
                description: isValidated.description,
                taskcode1: isValidated.taskCode1,
                taskcode2: isValidated.taskCode2,
                taskcode3: isValidated.taskCode3,
                taskcode4: isValidated.taskCode4,
                hours1: isValidated.hours1,
                hours2: isValidated.hours2,
                hours3: isValidated.hours3,
                hours4: isValidated.hours4,
                dailiesstatus: isValidated.dailiesStatus
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editDailyOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const viewDaily = () => window.open(`${path}${isValidated.id}.pdf`)

  const signDaily = (signature, receivedBy) => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng) {

        fetch('/api/signDaily', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            signature: signature,
            receivedBy: receivedBy,
            dailiesStatus: 'Signed'
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                signature: signature,
                receivedBy: receivedBy,
                dailiesstatus: 'Signed'
              } :
              data
            ))

            createPDF(isValidated.id, '', 'sign', props.user, signature)

            setIsModal(prevState => ({...prevState, sign: false, preview: false}))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'sign', '', props.user.username)

            isChanged.current = false
            closeModal() // needed??

          },
          (error) => {
            setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not sign daily. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'signDaily', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.bufferData) {

      addPending({
        actionId: 2,
        action: 'signDaily',
        table: 'Dailies',
        jobnumber: props.filter.jobNumber,
        gradeid: props.filter.gradeId,
        entryby: props.user.username,
        entrytime: formatDateTime(new Date()),
        entrylat: '',
        entrylng: '',
        entrydevice: props.user.device,
        modby: '',
        modtime: null,
        modlat: '',
        modlng: '',
        moddevice: '',
        by: props.user.username,
        time: formatDateTime(new Date()),
        lat: '',
        lng: '',
        device: props.user.device,
        id: isValidated.id,
        signature: signature,
        receivedBy: receivedBy,
        dailiesstatus: 'Signed',
        user: props.user
      })
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          setFetchedData(fetchedData.map(data =>
            data.id === isValidated.id ?
            {...data,
              modby: props.user.username,
              modtime: formatDateTime(new Date()),
              modlat: '',
              modlng: '',
              signature: signature,
              receivedBy: receivedBy,
              dailiesstatus: 'Signed'
            } :
            data
          ))

          isChanged.current = false
          closeModal()

        },
        (error) => {
          addToServer()
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'signDailyOffline', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    } else {

      addToServer()

    }

  }

  const deleteDaily = () => {

    const addToServer = () => {

      setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteDaily', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id,
          path: `${path}${isValidated.id}.pdf`
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', '', props.user.username)

          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {
          setIsModal(prevState => ({...prevState, saving: false}))
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteDaily', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    // if (props.user.daily < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deleteDaily',
            table: 'Dailies',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              closeModal()

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteDailyOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const resizecanvasSign = () => {

    if (document.activeElement.tagName !== 'INPUT') {

      let ratio =  Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      canvasSign.current.width = canvasSign.current.offsetWidth * ratio;
      canvasSign.current.height = canvasSign.current.offsetHeight * ratio;
      canvasSign.current.getContext("2d").scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      signaturePad.current.clear()

    }

  }

  const addSignature = () => {

    let signature = signaturePad.current.toDataURL('image/jpeg');

    if (signature === null || signature === '') {

        alert("Signature is blank.");

    } else {

      // signature = signature.replace('data:image/jpeg;base64,', '');
	    // signature = signature.replace(' ', '+');

      getLocation(function(latlng){

        fetch('/api/addSignature', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: props.user.userid,
            signature: signature,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add signature', '', props.user.username)

            signaturePad.current.clear()
          },
          (error) => {
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addSignature', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const undoSignature = () => signaturePad.current.clear()

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const viewUnsignedDailies = () => setIsUnsignedDailies(isUnsignedDailies ? false : true)

  const addTask = () => {
    let task = !isTask.second ? 'second' : !isTask.third ? 'third' : 'fourth'
    //console.log(`task: ${task}`)

    setIsTask(prevState => ({...prevState, [task]: true}))
  }

  const removeTask1 = () => {
    console.log('clicked')
    setIsValidated(prevState => ({...prevState, taskCode1: '', hours1: ''}))
  }

  const removeTask2 = () => {
    setIsTask(prevState => ({...prevState, second: false}))
    setIsValidated(prevState => ({...prevState, taskCode2: '', hours2: ''}))
  }

  const removeTask3 = () => {
    setIsTask(prevState => ({...prevState, third: false}))
    setIsValidated(prevState => ({...prevState, taskCode3: '', hours3: ''}))
  }

  const removeTask4 = () => {
    setIsTask(prevState => ({...prevState, fourth: false}))
    setIsValidated(prevState => ({...prevState, taskCode4: '', hours4: ''}))
  }

  const openAdd = () => {

    if (props.user.daily < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      if (fetchedData.length > 0) {

        let data = fetchedData[0]

        setIsValidated(prevState => ({...prevState, contractor: data.contractor, foreman: data.foreman, equipment: data.equipment}))        

      }

      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openMenu = () => setIsModal(prevState => ({...prevState, menu: true}))

  const openSign = () => {
    //if (isValidated.dailiesStatus === 'Complete') {
      setIsModal(prevState => ({...prevState, sign: true}))
    //} else {
      //alert('The Daily must be marked Complete to sign.')
    //}
  }

  const openDescription = () => setIsModal(prevState => ({...prevState, description: true}))

  const closeDescription = () => setIsModal(prevState => ({...prevState, description: false}))

  const openEquipment = () => {
    alert('Temporarily disabled')
    //setIsModal(prevState => ({...prevState, equipment: true}))
  }

  const closeEquipment = () => setIsModal(prevState => ({...prevState, equipment: false}))

  const closeMenu = () => setIsModal(prevState => ({...prevState, menu: false}))

  const closeSign = () => setIsModal(prevState => ({...prevState, sign: false}))

  const toggleFeed = () => setIsModal(prevState => ({...prevState, feed: isModal.feed ? false : true}))

  const openPreview = () => setIsModal(prevState => ({...prevState, preview: true}))

  const toggleTask = () => setIsModal(prevState => ({...prevState, task: isModal.task ? false : true}))

  const closePreview = () => setIsModal(prevState => ({...prevState, preview: false}))

  const togglePreview = () => {
    if (isModal.preview) clearIsValidated()
    setIsModal(prevState => ({...prevState, preview: isModal.preview ? false : true}))
  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))
        setIsTask(prevState => ({...prevState, second: false, third: false, fourth: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false}))
      setIsTask(prevState => ({...prevState, second: false, third: false, fourth: false}))
      clearIsValidated()
    }

  }

  const positionDescription = (e) => {
    descriptionPosition.current = e.target.selectionStart
    //console.log(`postion: ${descriptionPosition.current}`)
  }

  const positionEquipment = (e) => {
    equipmentPosition.current = e.target.selectionStart
    //console.log(`postion: ${equipmentPosition.current}`)
  }

  const downloadDailies = () => {

    const merge = () => {

      fetch('/api/mergeDailies', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fileName: `not used`,
          fileArray: fileArray.current
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'download', '', props.user.username)

          window.open(`dailies.pdf`)

        },
        (error) => {
          alert('Error: could not merge PDFs for download. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'mergeDailies', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    if (fetchedData.length > 100) {

      if (window.confirm(`You wish to download ${fetchedData.length} dailies? This may take a few minutes. Proceed?`)) {

        merge()

      } else {

        alert('You can also select a date range to limit the number of dailies.')

      }

    } else if (fetchedData.length > 50) {

      alert(`You are downloading ${fetchedData.length} dailies. This may take a minute.`)

      merge()

    } else {

      merge()

    }

  }

  fileArray.current = [] // clear before pushing

  let listOfData = fetchedData.map((data, i) => {

    let type = data.dailiestype === null ? '' : data.dailiestype
    let description = data.description === null ? '' : data.description
    let status = data.dailiesstatus === null ? '' : data.dailiesstatus
    let dailiesDate = data.dailiesdate === null ? '' : data.dailiesdate
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (
      ((isUnsignedDailies && status === 'Incomplete') || !isUnsignedDailies) &&
      filter
    ) {

      fileArray.current.push(`daily${data.id}.pdf`)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{dailiesDate}</td>
          <td>{type}</td>
          <td>{entryBy}</td>
          <td className='clipText'>{description}</td>
          <td>{status}</td>
          <td>{status === 'Complete' ? <Icon name='edit' title='Click to Sign' outline={true} /> : null}</td>
        </tr>
      )

    }

  })

  let listOfFeed = fetchedData.map((data, i) => {

    let type = data.dailiestype === null ? '' : data.dailiestype
    let description = data.description === null ? '' : data.description
    let status = data.dailiesstatus === null ? '' : data.dailiesstatus
    let dailiesDate = data.dailiesdate === null ? '' : data.dailiesdate
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (
      ((isUnsignedDailies && status === 'Incomplete') || !isUnsignedDailies) &&
      filter
    ) {

      fileArray.current.push(`daily${data.id}.pdf`)

      // <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
      //     <td style={{display: 'none'}}>{i}</td>
      //     <td>{dailiesDate}</td>
      //     <td>{type}</td>
      //     <td>{entryBy}</td>
      //     <td className='clipText'>{description}</td>
      //     <td>{status}</td>
      //     <td>{status === 'Complete' ? <Icon name='edit' title='Click to Sign' outline={true} /> : null}</td>
      //   </tr>

      return (

        <div className='dailyFeed' data-id={data.id} data-i={i} onClick={selectFeed}>
          <p style={{fontSize: 'large'}}>{dailiesDate}{status === 'Incomplete' ? <span style={{color: 'tomato', fontSize: 'large'}}> (Incomplete)</span> : null}</p>
          <p style={{color: 'dodgerblue'}}>{entryBy}</p>
          <p>{description}</p>          
        </div>
        
      )

    }

  })

  let listOfTasks = isModal.add || isModal.edit ? fetchedTasks.map(task => <option value={task.task}>{task.task} - {task.description}</option>) : null

  let listOfDailyTasks = isModal.task && isValidated.id !== null ? fetchedTasks.map(task => {

    // console.log(`task1: ${task.task}`)
    // console.log(`task2: ${isValidated.i}`)
    // console.log(`task2: ${fetchedData[isValidated.i].taskCode1}`)
    // console.log(`task2: ${JSON.stringify(fetchedData[isValidated.i])}`)
    // console.log(`task2: ${JSON.stringify(fetchedData[isValidated.i].taskCode1)}`)

    if (task.task === fetchedData[isValidated.i].taskcode1 || task.task === fetchedData[isValidated.i].taskcode2 || task.task === fetchedData[isValidated.i].taskcode3 || task.task === fetchedData[isValidated.i].taskcode4) {
        
      return (
        <div><small>{task.task}: {task.description}</small></div>
      )

    }

  }) : null  

  let modalContent = (
    <div>

      <div style={{textAlign: 'center'}}>

        <div>
          <label className='label'>Date</label>
          <input className='input' type="date" pattern=".{1,}" name='dailiesDate' onInput={validate} onChange={changedData} defaultValue={isValidated.dailiesDate === '' || isValidated.dailiesDate === null ? null : isValidated.dailiesDate} required />
        </div>

        <div>
          <label className='label'>Daily Type</label>
          <select className='select' pattern=".{1,}" name='dailiesType' onInput={validate} onChange={changedData} defaultValue={isValidated.dailiesType} required>
            <option value=""></option>
            <option value="technician">Technician</option>
            {props.filter.gradeId === 'PG' ? <option value="inspector">Inspector</option> : null}
            <option value="engineer">Engineer</option>
            <option value="geologist">Geologist</option>
          </select>
        </div>

        {isValidated.dailiesType === 'inspector' ?

          <>

            <div>
              <div><label className='label'>Permit No.</label></div>
              <textarea
                style={{height: 50}}
                className='textArea'
                pattern=".{1,}"
                name='permitNo'
                onInput={validate}
                onChange={changedData}
                defaultValue={isModal.add && fetchedData.length > 0 ? fetchedData[fetchedData.length-1].permitNo : isValidated.permitNo}
                required
              />
            </div>

            <div>
              <div><label className='label'>Jurisdiction</label></div>
              <textarea
                style={{height: 50}}
                className='textArea'
                pattern=".{1,}"
                name='jurisdiction'
                onInput={validate}
                onChange={changedData}
                defaultValue={isModal.add && fetchedData.length > 0 ? fetchedData[fetchedData.length-1].jurisdiction : isValidated.jurisdiction}
                required
              />
            </div>

            <div>
              <div><label className='label'>Inspector ID</label></div>
              <textarea
                style={{height: 50}}
                className='textArea'
                pattern=".{1,}"
                name='inspectorId'
                onInput={validate}
                onChange={changedData}
                defaultValue={isModal.add && fetchedData.length > 0 ? fetchedData[fetchedData.length-1].inspectorId : isValidated.inspectorId}
                required
              />
            </div>

          </> : null

        }

        <div>
          <div><label className='label'>Contractor</label></div>
          <textarea
            style={{height: 50}}
            className='textArea'
            pattern="[a-zA-Z0-9]{1,}"
            name='contractor'
            onInput={validate}
            onChange={changedData}
            defaultValue={isValidated.contractor}
            required
          />
        </div>

        <div>
          <div><label className='label'>Foreman</label></div>
          <textarea
            style={{height: 50}}
            className='textArea'
            pattern="[a-zA-Z0-9]{1,}"
            name='foreman'
            onInput={validate}
            onChange={changedData}
            defaultValue={isValidated.foreman}
            required
          />
        </div>

        <div>
          <label className='label'>Weather</label>
          <select className='select' pattern=".{1,}" name='weather' onInput={validate} onChange={changedData} defaultValue={isValidated.weather} required>
            <option value=""></option>
            <option value="Sunny and Warm">Sunny and Warm</option>
            <option value="Sunny and Cool">Sunny and Cool</option>
            <option value="Sunny and Windy">Sunny and Windy</option>
            <option value="Cloudy and Warm">Cloudy and Warm</option>
            <option value="Cloudy and Cool">Cloudy and Cool</option>
            <option value="Cloudy and Windy">Cloudy and Windy</option>
          <option value="Rainy">Rainy</option>
          </select>
        </div>

        <div>
          <label className='label'>Yardage Today</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='yardageToday' onInput={validate} onChange={changedData} defaultValue={isValidated.yardageToday} required />
        </div>

        <div>
          <label className='label'>Yardage To Date</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='yardageToDate' onInput={validate} onChange={changedData} defaultValue={isValidated.yardageToDate} required />
        </div>

        <div>
          <div><label className='label'>Equipment</label><Icon name='add_circle' onClick={openEquipment}/></div>
          <textarea
            style={{height: 50}}
            className='textArea'
            pattern="[a-zA-Z0-9]{1,}"
            name='equipment'
            onInput={validate}
            onChange={changedData}
            onClick={positionEquipment}
            onKeyUp={positionEquipment}
            defaultValue={isValidated.equipment}
            required
          />
        </div>

        <div>
          <div><label className='label'>Description</label><Icon name='add_circle' onClick={openDescription}/></div>
          <textarea style={{height: 400, maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} onClick={positionDescription} onKeyUp={positionDescription} value={isValidated.description} required></textarea>
        </div>

        <div>
          <div style={{display: 'inline-block'}}>

            <div style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}>

              <div>
                <label className='label'>Task</label>
              </div>

              <div style={{marginLeft: 30}}>
                <label className='label'>Hours</label>
              </div>

            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}>

              <div>
                <select style={{width: 75}} className='select' pattern=".{1,}" name='taskCode1' onInput={validate} onChange={changedData} value={isValidated.taskCode1} required>
                  <option value=""></option>
                  {listOfTasks}
                </select>
              </div>

              <div>
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='hours1' onInput={validate} onChange={changedData} value={isValidated.hours1} required />
              </div>

              <Icon name='cancel' color='tomato' outline={true} onClick={removeTask1} />

            </div>
          </div>
        </div>

        {isTask.second || isValidated.taskCode2 !== '' ?

          <div>
            <div style={{display: 'inline-block'}}>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <label className='label'>Task</label>
                </div>

                <div style={{marginLeft: 30}}>
                  <label className='label'>Hours</label>
                </div>

              </div>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <select style={{width: 75}} className='select' pattern=".{1,}" name='taskCode2' onInput={validate} onChange={changedData} defaultValue={isValidated.taskCode2} required>
                    <option value=""></option>
                    {listOfTasks}
                  </select>
                </div>

                <div>
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='hours2' onInput={validate} onChange={changedData} defaultValue={isValidated.hours2} required />
                </div>

                <Icon name='cancel' color='tomato' outline={true} onClick={removeTask2} />

              </div>
            </div>
          </div> : null

        }

        {isTask.third || isValidated.taskCode3 !== '' ?

          <div>
            <div style={{display: 'inline-block'}}>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <label className='label'>Task</label>
                </div>

                <div style={{marginLeft: 30}}>
                  <label className='label'>Hours</label>
                </div>

              </div>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <select style={{width: 75}} className='select' pattern=".{1,}" name='taskCode3' onInput={validate} onChange={changedData} defaultValue={isValidated.taskCode3} required>
                    <option value=""></option>
                    {listOfTasks}
                  </select>
                </div>

                <div>
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='hours3' onInput={validate} onChange={changedData} defaultValue={isValidated.hours3} required />
                </div>

                <Icon name='cancel' color='tomato' outline={true} onClick={removeTask3} />

              </div>
            </div>
          </div> : null

        }

        {isTask.fourth || isValidated.taskCode4 !== '' ?

          <div>

            <div style={{display: 'inline-block'}}>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <label className='label'>Task</label>
                </div>

                <div style={{marginLeft: 30}}>
                  <label className='label'>Hours</label>
                </div>

              </div>

              <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
              }}>

                <div>
                  <select style={{width: 75}} className='select' pattern=".{1,}" name='taskCode4' onInput={validate} onChange={changedData} defaultValue={isValidated.taskCode4} required>
                    <option value=""></option>
                    {listOfTasks}
                  </select>
                </div>

                <div>
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='hours4' onInput={validate} onChange={changedData} defaultValue={isValidated.hours4} required />
                </div>

                <Icon name='cancel' color='tomato' outline={true} onClick={removeTask4} />

              </div>
            </div>
          </div> : null

        }

        {!isTask.fourth ? <IconAndText text='Add Task?' name='add_circle' onClick={addTask} /> : null}

        <div>
          <label style={{fontSize: 20}} className='label'>Status</label>
          <select style={{fontSize: 20}} className='select' pattern=".{1,}" name='dailiesStatus' onInput={validate} onChange={changedData} defaultValue={isValidated.dailiesStatus} required>
            <option value="Incomplete">Incomplete</option>
            <option value="Complete">Complete</option>
          </select>
        </div>

      </div>

    </div>
  )

  let styleSignButton = {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'dodgerblue',
    border: 'none',
    borderRadius: '50%',
    padding: 15,
    height: 54,
    width: 54,
    margin: 10,
    color: '#ffffff',
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
    outline: 'none'
  }

  let previewContent = isModal.preview && fetchedData.length > 0 && isValidated.id !== null ?
  (<div style={{height: '100%', width: '100%'}}>
    <iframe style={{height: 'calc(100% - 8px)', width: 'calc(100% - 4px)'}} src={createPDF(isValidated.id, fetchedData, 'preview', props.user, '')}></iframe>
    {isModal.preview && isValidated.dailiesStatus === 'Complete' && props.user.userlevel === 'guest' ? <button style={styleSignButton} onClick={openSign}><i className='material-icons'>edit</i></button> : null}
  </div>) :
  <p>Select a daily to preview</p>  

  return (
    <>
      {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
        null :
        <>
          {isModal.add || isModal.edit ? <Modal add={isModal.add ? addDaily : isModal.edit ? editDaily : null} delete={isModal.edit ? deleteDaily : null} view={isModal.edit ? viewDaily : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
          {isModal.preview ==='derp' ? <Modal sign={isModal.preview && isValidated.dailiesStatus === 'Complete' ? openSign : null} content={previewContent} closeModal={closePreview} maxWidth='none' /> : null}
          {isModal.sign ? <Sign parent='daily' user={props.user} signDaily={signDaily} close={closeSign} /> : null}
          {isModal.description ? <DailyDescription select={selectDailyDescription} close={closeDescription} /> : null}
          {isModal.equipment ? <EquipDescription select={selectEquipDescription} close={closeEquipment} /> : null}
          {!isModal.loading ?            

            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{margin: 10, flex: isModal.preview ? '0 1 auto' : '1'}}>

                <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                  <div style={{width: '100%'}}>

                    {props.user.userlevel === 'guest' || props.user.daily < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                    {props.user.userlevel !== 'guest' ? <Icon name='assignment_late' color={isUnsignedDailies ? 'tomato' : 'gray'} onClick={viewUnsignedDailies} /> : null}
                    {props.user.userlevel === 'guest' ? <Icon name='file_download' title='Download Dailies' outline={true} onClick={downloadDailies} /> : null}
                    {props.user.device === 'desktop' ? <Icon name='preview' title='Preview Dailies' color={isModal.preview ? 'dodgerblue' : 'gray'} onClick={togglePreview} /> : null}
                    {props.user.userlevel === 'guest' ? <Icon name='task' title='View Task Codes' color={isModal.task ? 'dodgerblue' : 'gray'} outline={true} onClick={toggleTask} /> : null}
                    <Icon name={isModal.feed? 'feed' : 'table_rows'} title='View reports as a feed or table' color='dodgerblue' outline={true} onClick={toggleFeed} />
                    <Icon name='refresh' title='Refresh' onClick={fetchData} />

                  </div>

                  <SearchBar search={search} clearSearch={clearSearch} />                  

                  {fetchedData.length > 0 ?

                    <>

                      {props.user.userlevel === 'guest' ? <small style={{margin: 10}}>{fetchedData.length} Total Records</small> : <small style={{margin: 10}}>Limits to past 100 entries</small>}

                      <div style={{flex: '1', overflow: 'auto'}}>

                        {!isModal.feed ?

                          <table>

                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>By</th>
                                <th>Descrip.</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {listOfData}
                            </tbody>

                          </table> :

                          <div style={{display: 'flex', flexFlow: 'column'}}>
                            {listOfFeed}
                          </div>

                        }

                      </div>

                    </> : <p style={{margin: 10}}>No dailies found.</p>

                  }

                  {isModal.task ?

                    <div style={{borderTop: '1px solid gray'}}>
                      {listOfDailyTasks}
                    </div> : null                  

                  }

                </div>

              </div>

              {isModal.preview ?

                <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
                  {previewContent}
                </div> : null

              }

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </>
      }
    </>
  )

}

export default Dailies
