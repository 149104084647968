import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Plans from 'components/main/Plans'
import ViewLocation from 'components/utils/ViewLocation'
import Job from 'components/menu/Job'

import { formatDateYMD,formatDateTime, getLocation, catchError, replaceStr, formatBytes, filterData, addActivity } from 'scripts/common'

const Documents = (props) => {

  const fileRef = useRef(null)

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    loading: true
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    description: '',
    fileName: '',
    fileSize: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    description: '',
    fileName: '',
    fileSize: ''
  })

  const fetchData = () => {

    fetch('/api/selectManageDocuments', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectManageDocuments', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.currentTarget.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid,
        description: fetchedData[i].description,
        fileName: fetchedData[i].fileName,
        fileSize: fetchedData[i].fileSize
      }))
      openEdit()

    }

  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,jobNumber: td[2].textContent}))
    closeJob()

  }

  const changedData = () => isChanged.current = true

  const addDocument = () => {

    let file = fileRef.current.files[0]
    //console.log(`file ${file.name} size: ${file.size}`)

    if (props.user.document < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
      alert("Please select a job number.")
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
      alert("Please select grading or post.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please provide a description.")
    } else if (!file) {
      alert("Please add a PDF")
    } else {

      getLocation(function(latlng){

        fetch('/api/addDocument', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            description: replaceStr(isValidated.description),
            fileName: file.name,
            fileSize: file.size
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            if (result.length > 0) {
              uploadDocument(result[0].lastId)
            } else {
              alert('Error: document id not found. Could not upload PDF.')
              catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'uploadDocument', 'missing lastId', props.user.username, props.user.device)
            }

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', 'Document', props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add document. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'addDocument', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const editDocument = () => {

    if (props.user.document < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
      alert("Please select a job number.")
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
      alert("Please select grading or post.")
    } else if (isValidated.description === null || isValidated.description ==='') {
      alert("Please provide a description.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editDocument', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            description: replaceStr(isValidated.description),
            fileName: isValidated.fileName,
            fileSize: isValidated.fileSize
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //uploadDocument(isValidated.id)

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                description: isValidated.description,
                fileName: isValidated.fileName,
                fileSize: isValidated.fileSize
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', 'Document', props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit lab. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'editDocument', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const deleteDocument = () => {

    if (props.user.document < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteDocument', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', 'Document', props.user.username)

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not delete lab. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'deleteDocument', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      }

    }

  }

  const uploadDocument = (id) => {

    let file = fileRef.current.files[0]
    // console.log(`file ${file.name} size: ${file.size}`)

    let fileData = new FormData()
    fileData.append('filePath', 'reports')
    fileData.append('fileName', `report${id}.pdf`)
    fileData.append('filePDF', file)

    //console.log('upload it');

    fetch('/api/uploadDocument', {
      method: 'post',
      body: fileData
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

      },
      (error) => {

        alert('Error: could not upload Document. Contact and admin.')
        catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'uploadDocument', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.document < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let description = data.description === null ? '' : data.description
    let fileName = data.fileName === null ? '' : data.fileName
    let fileSize = data.fileSize === null ? '' : formatBytes(Number(data.fileSize))
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let modTime = data.modtime === null ? '' : formatDateTime(data.modtime)

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
            <>
              <td>{jn}</td>
              <td>{gd}</td>
            </> : null
          }
          <td>{description}</td>
          <td>{fileName}</td>
          <td>{fileSize}</td>
          <td>{entryBy}</td>
          <td>{entryTime}</td>
          <td>{modBy}</td>
          <td>{modTime}</td>
        </tr>
      )

    }

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <div>
          <label>JN
            <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} onClick={openJob} />
          </label>
        </div>

        <div>
          <label>Grading
            <select style={{width: 75}} className='select' pattern=".{1,}" name='gradeId' onInput={validate} onChange={changedData} defaultValue={isValidated.gradeId} required>
              <option value=''></option>
              <option value='RG'>Grading</option>
              <option value='PG'>Post</option>
            </select>
          </label>
        </div>

        <div style={{marginTop: 10, textAlign: 'center'}}>
          <label>Description</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} defaultValue={isValidated.description} required></textarea>
        </div>

        {isModal.edit ?
          <>
            <p>{isValidated.fileName}</p>
            <div style={{margin: 10}}><input type="file" ref={fileRef} accept="application/pdf" /></div>
            <div><small>*The old app did not save file names.</small></div>
          </> :
          <>
            <div style={{margin: 10}}><input type="file" ref={fileRef} accept="application/pdf" /></div>
            <div><small>*The old app did not save file names.</small></div>
          </>
        }

      </div>

    </div>
  )

  // <Icon name='map' color={isModal.plan ? 'dodgerblue' : 'gray'} onClick={togglePlan} />

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addDocument : isModal.edit ? editDocument : null} delete={isModal.edit ? deleteDocument : null} content={modalContent} closeModal={closeModal} /> : null}
      {isModal.job ? <Job user={props.user} selectJob={selectJob} closeModal={closeJob} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{flex: '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <div style={{width: '100%'}}>

                <Icon name='add_circle' onClick={openAdd} />
                <Icon name='refresh' onClick={fetchData} />

              </div>

              <SearchBar search={search} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <p style={{margin: 10}}>{fetchedData.length} Total Records. (If no filters, limit 500)</p>

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <><th>JN</th><th></th></> : null}
                          <th>Descrip.</th>
                          <th>File Name</th>
                          <th>File Size</th>
                          <th>Entry by</th>
                          <th>Entry time</th>
                          <th>Mod by</th>
                          <th>Mod time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No documents found.</p>

              }

            </div>

          </div>

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>

  )

}

export default Documents
