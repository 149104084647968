import React, {useState, useEffect} from 'react'
//import Icon from 'components/utils/Icon'
//import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
// import SearchBar from 'components/utils/SearchBar'

// import ScheduleInput from 'components/input/ScheduleInput'

// import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity } from 'scripts/common'

// import { addPending, selectData } from 'scripts/offline'

//import { showCalendar } from 'scripts/schedule'

const ScheduleCalendar = (props) => {

  // let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  // const [calendarDate, setCalendarDate] = useState({
  //   day: new Date().getDate(),
  //   month: new Date().getMonth(),
  //   year: new Date().getFullYear(),
  //   monthName: months[new Date().getMonth()],
  //   contentYear: null
  // })

  const [calendar, setCalendar] = useState(<p>loading...</p>)
  const [tableHeader, setTableHeader] = useState(<th></th>)
  
  const [isModal, setIsModal] = useState({
    month: false,
    year: false
  })

  // useEffect(() => {

  //   let startYear = 2023

  //   let currentYear = new Date().getFullYear()

  //   let yearDiff = currentYear - startYear

  //   let listOfYears = []

  //   for (let i=0; i <= yearDiff; i++) {

  //     let year = currentYear + i

  //     listOfYears.push(
  //       <tr data-id={year} onClick={selectYear}>
  //         <td>{year}</td>                      
  //       </tr>
  //     )

  //   }    

  //   let contentYear = (
  //     <table style={{width: '100%', height: '100%'}}>

  //         <thead>
  //           <tr>
  //             <th>Year</th>            
  //           </tr>
  //         </thead>

  //         <tbody>
  //           {listOfYears}          
  //         </tbody>

  //       </table>
  //   )

  //   setCalendarDate(prevState => ({...prevState, contentYear: contentYear}))

  // }, [])

  useEffect(() => {
    buildCalender()    
  }, [props.display, props.calendarDate, props.fetchedData])

  const selectRow = (e) => {

    let td = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let data = td.getAttribute('data-date')

    //let i = td.getAttribute('data-i')

    // if (i === '' || i === null) {
    //   alert('Error: data index not found. Contact an admin.')
    // // } else if (fetchedData[i].syncedID === false) {
    // //   alert('ID not synced. Please refresh the data.')
    // } else {
      
      // openEdit(i)

      alert(`date: ${data}`)

    //}

  }

  // const selectMonth = (e) => {

  //   let tr = e.currentTarget        
  //   //let td = tr.getElementsByTagName('td')
  //   let id = tr.getAttribute('data-id')

  //   setCalendarDate(prevState => ({...prevState, 
  //     month: id,
  //     monthName: months[id]
  //   }))

  //   closeMonth()

  // }

  // const selectYear = (e) => {

  //   let tr = e.currentTarget        
  //   //let td = tr.getElementsByTagName('td')
  //   let id = tr.getAttribute('data-id')

  //   setCalendarDate(prevState => ({...prevState, year: id}))

  //   closeYear()

  // }

  const openAdd = (e) => {

    let td = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let day = `${td.getAttribute('data-date')}`

    //let i = td.getAttribute('data-i')

    // if (i === '' || i === null) {
    //   alert('Error: data index not found. Contact an admin.')
    // // } else if (fetchedData[i].syncedID === false) {
    // //   alert('ID not synced. Please refresh the data.')
    // } else {
      
      // openEdit(i)

      console.log(day < 10 ? true : false)

      props.openAdd(`${props.calendarDate.year}-${props.calendarDate.month + 1 < 10 ? `0${props.calendarDate.month + 1}` : props.calendarDate.month}-${day < 10 ? `0${day}` : day}T00:00:00`)

    //}

  }

  const openEdit = (e) => {

    e.stopPropagation()
    let div = e.currentTarget
    let i = div.getAttribute('data-i')

    console.log('hi')
    console.log(`i: ${i}`)

    props.openEdit(i)    

  }  

  const buildCalender = () => {
    
    // <th>Sun</th>
    // <th>Mon</th>
    // <th>Tue</th>
    // <th>Wed</th>
    // <th>Thu</th>
    // <th>Fri</th>
    // <th>Sat</th>

    let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    // <tbody>
    //   <tr data-id={'Today'} onClick={selectPeriod}>
    //     <td>Today</td>                      
    //   </tr>
    //   <tr data-id={'1 day'} onClick={selectPeriod}>            
    //     <td>1 day</td>                      
    //   </tr>
    //   <tr data-id={'2 days'} onClick={selectPeriod}>            
    //     <td>2 days</td>                      
    //   </tr>
    //   <tr data-id={'3 days'} onClick={selectPeriod}>            
    //     <td>3 days</td>                      
    //   </tr>
    //   <tr data-id={'5 days'} onClick={selectPeriod}>            
    //     <td>5 days</td>        
    //   </tr>
    //   <tr data-id={'Week'} onClick={selectPeriod}>            
    //     <td>Week</td>                      
    //   </tr>
    //   <tr data-id={'Month'} onClick={selectPeriod}>            
    //     <td>Month</td>                      
    //   </tr>                    
    // </tbody>

    let listOfCalendar = []
    //let tableHeader = (<th></th>)

    let month = props.calendarDate.month
    let year = props.calendarDate.year    

    let firstDay = (new Date(year, month)).getDay()
    let daysInMonth = 32 - new Date(year, month, 32).getDate()    

    // creating all cells
    let today = new Date()
    let date = 1;
    //console.log(`firstDay: ${firstDay}`)
    let row = Math.ceil((today.getDate() + firstDay) / 7 - 1) // starts at 0, hence - 1
    // console.log(`row: ${row}`)
    // console.log(`
    // ${today.getDate()}
    // ${firstDay}
    // ${(today.getDate() + firstDay)}
    // ${(today.getDate() + firstDay) / 7}
    // ${(today.getDate() + firstDay) / 7 - 1}
    // ${Math.ceil((today.getDate() + firstDay) / 7 - 1)}
    // `)

    let data = props.fetchedData
    let dateArray = []
    let period = props.calendarDate.period 
    let startDate = props.calendarDate.startDate === null ? 1 : props.calendarDate.startDate

    let rowCount = 6
    let colCount = 7

    switch (period) {
      case 'Today':
        date = today.getDate()
        // rowCount = 1
        // colCount = 1
      case '1 Day':
        rowCount = 1
        colCount = 1
      case '2 Days':
        rowCount = 1
        colCount = 2
      case '3 Days':
        rowCount = 1
        colCount = 3
      case '5 Days':
        rowCount = 1
        colCount = 5
      case 'Week':
        rowCount = 1
        colCount = 7
        break;
      case 'Month':
        rowCount = 6
        colCount = 7
        break;
      default:
        console.log('Schedule Period not identified')
    }

    // let timeFormat = (
    //   <tr className='scheduleTable'>{days}
    //     <td></td>
    //     <td data-date={date} onClick={openAdd}>                            
                                  
    //     </td>
    //   </tr>
    // )

    // let listOfTimes = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]

    // let timeFormat = listOfTimes.map((data, i) => {      
  
    //   //let filter = filterData(data, searchValue)
    //   //console.log(`filter ${filter}`)
  
    //   // if (
    //   //   ((isRemoved && suf === 'R') || !isRemoved) &&
    //   //   ((isFailingTest && failingTest === undefined) || !isFailingTest) &&
    //   //   filter
    //   // ) {        
  
    //     return (
    //       <tr>            
    //         <td>{reqComp}</td>          
    //         <td>{passFail}</td>
    //       </tr>
    //     )
  
    //   //}
  
    // })

    if (period === 'Today') {

      let days = []

      for (let k = 0; k < data.length; k++) {
  
        // console.log(`
        //   start: ${new Date(data[k].startDate)}
        //   start: ${new Date(data[k].startDate).getDate()}
        //   date: ${date}
        // `)

        if (new Date(data[k].startDate).getDate() === date) {
          dateArray.push(
            <div 
              onClick={openEdit} 
              data-i={k} 
              className={
                data[k].status === 0 ? 'scheduleConfirmed' : 
                data[k].status === 1 ? 'schedulePending' : 
                'scheduleCancelled'
              }
            >
              {data[k].jobNumber} @ {data[k].startTime}
            </div>
          )
        }

      }

      days.push(
        <td data-date={date} onClick={openAdd}>                            
          {dateArray}                      
        </td>
      )   
    
      //tableheader.push(<th>${dayName[today.getDay()]}</th>)
      listOfCalendar.push(<tr className='scheduleTable'>{days}</tr>)
      
      setTableHeader(<th>{dayNames[today.getDay()]} ({date})</th>)
      setCalendar(listOfCalendar)

    } else if (period === '2 Days') {

      let days = []

      for (let i = 0; i < 2; i++) {        

        for (let k = 0; k < data.length; k++) {
    
          // console.log(`
          //   start: ${new Date(data[k].startDate)}
          //   start: ${new Date(data[k].startDate).getDate()}
          //   date: ${date}
          // `)

          if (new Date(data[k].startDate).getDate() === date + i) {
            dateArray.push(
              <div 
                onClick={openEdit} 
                data-i={k} 
                className={
                  data[k].status === 0 ? 'scheduleConfirmed' : 
                  data[k].status === 1 ? 'schedulePending' : 
                  'scheduleCancelled'
                }
              >
                {data[k].jobNumber} @ {data[k].startTime}
              </div>
            )
          }

        }

        days.push(
          <td data-date={date} onClick={openAdd}>                            
            {dateArray}                      
          </td>
        )
        
      }
    
      //tableheader.push(<th>${dayName[today.getDay()]}</th>)
      listOfCalendar.push(<tr className='scheduleTable'>{days}</tr>)
      
      setTableHeader(<th>{dayNames[today.getDay()]} ({date})</th>)
      setCalendar(listOfCalendar)    

    } else  {

      for (let i = 0; i < rowCount; i++) {
        
        let days = []
  
        //console.log(`row: ${i}`)
  
        //creating individual cells, filing them up with data.
        for (let j = 0; j < colCount; j++) {
  
          //console.log(`day: ${j}, ${date}`)
  
          if (i === 0 && j < firstDay) {
  
            // console.log(`row1:
            //   ${firstDay}
            //   ${row}
            //   ${date}
            //   `)
            
            if ((period === 'Week' && row === i) || period === 'Month') days.push(<td onClick={selectRow}></td>)
  
          } else if (((period === 'Week' && row === i) || period === 'Month') && date > daysInMonth) {
  
            break;
  
          } else {
  
            if ((period === 'Week' && row === i) || period === 'Month') {
  
              // console.log(`
              // week: ${props.display.week}
              // row: ${i}            
              // `)
  
              //let selectedDate = today.getFullYear()-today.getMonth()-today.getDate()
  
              // onClick={props.openAdd('origin', `${today.getFullYear()-today.getMonth()-today.getDate()}`)}
  
              // console.log(`
              //   date: ${date}
              //   month: ${month}
              //   year: ${year}
              //   today.getDate(): ${today.getDate()}
              //   month: ${today.getMonth()}
              //   year: ${today.getFullYear()}
              //   compare1: ${date === today.getDate()}
              //   compare2: ${month === today.getMonth()}
              //   compare3: ${year === today.getFullYear()}
              // `)            
              
              console.log(`
                data.length: ${data.length}
              `)
  
              for (let k = 0; k < data.length; k++) {
  
                console.log(`
                  start: ${new Date(data[k].startDate)}
                  start: ${new Date(data[k].startDate).getDate()}
                  date: ${date}
                `)
  
                if (new Date(data[k].startDate).getDate() === date) {
                  dateArray.push(
                    <div 
                      onClick={openEdit} 
                      data-i={k} 
                      className={
                        data[k].status === 0 ? 'scheduleConfirmed' : 
                        data[k].status === 1 ? 'schedulePending' : 
                        'scheduleCancelled'
                      }
                    >
                      {data[k].jobNumber} @ {data[k].startTime}
                    </div>
                  )
                }
  
              }
  
              if (date === today.getDate() && Number(year) === today.getFullYear() && Number(month) === today.getMonth()) {
                days.push(<td style={{backgroundColor: 'dodgerblue', color: 'white'}} data-date={date} onClick={openAdd}>{date}</td>)
              } else {
                days.push(
                  <td data-date={date} onClick={openAdd}>
                    <div>{date}</div>                  
                    {dateArray}                      
                  </td>
                )
              }
              
              dateArray = []
  
            }
  
            //console.log(date)          
  
            date++
          }
  
        }
  
        listOfCalendar.push(<tr className='scheduleTable'>{days}</tr>)
  
        //console.log(JSON.stringify(listOfCalendar))
  
      }
      
    }    
    
    //listOfCalendar = <p>done...</p>
    setCalendar(listOfCalendar)
    //console.log('done')

  }

  // let styleBtn = {
  //   display: 'inline-block',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   borderRadius: 10,
  //   border: '2px solid dodgerblue',
  //   margin: 10,
  //   padding: 5
  // }

  // let contentMonth = (
  //   <table style={{width: '100%', height: '100%'}}>

  //       <thead>
  //         <tr>
  //           <th>Month</th>            
  //         </tr>
  //       </thead>

  //       <tbody>
  //         <tr data-id={0} onClick={props.selectMonth}>
  //           <td>January</td>                      
  //         </tr>
  //         <tr data-id={1} onClick={props.selectMonth}>            
  //           <td>February</td>                      
  //         </tr>
  //         <tr data-id={2} onClick={props.selectMonth}>            
  //           <td>March</td>                      
  //         </tr>
  //         <tr data-id={3} onClick={props.selectMonth}>            
  //           <td>April</td>        
  //         </tr>
  //         <tr data-id={4} onClick={props.selectMonth}>            
  //           <td>May</td>                      
  //         </tr>
  //         <tr data-id={5} onClick={props.selectMonth}>            
  //           <td>June</td>                      
  //         </tr>
  //         <tr data-id={6} onClick={props.selectMonth}>            
  //           <td>July</td>                      
  //         </tr>
  //         <tr data-id={7} onClick={props.selectMonth}>            
  //           <td>August</td>                      
  //         </tr>
  //         <tr data-id={8} onClick={props.selectMonth}>            
  //           <td>September</td>                      
  //         </tr>
  //         <tr data-id={9} onClick={props.selectMonth}>            
  //           <td>October</td>                     
  //         </tr>
  //         <tr data-id={10} onClick={props.selectMonth}>            
  //           <td>November</td>                      
  //         </tr>
  //         <tr data-id={11} onClick={props.selectMonth}>            
  //           <td>December</td>          
  //         </tr>
  //       </tbody>

  //     </table>
  // )

  // let contentYear = fetchedData.map((data, i) => {

  //   // let pre = data.pre === null ? '' : data.pre
  //   // let number = data.testno === null ? '' : data.testno
  //   // let suf = data.suf === null ? '' : data.suf
  //   // let testNo = `${pre} ${number} ${suf}`
  //   // let dryDens = data.drydens === null ? '' : data.drydens
  //   // let moistCont = data.moistcont === null ? '' : data.moistcont
  //   // let md = `${dryDens} @ ${moistCont}`
  //   // let optD = data.optd === null ? '' : data.optd
  //   // let optM = data.optm === null ? '' : data.optm
  //   // let curve = data.curve === null ? '' : data.curve
  //   // let max = `${curve} - ${optD} @ ${optM}`
  //   // let reqComp = data.reqcomp === null ? '' : data.reqcomp
  //   // let elevD = data.elevd === null ? '' : data.elevd
  //   // let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
  //   // let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''   

  //   let filter = filterData(data, searchValue)
  //   //console.log(`filter ${filter}`)

  //   if (filter) {      

  //     recordCount += 1

  //     // <th>Date</th>
  //     // <th>JN</th>
  //     // <th>Time</th>
  //     // <th>Hours</th>
  //     // <th>Desc</th> 

  //     return (
  //       <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={selectRow}>
  //         <td>{data.startDate}</td>
  //         <td>{data.jobNumber}</td>
  //         <td>{data.startTime.substring(0,5)}</td>
  //         <td>{data.duration}</td>
  //         <td>{data.description}</td>
  //         <td>{data.tech}</td>   
  //       </tr>
  //     )

  //   }

  // })

  // let contentYear = (
  //   <table style={{width: '100%', height: '100%'}}>

  //       <thead>
  //         <tr>
  //           <th>Year</th>            
  //         </tr>
  //       </thead>

  //       <tbody>
  //         <tr data-id={2028} onClick={selectYear}>
  //           <td>2018</td>                      
  //         </tr>
  //         <tr data-id={2027} onClick={selectYear}>            
  //           <td>2019</td>                      
  //         </tr>
  //         <tr data-id={2020} onClick={selectYear}>            
  //           <td>2020</td>                      
  //         </tr>
  //         <tr data-id={2021} onClick={selectYear}>            
  //           <td>2021</td>        
  //         </tr>
  //         <tr data-id={2022} onClick={selectYear}>            
  //           <td>2022</td>                      
  //         </tr>
  //         <tr data-id={2023} onClick={selectYear}>            
  //           <td>2023</td>                      
  //         </tr>
  //         <tr data-id={2024} onClick={selectYear}>            
  //           <td>2024</td>                      
  //         </tr>
  //         <tr data-id={2025} onClick={selectYear}>            
  //           <td>2025</td>                      
  //         </tr>
  //         <tr data-id={2025} onClick={selectYear}>            
  //           <td>2026</td>                      
  //         </tr>
  //         <tr data-id={2024} onClick={selectYear}>            
  //           <td>2027</td>                     
  //         </tr>
  //         <tr data-id={2023} onClick={selectYear}>            
  //           <td>2028</td>                      
  //         </tr>          
  //       </tbody>

  //     </table>
  // )
  
  let content = (
    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>      

      <table style={{width: '100%', height: '100%', tableLayout: 'fixed'}}>

        <thead>
          <tr>
            {tableHeader}
          </tr>
        </thead>

        <tbody>
          {calendar}
        </tbody>

      </table>

    </div>
  )  
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default ScheduleCalendar
