import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import Plans from 'components/main/Plans'
import CoordinateFactors from 'components/menu/CoordinateFactors'

import TestInput from 'components/input/TestInput'

import { formatDateYMD, formatDateTime, getLocation, catchError, exportToExcel, filterData, addActivity, replaceStr } from 'scripts/common'

import { pdfTests } from 'scripts/pdf'

const Tests = (props) => {

  const classifyPoint = require("robust-point-in-polygon")

  const forDownload = useRef([])
  const coordFactor = useRef({n: 0, e: 0}) // for download
  const autoCadFactor = useRef({
    blockSize: '',
    blockRotation: '',
    labelSize: '',
    labelRotation: '',
    block: 'DensityTest',
    includeCoordFactor: true
  }) // for download

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const fetchPrefixes = useRef([])
  const [testFilters, setTestFilters] = useState([])  
  const [reportDetails, setReportDetails] = useState({
    reportDate: '',
    tableName: '',
    page: ''
  })

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    curve: false,
    prefix: false,
    plan: false,
    google: false,
    locate: false,
    warning: 0,
    warningContent: '',
    loading: true,
    coordFactor: false,
    filter: false,
    filterMenu: false,
    filterEdit: false,
    filterPolygon: false,
    autocad: false,
    preview: false,
    archive: false // for marking failing tests archived
  })

  const isChanged = useRef(false)

  const [isRetest, setIsRetest] = useState(false)
  const [isFailingTest, setIsFailingTest] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  const [filter, setFilter] = useState({
    id: '',
    description: '',
    prefix: '',
    tests: '',
    n: '',
    e: '',
    polygon: [],
    position: -1 // indicates position relative to polygon, see below
  })

  // filter.postion
  // -1 if point is contained inside loop
  // 0 if point is on the boundary of loop
  // 1 if point is outside loop

  // used to update passFail in Modal
  const updatePassFail = useRef({
    dryDens: null,
    optD: null,
    reqComp: null
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: ''
  })

  const fetchCoordFactors = () => {

    fetch('/api/selectCoordFactors', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        if (result.length > 0) {

          let n = props.filter.gradeId === 'RG' ? result[0].rgnf : result[0].pgnf
          let e = props.filter.gradeId === 'RG' ? result[0].rgef : result[0].pgef

          coordFactor.current = {
            n: n === null ? 0 : Number(n),
            e: e === null ? 0 : Number(e)
          }

        } else {

          coordFactor.current = {n: 0, e: 0}

        }

      },
      (error) => {
        console.log('Error: selectCoordFactors --> ' + error)
      }
    )

  }

  const fetchData = (archived, failing, removed) => {

    //var classifyPoint = require("robust-point-in-polygon")
    // var polygon = [[1, 1], [1, 2], [2, 2], [2, 1]]

    // console.log(
    //   classifyPoint(polygon, [1.5, 1.5]),
    //   classifyPoint(polygon, [1, 2]),
    //   classifyPoint(polygon, [100000, 10000]))

    fetch('/api/selectManageTests', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        archived: archived,
        failing: failing,
        removed: removed 
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'Tests', props.user.username)

        setFetchedData(result.map((data, i) => {

          // i dont think this is necessary.. you are saving passfail so why calc it here?

          //let relComp = Math.round((data.drydens/data.optd)*100)

          //let passFail = relComp >= data.reqcomp ? 'P' : 'F'

          //pf for filter

          return {...data,
            pf: data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '',
            entrytime: data.entrytime !== null ? formatDateTime(data.entrytime) : data.entrytime,
            modtime: data.modtime !== null ? formatDateTime(data.modtime) : data.modtime,
            testdate: formatDateYMD(data.testdate),
            testTypeFilter: data.testtype === 'N' ? 'nuke' : data.testtype === 'S' ? 'sand cone' : data.testtype === 'D' ? 'knocker bar drive tube' : '',
            testTypeDisplay: data.testtype === 'N' ? 'nuke' : data.testtype === 'S' ? 'sand' : data.testtype === 'D' ? 'drive' : ''
          }

        }))

        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTests', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

    fetch('/api/selectPrefixes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        fetchPrefixes.current = result
      },
      (error) => {
        console.log('Error: selectPrefixes --> ' + error)
      }
    )

  }

  const fetchFilters = () => {

    fetch('/api/selectTestFilters', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setTestFilters(result)

        //setIsModal(prevState => ({...prevState, filterMenu: false}))

      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTestFilters', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
    fetchFilters()
    fetchCoordFactors()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {

        let pre = data.pre === null ? '' : data.pre
        let number = data.testno === null ? '' : data.testno
        let suf = data.suf === null ? '' : data.suf
        let testNo = `${pre} ${number} ${suf}`
        let curve = data.curve === null ? '' : data.curve
        let elevD = data.elevd === null ? '' : data.elevd
        let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
        let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
        let entryTime = data.entrytime === null ? '' : data.entrytime

        return true

        //{testNo: testNo, lat: data.entrylat, lng: data.entrylng}

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, pre, number, suf, testNo

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      pre = data[i].pre === null ? '' : data[i].pre
      number = data[i].testno === null ? '' : data[i].testno
      suf = data[i].suf === null ? '' : data[i].suf
      testNo = `${pre} ${number} ${suf}`

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: testNo,
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i], searchValue)
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    if (name === 'dryDens' || name === 'optD' || name === 'reqComp') updatePassFail.current.[name] = value

    // update in case
    let relComp = Math.round((Number(updatePassFail.current.dryDens)/Number(updatePassFail.current.optD))*100)
    let passFail = relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

    // console.log(
    //   `
    //   dryDens: ${updatePassFail.current.dryDens}
    //   optD: ${updatePassFail.current.optD}
    //   reqComp: ${updatePassFail.current.reqComp}
    //   relComp: ${relComp},
    //   passFail: ${passFail},
    //   `
    // )

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null, relComp: relComp, passFail: passFail}))
  }

  const updateTestFilters = (event) => {
    let name = event.target.getAttribute('name')
    let value = event.target.value

    setFilter(prevState => ({...prevState, [name]: value}))
  }

  const updateReportDetails = (event) => {
    let name = event.target.getAttribute('name')
    let value = event.target.value

    setReportDetails(prevState => ({...prevState, [name]: value}))
  }

  const updateAutoCadFactor = (event) => {
    let name = event.target.getAttribute('name')
    let value = event.target.value

    autoCadFactor.current = {...autoCadFactor.current, [name]: value}

  }

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    //let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {

      alert('Error: data index not found. Contact an admin.')

    } else {

      if (target.nodeName === 'TD') {

        // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
        // let today = new Date()
        // let entry = new Date(fetchedData[i].entrytime)
        // let timeDiff = Math.abs(entry.getTime() - today.getTime())
        // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

        // if (props.user.test < 2) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

        // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username !== fetchedData[i].entryby) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

        // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

        // } else if (props.user.test === 4) {

        //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

        // } else {

        //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

        // }

        // // this is for removed, if returns undefined, then not removed
        // let removed =  fetchedData.find(test => test.testno === fetchedData[i].testno && test.suf === 'R')
        // let reTest = false

        // if (!removed && fetchedData[i].passfail === 'F') {

        //   // this is for retest, if returns undefined, then not found, so retest needed
        //   reTest = fetchedData.find(test => test.testno === fetchedData[i].testno && test.passfail === 'P')
        //   //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)

        // }

        // // used to dynamically update relComp and passFail during input
        // updatePassFail.current = {
        //   dryDens: fetchedData[i].drydens,
        //   optD: fetchedData[i].optd,
        //   reqComp: fetchedData[i].reqcomp
        // }

        // setIsValidated(prevState => ({...prevState,
        //   entryby: fetchedData[i].entryby,
        //   entrytime: fetchedData[i].entrytime,
        //   entrylat: fetchedData[i].entrylat,
        //   entrylng: fetchedData[i].entrylng,
        //   entrydevice: fetchedData[i].entrydevice,
        //   modby: fetchedData[i].modby,
        //   modtime: fetchedData[i].modtime,
        //   modlat: fetchedData[i].modlat,
        //   modlng: fetchedData[i].modlng,
        //   moddevice: fetchedData[i].moddevice,
        //   id: fetchedData[i].id,
        //   reTest: reTest === undefined ? true : false,
        //   showRemoved: removed === undefined ? true : false,
        //   removed: removed === undefined ? false : true,
        //   nukeId: fetchedData[i].nukeId,
        //   testDate: fetchedData[i].testdate,
        //   pre: fetchedData[i].pre,
        //   testNo: fetchedData[i].testno,
        //   suf: fetchedData[i].suf,
        //   testType: fetchedData[i].testtype,
        //   north: fetchedData[i].north,
        //   east: fetchedData[i].east,
        //   elevD: fetchedData[i].elevd,
        //   moistCont: fetchedData[i].moistcont,
        //   dryDens: fetchedData[i].drydens,
        //   maxId: fetchedData[i].maxId,
        //   curve: fetchedData[i].curve,
        //   optD: fetchedData[i].optd,
        //   optM: fetchedData[i].optm,
        //   reqComp: fetchedData[i].reqcomp,
        //   relComp: fetchedData[i].relcomp,
        //   passFail: fetchedData[i].passfail,        
        //   sandInitialWt: fetchedData[i].sandInitialWt, 
        //   sandFinalWt: fetchedData[i].sandFinalWt, 
        //   sandExtraWt: fetchedData[i].sandExtraWt, 
        //   sandDensity: fetchedData[i].sandDensity, 
        //   tubeWetWt: fetchedData[i].tubeWetWt, 
        //   tubeTareWt: fetchedData[i].tubeTareWt, 
        //   tubeFactor: fetchedData[i].tubeFactor, 
        //   moistWetWt: fetchedData[i].moistWetWt, 
        //   moistDryWt: fetchedData[i].moistDryWt, 
        //   moistTareWt: fetchedData[i].moistTareWt
        // }))
        openEdit(i)

      } else if (target.nodeName === 'I') {

        setIsValidated(prevState => ({...prevState,  
          id: fetchedData[i].id,        
          archiveNote: fetchedData[i].archiveNote
        }))
        openArchive()

      }

    }

  }

  const selectTestFilters = (e) => {

    let i = e.target.parentNode.getAttribute('data-i')

    if (e.target.nodeName === 'I' || e.target.nodeName === 'Button') {
      //do nothing
    } else if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      createFilterPolygon(testFilters[i].n, testFilters[i].e)
      .then(result => {

        setFilter(prevState => ({...prevState,
          id: testFilters[i].id,
          description: testFilters[i].description,
          prefix: testFilters[i].prefix,
          tests: testFilters[i].tests,
          n: testFilters[i].n,
          e: testFilters[i].e,
          polygon: result,
          position: testFilters[i].position
        }))
  
        setIsModal(prevState => ({...prevState, filterMenu: false}))

      })
      .catch(err => console.log(`err: ${err}`))            

    }

  }

  const createFilterPolygon = async (n, e) => {

    let polygon = []

    // console.log(`
    // n: ${JSON.stringify(n)}
    // e: ${JSON.stringify(e)}
    // `)

    let north
    let east

    if (typeof n === 'string') {

      north = n.split(',')
      east = e.split(',')

    } else {

      north = n
      east = e

    }

    //console.log(`north: ${north}`)

    if (north.length > 0) {

      for (let i=0; i < north.length; i++) {
        //console.log(`before ${i}`)
        let result = await Promise.resolve(i);
        polygon.push([Number(east.[i]), Number(north.[i])])
        //console.log(`after ${i}`)
      }

    }

    //console.log(`polygon: ${JSON.stringify(polygon)}`)

    return polygon

  }  

  const changedData = () => isChanged.current = true

  // const updateFilterPrefix = (e) => {
  //   let value = e.target.value
  //   setIsModal(prevState => ({...prevState, filterEdit: true}))
  // }

  // const updateFilterTests = (e) => {
  //   let value = e.target.value
  //   setSearchTests(value)
  // }

  const addFilter = () => {

    if (props.filter.jobNumber === '') {
      alert('Please select a JN')
    } else {

      let desc = prompt("Provide a description")

      if (desc === '') {
        alert('Please provide a description')
      } else {

        fetch('/api/addTestFilter', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter,
            description: desc,
            info: filter
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            fetchFilters()            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', 'Test filter', props.user.username)

          },
          (error) => {
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestFilter', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      }

    }

  }

  const editFilter = () => {

    if (props.filter.jobNumber === '') {
      alert('Please select a JN')
    } else if (filter.description === '') {
      alert('Please provide a description')
    } else {

      fetch('/api/editTestFilter', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter,
          info: filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          fetchFilters()

          setIsModal(prevState => ({...prevState, filterEdit: false}))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', 'Test filter', props.user.username)

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestFilter', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  const deleteFilter = (e) => {

    let parent = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let i = parent.getAttribute('data-i')

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteTestFilter', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: testFilters[i].id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', 'Test Filter', props.user.username)

          setTestFilters(testFilters.filter(data => data.id !== testFilters[i].id))

        },
        (error) => {

          alert('Error: could not delete filter. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestFilter', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  const clearFilter = () => {

    setFilter({
      id: '',
      description: '',
      prefix: '',
      tests: '',
      n: '',
      e: '',
      polygon: [],
      position: -1
    })

  }

  const clearTestDate = () => {

    setIsValidated(prevState => ({...prevState, testDate: ''}))
    changedData()

  }

  // const selectPrefix = (e) => {

  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')

  //   if (isValidated.pre !== td[0].textContent) {

  //     setIsValidated(prevState => ({...prevState, pre: td[0].textContent}))
  //     changedData()

  //   }

  //   closePrefix()

  // }

  // const clearPrefix = (e) => {

  //   if (isValidated.pre !== '') {

  //     setIsValidated(prevState => ({...prevState, pre: ''}))
  //     changedData()

  //   }

  //   closePrefix()

  // }

  // const selectCurve = (e) => {

  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')

  //   let maxId = Number(td[0].textContent)
  //   let optD = Number(td[3].textContent)
  //   let optM = Number(td[4].textContent)

  //   updatePassFail.current.optD = optD

  //   if (isValidated.maxId !== maxId) {

  //     setIsValidated(prevState => ({...prevState,
  //       maxId: maxId,
  //       curve: td[2].textContent,
  //       optD: optD,
  //       optM: optM
  //     }))
  //     changedData()

  //   }

  //   closeCurve()

  // }

  // const selectLocate = (n,e) => {
  //   isChanged.current = true
  //   setIsValidated(prevState => ({...prevState,
  //     north: n,
  //     east: e
  //   }))
  // }

  const selectFilterPolygon = (n,e) => {   
    
    createFilterPolygon(n, e)
      .then(result => {

        setFilter(prevState => ({...prevState,
          n: n,
          e: e,
          polygon: result
        }))

      })
      .catch(err => console.log(`err: ${err}`)) 
    
  }

  const addTest = () => {

    if (props.user.test < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (!isValidated.removed && isValidated.testType === 'N' && (props.user.nukeId === '' || props.user.nukeId === null)) {
      alert('Please select a Nuke from the Home page.')
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.");
    
    } else if (isValidated.testType ==='S' && isValidated.sandInitialWt === '') {
      alert("Please provide an Initial Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandFinalWt === '') {
      alert("Please provide a Final Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandExtraWt === '') {
      alert("Please provide Wt of Sand in Cone/Plate.")
    } else if (isValidated.testType ==='S' && isValidated.sandDensity === '') {
      alert("Please provide Density of Sand.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.wetWt === '') {
      alert("Please provide Wet Wt of Soil and Tube.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.tareWt === '') {
      alert("Please provide Wt of Tube.")
    } else if (isValidated.testType ==='D' && isValidated.volumeFactor === '') {
      alert("Please provide Tube Factor.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistWetWt === '') {
      alert("Please provide Wet Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistDryWt === '') {
      alert("Please provide Dry Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistTareWt === '') {
      alert("Please provide Tare Wt for Moisture.")    

    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    // } else if (isValidated.curve === null || isValidated.curve ==='') {
    //     alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    // } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
    //     alert("Relative compaction cannot be greater than 100%.");
    } else {

      // include optd and optm???

      getLocation(function(latlng){

        fetch('/api/addTest', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
            testdate: isValidated.testDate,
            pre: isValidated.pre,
            testno: isValidated.testNo,
            suf: isValidated.suf,
            testtype: isValidated.testType,
            north: isValidated.north,
            east: isValidated.east,
            elevd: isValidated.elevD,
            moistcont: isValidated.moistCont,
            drydens: isValidated.dryDens,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optd: isValidated.optD,
            optm: isValidated.optM,
            reqcomp: isValidated.reqComp,
            relcomp: isValidated.relComp,
            passfail: isValidated.passFail,
            removed: isValidated.removed,
            showRemoved: isValidated.showRemoved
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', `Test ${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`, props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTest', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

  }

  const editTest = () => {

    // if (props.user.test < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.");
    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    } else if (isValidated.curve === null || isValidated.curve ==='') {
        alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
        alert("Relative compaction cannot be greater than 100%.");
    } else {

      getLocation(function(latlng){

        fetch('/api/editTest', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            testdate: isValidated.testDate,
            pre: isValidated.pre,
            testno: isValidated.testNo,
            suf: isValidated.suf,
            testtype: isValidated.testType,
            north: isValidated.north,
            east: isValidated.east,
            elevd: isValidated.elevD,
            moistcont: isValidated.moistCont,
            drydens: isValidated.dryDens,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optd: isValidated.optD,
            optm: isValidated.optM,
            reqcomp: isValidated.reqComp,
            relcomp: isValidated.relComp,
            passfail: isValidated.passFail,
            removed: isValidated.removed
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                testdate: isValidated.testDate,
                testtype: isValidated.testType,
                pre: isValidated.pre,
                testno: isValidated.testNo,
                suf: isValidated.suf,
                testtype: isValidated.testType,
                north: isValidated.north,
                east: isValidated.east,
                elevd: isValidated.elevD,
                moistcont: isValidated.moistCont,
                drydens: isValidated.dryDens,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optd: isValidated.optD,
                optm: isValidated.optM,
                reqcomp: isValidated.reqComp,
                relcomp: isValidated.relComp,
                passfail: isValidated.passFail,
                removed: isValidated.removed
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', `Test ${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`, props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTest', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

  }

  const deleteTest = () => {

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteTest', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', `Test ${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`, props.user.username)

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTest', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      }

    }

  }

  const editArchive = () => {
    
    if (props.user.test < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.archiveNote === null || isValidated.archiveNote ==='') {
      alert("Please specificy why you want to archive this test.")    
    } else {

      getLocation(function(latlng){

        fetch('/api/editArchive', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,            
            archiveNote: replaceStr(isValidated.archiveNote),
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                archiveBy: props.user.username,
                archiveTime: formatDateTime(new Date()),
                archiveNote: isValidated.archiveNote
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'archive', `Test ${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`, props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editArchive', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

  }

  const removeTest = () => {
    if (window.confirm('You want to remove me ಠ_ಠ ?')) addTest()
  }

  const reTest = () => {


    //console.log(`fetchedData: ${JSON.stringify(fetchedData)}`)
    //console.log(`isValidated: ${JSON.stringify(isValidated)}`)
    let suf
    let sufArr = fetchedData.map(data => data.testno === isValidated.testNo && (data.suf !== '' && data.suf !== null) ? data.suf.charCodeAt() : 0)
    //console.log(`sufArr: ${sufArr}`)
    let newSuf = Math.max(...sufArr)
    //console.log(`newSuf: ${newSuf}`)

    if (newSuf === 0) {
      suf ='A'
    } else {
      suf = String.fromCharCode(newSuf.charCodeAt() + 1)
    }

    setIsValidated(prevState => (
      {...prevState,
        suf: suf,
        moistCont: null,
        dryDens: null,
        testDate: '',
        reTest: false,
        reTestActive: true
      }
    ))

    alert('Suffix ' + suf + ' created. Please provide a moisture, dry density and test date.');

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const downloadTestsForCAD = () => {

    async function waitFor() {

      let autoCadData = forDownload.current.filter((data, i) => {
        if (i === 0) {
          return true
        } else if (data.testno !== forDownload.current[i-1].testno) {
          return true
        } else {
          return false
        }
      })

      let txt = ''
      let delta = 1.5 // difference between block and label
      let rotate = Number(autoCadFactor.current.blockRotation)
      let toRad = 0.0174533
      let dx = 0
      let dy = 0

      if (rotate === 0 || rotate === 360 ) {
        dx = 0
        dy = 2
      } else if (rotate === 90 ) {
        dx = 2
        dy = 0
      } else if (rotate === 180 ) {
        dx = 0
        dy = -2
      } else if (rotate === 270 ) {
        dx = -2
        dy = 0
      } else if ((rotate > 0 && rotate < 90) || (rotate > 180 && rotate < 270 )) {
        dx = delta * Math.cos(rotate * toRad)
        dy = delta * Math.sin(rotate * toRad)

      } else if ((rotate > 90 && rotate < 180) || (rotate > 270 && rotate < 360)) {
        dx = delta * Math.cos(rotate * toRad) * -1
        dy = delta * Math.sin(rotate * toRad) * -1
      }

      for (let i=0; i < autoCadData.length; i++) {

        let data = autoCadData[i]
        let result = await Promise.resolve(i);

        // console.log(`
        //   n: ${data.north}
        //   e: ${data.east}
        //   nf: ${coordFactor.current.n}
        //   ef: ${coordFactor.current.e}
        // `)

        let pre = data.pre === null ? '' : data.pre
        let number = data.testno === null ? '' : data.testno
        let suf = data.suf === null ? '' : data.suf
        let testNo = `${pre}${number}${suf}`.trim()
        let factor = autoCadFactor.current
        let n = factor.includeCoordFactor ? Number(data.north) +  Number(coordFactor.current.n) : Number(data.north)
        let e = factor.includeCoordFactor ? Number(data.east) +  Number(coordFactor.current.e) : Number(data.north)


        if (isNaN(n) === false && isNaN(e) === false) {

              txt += `layer n "~Density Test ${formatDateYMD(new Date())}"\n\n`
              txt += `layer s "~Density Test ${formatDateYMD(new Date())}"\n\n`
              txt += `insert ${factor.block}\n`
              txt += `${e},${n},0 ${factor.blockSize} ${factor.blockSize} ${factor.blockRotation}\n` // ,z xscale yscale rotate
              txt += `text\n`
              txt += `J\n`
              txt += `MC\n`
              txt += `${e + dx},${n + dy}\n`
              txt += `${factor.labelSize}\n` // size
              txt += `${factor.labelRotation}\n` // rotate
              txt += `${testNo}\n`

        }

      }

      txt += `\n`

      return txt

    }

    //if (props.filter.jobNumber !== '' && props.filter.jobNumber !== null) {

      waitFor().then(res => {

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.trim()))
        element.setAttribute('download', `${props.filter.jobNumber} ${props.filter.gradeId} Tests ${formatDateYMD(new Date())}.scr`)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'download', 'Tests for AutoCAD', props.user.username)

      })

    //} else {
      //alert('Please select a JN')
    //}

  }

  const viewFailingTest = () => {
    if (isFailingTest) {
      fetchData(isModal.archived, false, isRemoved)
    } else {
      fetchData(isModal.archived, true, isRemoved)
    }

    setIsFailingTest(isFailingTest ? false : true)

  }

  const viewRemoved = () => {
    if (isRemoved) {
      fetchData(isModal.archived, isFailingTest, false)
    } else {
      fetchData(isModal.archived, isFailingTest, true)
    }

    setIsRemoved(isRemoved ? false : true)
  }

  const openAdd = () => {

    if (props.user.test < 2) {

      alert('You do not have the required permission. Contact an admin.')

    // if (isModal.warning) {
    //
    //   alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      let testNo = fetchedData.length < 1 ? 1 : Math.max(...fetchedData.map(o => o.testno)) + 1

      setIsValidated(prevState => ({...prevState, testNo: testNo}))
      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))

  const openArchive = () => setIsModal(prevState => ({...prevState, archive: true}))

  const openPrefix = () => setIsModal(prevState => ({...prevState, prefix: true}))

  const openCurve = () => setIsModal(prevState => ({...prevState, curve: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const openFilterPolygon = () => setIsModal(prevState => ({...prevState, filterPolygon: true}))

  const openAutoCad = () => {

    if (props.filter.jobNumber !== '' && props.filter.jobNumber !== null) {

      setIsModal(prevState => ({...prevState, autocad: true}))

    } else {

      if (window.confirm("You did not select a JN. Proceed?")) {

        setIsModal(prevState => ({...prevState, autocad: true}))

      }

    }

  }

  const closeAutoCad = () => setIsModal(prevState => ({...prevState, autocad: false}))

  const toggleArchived = () => {
    if (isModal.archived) {
      fetchData(false, isFailingTest, isRemoved)
    } else {
      fetchData(true, isFailingTest, isRemoved)
    }

    setIsModal(prevState => ({...prevState, archived: isModal.archived ? false : true}))    
  }

  const togglePlan = () => setIsModal(prevState => ({...prevState, plan: isModal.plan ? false : true}))

  const toggleFilter = () => setIsModal(prevState => ({...prevState, filter: isModal.filter ? false : true}))

  const togglePosition = () => {
    console.log(`toggle: ${filter.position === -1 ? 1 : -1}`)
    setFilter(prevState => ({...prevState, position: filter.position === -1 ? 1 : -1}))
  }

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const togglePreview = () => setIsModal(prevState => ({...prevState, preview: isModal.preview ? false : true}))

  const openFilterMenu = () => {

    if (props.filter.jobNumber === '') {
      alert('Please select a JN')
    } else {
      setIsModal(prevState => ({...prevState, filterMenu: true}))
    }

  }

  const openFilterEdit = (e) => {

    let parent = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let i = parent.getAttribute('data-i')
    
    createFilterPolygon(testFilters[i].n, testFilters[i].e)
    .then(result => {

      setFilter({
        id: testFilters[i].id,
        description: testFilters[i].description,
        prefix: testFilters[i].prefix,
        tests: testFilters[i].tests,
        n: testFilters[i].n,
        e: testFilters[i].e,
        polygon: result,
        position: testFilters[i].position
      })
  
      setIsModal(prevState => ({...prevState, filterEdit: true}))

    })
    .catch(err => console.log(`err: ${err}`))    

  }

  const openCoordFactor = () => {

    if (props.filter.jobNumber === '') {
      alert('Please select a JN')
    } else {
      setIsModal(prevState => ({...prevState, coordFactor: true}))
    }

  }

  const closeCoordFactor = () => setIsModal(prevState => ({...prevState, coordFactor: false}))

  const closeFilterMenu = () => setIsModal(prevState => ({...prevState, filterMenu: false}))

  const closeFilterEdit = () => setIsModal(prevState => ({...prevState, filterEdit: false}))

  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, archive: false})) 

  const updateFetchedData = (data) => setFetchedData(data)

  const closeFilterPolygon = () => setIsModal(prevState => ({...prevState, filterPolygon: false}))

  const filterPrefix = (prefix) => {

    let arrFilter = filter.prefix.split(',')

    for (let i=0; i < arrFilter.length; i++) {
      if (arrFilter[i].trim().toLowerCase() === prefix.trim().toLowerCase() || (arrFilter[i].trim().toLowerCase() === 'none' && prefix === '')) return true
    }

    return false

  }

  const filterTests = (test) => {

    let arrFilter = filter.tests.split(',')

    for (let i=0; i < arrFilter.length; i++) {
      if (arrFilter[i].includes('-')) {
        let filter = arrFilter[i]
        let index = filter.indexOf('-')
        let start = filter.substring(0,index)
        let end = filter.substring(index+1)

        if (Number(test) >= Number(start) && Number(test) <= Number(end)) {return true}

      } else if (Number(test) === Number(arrFilter[i])) {
        return true
      }
    }

    return false

  }

  let nCount = 0
  let sCount = 0
  let dCount = 0
  let oCount = 0
  let recordCount = 0

  forDownload.current = [] // clear before pushing

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let pre = data.pre === null ? '' : data.pre
    let number = data.testno === null ? '' : data.testno
    let suf = data.suf === null ? '' : data.suf
    let testNo = `${pre} ${number} ${suf}`
    let dryDens = data.drydens === null ? '' : data.drydens
    let moistCont = data.moistcont === null ? '' : data.moistcont
    let md = `${dryDens} @ ${moistCont}`
    let optD = data.optd === null ? '' : data.optd
    let optM = data.optm === null ? '' : data.optm
    let curve = data.curve === null ? '' : data.curve
    let max = `${curve} - ${optD} @ ${optM}`
    let reqComp = data.reqcomp === null ? '' : data.reqcomp
    let relComp = data.relcomp === null ? '' : data.relcomp
    let elevD = data.elevd === null ? '' : data.elevd
    let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let modTime = data.modtime === null ? '' : data.modtime

    let failingTest = null

    if (data.passfail !== 'P') {
      //console.log(`data.passfail: ${data.passfail}`)
      failingTest = fetchedData.find(retest => retest.testno === data.testno && retest.passfail === 'P')
      //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
    }
    
    let coords = [Number(data.east), Number(data.north)]     

    if (
      ((isRemoved && suf === 'R') || !isRemoved) &&
      ((isFailingTest && failingTest === undefined) || !isFailingTest) &&
      ((filter.prefix === '' || filterPrefix(data.pre))) &&
      ((filter.tests === '' || filterTests(data.testno))) &&
      ((filter.n === '' || (filter.polygon !== [] && (classifyPoint(filter.polygon, coords) === 0 || classifyPoint(filter.polygon, coords) === filter.position)))) &&
      filterData(data, searchValue)
    ) {

      forDownload.current.push(data)

      if (isModal.google) updateMap(data.id, true)

      if (data.testtype === 'N') {
        nCount += 1
      } else if (data.testtype === 'S') {
        sCount += 1
      } else if (data.testtype === 'D') {
        dCount += 1
      } else {
        oCount += 1
      }

      recordCount += 1

      return (
        <tr key={data.id.toString()} data-id={data.id} data-i={i} onClick={selectRow} onMouseOver={hightlightMarker}>          
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>
            {
              isFailingTest ? 
              data.archiveBy !== '' ? <Icon name='archive' /> : <Icon name='circle' outline={true} onClick={openArchive} title='Archive ?' /> : 
              data.archiveBy !== '' ? <Icon name='archive' onClick={null} /> : ''
            }
          </td>
          <td>{jn} {gd}</td>
          <td>{data.testdate}</td>
          <td>{testNo.trim()}</td>
          <td style={{display: 'none'}}>{data.east}</td>
          <td style={{display: 'none'}}>{data.north}</td>
          <td style={{
            backgroundColor: data.testTypeDisplay === 'nuke' ? 'orange' : data.testTypeDisplay === 'sand' ? 'dodgerblue' :data.testTypeDisplay === 'drive' ? 'gray' : 'white',
            color: data.testTypeDisplay !== 'nuke' && data.testTypeDisplay !== 'sand' && data.testTypeDisplay !== 'drive' ? 'black' : 'white'
          }}>{data.testTypeDisplay}</td>
          {isModal.preview ? null :
            <>
              <td>{data.nukeId}</td>
              <td>{elevD}</td>
              <td>{md.trim()}</td>
              <td>{max.trim()}</td>
              <td>{reqComp}</td>
              <td>{relComp}</td>
              <td>{passFail}</td>
              <td>{entryBy}</td>
              <td>{entryTime}</td>
              <td>{modBy}</td>
              <td>{modTime}</td>
            </>
          }
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  let listOfTestFilters = testFilters.map((data, i) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let gd = data.gradeId === null ? '' : data.gradeId === 'RG' ? 'Grading' : data.gradeId === 'PG' ? 'Post' : ''

    return (
      <tr key={data.id.toString()} data-i={i} onClick={selectTestFilters}>
        <td>{jn} {gd}</td>
        <td>{data.description}</td>
        <td><Icon name='edit' title='Edit filter' outline={true} onClick={openFilterEdit} /></td>
        <td><Icon name='delete' title='Delete filter' color='tomato' outline={true} onClick={deleteFilter} /></td>
      </tr>
    )

  })

  // let modalContent = (
  //   <div key={isValidated.reTestActive} style={{width: '100%', height: '100%', textAlign: 'center'}}>

  //     <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

  //       {isModal.edit && isValidated.showRemoved ?

  //         <>
  //           <label>Removed?
  //             <select style={{width: 75}} className='select' pattern=".{1,}" name='removed' onInput={validate} onChange={clearTestDate} required>
  //               <option value={false}>No</option>
  //               <option value={true}>Yes</option>
  //             </select>
  //           </label><br />
  //         </> : isModal.edit && !isValidated.showRemoved ? <p>I have been Removed :(</p> : null

  //       }

  //       {isValidated.testType === 'N' ?

  //         <div>
  //           <label>Nuke ID</label>
  //           <input style={{width: 75}} className='input' type="text" value={isModal.add ? props.user.nukeId : isValidated.nukeId} disabled />
  //         </div> : null

  //       }

  //       <label>Test Date
  //         <input className='input' type="date" pattern=".{1,}" name='testDate' onInput={validate} onChange={changedData} defaultValue={isValidated.testDate} required />
  //       </label><br />

  //       <label>Prefix
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.pre} onClick={openPrefix} />
  //       </label><br />

  //       <label>Test No
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.testNo} disabled />
  //       </label><br />

  //       <label>Suffix
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.suf} disabled />
  //       </label><br />

  //       <label>Test Type
  //         <select style={{width: 75}} className='select' pattern=".{1,}" name='testType' onInput={validate} onChange={changedData} defaultValue={isValidated.testType} required>
  //           <option value=""></option>
  //           <option value="N">N</option>
  //           <option value="S">S</option>
  //           <option value="D">D</option>
  //         </select>
  //       </label><br />

  //       <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

  //         <CircleButton iconName='add_location' onClick={openLocate} /><br />

  //         {isValidated.north !== null && isValidated.north !== '' ? <p>N: {isValidated.north}</p> : null}
  //         {isValidated.east !== null && isValidated.east !== '' ? <p>E: {isValidated.east}</p> : null}

  //       </div>

  //       <label>Elev/ Depth (ft)
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elevD' onInput={validate} onChange={changedData} defaultValue={isValidated.elevD} required />
  //       </label><br />

  //       {isValidated.pre !== 'AC' ?

  //         <div>
  //           <label>Moisture Content (%)</label>
  //           <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='moistCont' onInput={validate} onChange={changedData} defaultValue={isValidated.moistCont} required />
  //         </div> :
  //         <p>Asphalt doesnt need a Moisture</p>

  //       }

  //       <div>
  //         <label>Dry Density (pcf)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{2,3}(\.\d{1})?" name='dryDens' onInput={validate} onChange={changedData} defaultValue={isValidated.dryDens} required />
  //       </div>

  //       <label>Curve
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.curve} onClick={openCurve} />
  //       </label><br />

  //       <label>Req Comp (%)
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='reqComp' onInput={validate} onChange={changedData} defaultValue={isValidated.reqComp} required />
  //       </label><br />

  //       <label>Rel Comp (%)
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.relComp} disabled />
  //       </label><br />

  //       <label>Pass/Fail
  //         <input style={{width: 75}} className='input' type="text" value={isValidated.passFail} disabled />
  //       </label>

  //     </div>

  //   </div>
  // )

  let archiveContent = (
    <div>

      <div style={{textAlign: 'center'}}>

        <div>
          <label className='label'>Note</label>
          <textarea style={{height: 400, maxWidth: 'calc(100% - 30px)'}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='archiveNote' onInput={validate} onChange={changedData} required>{isValidated.archiveNote}</textarea>
        </div>
        
      </div>

    </div>
  )

  let contentFilterMenu = (
    <div>
      {testFilters.length === 0 ?
        <p>No filters found</p> :
        <table>

          <thead>
            <tr>
              <th>JN</th>
              <th>Desc</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {listOfTestFilters}
          </tbody>

        </table>
      }
    </div>
  )

  let contentFilterEdit = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label>Desc</label>
          <input style={{width: 200}} className='input' type="text" name='description' defaultValue={filter.description} onChange={updateTestFilters} />
        </div>

        <div>
          <label>Prefix</label>
          <input style={{width: 200}} className='input' type="text" name='prefix' defaultValue={filter.prefix} onChange={updateTestFilters} />
        </div>

        <div>
          <label>Tests</label>
          <input style={{width: 200}} className='input' type="text" name='tests' defaultValue={filter.tests} onChange={updateTestFilters} />
        </div>

      </div>

    </div>
  )

  let contentAutoCad = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>

          <span>
            <label className='label' style={{display: 'inline-block', width: 100}}>Block</label>
          </span>

          <span>
            <label className='label' style={{display: 'inline-block', width: 100, marginRight: 10}}>Label</label>
          </span>

        </div>

        <div>

          <span>
            <label className='label'>Enter Size</label>
            <input className='input' style={{width: 100}} className='input' type="text" name='blockSize' onChange={updateAutoCadFactor} />
          </span>

          <span>
            <input className='input' style={{width: 100}} className='input' type="text" name='labelSize' onChange={updateAutoCadFactor} />
          </span>

        </div>

        <div>

          <span>
            <label className='label'>Enter Rotation</label>
            <input className='input' style={{width: 100}} className='input' type="text" name='blockRotation' onChange={updateAutoCadFactor} />
          </span>

          <span>
            <input className='input' style={{width: 100}} className='input' type="text" name='labelRotation' onChange={updateAutoCadFactor} />
          </span>

        </div>

        <div>
          <label className='label'>Select Block</label>
          <select className='select' name='block' onChange={updateAutoCadFactor} required>
            <option value='DensityTest'>DensityTest</option>
            <option value='Circle'>Circle</option>
            <option value='Rectangle'>Rectangle</option>
            <option value='Square'>Square</option>
          </select>
        </div>

        <div style={{marginRight: 10}}>
          <label className='label'>Include Coord Factors?</label>
          <input type='checkbox' name='includeCoordFactor' onChange={updateAutoCadFactor} defaultChecked />
        </div>

        <div style={{margin: 10}}>
          <small>The location of the Block files must be added to 'Support File Search Path'</small>
          <div><small>{`AutoCad Icon --> Options --> Files --> Support File Search Path --> Add T:\CAD Files\Blocks`}</small></div>
        </div>

      </div>

    </div>
  )

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '1px solid black',
    margin: 5
  }

  //console.log(`poly: ${filter.polygon}`)

  //{isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} /> : null}

  return (
    <>
      {isModal.add || isModal.edit ?
        <TestInput                
          close={closeModal} 
          fetchedData={fetchedData}
          updateFetchedData={updateFetchedData}
          selectNuke={props.selectNuke}
          isModal={isModal}
          user={props.user}
          filter={props.filter}
          planMode={props.planMode}
          component={props.component}
        /> : null
      }
      {isModal.archive ? <Modal add={isFailingTest ? editArchive : null} content={archiveContent} closeModal={closeModal} height='none' /> : null}      
      {isModal.coordFactor ? <CoordinateFactors user={props.user} filter={props.filter} close={closeCoordFactor} /> : null}      
      {isModal.filterMenu ? <Modal content={contentFilterMenu} closeModal={closeFilterMenu} isModal={isModal} /> : null}
      {isModal.filterEdit ? <Modal add={editFilter} content={contentFilterEdit} closeModal={closeFilterEdit} isModal={isModal} /> : null}
      {isModal.filterPolygon ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectFilterPolygon} closePlan={closeFilterPolygon} planMode={'plansFilterPolygon'} modal={true} /> : null}
      {isModal.autocad ? <Modal add={downloadTestsForCAD} content={contentAutoCad} closeModal={closeAutoCad} isModal={isModal} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{flex: isModal.google || isModal.plan || isModal.preview ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <div style={{width: '100%'}}>

                {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                <Icon name='info' title='Add Coordinate Factors' onClick={openCoordFactor} outline={true} />
                <Icon name='warning' title='View Re-tests Needed' color={isFailingTest ? 'tomato' : 'gray'} onClick={viewFailingTest} />
                <Icon name='delete' title='View Removed Tests' color={isRemoved ? 'tomato' : 'gray'} onClick={viewRemoved} />
                <Icon name='archive' title='View Archived Tests' color={isModal.archived ? 'dodgerblue' : 'gray'} onClick={toggleArchived} />
                <Icon name='file_download' title='Download to Excel' onClick={exportToExcel} />
                <Icon name='hdr_auto' title='Download to AutoCAD' outline={true} onClick={openAutoCad} />
                <Icon name='refresh' title='Refresh' onClick={fetchData} />
                <Icon name='location_on' title='View in Google Maps' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
                <Icon name='filter_list' title='Advanced Filter' color={isModal.filter ? 'dodgerblue' : 'gray'} onClick={toggleFilter} />
                <Icon name='article' title='View Report' color={isModal.preview ? 'dodgerblue' : 'gray'} onClick={togglePreview} />

              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {isModal.filter ?

                <>

                  <div style={{display: 'flex', alignItems: 'center'}}>

                    <input style={{width: 200, marginLeft: 10}} className='input' type="text" name='prefix' placeholder='prefix i.e. none, FG, WL' onChange={updateTestFilters} value={filter.prefix} />
                    <input style={{width: 200, marginLeft: 10}} className='input' type="text" name='tests' placeholder='tests i.e. 1-10, 12, 14-17' onChange={updateTestFilters} value={filter.tests} />
                    
                    {!isModal.preview ?

                      <>
                    
                        <Icon name='map' title='Draw Polygon' onClick={openFilterPolygon} />
                        
                        {filter.polygon.length > 0 ?

                          <>

                            <div style={styleBtn}>                    
                              <Icon name='share_location' outline={true} title='Polygon Found!' />
                              <div style={{display: 'inline-block', margin: 10}}>
                                <small style={{marginRight: 10}}>Inside</small>
                                <label className='switch'>
                                  <input type='checkbox' onClick={togglePosition} defaultChecked={filter.position === -1 ? false : true} />
                                  <span className='slider round'></span>
                                </label>
                                <small style={{margin: 10}}>Outside</small>
                              </div>
                            </div>                       
                            
                          </> : null

                        }

                        <Icon name='close' title='Clear filter' color='tomato' onClick={clearFilter} />
                        <Icon name='save' title='Save filter' onClick={addFilter} outline={true} />
                        <Icon name='list' title='Saved Filters' onClick={openFilterMenu} />

                      </> : null
                  
                    }

                  </div>

                  {isModal.preview ?

                    <div style={{display: 'flex', alignItems: 'center'}}>
                    
                      <Icon name='map' title='Draw Polygon' onClick={openFilterPolygon} />
                      
                      {filter.polygon.length > 0 ?

                        <>

                          <div style={styleBtn}>                    
                            <Icon name='share_location' outline={true} title='Polygon Found!' />
                            <div style={{display: 'inline-block', margin: 10}}>
                              <small style={{marginRight: 10}}>Inside</small>
                              <label className='switch'>
                                <input type='checkbox' onClick={togglePosition} defaultChecked={filter.position === -1 ? false : true} />
                                <span className='slider round'></span>
                              </label>
                              <small style={{margin: 10}}>Outside</small>
                            </div>
                          </div>                       
                          
                        </> : null

                      }

                      <Icon name='close' title='Clear filter' color='tomato' onClick={clearFilter} />
                      <Icon name='save' title='Save filter' onClick={addFilter} outline={true} />
                      <Icon name='list' title='Saved Filters' onClick={openFilterMenu} />

                    </div> : null

                  }
                  
                </> : null

              }

              {fetchedData.length > 0 ?

                <>

                  <small style={{margin: 10}}>{recordCount} Total Records. (If no filters, limit 500)</small>

                  {nCount > 0 || sCount > 0 || dCount > 0 ?
                    <div style={{marginLeft: 10, marginTop: 10}}>
                      {nCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'orange', color: 'white'}}>Nuke: {Math.round(nCount / recordCount * 100 * 10) / 10}%</span>}
                      {sCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'dodgerblue', color: 'white'}}>Sand: {Math.round(sCount / recordCount * 100 * 10) / 10}%</span>}
                      {dCount === 0 ? null : <span style={{marginRight: 10, borderRadius: 10, width: 100, padding: 10, backgroundColor: 'gray', color: 'white'}}>Drive: {Math.round(dCount / recordCount * 100 * 10) / 10}%</span>}
                      {oCount === 0 ? null : <span style={{marginRight: 10, width: 100, padding: 10}}>?: {Math.round(oCount / recordCount * 100 * 10) / 10}%</span>}
                    </div> : null
                  }

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table id="toExcel">

                      <thead>
                        <tr>                          
                          {isModal.google ? <th></th> : null}
                          <th>{isFailingTest ? `Archive ?` : ''}</th>
                          <th>JN</th>
                          <th>Date</th>
                          <th>Test</th>
                          <th style={{display: 'none'}}>E</th>
                          <th style={{display: 'none'}}>N</th>
                          <th>Type</th>
                          {isModal.preview || isModal.google ? null :
                            <>
                              <th>Nuke</th>
                              <th>E/D</th>
                              <th>D/M</th>
                              <th>Max</th>
                              <th>Req.</th>
                              <th>Rel.</th>
                              <th>P/F</th>
                              <th>Entry by</th>
                              <th>Entry time</th>
                              <th>Mod by</th>
                              <th>Mod time</th>
                            </>
                          }
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No tests found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

          {isModal.plan ?

            <div style={{margin: 10, flex: '0 1 auto', overflow: 'auto'}}>
              <Plans user={props.user} filter={props.filter} planMode={props.planMode} />
            </div> : null

          }

          {isModal.preview ?

            <div style={{flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
                <span>
                  <input className='input' style={{width: 100}} placeholder='Report Date' className='input' type="text" name='reportDate' onChange={updateReportDetails} />
                  <input className='input' style={{width: 100}} placeholder='TABLE ?' className='input' type="text" name='tableName' onChange={updateReportDetails} />
                  <input className='input' style={{width: 100}} placeholder='Start Page' className='input' type="text" name='page' onChange={updateReportDetails} />
                </span>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
                  <iframe style={{height: 'calc(100% - 10px)', width: 'calc(100% - 10px)'}} src={pdfTests(forDownload.current, props.filter, fetchPrefixes.current, reportDetails)}></iframe>
                </div>
              </div>
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>
  )

}

export default Tests
