import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError } from 'scripts/common'

//import { selectData } from 'scripts/offline'

const CoordinateFactors = (props) => {

  // isModal is for ViewLocation in Modal and nothing more

  const [isModal, setIsModal] = useState({
    edit: true
  })

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    rgnf: '',
    rgnf: '',
    rgef: '',
    pgnf: '',
    pgef: ''
  })

  const fetchData = () => {

    fetch('/api/selectCoordFactors', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {

        setIsValidated(result[0])

      },
      (error) => {
        alert('Error: could not select factors. Contact and admin.')
        catchError(props.filter.jobNumber, '', props.component, 'selectCoordFactors', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const editCoordFactors = () => {

    if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else {

      getLocation(function(latlng){

        fetch('/api/editCoordFactors', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            rgnf: isValidated.rgnf === null || isValidated.rgnf === '' ? 0 : isValidated.rgnf,
            rgef: isValidated.rgef === null || isValidated.rgef === '' ? 0 : isValidated.rgef,
            pgnf: isValidated.pgnf === null || isValidated.pgnf === '' ? 0 : isValidated.pgnf,
            pgef: isValidated.pgef === null || isValidated.pgef === '' ? 0 : isValidated.pgef,
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.close()

          },
          (error) => {
            alert('Error: could not edit factors. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'editCoordFactors', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  let content = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div>
        <h2 style={{display: 'inline-block', textAlign: 'center', margin: 10}}>Coordinate Factor</h2>
      </div>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10, borderBottom: '1px solid black'}}>

        <h3>Grading</h3>

        <div>
          <label className='label'>North</label>
          <input style={{width: 100}} className='input' type="text" pattern="\d{1,}" name='rgnf' onInput={validate} onChange={changedData} value={isValidated.rgnf} required />
        </div>

        <div>
          <label className='label'>East</label>
          <input style={{width: 100}} className='input' type="text" pattern="\d{1,}" name='rgef' onInput={validate} onChange={changedData} value={isValidated.rgef} required />
        </div>

        <h3>Post</h3>

        <div>
          <label className='label'>North</label>
          <input style={{width: 100}} className='input' type="text" pattern="\d{1,}" name='pgnf' onInput={validate} onChange={changedData} value={isValidated.pgnf} required />
        </div>

        <div>
          <label className='label'>East</label>
          <input style={{width: 100}} className='input' type="text" pattern="\d{1,}" name='pgef' onInput={validate} onChange={changedData} value={isValidated.pgef} required />
        </div>

      </div>

      <div>
        <small>Coordinates are sometimes truncated for display purposes in reports, however CAD drawings may use the full coordinate system. These factors are applied to coordinates for CAD use.</small>
      </div>

    </div>
  )

  return <Modal content={content} add={editCoordFactors} closeModal={close} isValidated={isValidated} isModal={isModal} />
}

export default CoordinateFactors
