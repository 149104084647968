import React, {useState, useEffect, useRef} from 'react'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import Prefix from 'components/menu/Prefix'
import Curve from 'components/menu/Curve'
import Plans from 'components/main/Plans'
import Nukes from 'components/main/Nukes'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const TestInput = (props) => {

  const [isModal, setIsModal] = useState({    
    curve: false,
    prefix: false,    
    locate: false,
    warning: 0,
    warningContent: '',    
    nuke: false,
    saving: false
  })

  const isChanged = useRef(false)

  const [myNuke, setMyNuke] = useState([])

  // const [isRetest, setIsRetest] = useState(false)
  // const [isFailingTest, setIsFailingTest] = useState(false)
  // const [isRemoved, setIsRemoved] = useState(false)  

  // used to update passFail in Modal
  const updatePassFail = useRef({
    sandInitialWt: '', 
    sandFinalWt: '', 
    sandExtraWt: '', 
    sandDensity: '', 
    wetWt: '', 
    tareWt: '', 
    volumeFactor: '', 
    moistWetWt: '', 
    moistDryWt: '', 
    moistTareWt: '',
    dryDens: null,
    optD: null,
    reqComp: null
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: '',
    sandInitialWt: '', 
    sandFinalWt: '', 
    sandExtraWt: '', 
    sandDensity: '', 
    wetWt: '', 
    tareWt: '', 
    volumeFactor: '', 
    moistWetWt: '', 
    moistDryWt: '', 
    moistTareWt: ''
  })  

  useEffect(() => {
    fetchNuke()

    if (props.isModal.add) {

      // if adding test, temporary: dynamically assigned on post
      let testNo = props.fetchedData.length < 1 ? 1 : Math.max(...props.fetchedData.map(o => o.gradeid === props.filter.gradeId ? o.testno : 0)) + 1      

      setIsValidated(prevState => ({...prevState,        
        testNo: testNo,
        north: props.location === undefined ? '' : props.location.n,
        east: props.location === undefined ? '' : props.location.e      
      }))

    } else if (props.isModal.edit) {

      let i = props.isModal.i

      //console.log(`i: ${JSON.stringify(props.isModal)}`)
      //console.log(`fetchedData: ${JSON.stringify(props.fetchedData[i])}`)
      //console.log(`fetchedData: ${JSON.stringify(props.fetchedData)}`)    
  
      // this is for removed, if returns undefined, then not removed
      let removed =  props.fetchedData.find(test => test.testno === props.fetchedData[i].testno && test.suf === 'R')
      let reTest = false
  
      if (!removed && props.fetchedData[i].passfail === 'F') {
  
        // this is for retest, if returns undefined, then not found, so retest needed
        reTest = props.fetchedData.find(test => test.testno === props.fetchedData[i].testno && test.passfail === 'P')
        //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
  
      }
  
      // used to dynamically update relComp and passFail during input
      updatePassFail.current = {
        sandInitialWt: props.fetchedData[i].sandInitialWt, 
        sandFinalWt: props.fetchedData[i].sandFinalWt, 
        sandExtraWt: props.fetchedData[i].sandExtraWt, 
        sandDensity: props.fetchedData[i].sandDensity, 
        wetWt: props.fetchedData[i].wetWt, 
        tareWt: props.fetchedData[i].tareWt, 
        volumeFactor: props.fetchedData[i].volumeFactor, 
        moistWetWt: props.fetchedData[i].moistWetWt, 
        moistDryWt: props.fetchedData[i].moistDryWt, 
        moistTareWt: props.fetchedData[i].moistTareWt,
        dryDens: props.fetchedData[i].drydens,
        optD: props.fetchedData[i].optd,
        reqComp: props.fetchedData[i].reqcomp
      }
  
      setIsValidated(prevState => ({...prevState,
        entryby: props.fetchedData[i].entryby,
        entrytime: props.fetchedData[i].entrytime,
        entrylat: props.fetchedData[i].entrylat,
        entrylng: props.fetchedData[i].entrylng,
        entrydevice: props.fetchedData[i].entrydevice,
        modby: props.fetchedData[i].modby,
        modtime: props.fetchedData[i].modtime,
        modlat: props.fetchedData[i].modlat,
        modlng: props.fetchedData[i].modlng,
        moddevice: props.fetchedData[i].moddevice,
        id: props.fetchedData[i].id,
        reTest: reTest === undefined ? true : false,
        showRemoved: removed === undefined ? true : false,
        removed: removed === undefined ? false : true,
        nukeId: props.fetchedData[i].nukeId,
        testDate: props.fetchedData[i].testdate,
        pre: props.fetchedData[i].pre,
        testNo: props.fetchedData[i].testno,
        suf: props.fetchedData[i].suf,
        testType: props.fetchedData[i].testtype,
        north: props.fetchedData[i].n !== undefined ? props.fetchedData[i].n : props.fetchedData[i].north,
        east: props.fetchedData[i].e !== undefined ? props.fetchedData[i].e : props.fetchedData[i].east,
        elevD: props.fetchedData[i].elevd,
        moistCont: props.fetchedData[i].moistcont,
        dryDens: props.fetchedData[i].drydens,
        maxId: props.fetchedData[i].maxId,
        curve: props.fetchedData[i].curve,
        optD: props.fetchedData[i].optd,
        optM: props.fetchedData[i].optm,
        reqComp: props.fetchedData[i].reqcomp,
        relComp: props.fetchedData[i].relcomp,
        passFail: props.fetchedData[i].passfail,        
        sandInitialWt: props.fetchedData[i].sandInitialWt, 
        sandFinalWt: props.fetchedData[i].sandFinalWt, 
        sandExtraWt: props.fetchedData[i].sandExtraWt, 
        sandDensity: props.fetchedData[i].sandDensity, 
        wetWt: props.fetchedData[i].wetWt, 
        tareWt: props.fetchedData[i].tareWt, 
        volumeFactor: props.fetchedData[i].volumeFactor, 
        moistWetWt: props.fetchedData[i].moistWetWt, 
        moistDryWt: props.fetchedData[i].moistDryWt, 
        moistTareWt: props.fetchedData[i].moistTareWt
      }))

    }
    
  }, [])  

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    if (isValidated.testType === 'S') {

      if (
        name === 'sandInitialWt' || 
        name === 'sandFinalWt' || 
        name === 'sandExtraWt' ||
        name === 'sandDensity' ||
        name === 'wetWt' ||
        name === 'tareWt' ||      
        name === 'moistWetWt' ||
        name === 'moistDryWt' ||
        name === 'moistTareWt' ||
        name === 'reqComp'
      ) updatePassFail.current.[name] = Number(value)

      let sandWt = updatePassFail.current.sandInitialWt - updatePassFail.current.sandFinalWt - updatePassFail.current.sandExtraWt
      let vf = sandWt / updatePassFail.current.sandDensity
      let volumeFactor = isNaN(vf) ? '' : vf
      let wetDens = (updatePassFail.current.wetWt - updatePassFail.current.tareWt) / volumeFactor

      let mc = Math.round((updatePassFail.current.moistWetWt - updatePassFail.current.moistDryWt) / (updatePassFail.current.moistDryWt- updatePassFail.current.moistTareWt) * 100 * 10) / 10
      let moistCont = isNaN(mc) ? '' : mc
      let dd = Math.round(wetDens / (1 + moistCont / 100) * 10) / 10
      let dryDens = isNaN(dd) ? '' : dd
      let optD = updatePassFail.current.optD

      let rc = dryDens !== '' && optD !== '' && optD !== null ? Math.round((Number(dryDens)/Number(updatePassFail.current.optD))*100) : ''
      let relComp = isNaN(rc) ? '' : rc
      let passFail = relComp === '' ? '' : relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

      setIsValidated(prevState => ({...prevState, [name]: state ? value : null, volumeFactor: volumeFactor, dryDens: dryDens, moistCont: moistCont, relComp: relComp, passFail: passFail}))

    } else if (isValidated.testType === 'D') {

      if (        
        name === 'wetWt' ||
        name === 'tareWt' ||
        name === 'volumeFactor' ||      
        name === 'moistWetWt' ||
        name === 'moistDryWt' ||
        name === 'moistTareWt' ||
        name === 'reqComp'
      ) updatePassFail.current.[name] = Number(value)
      
      // grams to lbs: 1/454
      
      let wetDens = (updatePassFail.current.wetWt - updatePassFail.current.tareWt) / 454 / updatePassFail.current.volumeFactor

      let mc = Math.round((updatePassFail.current.moistWetWt - updatePassFail.current.moistDryWt) / (updatePassFail.current.moistDryWt- updatePassFail.current.moistTareWt) * 100 * 10) / 10
      let moistCont = isNaN(mc) ? '' : mc
      let dd = Math.round(wetDens / (1 + moistCont / 100) * 10) / 10
      let dryDens = isNaN(dd) ? '' : dd
      let optD = updatePassFail.current.optD

      let rc = dryDens !== '' && optD !== '' && optD !== null ? Math.round((Number(dryDens)/Number(updatePassFail.current.optD))*100) : ''
      let relComp = isNaN(rc) ? '' : rc
      let passFail = relComp === '' ? '' : relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

      setIsValidated(prevState => ({...prevState, [name]: state ? value : null, dryDens: dryDens, moistCont: moistCont, relComp: relComp, passFail: passFail}))

    } else if (name === 'dryDens' || name === 'optD' || name === 'reqComp') {
      
      updatePassFail.current.[name] = value

      let dryDens = updatePassFail.current.dryDens
      let optD = updatePassFail.current.optD
      
      let rc = dryDens !== '' && optD !== '' && optD !== null ? Math.round((Number(dryDens)/Number(updatePassFail.current.optD))*100) : ''
      let relComp = isNaN(rc) ? '' : rc
      let passFail = relComp === '' ? '' : relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

      console.log(`
        curve: ${isValidated.curve}
        dryDens: ${dryDens}
        optD: ${optD}
        rc: ${rc}
        relComp: ${relComp}
        passFail" ${passFail}
      `)

      setIsValidated(prevState => ({...prevState, [name]: state ? value : null, relComp: relComp, passFail: passFail}))

    } else {

      setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

    }

  }

  const close = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        props.close()
      }
    } else {
      props.close()
    }

  }

  const changedData = () => isChanged.current = true

  const clearTestDate = () => {

    setIsValidated(prevState => ({...prevState, testDate: ''}))
    changedData()

  }

  const selectPrefix = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (isValidated.pre !== td[0].textContent) {

      setIsValidated(prevState => ({...prevState, pre: td[0].textContent}))
      changedData()

    }

    closePrefix()

  }

  const clearPrefix = (e) => {

    if (isValidated.pre !== '') {

      setIsValidated(prevState => ({...prevState, pre: ''}))
      changedData()

    }

    closePrefix()

  }

  const selectCurve = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    let maxId = Number(td[0].textContent)
    let optD = Number(td[3].textContent)
    let optM = Number(td[4].textContent)    

    if (isValidated.maxId !== maxId) {

      updatePassFail.current.optD = optD

      // update in case
      let relComp = Math.round((Number(updatePassFail.current.dryDens)/Number(updatePassFail.current.optD))*100)
      let passFail = relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

      // console.log(
      //   `
      //   dryDens: ${updatePassFail.current.dryDens}
      //   optD: ${updatePassFail.current.optD}
      //   reqComp: ${updatePassFail.current.reqComp}
      //   relComp: ${relComp},
      //   passFail: ${passFail},
      //   `
      // )

      setIsValidated(prevState => ({...prevState,
        maxId: maxId,
        curve: td[2].textContent,
        optD: optD,
        optM: optM,
        relComp: relComp, 
        passFail: passFail
      }))
      changedData()

    }

    closeCurve()

  }

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      north: n,
      east: e
    }))
  }

  const addData = () => {

    const addToServer = () => {

      //setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/addTest', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
            testdate: isValidated.testDate,
            pre: isValidated.pre,
            testno: isValidated.testNo,
            suf: isValidated.suf,
            testtype: isValidated.testType,
            north: isValidated.north,
            east: isValidated.east,
            elevd: isValidated.elevD,
            moistcont: isValidated.moistCont,
            drydens: isValidated.dryDens,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optd: isValidated.optD,
            optm: isValidated.optM,
            reqcomp: isValidated.reqComp === '' ? null : isValidated.reqComp,
            relcomp: isValidated.relComp === '' ? null : isValidated.relComp,
            passfail: isValidated.passFail,
            removed: isValidated.removed,
            showRemoved: isValidated.showRemoved,
            sandInitialWt: isValidated.sandInitialWt, 
            sandFinalWt: isValidated.sandFinalWt, 
            sandExtraWt: isValidated.sandExtraWt, 
            sandDensity: isValidated.sandDensity, 
            wetWt: isValidated.wetWt, 
            tareWt: isValidated.tareWt, 
            volumeFactor: isValidated.volumeFactor, 
            moistWetWt: isValidated.moistWetWt, 
            moistDryWt: isValidated.moistDryWt, 
            moistTareWt: isValidated.moistTareWt
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result[0].lastId))

            let description = `${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', description.trim(), props.user.username)

            props.updateFetchedData(
              [{entryby: props.user.username,
                entrytime: formatDateTime(new Date()),
                entrylat: latlng.lat,
                entrylng: latlng.lng,
                entrydevice: props.user.device,
                id: result[0].lastId,
                jobnumber: props.filter.jobNumber,
                gradeid: props.filter.gradeId,
                nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
                testdate: isValidated.testDate,                
                pre: isValidated.pre,
                testno: isValidated.testNo,
                suf: isValidated.suf,
                testtype: isValidated.testType,
                north: isValidated.north,
                east: isValidated.east,
                elevd: isValidated.elevD,
                moistcont: isValidated.moistCont,
                drydens: isValidated.dryDens,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optd: isValidated.optD,
                optm: isValidated.optM,
                reqcomp: isValidated.reqComp,
                relcomp: isValidated.relComp,
                passfail: isValidated.passFail,
                removed: isValidated.removed,
                sandInitialWt: isValidated.sandInitialWt, 
                sandFinalWt: isValidated.sandFinalWt, 
                sandExtraWt: isValidated.sandExtraWt, 
                sandDensity: isValidated.sandDensity, 
                wetWt: isValidated.wetWt, 
                tareWt: isValidated.tareWt, 
                volumeFactor: isValidated.volumeFactor, 
                moistWetWt: isValidated.moistWetWt, 
                moistDryWt: isValidated.moistDryWt, 
                moistTareWt: isValidated.moistTareWt
              }, ...props.fetchedData]
            )            

            //fetchData() // i need the id if edited
            isChanged.current = false
            close()

          },
          (error) => {
            //setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not add test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTest', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    if (props.user.test < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (!isValidated.removed && isValidated.testType === 'N' && (props.user.nukeId === '' || props.user.nukeId === null)) {
      alert('Please select a Nuke from the Home page.')
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.");
    
    } else if (isValidated.testType ==='S' && isValidated.sandInitialWt === '') {
      alert("Please provide an Initial Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandFinalWt === '') {
      alert("Please provide a Final Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandExtraWt === '') {
      alert("Please provide Wt of Sand in Cone/Plate.")
    } else if (isValidated.testType ==='S' && isValidated.sandDensity === '') {
      alert("Please provide Density of Sand.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.wetWt === '') {
      alert("Please provide Wet Wt of Soil and Tube.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.tareWt === '') {
      alert("Please provide Wt of Tube.")
    } else if (isValidated.testType ==='D' && isValidated.volumeFactor === '') {
      alert("Please provide Tube Factor.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistWetWt === '') {
      alert("Please provide Wet Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistDryWt === '') {
      alert("Please provide Dry Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistTareWt === '') {
      alert("Please provide Tare Wt for Moisture.")    

    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    // } else if (isValidated.curve === null || isValidated.curve ==='') {
    //     alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    // } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
    //     alert("Relative compaction cannot be greater than 100%.");
    } else {

      if (isValidated.curve === null || isValidated.curve === '') {

        if (!window.confirm('No curve selected. Proceed ಠ_ಠ ?')) return

      } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {

        if (!window.confirm('Relative compaction is greater than 100%. Proceed ಠ_ಠ ?')) return

      }      

      // include optd and optm???

      if (props.user.bufferData) {

        let id = Math.max(...props.fetchedData.map(o => o.gradeid === props.filter.gradeId ? o.id : 0)) + 1

        addPending({
          actionId: 1,
          action: 'addTest',
          table: 'Tests',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
          testdate: isValidated.testDate,
          pre: isValidated.pre,
          testno: isValidated.testNo,
          suf: isValidated.suf,
          testtype: isValidated.testType,
          north: isValidated.north,
          east: isValidated.east,
          elevd: isValidated.elevD,
          moistcont: isValidated.moistCont,
          drydens: isValidated.dryDens,
          maxId: isValidated.maxId,
          curve: isValidated.curve,
          optd: isValidated.optD,
          optm: isValidated.optM,
          reqcomp: isValidated.reqComp,
          relcomp: isValidated.relComp,
          passfail: isValidated.passFail,
          removed: isValidated.removed,
          showRemoved: isValidated.showRemoved
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            props.updateFetchedData(prevState =>
              [
                {
                  syncedID: false,
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
                  testdate: isValidated.testDate,                  
                  pre: isValidated.pre,
                  testno: isValidated.testNo,
                  suf: isValidated.suf,
                  testtype: isValidated.testType,
                  north: isValidated.north,
                  east: isValidated.east,
                  elevd: isValidated.elevD,
                  moistcont: isValidated.moistCont,
                  drydens: isValidated.dryDens,
                  maxId: isValidated.maxId,
                  curve: isValidated.curve,
                  optd: isValidated.optD,
                  optm: isValidated.optM,
                  reqcomp: isValidated.reqComp,
                  relcomp: isValidated.relComp,
                  passfail: isValidated.passFail,
                  removed: isValidated.removed
                },
                ...prevState
              ]
            )

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      //setIsModal(prevState => ({...prevState, saving: true}))

      getLocation(function(latlng){

        fetch('/api/editTest', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            testdate: isValidated.testDate,
            pre: isValidated.pre,
            testno: isValidated.testNo,
            suf: isValidated.suf,
            testtype: isValidated.testType,
            north: isValidated.north,
            east: isValidated.east,
            elevd: isValidated.elevD,
            moistcont: isValidated.moistCont,
            drydens: isValidated.dryDens,
            maxId: isValidated.maxId,
            curve: isValidated.curve,
            optd: isValidated.optD,
            optm: isValidated.optM,
            reqcomp: isValidated.reqComp === '' ? null : isValidated.reqComp,
            relcomp: isValidated.relComp === '' ? null : isValidated.relComp,
            passfail: isValidated.passFail,
            removed: isValidated.removed,
            sandInitialWt: isValidated.sandInitialWt, 
            sandFinalWt: isValidated.sandFinalWt, 
            sandExtraWt: isValidated.sandExtraWt, 
            sandDensity: isValidated.sandDensity, 
            wetWt: isValidated.wetWt, 
            tareWt: isValidated.tareWt, 
            volumeFactor: isValidated.volumeFactor, 
            moistWetWt: isValidated.moistWetWt, 
            moistDryWt: isValidated.moistDryWt, 
            moistTareWt: isValidated.moistTareWt
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))            

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                testdate: isValidated.testDate,
                pre: isValidated.pre,
                testno: isValidated.testNo,
                suf: isValidated.suf,
                testtype: isValidated.testType,
                north: isValidated.north,
                east: isValidated.east,
                elevd: isValidated.elevD,
                moistcont: isValidated.moistCont,
                drydens: isValidated.dryDens,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optd: isValidated.optD,
                optm: isValidated.optM,
                reqcomp: isValidated.reqComp,
                relcomp: isValidated.relComp,
                passfail: isValidated.passFail,
                removed: isValidated.removed,
                sandInitialWt: isValidated.sandInitialWt, 
                sandFinalWt: isValidated.sandFinalWt, 
                sandExtraWt: isValidated.sandExtraWt, 
                sandDensity: isValidated.sandDensity, 
                wetWt: isValidated.wetWt, 
                tareWt: isValidated.tareWt, 
                volumeFactor: isValidated.volumeFactor, 
                moistWetWt: isValidated.moistWetWt, 
                moistDryWt: isValidated.moistDryWt, 
                moistTareWt: isValidated.moistTareWt
              } :
              data
            ))

            let description = `${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', description.trim(), props.user.username)

            isChanged.current = false
            close()
            //alert('Updated')

          },
          (error) => {
            //setIsModal(prevState => ({...prevState, saving: false}))
            alert('Error: could not edit test. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTest', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

    // if (props.user.test < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (props.isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.")

    } else if (isValidated.testType ==='S' && isValidated.sandInitialWt === '') {
      alert("Please provide an Initial Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandFinalWt === '') {
      alert("Please provide a Final Wt.")
    } else if (isValidated.testType ==='S' && isValidated.sandExtraWt === '') {
      alert("Please provide Wt of Sand in Cone/Plate.")
    } else if (isValidated.testType ==='S' && isValidated.sandDensity === '') {
      alert("Please provide Density of Sand.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.wetWt === '') {
      alert("Please provide Wet Wt of Soil and Tube.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.tareWt === '') {
      alert("Please provide Wt of Tube.")
    } else if (isValidated.testType ==='D' && isValidated.volumeFactor === '') {
      alert("Please provide Tube Factor.")

    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistWetWt === '') {
      alert("Please provide Wet Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistDryWt === '') {
      alert("Please provide Dry Soil/Tare Wt for Moisture.")
    } else if ((isValidated.testType ==='S' || isValidated.testType ==='D') && isValidated.moistTareWt === '') {
      alert("Please provide Tare Wt for Moisture.")

    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    // } else if (isValidated.curve === null || isValidated.curve ==='') {
    //     alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    // } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
    //     alert("Relative compaction cannot be greater than 100%.");
    } else {

      if (isValidated.curve === null || isValidated.curve === '') {

        if (!window.confirm('No curve selected. Proceed ಠ_ಠ ?')) return

      } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {

        if (!window.confirm('Relative compaction is greater than 100%. Proceed ಠ_ಠ ?')) return

      }

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editTest',
          table: 'Tests',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
          testdate: isValidated.testDate,
          pre: isValidated.pre,
          testno: isValidated.testNo,
          suf: isValidated.suf,
          testtype: isValidated.testType,
          north: isValidated.north,
          east: isValidated.east,
          elevd: isValidated.elevD,
          moistcont: isValidated.moistCont,
          drydens: isValidated.dryDens,
          maxId: isValidated.maxId,
          curve: isValidated.curve,
          optd: isValidated.optD,
          optm: isValidated.optM,
          reqcomp: isValidated.reqComp,
          relcomp: isValidated.relComp,
          passfail: isValidated.passFail,
          removed: isValidated.removed,
          showRemoved: isValidated.showRemoved
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            props.updateFetchedData(props.fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                moddevice: props.user.device,
                testdate: isValidated.testDate,
                pre: isValidated.pre,
                testno: isValidated.testNo,
                suf: isValidated.suf,
                testtype: isValidated.testType,
                north: isValidated.north,
                east: isValidated.east,
                elevd: isValidated.elevD,
                moistcont: isValidated.moistCont,
                drydens: isValidated.dryDens,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optd: isValidated.optD,
                optm: isValidated.optM,
                reqcomp: isValidated.reqComp,
                relcomp: isValidated.relComp,
                passfail: isValidated.passFail,
                removed: isValidated.removed
              } :
              data
            ))

            isChanged.current = false
            close()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      //setIsModal(prevState => ({...prevState, saving: true}))

      fetch('/api/deleteTest', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          let description = `${isValidated.pre} ${isValidated.testNo} ${isValidated.suf}`

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', description.trim(), props.user.username)

          //fetchData()
          props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          close()

        },
        (error) => {
          //setIsModal(prevState => ({...prevState, saving: false}))
          alert('Error: could not delete test. Contact and admin.')
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTest', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

    if (props.isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deleteTest',
            table: 'Tests',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))
              
              props.updateFetchedData(props.fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              close()

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const removeTest = () => {
    if (window.confirm('You want to remove me ಠ_ಠ ?')) addData()
  }

  const reTest = () => {


    //console.log(`fetchedData: ${JSON.stringify(fetchedData)}`)
    //console.log(`isValidated: ${JSON.stringify(isValidated)}`)
    let suf
    let sufArr = props.fetchedData.map(data => data.testno === isValidated.testNo && (data.suf !== '' && data.suf !== null) ? data.suf.charCodeAt() : 0)
    //console.log(`sufArr: ${sufArr}`)
    let newSuf = Math.max(...sufArr)
    //console.log(`newSuf: ${newSuf}`)

    if (newSuf === 0) {
      suf ='A'
    } else {
      suf = String.fromCharCode(newSuf + 1)
    }

    setIsValidated(prevState => (
      {...prevState,
        suf: suf,
        moistCont: null,
        dryDens: null,
        testDate: '',
        reTest: false,
        reTestActive: true
      }
    ))

    alert('Suffix ' + suf + ' created. Please provide a moisture, dry density and test date.');

  }

  const openPrefix = () => setIsModal(prevState => ({...prevState, prefix: true}))

  const openCurve = () => setIsModal(prevState => ({...prevState, curve: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const openNuke = () => setIsModal(prevState => ({...prevState, nuke: true}))

  const closePrefix = () => setIsModal(prevState => ({...prevState, prefix: false}))

  const closeCurve = () => setIsModal(prevState => ({...prevState, curve: false}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const closeNuke = () => setIsModal(prevState => ({...prevState, nuke: false}))

  //======= START NUKE SECTION ========

  const fetchNuke = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // my Nuke

      selectData('MyNuke').then(res => {
        if (res.length > 0) {

          if (res[0].checkIn !== '' && res[0].checkIn !== null) {            
            props.selectNuke('')
          } else {
            props.selectNuke(res[0].nukeId)
          }

        } else {
          props.selectNuke('')
        }

        setMyNuke(res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime)))
      })

    } else {

      fetch('/api/myNuke', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

         // updates userDetails so nukeId can be used in Tests
         if (result.length > 0) {

           if (result[0].checkIn !== '' && result[0].checkIn !== null) {
             props.selectNuke('')
           } else {
             props.selectNuke(result[0].nukeId)
           }

         } else {
           props.selectNuke('')
         }

          setMyNuke(result)
        },
        (error) => {
          console.log('Error: myNuke --> ' + error)
        }
      )

    }

  }

  const updateNuke = (result) => {

    // updates userDetails so nukeId can be used in Tests

    if (result[0].checkIn === '') {

      props.selectNuke('')
      setMyNuke(result)

    } else {

      props.selectNuke(result[0].nukeId)
      setMyNuke([{checkIn: true, nukeId: myNuke[0].nukeId, entrytime: ''}])

    }

  }

  //======= END NUKE SECTION ========  

  let moisture = (

    <>
      <div><small>Moisture Input</small></div>

      <div>
        <label className='label'>Wet Soil/Tare Wt (g)</label>
        <input style={{width: 75}} className='input' type="text" pattern="\d{2,}(\.\d{1})?" name='moistWetWt' onInput={validate} onChange={changedData} value={isValidated.moistWetWt} required />        
      </div>

      <div>
        <label className='label'>Dry Soil/Tare Wt (g)</label>
        <input style={{width: 75}} className='input' type="text" pattern="\d{2,}(\.\d{1})?" name='moistDryWt' onInput={validate} onChange={changedData} value={isValidated.moistDryWt} required />        
      </div>

      <div>
        <label className='label'>Tare Wt (g)</label>
        <input style={{width: 75}} className='input' type="text" pattern="\d{2,}(\.\d{1})?" name='moistTareWt' onInput={validate} onChange={changedData} value={isValidated.moistTareWt} required />        
      </div>
    
    </>

  )

  let modalContent = (
    <div key={isValidated.reTestActive} style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {props.isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        {props.isModal.edit && isValidated.showRemoved ?

          <div>
            <label className='label'>Removed?</label>
            <select style={{width: 75}} className='select' pattern=".{1,}" name='removed' onInput={validate} onChange={clearTestDate} required>
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>            
          </div> : props.isModal.edit && !isValidated.showRemoved ? <p>I have been Removed :(</p> : null

        }

        {isValidated.testType === 'N' ?

          <div>
            <label className='label'>Nuke ID</label>
            <input style={{width: 75}} className='input' type="text" value={props.isModal.add ? props.user.nukeId : isValidated.nukeId} disabled />
          </div> : null

        }

        <div>
          <label className='label'>Test Date</label>
          <input className='input' type="date" pattern=".{1,}" name='testDate' onInput={validate} onChange={changedData} value={isValidated.testDate} required />          
        </div>

        <div>
          <label className='label'>Prefix</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.pre} onClick={openPrefix} />          
        </div>
        
        <div>
          <label className='label'>Test No</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.testNo} disabled />          
        </div>

        <div>
          <label className='label'>Suffix</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.suf} disabled />          
        </div>

        <div>
          <label className='label'>Test Type</label>
            <select style={{width: 75}} className='select' pattern=".{1,}" name='testType' onInput={validate} onChange={changedData} value={isValidated.testType} required>
            <option value=""></option>
            <option value="N">N</option>
            <option value="S">S</option>
            <option value="D">D</option>
          </select>          
        </div>

        {isValidated.testType === 'S' ?
        
          <>

            <div><small>Sand Cone Input</small></div>

            <div>
              <label className='label'>Initial Sand Wt (lb)</label>
              <input style={{width: 75}} className='input' min={0} step={0.01} pattern="\d{1,2}(\.\d{1,})?" name='sandInitialWt' onInput={validate} onChange={changedData} value={isValidated.sandInitialWt} required />              
            </div>

            <div>
              <label className='label'>Final Sand Wt (lb)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1,})?" name='sandFinalWt' onInput={validate} onChange={changedData} value={isValidated.sandFinalWt} required />              
            </div>

            <div>
              <label className='label'>Cone/Plate Sand Wt (lb)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1,})?" name='sandExtraWt' onInput={validate} onChange={changedData} value={isValidated.sandExtraWt} required />              
            </div>

            <div>
              <label className='label'>Sand Density (pcf)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,3}(\.\d{1,})?" name='sandDensity' onInput={validate} onChange={changedData} value={isValidated.sandDensity} required />              
            </div>

            <div>
              <label className='label'>Wet Soil/Tare Wt (lb)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1,})?" name='wetWt' onInput={validate} onChange={changedData} value={isValidated.wetWt} required />              
            </div>

            <div>
              <label className='label'>Tare Wt (lb)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1,})?" name='tareWt' onInput={validate} onChange={changedData} value={isValidated.tareWt} required />              
            </div>

            <div>
              <label className='label'>Volume Factor</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.0001} value={isValidated.volumeFactor} disabled />              
            </div>

            {moisture}
          
          </> :

          isValidated.testType === 'D' ?

          <>

            <div><small>Drive Tube Input</small></div>

            <div>
              <label className='label'>Wet Soil/Tube Wt (g)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,}(\.\d{1})?" name='wetWt' onInput={validate} onChange={changedData} value={isValidated.wetWt} required />              
            </div>

            <div>
              <label className='label'>Tube Wt (g)</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,}(\.\d{1})?" name='tareWt' onInput={validate} onChange={changedData} value={isValidated.tareWt} required />              
            </div>

            <div>
              <label className='label'>Volume Factor</label>
              <input style={{width: 75}} className='input' type="number" min={0} step={0.0001} pattern="\d{1,}(\.\d{1,})?" name='volumeFactor' onInput={validate} onChange={changedData} value={isValidated.volumeFactor} required />              
            </div>

            {moisture}
          
          </> : null
      
        }

        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          {props.planMode !== undefined ? <div><CircleButton iconName='add_location' onClick={openLocate} /></div> : <small>Locate before input.</small>}

          {isValidated.north !== null && isValidated.north !== '' ? <p>N: {isValidated.north}</p> : null}
          {isValidated.east !== null && isValidated.east !== '' ? <p>E: {isValidated.east}</p> : null}

        </div>

        <div>
          <label className='label'>Elev/ Depth (ft)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,}(\.\d{1})?" name='elevD' onInput={validate} onChange={changedData} value={isValidated.elevD} required />
        </div>

        {isValidated.pre !== 'AC' ?

          <div>
            <label className='label'>Moisture Content (%)</label>
            <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1})?" name='moistCont' onInput={validate} onChange={changedData} value={isValidated.moistCont} disabled={isValidated.testType ==='S' || isValidated.testType ==='D' ? true : false} required />
          </div> :
          <p>Asphalt doesnt need a Moisture</p>

        }

        <div>
          <label className='label'>Dry Density (pcf)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{2,3}(\.\d{1})?" name='dryDens' onInput={validate} onChange={changedData} value={isValidated.dryDens} disabled={isValidated.testType ==='S' || isValidated.testType ==='D' ? true : false} required />
        </div>

        <div>
          <label className='label'>Curve</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.curve} onClick={openCurve} />          
        </div>

        <div>
          <label className='label'>Req Comp (%)</label>
          <input style={{width: 75}} className='input' type="number" min={0} step={0.01} pattern="\d{1,2}(\.\d{1})?" name='reqComp' onInput={validate} onChange={changedData} value={isValidated.reqComp} required />          
        </div>

        <div>
          <label className='label'>Rel Comp (%)</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.relComp} disabled />          
        </div>

        <div>
          <label className='label'>Pass/Fail</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.passFail} disabled />          
        </div>

      </div>

    </div>
  )

  let content = (    
    <>
        {props.isModal.add || props.isModal.edit ? 
          <Modal 
            add={props.isModal.add || isValidated.reTestActive ? addData : (props.isModal.edit && isValidated.removed && isValidated.showRemoved) ? removeTest : props.isModal.edit ? editData : null} 
            nuke={openNuke}
            reTest={isValidated.reTest ? reTest : null} 
            delete={props.isModal.edit ? deleteData : null} 
            content={modalContent} 
            closeModal={close} 
            isValidated={isValidated} 
            isModal={isModal}
            zIndex={props.zIndex} 
          /> : null
        }
        {isModal.nuke ? <Modal content={<Nukes user={props.user} filter={props.filter} updateParent={fetchNuke} updateNuke={updateNuke} myNuke={myNuke} closeModal={closeNuke} />} closeModal={closeNuke} zIndex={props.zIndex} /> : null}
        {isModal.curve ? <Curve user={props.user} filter={props.filter} selectCurve={selectCurve} closeModal={closeCurve} zIndex={props.zIndex} /> : null}
        {isModal.prefix ? <Prefix user={props.user} filter={props.filter} selectPrefix={selectPrefix} clearPrefix={clearPrefix} closeModal={closePrefix} zIndex={props.zIndex} /> : null}
        {isModal.locate ? <Plans user={props.user} filter={props.filter} component={props.component} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} zIndex={props.zIndex} /> : null}
        
    </>     
    
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default TestInput
