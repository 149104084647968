export const breakResultCyl = (data) => {

  // per ASTM C39, this handles cylinders (concrete, grout?)
  // L/D factor: y = -0.16x2 + 0.584x + 0.447
  // determined from ASTM C39 and using excel to obtain the formula for y ( factor)

  //console.log(`data: ${JSON.stringify(data)}`)

  let strength1 = ''
  let strength2 = ''
  let strength3 = ''
  let strength4 = ''
  let strength5 = ''
  let strength6 = ''
  let percent1 = ''
  let percent2 = ''
  let percent3 = ''
  let percent4 = ''
  let percent5 = ''
  let percent6 = ''

  let specimenType = data.specimenType.toLowerCase()

  if (data !== null) {

    if (data.diameter !== '' && specimenType !== '') {

      let diameter = Number(data.diameter)

      let factor1 = data.diameter !== '' && data.length1 !== '' ? Number(data.length1) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length1) / diameter) ** 2 + 0.584 * (Number(data.length1) / diameter) + 0.447 : 0
      let factor2 = data.diameter !== '' && data.length2 !== '' ? Number(data.length2) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length2) / diameter) ** 2 + 0.584 * (Number(data.length2) / diameter) + 0.447 : 0
      let factor3 = data.diameter !== '' && data.length3 !== '' ? Number(data.length3) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length3) / diameter) ** 2 + 0.584 * (Number(data.length3) / diameter) + 0.447 : 0
      let factor4 = data.diameter !== '' && data.length4 !== '' ? Number(data.length4) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length4) / diameter) ** 2 + 0.584 * (Number(data.length4) / diameter) + 0.447 : 0
      let factor5 = data.diameter !== '' && data.length5 !== '' ? Number(data.length5) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length5) / diameter) ** 2 + 0.584 * (Number(data.length5) / diameter) + 0.447 : 0
      let factor6 = data.diameter !== '' && data.length6 !== '' ? Number(data.length6) / diameter >= 1.75 ? 1 : -0.16 * (Number(data.length6) / diameter) ** 2 + 0.584 * (Number(data.length6) / diameter) + 0.447 : 0


      let area = specimenType === 'cylinder' ? Math.PI * (diameter / 2) ** 2 : specimenType === 'cube' ? diameter ** 2 : specimenType === 'prism' ? diameter : 0

      strength1 = data.maxLoad1 === '' ? '' : Math.round(Number(data.maxLoad1) / area * factor1)
      strength2 = data.maxLoad2 === '' ? '' : Math.round(Number(data.maxLoad2) / area * factor2)
      strength3 = data.maxLoad3 === '' ? '' : Math.round(Number(data.maxLoad3) / area * factor3)
      strength4 = data.maxLoad4 === '' ? '' : Math.round(Number(data.maxLoad4) / area * factor4)
      strength5 = data.maxLoad5 === '' ? '' : Math.round(Number(data.maxLoad5) / area * factor5)
      strength6 = data.maxLoad6 === '' ? '' : Math.round(Number(data.maxLoad6) / area * factor6)

      if (data.designStrength !== '') {

        let designStrength = Number(data.designStrength)

        percent1 = strength1 !== '' ? Math.round(strength1 / designStrength * 100) : ''
        percent2 = strength2 !== '' ? Math.round(strength2 / designStrength * 100) : ''
        percent3 = strength3 !== '' ? Math.round(strength3 / designStrength * 100) : ''
        percent4 = strength4 !== '' ? Math.round(strength4 / designStrength * 100) : ''
        percent5 = strength5 !== '' ? Math.round(strength5 / designStrength * 100) : ''
        percent6 = strength6 !== '' ? Math.round(strength6 / designStrength * 100) : ''

      }

    }

  }

  let result = {
    strength1: strength1,
    strength2: strength2,
    strength3: strength3,
    strength4: strength4,
    strength5: strength5,
    strength6: strength6,
    percent1: percent1,
    percent2: percent2,
    percent3: percent3,
    percent4: percent4,
    percent5: percent5,
    percent6: percent6
  }

  //console.log(`result: ${JSON.stringify(result)}`)

  return result

}

export const breakResultOther = (data) => alert('developing')
