import React, { useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import Assign from 'components/utils/Assign'

import TestInput from 'components/input/TestInput'

import { formatDateTime, formatDateYMD, catchError, filterData } from 'scripts/common'

const Warning = (props) => {

  const [fetchedIssues, setFetchedIssues] = useState([])
  const [fetchedDailies, setFetchedDailies] = useState([])
  const [fetchedTests, setFetchedTests] = useState([])
  const [fetchedMaterial, setFetchedMaterial] = useState([])
  const [fetchedLab, setFetchedLab] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    loading: true,
    issues: false,
    material: false,
    dailies: false,
    tests: false,
    lab: false
  })

  let content = '' // for modal
  let header = '' // for modal
  let body = '' // for modal

  const fetchData = () => {

    fetch('/api/selectWarningIssues', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username,
        component: props.component
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedIssues(result)

        //setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectWarningDailies', JSON.stringify(error), props.user.username, props.user.device)
    )

    fetch('/api/selectWarningDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username,
        component: props.component
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedDailies(result)

        //setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectWarningDailies', JSON.stringify(error), props.user.username, props.user.device)
    )

    fetch('/api/selectWarningTests', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username,
        component: props.component
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedTests(result)

        //setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectWarningTests', JSON.stringify(error), props.user.username, props.user.device)
    )

    fetch('/api/selectWarningMaterial', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username,
        component: props.component
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedMaterial(result)

        //setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectWarningMaterial', JSON.stringify(error), props.user.username, props.user.device)
    )

    if (props.user.assign > 1) {

      fetch('/api/assignFetchedJobs', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: '', phase: '', status: 'unassigned'})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedLab(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const underDevelopment = () => alert(`Under development. For now, go to the section to edit.`)

  let bodyIssues = fetchedIssues.map((data, i) => {

    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''

    if (filterData(data, searchValue)) {

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={underDevelopment}>
          <td style={{display: 'none'}}>{i}</td>
          {props.component === 'Home' ? null : <td>{data.jobnumber} {gd}</td>}
          <td>{formatDateYMD(data.issueDate)}</td>
          <td>{data.entryby}</td>          
          <td className='clipText'>{data.description}</td>
          <td>{data.resolved === 1 ? 'Yes' : 'No'}</td>
        </tr>
      )

    }

  })

  let bodyMaterial = fetchedMaterial.map((data, i) => {

    if (filterData(data, searchValue)) {

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={underDevelopment}>
          <td style={{display: 'none'}}>{i}</td>
          {props.component === 'Home' ? null : <td>{data.jobNumber}</td>}
          <td>{formatDateYMD(data.castDate)}</td>
          <td>{data.entryby}</td>
          <td>{data.fieldMarking}</td>
          <td className='wrapText'>{data.notes}</td>
          <td>{data.materialType}</td>
        </tr>
      )

    }

  })

  let bodyDailies = fetchedDailies.map((data, i) => {

    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''

    if (filterData(data, searchValue)) {

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={underDevelopment}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{data.jobnumber} {gd}</td>
          <td>{formatDateYMD(data.dailiesdate)}</td>
          <td>{data.entryby}</td>
          <td>{data.dailiestype}</td>
          <td className='clipText'>{data.description}</td>
        </tr>
      )

    }

  })

  let bodyTests = fetchedTests.map((data, i) => {

    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let pre = data.pre === null ? '' : data.pre
    let number = data.testno === null ? '' : data.testno
    let suf = data.suf === null ? '' : data.suf
    let testNo = `${pre} ${number} ${suf}`
    let dryDens = data.drydens === null ? '' : data.drydens
    let moistCont = data.moistcont === null ? '' : data.moistcont
    let md = `${dryDens} @ ${moistCont}`
    let optD = data.optd === null ? '' : data.optd
    let optM = data.optm === null ? '' : data.optm
    let curve = data.curve === null ? '' : data.curve
    let max = `${curve} - ${optD} @ ${optM}`
    let reqComp = data.reqcomp === null ? '' : data.reqcomp
    let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''

    if (filterData(data, searchValue)) {

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={underDevelopment}>
          <td style={{display: 'none'}}>{i}</td>
          {props.component === 'Home' ? null : <td>{data.jobnumber} {gd}</td>}
          <td>{formatDateYMD(data.testdate)}</td>
          <td>{entryBy}</td>
          <td>{testNo.trim()}</td>
          <td>{md.trim()}</td>
          <td>{max.trim()}</td>
          <td>{Math.round((data.drydens/data.optd)*100)}</td>
          <td>{reqComp}</td>
        </tr>
      )

    }

  })

  const openIssues = () => setIsModal(prevState => ({...prevState, issues: true}))

  const openMaterial = () => setIsModal(prevState => ({...prevState, material: true}))

  const openDailies = () => setIsModal(prevState => ({...prevState, dailies: true}))

  const openTests = () => setIsModal(prevState => ({...prevState, tests: true}))

  const openLab = () => setIsModal(prevState => ({...prevState, lab: true}))

  const closeView = () => setIsModal(prevState => ({...prevState, issues: false, material: false, dailies: false, tests: false, lab: false}))

  let modalContent = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{display: 'inline-block', marginTop: 10}}>

        <table>

          <thead>
            {isModal.issues ?
              <tr>
                {props.component === 'Home' ? null : <th>JN</th>}
                <th>Date</th>
                <th>By</th>
                <th>Descrip.</th>
                <th>Resolved?</th>
              </tr> :
            isModal.material ?
              <tr>
                {props.component === 'Home' ? null : <th>JN</th>}
                <th>Cast</th>
                <th>By</th>
                <th>Set</th>
                <th>Location</th>
                <th>Material</th>
              </tr> :
              isModal.dailies ?
              <tr>
                {props.component === 'Home' ? null : <th>JN</th>}
                <th>Date</th>
                <th>By</th>
                <th>Type</th>
                <th>Descrip.</th>
              </tr> :
              isModal.tests ?
              <tr>
                {props.component === 'Home' ? null : <th>JN</th>}
                <th>Date</th>
                <th>By</th>
                <th>Test</th>
                <th>D/M</th>
                <th>Max</th>
                <th>Rel.</th>
                <th>Req.</th>
              </tr> : null
            }
          </thead>

          <tbody>
          {
            isModal.issues ?
            bodyIssues :
            isModal.material ?
            bodyMaterial :
            isModal.dailies ?
            bodyDailies :
            isModal.tests ?
            bodyTests : null
          }
          </tbody>

        </table>
      </div>

    </div>
  )

  let styleCard = {
    flex: '1 1 120px',
    margin: 5,
    border: '1px solid gray',
    borderRadius: 5,
    height: 75,
    textAlign: 'center',
    cursor: 'pointer',
    maxWidth: '200px'
  }

  //let backGroundColor = `repeating-linear-gradient(-45deg, orange, orange 5px, white 5px, white 20px)`

  let styleIssue1 = {...styleCard, background: 'tomato', color: 'white'}
  let styleIssue2 = {...styleCard, background: 'dodgerblue', color: 'white'}
  let styleIssue3 = {...styleCard, background: 'orange', color: 'white'}
  let styleIssue4 = {...styleCard, background: 'violet', color: 'white'}
  let styleIssue5 = {...styleCard, background: 'mediumseagreen', color: 'white'}

  // {isModal.add || isModal.edit || isModal.addAction || isModal.editAction ?
  //   <IssueInput                
  //     close={closeModal}
  //     closeAction={closeAction}
  //     fetchedData={fetchedData}
  //     fetchedAction={fetchedAction}
  //     updateFetchedData={updateFetchedData}
  //     updateFetchedAction={updateFetchedAction}
  //     openActionAdd={openActionAdd}           
  //     isModal={isModal}
  //     user={props.user}
  //     filter={props.filter}
  //     planMode={props.planMode}
  //     component={props.component}
  //   /> : null
  // }
  // {isModal.add || isModal.edit ?
  //   <TestInput                
  //     close={closeModal} 
  //     fetchedData={fetchedData}
  //     updateFetchedData={updateFetchedData}
  //     selectNuke={props.selectNuke}
  //     isModal={isModal}
  //     user={props.user}
  //     filter={props.filter}
  //     planMode={props.planMode}
  //     component={props.component}
  //   /> : null
  // }

  return (

    <>
      

      {isModal.issues || isModal.material || isModal.dailies || isModal.tests ? <Modal closeModal={closeView} content={modalContent} /> : null}

      {isModal.lab ? <Modal closeModal={closeView} content={<Assign filter={props.filter} user={props.user} />} maxWidth='100%' /> : null}      

      <div style={{display: 'flex', flexWrap: 'wrap', margin: 10, maxWidth: 400}}>

        {fetchedDailies.length > 0 ?
          <div style={styleIssue4} onClick={openDailies}>
            <h3>Incomplete Dailies!</h3>
          </div> : null
        }

        {props.component === 'Home' && props.filter.jobNumber === '' ? null :

          <>

            {fetchedIssues.length > 0 ?
              <div style={styleIssue1} onClick={openIssues}>
                <h3>Issues Found!</h3>
              </div> : null
            }

            {fetchedTests.length > 0 ?
              <div style={styleIssue2} onClick={openTests}>
                <h3>ReTests Needed!</h3>
              </div> : null
            }

            {fetchedMaterial.length > 0 ?
              <div style={styleIssue3} onClick={openMaterial}>
                <h3>Material Pickup Needed!</h3>
              </div> : null
            }

          </>

        }

        {props.user.assign > 1 ?

          <>            

            {fetchedLab.length > 0 ?
              <div style={styleIssue5} onClick={openLab}>
                <h3>Assign Lab!</h3>
              </div> : null
            }

          </> : null

        }

      </div>


    </>

  )

}

export default Warning
