import React from 'react'

let style = {
  position: 'fixed',
  right: 0,
  bottom: 0,
  background: 'dodgerblue',
  border: 'none',
  borderRadius: '50%',
  padding: 15,
  height: 54,
  width: 54,
  margin: 10,
  color: '#ffffff',
  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
  outline: 'none',
  zIndex: 9
}
//<button className='show_outstanding btn_circle btn_circle_card' name='jnfield'><i className='material-icons'>warning</i></button>
const AddButton = (props) => <button style={style} onClick={props.onClick}><i className='material-symbols-outlined'>add</i></button>

export default AddButton
