import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, filterData } from 'scripts/common'

import { selectData } from 'scripts/offline'

const Prefix = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('TestPrefixes').then(res => setFetchedData(res))

    } else {

      fetch('/api/selectMenuPrefixes', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gradeId: props.filter.gradeId
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setFetchedData(result)
        },
        (error) => {
          console.log('Error: selectMenuPrefixes --> ' + error)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let prefix = data.prefix === null ? '' : data.prefix
    let description = data.description === null ? '' : data.description

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={props.selectPrefix}>
          <td>{prefix}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let content = (
    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{textAlign: 'center'}}>
        <div style={{
          border: '2px solid tomato',
          borderRadius: 5,
          color: 'tomato',
          cursor: 'pointer',
          padding: 5,
          margin: 10
        }} onClick={props.clearPrefix}>Clear Prefix
        </div>
      </div>

      <div style={{margin: 10, flex: '1', overflow: 'auto', display: 'flex', justifyContent: 'center'}}>

        <table>

          <thead>
            <tr>
              <th>Prefix</th>
              <th>Descrip</th>
            </tr>
          </thead>

          <tbody>
            {listOfData}
          </tbody>

        </table>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} zIndex={props.zIndex} />
}

export default Prefix
