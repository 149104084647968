import React, { useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import Status from 'components/main/Status'
import Nukes from 'components/main/Nukes'
import Mileage from 'components/main/Mileage'
import SiteLocation from 'components/utils/SiteLocation'
import MyDailies from 'components/main/MyDailies'
import MyLab from 'components/main/MyLab'
//import Stats from 'components/main/Stats'
import Warning from 'components/utils/Warning'

import { formatDateTime, formatTime, getLocation, addActivity } from 'scripts/common'

import { selectData } from 'scripts/offline'

const Home = (props) => {

  const [myStatus, setMyStatus] = useState([])
  const [myNuke, setMyNuke] = useState([])
  const [myMileage, setMyMileage] = useState([])
  // const [nukes, setNukes] = useState([])
  const [isModal, setIsModal] = useState({
    status: false,
    nuke: false,
    mileage: false,
    myDailies: false,
    myLab: false,
    siteLocation: false
  })

  const fetchStatus = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('MyStatus').then(res => setMyStatus(res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime))))

    } else {

      fetch('/api/myStatus', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
          setMyStatus(result)
        },
        (error) => {
          console.log('Error: myStatus --> ' + error)
        }
      )

    }
  }

  const fetchNuke = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      // my Nuke

      selectData('MyNuke').then(res => {
        if (res.length > 0) {          

          if (res[0].checkIn !== '' && res[0].checkIn !== null) {
            //console.log('here2')
            props.selectNuke('')
          } else {

            //console.log('here3')
            props.selectNuke(res[0].nukeId)
          }

        } else {
          props.selectNuke('')
        }

        setMyNuke(res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime)))
      })

    } else {

      fetch('/api/myNuke', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

         // updates userDetails so nukeId can be used in Tests
         if (result.length > 0) {

           if (result[0].checkIn !== '' && result[0].checkIn !== null) {
             props.selectNuke('')
           } else {
             props.selectNuke(result[0].nukeId)
           }

         } else {
           props.selectNuke('')
         }

          setMyNuke(result)
        },
        (error) => {
          console.log('Error: myNuke --> ' + error)
        }
      )

    }

  }

  const fetchMileage = () => {
    fetch('/api/myMileage', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        by: props.user.username,
        time: formatDateTime(new Date())
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setMyMileage(result)
      },
      (error) => {
        console.log('Error: myMileage --> ' + error)
      }
    )
  }

  const updateStatus = (status) => setMyStatus(status)

  const updateNuke = (result) => {

    // updates userDetails so nukeId can be used in Tests

    if (result[0].checkIn === '') {

      props.selectNuke('')
      setMyNuke(result)

    } else {

      props.selectNuke(result[0].nukeId)
      setMyNuke([{checkIn: true, nukeId: myNuke[0].nukeId, entrytime: ''}])

    }

  }

  const fetchData = () => {

    fetchStatus()
    fetchNuke()
    fetchMileage()

  }

  useEffect(() => {
    //let data = https://maps.googleapis.com/maps/api/distancematrix/json?destinations=New%20York%20City%2C%20NY&origins=Washington%2C%20DC%7CBoston&units=imperial&key=YOUR_API_KEY
    //console.log(data)
    fetchData()
  }, [])

  const directions = () => {
    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'directions', '', props.user.username)

    props.filter.jobNumber !== '' && props.filter.jobNumber !== null ?
    window.open(`http://maps.google.com/?q=${props.filter.lat}, ${props.filter.lng}`) :
    alert('Please select a Job Number first')
  }

  const openStatus = () => setIsModal(prevState => ({...prevState, status: true}))

  const closeStatus = () => setIsModal(prevState => ({...prevState, status: false}))

  const openNuke = () => setIsModal(prevState => ({...prevState, nuke: true}))

  const closeNuke = () => setIsModal(prevState => ({...prevState, nuke: false}))

  const openSiteLocation = () => {

    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'site location', props.user.username)
    
    setIsModal(prevState => ({...prevState, siteLocation: true}))

  }

  const closeSiteLocation = () => setIsModal(prevState => ({...prevState, siteLocation: false}))

  const openMyDailies = () => {

    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'my dailies', props.user.username)
    
    setIsModal(prevState => ({...prevState, myDailies: true}))

  }

  const openMyLab = () => {

    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'my lab', props.user.username)
    
    setIsModal(prevState => ({...prevState, myLab: true}))

  }

  const closeMyDailies = () => setIsModal(prevState => ({...prevState, myDailies: false}))

  const closeMyLab = () => setIsModal(prevState => ({...prevState, myLab: false}))

  const openMileage = () => {

    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'mileage', props.user.username)

    setIsModal(prevState => ({...prevState, mileage: true}))

  }

  const closeMileage = () => setIsModal(prevState => ({...prevState, mileage: false}))

  let distance = myMileage.length > 0 ? Math.round(10 * myMileage[0].sumDistance / 1609.34) / 10 : ''
  let duration = myMileage.length > 0 ? Math.round(10 * myMileage[0].sumDuration / 3600) / 10 : ''

  let styleCard = {
    flex: '1 1 120px',
    margin: 5,
    border: '1px solid gray',
    borderRadius: 5,
    height: 100,
    textAlign: 'center',
    cursor: 'pointer',
    maxWidth: '300px'
  }

  let backGroundColor = `repeating-linear-gradient(-45deg, orange, orange 5px, white 5px, white 20px)`

  let styleSite = {...styleCard, background: 'rgba(144, 238, 144, .5)'}
  let styleIssue = {...styleCard, background: backGroundColor}
  let styleStatus = {...styleCard, background: myStatus.length > 0 ?  'none' : backGroundColor}
  let styleNuke = {...styleCard, background: myNuke.length > 0 ? myNuke[0].checkIn !== '' && myNuke[0].checkIn !== null ? backGroundColor : 'none' : backGroundColor}
  let styleMileage = {...styleCard, background: distance > 0 ?  'none' : backGroundColor}

  return (
    <>
      {props.user.userlevel === 'guest' ?
        <>
          <div>
            <p>Welcome {props.user.fullname}!</p>
            <p>As a guest, you may view and access Dailies and Plans.</p>
            <p>You may also sign and download Dailies.</p>
            <p>Feel free to explore!</p>
          </div>
        </> :
        <>
          {isModal.status ? <Modal content={<Status user={props.user} filter={props.filter} updateParent={fetchStatus} updateStatus={updateStatus} closeModal={closeStatus} />} closeModal={closeStatus} /> : null}
          {isModal.nuke ? <Modal content={<Nukes user={props.user} filter={props.filter} updateParent={fetchNuke} updateNuke={updateNuke} myNuke={myNuke} closeModal={closeNuke} />} closeModal={closeNuke} /> : null}
          {isModal.mileage ? <Modal content={<Mileage user={props.user} filter={props.filter} updateParent={fetchMileage} closeModal={closeMileage} />} closeModal={closeMileage} /> : null}
          {isModal.siteLocation ? <SiteLocation closeModal={closeSiteLocation} latlng={props.filter} /> : null}
          {isModal.myDailies ? <Modal content={<MyDailies user={props.user} filter={props.filter} component={props.component} />} closeModal={closeMyDailies} /> : null}
          {isModal.myLab ? <Modal content={<MyLab user={props.user} filter={props.filter} component={props.component} />} closeModal={closeMyLab} /> : null}

          {props.user.userlevel !== 'guest' ? <Warning filter={props.filter} user={props.user} component={props.component} /> : null}

          <div style={{display: 'flex', flexWrap: 'wrap', margin: 10}}>

            <div style={styleStatus} onClick={openStatus}>
              <h3>Status</h3>
              <div>{myStatus.length > 0 ? `${myStatus[0].status} @ ${formatTime(myStatus[0].entrytime, true)}` : 'No status today'}</div>
            </div>

            <div style={styleNuke} onClick={openNuke}>
              <h3>Nuke</h3>
              <div>
                {
                  myNuke.length > 0 ?
                  `${myNuke[0].checkIn !== '' && myNuke[0].checkIn !== null ? `Returned Nuke ${myNuke[0].nukeId}` : `Nuke ${myNuke[0].nukeId} @ ${formatTime(myNuke[0].entrytime)}`}` :
                  'No nuke today'
                }
              </div>
            </div>

            <div style={styleMileage} onClick={openMileage}>
              <h3>Mileage</h3>
              <div>{distance > 0 ? `${distance} miles (about ${duration} hr${duration === 1 ? '' : 's'}) Google` : 'No miles today'}</div>
            </div>

            <div style={styleSite} onClick={openSiteLocation}>
              <h3>Site Location</h3>
              <Icon name='share_location' />
            </div>

            <div style={styleCard} onClick={directions}>
              <h3>Directions</h3>
              <Icon name='directions' />
            </div>

            <div style={styleCard} onClick={openMyDailies}>
              <h3>My Dailies</h3>
              <Icon name='assignment' />
            </div>

            <div style={styleCard} onClick={openMyLab}>
              <h3>My Lab</h3>              
              <Icon name='science' outline={true} />              
            </div>

          </div>
        </>
      }
    </>
  )

  // return (
  //   <div className='testing1'>
  //     <div style={{backgroundColor:'coral'}}>A</div>
  //     <div style={{backgroundColor:'blue'}}>B</div>
  //     <div style={{backgroundColor:'red'}}>C</div>
  //     <div style={{backgroundColor:'orange'}}>D</div>
  //     <div style={{backgroundColor:'green'}}>E</div>
  //     <div style={{backgroundColor:'purple'}}>F</div>
  //   </div>
  // )
}


// <div style={styleIssue} onClick={null}>
//   <h3>Issues</h3>
//   <Stats user={props.user} filter={props.filter} />
// </div>


// <div>
//   {isModal.status ?
//
//
//     <div style={{display: 'flex', overflowX: 'auto'}}>
//
//       <div style={{flex: '1 1 auto'}}>
//         <Status user={props.user} filter={props.filter} />
//       </div>
//
//     </div> : null
//
//   }
// </div>



// <div style={{flex: '1 1 auto'}}>
//   <WhatsNew user={props.user} />
// </div>

// <div style={{flex: '1 1 auto'}}>
//   <Following user={props.user} />
// </div>

export default Home
