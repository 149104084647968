import React from 'react'
import Icon from 'components/utils/Icon'

import { formatDateTime } from 'scripts/common'

const Warning_Old = (props) => {

  let data = props.data

  let background = `repeating-linear-gradient(-45deg, orange, orange 5px, white 5px, white 20px)`

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid gray', color: 'tomato'}}>
      <Icon name='error' color='tomato' />
      <small><b>{props.content}</b></small>
    </div>
  )
}

export default Warning_Old
