import React, { useState, useEffect, useRef} from 'react'

import { formatDateYMD, formatDateTime, filterData } from 'scripts/common'

const Stats = (props) => {

  //const [fetchedData, setFetchedData] = useState([])

  const [fetchedUsers, setFetchedUsers] = useState([])
  const [fetchedStatuses, setFetchedStatuses] = useState([])
  const [fetchedNukes, setFetchedNukes] = useState([])

  //const [fetchedTests, setFetchedTests] = useState([])
  const [fetchedTestTypes, setFetchedTestTypes] = useState([])

  const [fetchedLab, setFetchedLab] = useState([])

  const [fetchedDailies, setFetchedDailies] = useState([])
  const [fetchedDailyTesting, setFetchedDailyTesting] = useState([])
  const [fetchedDistinctDailyDays, setFetchedDistinctDailyDays] = useState([])

  const [fetchedDrawings, setFetchedDrawings] = useState([])

  const [fetchedDevices, setFetchedDevices] = useState([])

  const fetchData = () => {

    fetch('/api/selectStatsStatuses', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        display: props.display
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedStatuses(result)
      },
      (error) => {
        console.log('Error: selectStatsStatuses --> ' + error)
      }
    )

    fetch('/api/selectStatsNukes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedNukes(result)
      },
      (error) => {
        console.log('Error: selectStatsNukes --> ' + error)
      }
    )

    fetch('/api/selectStatsTestTypes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedTestTypes(result)
      },
      (error) => {
        console.log('Error: selectStatsTestTypes --> ' + error)
      }
    )

    fetch('/api/selectStatsLab', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedLab(result)
      },
      (error) => {
        console.log('Error: selectStatsLab --> ' + error)
      }
    )

    fetch('/api/selectStatsDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDailies(result)
      },
      (error) => {
        console.log('Error: selectStatsDailies --> ' + error)
      }
    )

    fetch('/api/selectStatsDistinctDailyDays', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDistinctDailyDays(result)
      },
      (error) => {
        console.log('Error: selectStatsDistinctDailyDays --> ' + error)
      }
    )

    fetch('/api/selectStatsDailyTesting', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        console.log('result: ' + JSON.stringify(result))
        setFetchedDailyTesting(result)
      },
      (error) => {
        console.log('Error: selectStatsDailies --> ' + error)
      }
    )

    fetch('/api/selectAnalyzeDevices', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDevices(result)
      },
      (error) => {
        console.log('Error: selectAnalyzeDevices --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  let styleProgressBar = {
    cursor: 'pointer',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginRight: 5,
    overflow: 'hidden'
  }

  let titleDateDiff = 'Entry time VS selected Date (days). 0 = good, >0 = not good'

  // ========= STATUSES =========

  let listOfStatuses = fetchedStatuses.map(data => {
    let total = data.countStart + data.countEnd
    let dailies = fetchedDistinctDailyDays.find(res => res.entryby === data.entryby)
    let jobs = fetchedDailies.find(res => res.entryby === data.entryby)    

    let ratio = data.countStart > data.countEnd ? data.countEnd / data.countStart : data.countStart / data.countEnd
    ratio = Math.round(10*ratio)/10

    let ratioStart = dailies === undefined ? 'no dailies' : Math.round(data.countStart / dailies.countDays * 10) / 10
    let ratioEnd = dailies === undefined ? 'no dailies' : Math.round(data.countEnd / dailies.countDays * 10) / 10
    let ratioArrive = jobs === undefined ? 'no dailies' : Math.round(data.countArrive / (jobs.countIncomplete + jobs.countComplete + jobs.countSigned) * 10) / 10
    let ratioDepart = jobs === undefined ? 'no dailies' : Math.round(data.countDepart / (jobs.countIncomplete + jobs.countComplete + jobs.countSigned) * 10) / 10

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{total}</td>
          <td style={{color: 'white', backgroundColor: ratio < 0.7 ? 'tomato' : ratio < 0.9 ? 'orange' : 'dodgerblue'}}>{ratio}</td>
          <td style={{color: 'white', backgroundColor: ratioStart < 0.7 ? 'tomato' : ratioStart < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioStart}</td>
          <td style={{color: 'white', backgroundColor: ratioEnd < 0.7 ? 'tomato' : ratioEnd < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioEnd}</td>
          <td style={{color: 'white', backgroundColor: ratioArrive < 0.7 ? 'tomato' : ratioArrive < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioArrive}</td>
          <td style={{color: 'white', backgroundColor: ratioDepart < 0.7 ? 'tomato' : ratioDepart < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioDepart}</td>
        </tr>
      )

    }
  })

  // ========= NUKES =========

  let listOfNukes = fetchedNukes.map(data => {
    let total = data.countCheckOut
    let testing = fetchedDailyTesting.find(res => res.entryby === data.entryby)
    let ratioOut = testing === undefined ? 'no days' : Math.round(data.countCheckOut / testing.countDays * 10) / 10
    let ratioIn = testing === undefined ? 'no days' : Math.round(data.countCheckIn / testing.countDays * 10) / 10

    let ratio = data.countCheckIn / data.countCheckOut
    ratio = Math.round(10*ratio)/10

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{total}</td>
          <td>{testing === undefined ? 'no days' : testing.countDays}</td>
          <td style={{color: 'white', backgroundColor: ratio < 0.7 ? 'tomato' : ratio < 0.9 ? 'orange' : 'dodgerblue'}}>{ratio}</td>          
          <td style={{color: 'white', backgroundColor: ratioOut < 0.7 ? 'tomato' : ratioOut < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioOut}</td>
          <td style={{color: 'white', backgroundColor: ratioIn < 0.7 ? 'tomato' : ratioIn < 0.9 ? 'orange' : 'dodgerblue'}}>{ratioIn}</td>
        </tr>
      )

    }
  })

  // ========= HANDLE TESTS =========  

  let styleS, styleN, styleD, avgTestPerHour

  let listOfTests = fetchedTestTypes.map(data => {
    let total = data.countS + data.countN + data.countD
    let sPer = Math.round(10*data.countS/total*100)/10
    //let nPer = Math.round(10*data.countN/total*100)/10
    //let dPer = Math.round(10*data.countD/total*100)/10
    let testing = fetchedDailyTesting.find(res => res.entryby === data.entryby)    

    //console.log(`testing: ${JSON.stringify(sumHours)}`)
    let avgTestPerHour = testing === undefined ? 'no tests' : Math.round(total / testing.sumHours * 10) / 10
    //console.log(`avgTestPerHour: ${avgTestPerHour}`)

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      let dateDiff = Math.round(data.dateDiff * 100) / 100
    
      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{total}</td>
          <td style={{color: 'white', backgroundColor: dateDiff > 0.9 ? 'tomato' : dateDiff > 0.7 ? 'orange' : 'dodgerblue'}}>{dateDiff}</td>
          <td>{testing === undefined ? 'no hours' : testing.sumHours}</td>
          <td style={{color: 'white', backgroundColor: avgTestPerHour < 0.7 ? 'tomato' : avgTestPerHour < 0.9 ? 'orange' : 'dodgerblue'}}>{avgTestPerHour}</td>
          <td>{sPer}</td>
        </tr>
      )

    }
  })

  // ========= HANDLE Lab =========    

  let listOfLab = fetchedLab.map(data => {
    //let total = fetchedLab.length    

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      let dateDiff = Math.round(data.dateDiff * 100) / 100
    
      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{data.countSamples}</td>
          <td style={{color: 'white', backgroundColor: dateDiff > 0.9 ? 'tomato' : dateDiff > 0.7 ? 'orange' : 'dodgerblue'}}>{dateDiff}</td>
        </tr>
      )

    }
  })

  // ========= HANDLE DAILIES =========

  let listOfDailies = fetchedDailies.map(data => {
    let total = data.countIncomplete + data.countComplete + data.countSigned
    let incPer = Math.round(10*data.countIncomplete/total*100)/10
    let comPer = Math.round(10*data.countComplete/total*100)/10
    let signPer = Math.round(10*data.countSigned/total*100)/10

    let filter = filterData(data, props.searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {

      let dateDiff = Math.round(data.dateDiff * 100) / 100

      return (
        <tr onClick={null} onMouseOver={null}>              
          <td>{data.entryby}</td>
          <td>{total}</td>
          <td style={{color: 'white', backgroundColor: dateDiff > 0.9 ? 'tomato' : dateDiff > 0.7 ? 'orange' : 'dodgerblue'}}>{dateDiff}</td>
          <td>{Math.round(10*data.sumHours/total)/10}</td>
          <td>{data.sumHours}</td>
          <td>{incPer}</td>
          <td>{signPer}</td>
        </tr>
      )

    }
  })


  // ========= HANDLE DEVICES =========

  let styleT, styleDe, styleM

  let statsDevices = fetchedDevices.map(data => {
    let total = data.countT + data.countD + data.countM
    let tPer = Math.round(10*data.countT/total*100)/10
    let dPer = Math.round(10*data.countD/total*100)/10
    let mPer = Math.round(10*data.countM/total*100)/10

    styleT = {...styleProgressBar, backgroundColor: 'dodgerblue', width: `${tPer}%`}
    styleDe = {...styleProgressBar, backgroundColor: 'orange', width: `${dPer}%`}
    styleM = {...styleProgressBar, backgroundColor: 'lightgray', width: `${mPer}%`}

    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '90%', height: 20, margin: 10}}>
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>{data.entryby} ({total})</div></div>
        {tPer > 0 ? <div title={`${data.countT} (${tPer}%)`} style={styleT}><small>{data.countT} ({tPer}%)</small></div> : null}
        {dPer > 0 ? <div title={`${data.countD} (${dPer}%)`} style={styleDe}><small>{data.countD} ({dPer}%)</small></div> : null}
        {mPer > 0 ? <div title={`${data.countM} (${mPer}%)`} style={styleM}><small>{data.countM} ({mPer}%)</small></div> : null}
      </div>
    )
  })

  return (
    <>

      <div>

        <h3>Statuses</h3>

        <table>

          <thead>
            <tr>
              <th>User</th>
              <th>Total</th>
              <th>Start vs End Ratio</th>
              <th>Work vs Start Ratio</th>
              <th>Work vs End Ratio</th>
              <th>Work vs Arrive Ratio</th>
              <th>Work vs Depart Ratio</th>            
            </tr>
          </thead>

          <tbody>
            {listOfStatuses}
          </tbody>

        </table>

      </div>      

      <div>

        <h3>Nukes</h3>

        <table>

          <thead>
            <tr>
              <th>User</th>
              <th>Total</th>
              <th>Testing Days</th>
              <th>Out vs In Ratio</th>              
              <th>Testing vs Out Ratio</th>
              <th>Testing vs In Ratio</th>              
            </tr>
          </thead>

          <tbody>
            {listOfNukes}
          </tbody>

        </table>

      </div>


      <div>

        <h3>Tests</h3>

        <table>

          <thead>
            <tr>
              <th>User</th>
              <th>Total</th>
              <th>Time to Enter (days)</th>
              <th>Testing Hrs</th>
              <th>Avg Test/hr</th>
              <th>Sand Cone (%)</th>              
            </tr>
          </thead>

          <tbody>
            {listOfTests}
          </tbody>

        </table>

      </div>

      <div>

        <h3>Dailies</h3>

        <table>

          <thead>
            <tr>
              <th>User</th>
              <th>Total</th>
              <th>Time to Enter (days)</th>
              <th>Avg Hrs/Day?</th>
              <th>Total Hrs</th>
              <th>Inc (%)</th>
              <th>Sign (%)</th>            
            </tr>
          </thead>

          <tbody>
            {listOfDailies}
          </tbody>

        </table>

      </div>     


      

      <div>

        <h3>Lab</h3>

        <table>

          <thead>
            <tr>
              <th>User</th>
              <th>Total</th>
              <th>Time to Enter (days)</th>        
            </tr>
          </thead>

          <tbody>
            {listOfLab}
          </tbody>

        </table>

      </div>

      
    </>
  )
}

export default Stats
