import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import { addActivity } from 'scripts/common'

import 'styles/navBar.css';

function NavBar(props) {

  const [isModal, setIsModal] = useState({
    profile: false,
    menu: false
  })
  const minimumScreenSize = 800
  const [screenSizeNormal, setScreenSizeNormal] = useState(false)

  let fullname = props.user.fullname
  let profileInitials = fullname.substring(0, 1) + fullname.substring(fullname.indexOf(' ') + 1, fullname.indexOf(' ') + 2)

  const notifyMe = async () => {
    
    const registration = await navigator.serviceWorker.getRegistration();
    
    if (!registration) {
          console.log(`
            No service worker has been registered yet.
            Push subscription on this client isn't possible until a service worker is registered.
            Push notification to this client isn't possible until a service worker is registered.
          `);
          //registrationStatus.textContent = 'No service worker has been registered yet.';
          //subscriptionStatus.textContent = "Push subscription on this client isn't possible until a service worker is registered.";
          //notificationStatus.textContent = "Push notification to this client isn't possible until a service worker is registered.";
          return;
        }
        console.log(`Service worker registered. Scope: ${registration.scope}`);
    
    const subscription = await registration.pushManager.getSubscription();
    
    
    // Service worker is registered and now we need to subscribe for push
    // or unregister the existing service worker.
    if (!subscription) {
      console.log(`
        Ready to subscribe this client to push.';
        Push notification to this client will be possible once subscribed.
      `);
      return;
    }
    // Service worker is registered and subscribed for push and now we need
    // to unregister service worker, unsubscribe to push, or send notifications.
    console.log(`
      Service worker subscribed to push. Endpoint: ${subscription.endpoint}
      Ready to send a push notification to this client!
    `);    
    

    fetch("/api/notify-me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        endpoint: subscription.endpoint,
        info: {
          title: fullname,
          icon: 'logo192.png',
          body: `How's it going?`
        }                
      }),
    })        
    .then((res) => {
      console.log("worked?");      

      
    })
    .catch((err) => {
      console.log(`err: ${err}`);
    });    

  }

  const notifyAll = async () => {

    const response = await fetch("/api/notify-all", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({        
        info: {
          title: fullname,
          icon: 'logo192.png',
          body: `How's it going?`
        }                
      }),
    });
    if (response.status === 409) {
      //window.document.getElementById("notification-status-message").textContent =
        console.log("There are no subscribed endpoints to send messages to, yet.")
    }

  }

  async function unsubscribeButtonHandler() {
    const registration = await navigator.serviceWorker.getRegistration();
    const subscription = await registration.pushManager.getSubscription();
    fetch("/api/remove-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ endpoint: subscription.endpoint }),
    });
    const unsubscribed = await subscription.unsubscribe();
    if (unsubscribed) {
      console.info("Successfully unsubscribed from push notifications.");
      // unsubscribeButton.disabled = true;
      // subscribeButton.disabled = false;
      // notifyMeButton.disabled = true;
    }
  }

  const resizeScreen = () => {

    if (window.innerWidth > minimumScreenSize && screenSizeNormal === null) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth <= minimumScreenSize && screenSizeNormal === null) {
      setScreenSizeNormal(false)
    } else if (window.innerWidth > minimumScreenSize && !screenSizeNormal) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth < minimumScreenSize && screenSizeNormal) {
      setScreenSizeNormal(false)
    }

  }

  // testing this out. make the navbar more consolidated

  //window.addEventListener("resize", resizeScreen)

  // useEffect(() => {
  //   //resizeScreen()
  //
  //   setScreenSizeNormal(props.user.device === 'mobile' || props.user.device === 'tablet' ? false : true)
  // }, [])

  //const openAppMenu = () => setIsAppMenu(isAppMenu ? false : true)

  const openProfile = () => {
    addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'profile', props.user.username)
    setIsModal(prevState => ({...prevState, profile: true}))
  }
  const openMenu = () => {
    console.log('hello')
    setIsModal(prevState => ({...prevState, menu: true}))
  }
  const closeProfile = () => setIsModal(prevState => ({...prevState, profile: false}))
  const closeMenu = () => setIsModal(prevState => ({...prevState, menu: false}))

  const selectComponent = (e) => {
    props.onClick(e.target)
    closeMenu()
  }

  const selectRow = (e) => {
    props.onClick(e.target.parentNode)
    closeMenu()
  }

  const styleNormal = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto'
  }

  const styleSmall = {
    backgroundColor: '#383838',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const styleAppMenu = {
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto',
    textAlign: 'right'
  }

  const titleStyle = {
    display: 'inline-block',
    width: 50,
    height: 'auto',
    objectFit: 'contain',
    marginRight: 10
  }

  // {props.user.drawing > 0 ? <tr className={props.component === 'Drawings' ? 'activeMenu' : 'nonActive2'} data-name='Drawings' onClick={selectRow}><td>Drawings</td></tr> : null}

  let menuContent = (
    <div style={{textAlign: 'center'}}>
      <div style={{display: 'inline-block'}}>
        <table>

          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr className={props.component === 'Home' ? 'activeMenu' : 'nonActive2'} data-name='Home' onClick={selectRow}><td>Home</td></tr>
            {props.user.info > 0 ? <tr className={props.component === 'Info' ? 'activeMenu' : 'nonActive2'} data-name='Info' onClick={selectRow}><td>Info</td></tr> : null}
            {props.user.issue > 0 ? <tr className={props.component === 'Issue' ? 'activeMenu' : 'nonActive2'} data-name='Issue' onClick={selectRow}><td>Issues</td></tr> : null}
            {props.user.test > 0 || props.user.userlevel === 'guest' ? <tr className={props.component === 'Tests' ? 'activeMenu' : 'nonActive2'} data-name='Tests' onClick={selectRow}><td>Tests</td></tr> : null}
            {props.user.lab > 0 ? <tr className={props.component === 'Lab' ? 'activeMenu' : 'nonActive2'} data-name='Lab' onClick={selectRow}><td>Lab</td></tr> : null}
            {props.user.presat > 0 ? <tr className={props.component === 'Presat' ? 'activeMenu' : 'nonActive2'} data-name='Presat' onClick={selectRow}><td>Presat</td></tr> : null}
            {props.user.plan > 0 || props.user.userlevel === 'guest' ? <tr className={props.component === 'Plans' ? 'activeMenu' : 'nonActive2'} data-name='Plans' onClick={selectRow}><td>Plans</td></tr> : null}
            {props.user.daily > 0 || props.user.userlevel === 'guest' ? <tr className={props.component === 'Dailies' ? 'activeMenu' : 'nonActive2'} data-name='Dailies' onClick={selectRow}><td>Dailies</td></tr> : null}
            {props.user.document > 0 ? <tr className={props.component === 'Documents' ? 'activeMenu' : 'nonActive2'} data-name='Documents' onClick={selectRow}><td>Docs</td></tr> : null}
            {props.user.concrete > 0 ? <tr className={props.component === 'Concrete' ? 'activeMenu' : 'nonActive2'} data-name='Concrete' onClick={selectRow}><td>Data Sheets</td></tr> : null}
            {props.user.schedule > 0 ? <tr className={props.component === 'Schedule' ? 'activeMenu' : 'nonActive2'} data-name='Schedule' onClick={selectRow}><td>Schedule</td></tr> : null}
            {props.user.manage > 0 ? <tr className={props.component === 'Manage' ? 'activeMenu' : 'nonActive2'} data-name='Manage' onClick={selectRow}><td>Manage</td></tr> : null}
          </tbody>

        </table>
      </div>
    </div>
  )

  // {props.user.drawing > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Drawings' ? 'active' : 'nonActive'} data-name='Drawings' onClick={selectComponent}>Drawings</div> : null}

  let styleProfile = {
    background: 'dodgerblue',
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }  

  // console.log(`
  //   name: ${fullname}
  //   index: ${fullname.indexOf(' ')}
  //   1: ${fullname.substring(0, 1)}
  //   2: ${fullname.substring(fullname.indexOf(' ') + 1, fullname.indexOf(' ') + 2)}
  // `)

  return (
    <div>
      {isModal.profile ?
        <Modal
          content={
            <div>
              <h3 style={{marginLeft: 10}}>{props.user.fullname}</h3>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styleBtn} onClick={notifyMe}>
                  <Icon name='message' outline={true} />
                  <span style={{marginRight: 10}}><b>Notify Me</b></span>
                </div>                
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styleBtn} onClick={notifyAll}>
                  <Icon name='forum' outline={true} />
                  <span style={{marginRight: 10}}><b>Notify All</b></span>
                </div>                
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styleBtn} onClick={unsubscribeButtonHandler}>
                  <Icon name='cancel' color='tomato' outline={true} />
                  <span style={{marginRight: 10}}><b>Unsubscribe Notifications</b></span>
                </div>                
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>                
                <div style={styleBtn} onClick={props.logOut}>
                  <Icon name='logout' outline={true} />
                  <span style={{marginRight: 10}}><b>Sign Out</b></span>
                </div>
              </div>

            </div>
          }
          closeModal={closeProfile}
        /> : null
      }
      {isModal.menu ? <Modal content={menuContent} closeModal={closeMenu} /> : null}
      {screenSizeNormal ?
        <div style={styleNormal}>
          <img style={titleStyle} src="logo192.png" />
          <div style={{display: 'inline-block'}} className={props.component === 'Home' ? 'active' : 'nonActive'} data-name='Home' onClick={selectComponent}>Home</div>
          {props.user.info > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Info' ? 'active' : 'nonActive'} data-name='Info' onClick={selectComponent}>Info</div> : null}
          {props.user.test > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Tests' ? 'active' : 'nonActive'} data-name='Tests' onClick={selectComponent}>Tests</div> : null}
          {props.user.lab > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Lab' ? 'active' : 'nonActive'} data-name='Lab' onClick={selectComponent}>Lab</div> : null}
          {props.user.presat > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Presat' ? 'active' : 'nonActive'} data-name='Presat' onClick={selectComponent}>Presat</div> : null}
          {props.user.plan > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Plans' ? 'active' : 'nonActive'} data-name='Plans' onClick={selectComponent}>Plans</div> : null}
          {props.user.daily > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Dailies' ? 'active' : 'nonActive'} data-name='Dailies' onClick={selectComponent}>Dailies</div> : null}
          {props.user.document > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Documents' ? 'active' : 'nonActive'} data-name='Documents' onClick={selectComponent}>Docs</div> : null}
          {props.user.concrete > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Concrete' ? 'active' : 'nonActive'} data-name='Concrete' onClick={selectComponent}>Data Sheets</div> : null}
          {props.user.manage > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Manage' ? 'active' : 'nonActive'} data-name='Manage' onClick={selectComponent}>Manage</div> : null}
          <div>
            <div style={styleProfile} onClick={openProfile}>{profileInitials}</div>
          </div>
        </div> :
      !screenSizeNormal ?
        <div>
          <div style={styleSmall}>
            <img style={titleStyle} src="logo192.png" />
            <Icon name='apps' onClick={openMenu} />
            <div style={styleProfile} onClick={openProfile}>{profileInitials}</div>
          </div>
        </div> :null
      }
    </div>
  )

}

export default NavBar
