import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Stats from 'components/manage/Stats'
import Charts from 'components/manage/Charts'

import { formatDateTime, addActivity, catchError } from 'scripts/common'

const Analytics = (props) => {

  const canvasRef = useRef(null)
  const chartRef = useRef(null)
  
  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const mapPosition = useRef({
    center: null,
    zoom: null
  })

  const [fetchedData, setFetchedData] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    table: true,
    chart: true,
    google: false,
    loading: true
  })

  const [display, setDisplay] = useState({
    table: true,
    chart: false,
    week: true,
    month: false
  })

  const fetchData = () => {

    // fetch('/api/selectAnalyticsDistinctUsers', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter,
    //     user: props.user
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)

    //     addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'Analytics', props.user.username)

    //     setFetchedData(result.map((data, i) => {          

    //       return {...data,
    //         time: data.time !== null ? formatDateTime(data.time) : data.time,
    //         who: data.who.toLowerCase()
    //       }

    //     }))

    //     setIsModal(prevState => ({...prevState, loading: false}))

    //   },
    //   (error) => {
    //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectAnalyticsDistinctUsers', JSON.stringify(error), props.user.username, props.user.device)

    //   }
    // )    

  }

  // async function fetchData() {
  //
  //   let response = await fetch('/api/selectDistinctUsersForChart', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       filter: props.filter
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (user) => {
  //
  //       let users = user.map(data => data.entryby)
  //
  //       setFetchedDistinctUsers(users)
  //
  //       fetch('/api/selectActivityForChart', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           filter: props.filter
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //
  //           setFetchedData(result.map((data, i) => {
  //
  //             return {...data,
  //               time: formatDateTime(data.time),
  //               yLabelKey: users.indexOf(data.who)
  //             }
  //
  //           }))
  //         },
  //         (error) => {
  //           console.log('Error: selectActivityForChart --> ' + error)
  //         }
  //       )
  //
  //     },
  //     (error) => {
  //       console.log('Error: selectActivityForChart --> ' + error)
  //     }
  //   )
  //
  // }
  //
  // useEffect(() => {
  //   fetchData()
  // }, [props.filter])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData()
  //     .then(result => {
  //       if (isModal.chart) buildChart()
  //       //if (isModal.google) addMarkers()
  //     })
  //   }, 15000) // time in milliseconds (ie 1 mins)
  //   return () => clearInterval(interval);
  // }, [isModal, props.filter, searchValue, fetchedData])

  const toggleTable = () => setIsModal(prevState => ({...prevState, table: isModal.table ? false : true}))  

  const toggleChart = () => setIsModal(prevState => ({...prevState, chart: isModal.chart ? false : true}))

  const toggleGoogle = () => {

    if (isModal.google) mapPosition.current = {center: null, zoom: null}

    setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  }

  //const toggleDisplay = () => setDisplay(prevState => ({...prevState, table: display.table ? false : true, chart: display.chart ? false : true}))
  const toggleTimeFrame = () => setDisplay(prevState => ({...prevState, week: display.week ? false : true, month: display.month ? false : true}))

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let styleBtn = {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  let styleChart = {
    margin: 10, 
    flex: '1 0 auto', 
    overflow: 'auto'
  }

  let chartAndGoogle = (
    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%', overflow: 'auto'}}>

        {isModal.chart ? <Charts filter={props.filter} searchValue={searchValue} isModal={isModal} display={display} /> : null}

        {isModal.google ?

          <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
            <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
          </div> : null

        }

      </div>

    </div>
  )

              // <div>

              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='person_search' outline={true} />
              //     <span style={{marginRight: 10}}><b>Status</b></span>
              //   </div>
 
              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='home_repair_service' color='orange' outline={true} />
              //     <span style={{marginRight: 10}}><b>Nuke</b></span>
              //   </div>

              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='change_history' color='black' />
              //     <span style={{marginRight: 10}}><b>Tests</b></span>
              //   </div>

              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='assignment' outline={true} />
              //     <span style={{marginRight: 10}}><b>Dailies</b></span>
              //   </div>

              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='draw' outline={true} />
              //     <span style={{marginRight: 10}}><b>Draw</b></span>
              //   </div>

              //   <div style={styleBtn} onClick={null}>
              //     <Icon name='science' outline={true} />
              //     <span style={{marginRight: 10}}><b>Lab</b></span>
              //   </div>

              // </div>

              //<div style={{margin: 10, flex: isModal.table && (isModal.chart || isModal.google) ? '0 1 auto' : '1 0 auto'}}>

              // <div style={{display: 'inline-block', margin: 10}}>
              //     <small style={{margin: 10}}>Chart</small>
              //     <label className='switch'>
              //       <input type='checkbox' onClick={toggleDisplay} defaultChecked={true} />
              //       <span className='slider round'></span>
              //     </label>
              //     <small style={{margin: 10}}>Table</small>
              //   </div>

  return (

    <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <>
          
          <div style={{
            margin: 10, 
            flex: isModal.table && (isModal.chart || isModal.google) ? '1 1 auto' : '1 0 auto',
            maxWidth: isModal.table && (isModal.chart || isModal.google) ? '600px' : 'none'
            }}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>
                <Icon name='refresh' title='Refresh' onClick={null} />
                <Icon name='table_rows' title='Toggle Table' outline={true} color={isModal.table ? 'dodgerblue' : 'gray'} onClick={toggleTable} />
                <Icon name='timeline' title='Toggle Chart' color={isModal.chart ? 'dodgerblue' : 'gray'} onClick={toggleChart} />
                <Icon name='location_on' title='Toggle Map' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />               

                {isModal.chart ?

                  <div style={{display: 'inline-block', margin: 10}}>
                    <small style={{margin: 10}}>Month</small>
                    <label className='switch'>
                      <input type='checkbox' onClick={toggleTimeFrame} defaultChecked={true} />
                      <span className='slider round'></span>
                    </label>
                    <small style={{margin: 10}}>Week</small>
                  </div> : null

                }

              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />                         

              {isModal.table ?

                <>                  

                  <div style={{margin: 10, flex: '1', overflow: 'auto', resize: 'horizontal'}}>                    

                    <Stats filter={props.filter} searchValue={searchValue} display={display} />

                  </div>

                </> : null

              }

              {!isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

            </div>

          </div>

          {isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

        </>



    </div>

  )
  
}

export default Analytics
