import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
//import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, catchError, filterData, addActivity } from 'scripts/common'

const MyLab = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedTests, setFetchedTests] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true,
    tests: false
  })

  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectMyLab', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'My Lab', props.user.username)

        setFetchedData(result.map(data => (
          {...data,
            entrytime: formatDateTime(data.entrytime),
            modtime: formatDateTime(data.modtime),
            sampledate: formatDateYMD(data.sampledate),
            deliveredtime: formatDateTime(data.deliveredtime)
          }
        )))

        //setfetchedData(result)
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectMyLab', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.currentTarget
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')
    
    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else {

      if (fetchedData[i].status !== 'sampled' && fetchedData[i].status !== 'delivered') {        

        fetch('/api/selectMyLabTests', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: fetchedData[i].id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
    
            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'My Lab Tests', props.user.username)    
            
            setFetchedTests(result)

            setIsModal(prevState => ({...prevState, tests: true}))
          },
          (error) => {
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectMyLabTests', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {
        alert('No tests have been assigned.')
      }
    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const closeTests = () => setIsModal(prevState => ({...prevState, tests: false}))

  let listOfLab = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    //let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let sampleNo = data.sampleno === null ? '' : data.sampleno
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let purpose = data.purpose === null ? '' : data.purpose
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color    
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : data.entrytime
    let status = data.status === null ? '' : data.status
    let sampleStatus = data.sampleStatus === null ? '' : data.sampleStatus

    //  || entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    let filter = filterData(data, searchValue)

    if (filter) {      

      return (
        <tr key={data.id.toString()} data-i={i} onClick={selectRow}>
            <td>{data.sampledate}</td>
            {props.filter.jobNumber === '' ? <td>{jn}</td> : null}
            <td>{status}</td>            
            <td>{sampleNo}</td>
            <td className='wrapText'>{location}</td>
            <td className='wrapText'>{represents}</td>
            <td className='wrapText'>{purpose}</td>
            <td>{sampleType}</td>
        </tr>
      )
      
    }

  })

  let listOfTests = fetchedTests.map((data, i) => {
    
    let dueDate = data.duedate === null ? '' : formatDateYMD(data.duedate)

    let reviewed = 'black'
    let completed = 'gray'
    let started = 'yellow'
    let assigned = 'skyblue'

    let styleStatus = {
      color: data.status === 'reviewed' ? 'white' : 'black',
      backgroundColor: data.status === 'reviewed' ? reviewed :
      data.status === 'completed' ? completed :
      data.status === 'started' ? started :
      data.status === 'assigned' ? assigned : 'none'
    }

    let filter = filterData(data, searchValue)

    if (filter) {      

      return (
        <tr key={i.toString()} data-i={i} onClick={null}>            
          <td>{dueDate}</td>            
          <td>{data.tbl.replace('tbllab_', '')}</td>            
          <td style={styleStatus}>{data.status}</td>
        </tr>
      )
      
    }

  })

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  return (
    <>
      {isModal.tests ?

        <Modal 
          content={
            fetchedTests.length > 0 ?          

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      <th>Due</th>
                      <th>Test</th>                        
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfTests}
                  </tbody>

                </table>

              </div> : null
            
          }          
          closeModal={closeTests} 
        /> : null

      }
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <>

                <small style={{margin: 10}}>{fetchedData.length} Total Records</small>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table id="toExcel">

                    <thead>
                      <tr>
                        <th>Date</th>
                        {props.filter.jobNumber === '' ? <th>JN</th> : null}
                        <th>Status</th>                        
                        <th>SN</th>
                        <th>Location</th>
                        <th>Represents</th>
                        <th>Purpose</th>
                        <th>Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfLab}
                    </tbody>

                  </table>

                </div>

              </> : null

            }

          </div>

        </div> : <p style={{margin: 10}}>Loading...</p>
      }
    </>
  )

}

export default MyLab
