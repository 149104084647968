import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'

import Selects from 'components/utils/Selects'
import AssignLab from './AssignLab'
import Modal from 'components/utils/Modal'

import { formatDateTime, getLocation } from 'scripts/common'
import 'styles/container.css'

const Assign = (props) => {

  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedSamples, setFetchedSamples] = useState([])
  const [fetchedConsolOrShear, setFetchedConsolOrShear] = useState([])
  const [fetchedComment, setFetchedComment] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true
  })

  const [selectedStatus, setSelectedStatus] = useState('unassigned')

  //const [selectView, setSelectView] = useState('status')
  //const [selectJobnumber, setSelectJobnumber] = useState([])

  const [isAssignModal, setIsAssignModal] = useState({isDs: false, isConsol: false, isComments: false, id: null, field: null, testStatus: null})
  const [isAssign, setIsAssign] = useState(false)
  const [isPriority, setIsPriority] = useState(false)

  const [isSelectedJobRequired, setIsSelectedJobRequired] = useState(false)

  ///const changeView = () => selectView === 'normal' ? setSelectView('status') : setSelectView('normal')

  function assignDispose(event) {
    assignArchiveOrDispose(event,'dispose')
  }

  function assignArchive(event) {
    assignArchiveOrDispose(event,'archive')
  }

  function assignArchiveOrDispose(event, btn) {

    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let status = el.getAttribute('data-status')
    let newStatus

    const update = () => {
      getLocation(function(latlng){

        fetch('/api/assignArchiveOrDispose', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: id, status: newStatus, btn: btn, by: props.user.username, time: formatDateTime(new Date()), lat: latlng.lat, lng: latlng.lng})
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result))

            setFetchedSamples(fetchedSamples.map(sample => sample.labid === id ? {...sample, status: newStatus} : sample))

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })
    }

    if (status === 'archived' || status === 'disposed') {
      if (window.confirm("This sample is " + status + ". Proceed?")) {
        newStatus = btn
        update()
      }
    } else if (status === btn) {
      newStatus = 'sampled'
      update()
    } else {
      newStatus = btn
      update()
    }

  }

  function assignDisposeByJob(event) {
    assignArchiveOrDisposeByJob(event,'dispose')
  }

  function assignArchiveByJob(event) {
    assignArchiveOrDisposeByJob(event,'archive')
  }

  function assignArchiveOrDisposeByJob(event, btn) {

    if (window.confirm("This will update all samples currently visible for this jobnumber and phase to " + btn + ". Proceed?")) {

      let el = event.target
      let jobnumber = el.getAttribute('data-jobnumber')
      let phase = el.getAttribute('data-phase')
      //let status = document.getElementById('selectStatus').value

      getLocation(function(latlng){

        fetch('/api/assignArchiveOrDisposeByJob', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({jobnumber: jobnumber, phase: phase, status: selectedStatus, btn: btn, by: props.user.username, time: formatDateTime(new Date()), lat: latlng.lat, lng: latlng.lng})
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result))
            // let update = fetchedSamples
            // fetchedSamples.map((sample, i) => {
            //   if (sample.jobnumber === jobnumber && sample.phaseid === phase) {
            //     update[i].status = btn
            //     setFetchedSamples(update)
            //   }
            // })

            setFetchedSamples(fetchedSamples.map(sample => sample.jobnumber === jobnumber && sample.phaseid === phase ? {...sample, status: btn} : sample))

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const closeModal = (event) => setIsAssignModal({isDs: false, isConsol: false, isComments: false, id: null, field: null, testStatus: null})

  const openAssignModal = (event) => {

    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('data-field')

    if (field === 'comments') {

      fetch('/api/fetchComment', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: id})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
          setFetchedComment(result)
          setIsAssignModal({
            isDs: false,
            isConsol: false,
            isComments: true,
            id: id
          })
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    } else if (isAssign) {

      let testStatus = el.getAttribute('data-status')
      let dueDate = document.getElementById('dueDate').value

      if ((testStatus === null || testStatus === '') && (dueDate === null || dueDate === '')) {

        alert('Please select a Due Date')

      } else {

        fetch('/api/fetchConsolOrShear', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: id, field: field === 'statusconsol' ? 'consol' : field === 'statusds' ? 'ds' : ''})
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))
            setFetchedConsolOrShear(result)
            setIsAssignModal({
              isDs: field === 'statusds' ? true : false,
              isConsol: field === 'statusconsol' ? true : false,
              isComments: false,
              id: id,
              field: field,
              testStatus: testStatus
            })
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      }

    }

  }

  function assignLab(event) {

    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('data-field')
    let testStatus = el.getAttribute('data-status')
    let sampleStatus = el.getAttribute('data-samplestatus')
    let btn = el.getAttribute('data-btn') // used for consol/ds to allow for update/unassign/assign
    let dueDate = document.getElementById('dueDate').value

    const addTest = () => {

      //getLocation(function(latlng){
        fetch('/api/testRecordExists', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: id, field: field})
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            if (result.length === 0) {

              getLocation(function(latlng){
                fetch('/api/insertTestRecord', {
                  method: 'post',
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({id: id, field: field, priority: isPriority ? 1 : 0, dueDate: dueDate, by: props.user.username, time: formatDateTime(new Date()), lat: latlng.lat, lng: latlng.lng})
                })
                .then(res=>res.json())
                .then(
                  (result) => {
                    //console.log('addSample result: ' + JSON.stringify(result))
                  },
                  (error) => {
                    console.log('error: ' + error)
                  }
                )
              })

            }

          },
          (error) => {
            console.log('error: ' + error)
          }
        )
      //})

    }

    const deleteTest = () => {

      let table = field.replace('status', '')

      fetch('/api/assignDelete', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({labid: id, table: table})
      })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('deleted')
          },
          (error) => {
            console.log('error: ' + error)
          }
        )
    }

    const updateSample = () => {

      let saturation, cload, unload, reload, unload2, timereading
      let undistremold, satfieldm, dsload1, dsload2, dsload3, rate, reshear
      let statuses, newStatus, newTestStatus, count
      let d = document
      let priority = field.replace('status', 'priority')

      const update = () => {

        setIsAssignModal({isDs: false, isConsol: false, isComments: false})

        if (testStatus === null || testStatus === '') {

          addTest()
          newTestStatus = 'assigned'

        } else {

          if ((field === 'statusconsol' && btn === 'updateConsol') || (field === 'statusds' && btn === 'updateDs')) {

            newTestStatus = testStatus

          } else {

            newTestStatus = ''

          }

        }

        // update the current fetched data

        setFetchedSamples(fetchedSamples.map(sample => {
          if (sample.labid === id) {

            // now update the sample, whether consol, ds, or other

            if (field === 'statusconsol') {

              return {
                ...sample,
                [field]: newTestStatus,
                [priority]: isPriority,
                saturation: saturation,
                cload: cload,
                unload: unload,
                reload: reload,
                unload2: unload2,
                timereading: timereading
              }

            } else if (field === 'statusds') {

              return {
                 ...sample,
                 [field]: newTestStatus,
                 [priority]: isPriority,
                 undistremold: undistremold,
                 satfieldm: satfieldm,
                 dsload1: dsload1,
                 dsload2: dsload2,
                 dsload3: dsload3,
                 rate: rate,
                 reshear: reshear
               }

            } else {

              return  {
                ...sample,
                //status: newStatus,
                [field]: newTestStatus,
                [priority]: isPriority
              }

            }

          } else {
            return sample
          }

        }))

        // post new data, assign.js determines how to update

        getLocation(function(latlng){

          fetch('/api/assignTest', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: id,
              field: field,
              //status: newStatus,
              testStatus: newTestStatus,
              priority: isPriority,
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              saturation: saturation,
              cload: cload,
              unload: unload,
              reload: reload,
              unload2: unload2,
              timereading: timereading,
              undistremold: undistremold,
              satfieldm: satfieldm,
              dsload1: dsload1,
              dsload2: dsload2,
              dsload3: dsload3,
              rate: rate,
              reshear: reshear
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log(JSON.stringify(result))
            },
            (error) => {
              console.log('error: ' + error)
            }
          )

        })

      }

      if (field === 'statusconsol') {

        saturation = d.getElementById('saturation').value
        cload = d.getElementById('cload').value
        unload = d.getElementById('unload').value
        reload = d.getElementById('reload').value
        unload2 = d.getElementById('unload2').value
        timereading = d.getElementById('timereading').value

        if (cload === '' || cload === null) {
          alert('Please provide an initial Load To.')
        } else if (unload === '' || unload === null) {
          alert('Please provide an initial Unload To.')
        } else {
          update()
        }

      } else if (field === 'statusds') {

        undistremold = d.getElementById('undistremold').value
        satfieldm = d.getElementById('satfieldm').value
        dsload1 = d.getElementById('dsload1').value
        dsload2 = d.getElementById('dsload2').value
        dsload3 = d.getElementById('dsload3').value
        rate = d.getElementById('rate').value
        reshear = d.getElementById('reshear').value

        if (undistremold === '' || undistremold === null) {
          alert('Please select Undist or Remold.')
        } else if (satfieldm === '' || satfieldm === null) {
          alert('Please select Sat or Field Moist.')
        } else if (dsload1 === '' || dsload1 === null) {
          alert('Please specify a 1st load.')
        } else if (dsload2 === '' || dsload2 === null) {
          alert('Please specify a 2nd load.')
        } else if (dsload3 === '' || dsload3 === null) {
          alert('Please specify a 3rd load.')
        } else if (rate === '' || rate === null) {
          alert('Please specify a rate.')
        } else {
          update()
        }

      } else {
        update()
      }

    }

    // this was above, but updateSample() called before initialization error triggered..easy solution

    // if (sampleStatus === 'archived' || sampleStatus === 'disposed') {
    //
    //   alert('I am developing the logic for this atm. Let Danny know if you need to change something.')
    //
    //   // if (window.confirm('This sample is ' + sampleStatus + '. If you proceed, test results will be deleted. Proceed?')) {
    //   //
    //   // }
    //
    // } else

    if ((testStatus === null || testStatus === '') && (dueDate === null || dueDate === '') && (sampleStatus === 'sampled' || sampleStatus === 'delivered')) {

      alert('Please select a Due Date and either To Be Archived or Disposed.')

    } else if ((testStatus === null || testStatus === '') && (dueDate === null || dueDate === '')) {

      alert('Please select a Due Date.')

    } else if ((testStatus === null || testStatus === '') && (sampleStatus === 'sampled' || sampleStatus === 'delivered')) {

      alert('Please select either To Be Archived or Disposed.')

    } else {

      // now handle test

      if (testStatus === null || testStatus === '') {

        updateSample()

      } else if (testStatus === 'assigned') {

        if ((field !== 'statusconsol' && field !== 'statusds') || (field === 'statusconsol' && btn === 'deleteConsol') || (field === 'statusds' && btn === 'deleteDs')) { // consol/ds have two btns while other tests have one

          deleteTest()
          //console.log('deleted1')

        }

        updateSample()

      } else if (testStatus === 'reviewed' || testStatus === 'completed' || testStatus === 'started') {

        if ((field !== 'statusconsol' && field !== 'statusds') || (field === 'statusconsol' && btn === 'deleteConsol') || (field === 'statusds' && btn === 'deleteDs')) { // consol/ds have two btns while other tests have one

          if (window.confirm('This test is ' + testStatus + '. If you proceed, test results will be deleted. Proceed?')) {
            deleteTest()
            updateSample()
            //console.log('deleted2')
          }

        } else if (testStatus === 'started') {

          if ((field === 'statusconsol' && btn === 'updateConsol') || (field === 'statusds' && btn === 'updateDs')) { // consol/ds have two btns while other tests have one

            if (window.confirm('This test is ' + testStatus + '. If you proceed, you MUST inform the lab directly! Proceed?')) {
              deleteTest()
              updateSample()
              //console.log('deleted2')
            }

          }

        } else if (testStatus === 'reviewed' || testStatus === 'completed') {

          if ((field === 'statusconsol' && btn === 'updateConsol') || (field === 'statusds' && btn === 'updateDs')) { // consol/ds have two btns while other tests have one

            alert(`This test is ${testStatus}. You cannot modify this test.`)

          }

        }

      } else {

        alert('Error: test status not recognized during assign.')
        console.log('Error: test status --> ' + testStatus)

      }

    }

  }

  const addComment = (event) => {

    let el = event.target
    let d = document
    let id = el.getAttribute('data-id')
    let comments = d.getElementById('comments').value

    getLocation(function(latlng){

      fetch('/api/assignComments', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: id,
          comments: comments,
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: latlng.lat,
          lng: latlng.lng})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          console.log('added')

          setFetchedSamples(fetchedSamples.map(sample =>
            sample.labid === Number(id) ?
            {...sample,
              comments: comments
            } :
            sample
          ))

          setIsAssignModal({isDs: false, isConsol: false, isComments: false})
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    })
  }

  const selectStatus = (event) => {
    //let name = event.target.getAttribute('name')
    //let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setSelectedStatus(value)

  }

  const selectAssign = () => {
    if (isAssign) {
      setIsAssign(false)
    } else {
      setIsAssign(true)
      setIsPriority(false)
    }
  }

  const selectPriority = () => setIsPriority(isPriority ? false : true)

  const showDueDate = (event) => {
    let el = event.target
    let title = el.getAttribute('title')

    alert(title)
  }

  const showDeliveredInfo = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let content, sampled, delivered
    //console.log('samples: ' + JSON.stringify(fetchedSamples))
    let msg = fetchedSamples.map(sample => {

      if (sample.labid === id) {
        console.log('samples2: ' + JSON.stringify(sample))
        sampled = sample.entrytime !== null && sample.entrytime !== '' ?
        'Sampled by: ' + sample.entryby + ' @ ' + formatDateTime(new Date(sample.entrytime)) + '\n' : 'Not sampled\n'

        delivered = sample.deliveredtime !== null && sample.deliveredtime !== '' ?
        'Delivered by: ' + sample.deliveredby + ' @ ' + formatDateTime(new Date(sample.deliveredtime)) : 'Not delivered'
        content = sampled + delivered

        return (
          content
        )
      }

    })

    alert(content)
  }

  const showStatusInfo = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let content, sampled, delivered, dispose, disposed, archive, archived, location
    //console.log('samples: ' + JSON.stringify(fetchedSamples))
    let msg = fetchedSamples.map(sample => {

      if (sample.labid === id) {

        sampled = sample.entrytime !== null && sample.entrytime !== '' ?
        'Sampled by: ' + sample.entryby + ' @ ' + formatDateTime(new Date(sample.entrytime)) + '\n' : 'Not sampled\n'

        delivered = sample.deliveredtime !== null && sample.deliveredtime !== '' ?
        'Delivered by: ' + sample.deliveredby + ' @ ' + formatDateTime(new Date(sample.deliveredtime)) : 'Not delivered'

        content = sampled + delivered

        if ((sample.disposetime !== null && sample.disposetime !== '') || (sample.disposedtime !== null && sample.disposedtime !== '')) {

          dispose = sample.disposetime !== null && sample.disposetime !== '' ?
          '\nTo dispose by: ' + sample.disposeby + ' @ ' + formatDateTime(new Date(sample.disposetime)) : '\nNot assigned to dispose'

          disposed = sample.disposedtime !== null && sample.disposedtime !== '' ?
          '\nDisposed by: ' + sample.disposedby + ' @ ' + formatDateTime(new Date(sample.disposedtime)) : '\nNot disposed'


          content += dispose + disposed

        } else if ((sample.archivetime !== null && sample.archivetime !== '') || (sample.archivedtime !== null && sample.archivedtime !== '')) {

          archive = sample.archivetime !== null && sample.archivetime !== '' ?
          '\nTo archive by: ' + sample.archiveby + ' @ ' + formatDateTime(new Date(sample.archivetime)) : '\nNot assigned to archive'

          content += archive

          if (sample.archivedtime !== null && sample.archivedtime !== '') {
            archived = '\nArchived by: ' + sample.archivedby + ' @ ' + formatDateTime(new Date(sample.archivedtime))
            location = '\nRack: ' + sample.archiveRack
            location += '\nRow: ' + sample.archiveRow
            location += '\nCol: ' + sample.archiveCol

            content += archived + location
          }

        }

        return (
          content
        )
      }

    })

    alert(content)
  }

  const fetchData = () => {
    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId
    //let status = document.getElementById('selectStatus').value

    // fetch('/api/selectAssignJobs', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({jobnumber: jobnumber, phase: phase, status: selectedStatus})
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     setSelectJobnumber(result)
    //     setIsModal(prevState => ({...prevState, loading: false}))
    //   },
    //   (error) => {
    //     console.log('error: ' + error)
    //   }
    // )

    if (jobnumber === '' && phase === 'All' && selectedStatus === 'all') {

      setIsModal(prevState => ({...prevState, loading: false}))
      setIsSelectedJobRequired(true)
      
    } else {

      setIsSelectedJobRequired(false)

      fetch('/api/assignFetchedJobs', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase, status: selectedStatus})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedJobs(result)
          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

      fetch('/api/assignFetchedSamples', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase, status: selectedStatus})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedSamples(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter, selectedStatus])

  // useEffect(() => {
  //   console.log('render')
  // },[fetchedSamples])


  let phaseIds = ['P','C']
  let phases = phaseIds.map(phase => {

    let jobs = fetchedJobs.map(job => {

      //let archiveOrDisposeJob = isArchive ? <th><Icon name='archive' jobnumber={job.jobnumber} phase={phase} onClick={assignArchiveByJob} /></th> : isDisposeOf ? <th><Icon name='delete' color='tomato' jobnumber={job.jobnumber} phase={phase} onClick={assignDisposeByJob} /></th> : null

      let archiveJob = isAssign ? <th><Icon name='archive' iconClass='material-icons-outlined' jobnumber={job.jobnumber} phase={phase} onClick={assignArchiveByJob} /></th> : null
      let disposeJob = isAssign ? <th><Icon name='delete' color='tomato' iconClass='material-icons-outlined' jobnumber={job.jobnumber} phase={phase} onClick={assignDisposeByJob} /></th> : null

      let countSamples = 0

      let samples = fetchedSamples.map(sample => {
        //console.log('phaseId: ' + sample.phaseid + ', phase: ' + phase)
        if (job.jobnumber === sample.jobnumber && phase === sample.phaseid) {
          countSamples++
          // let date = new Date(sample.daterequested);
          // date = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate()// + ' '+ date.getHours()+ ':' + date.getMinutes();

          // let iconClass =
          //   (isArchive && (sample.status === 'archive' || sample.status === 'archived')) ||
          //   (isDisposeOf && (sample.status === 'dispose' || sample.status === 'disposed')) ?
          //   'material-icons' : 'material-icons-outlined'

          // let assignArchiveIcon = isAssign ? <td><Icon name='archive' id={sample.labid} status={sample.status} iconClass={sample.status === 'archive' || sample.status === 'archived' ? 'material-icons' : 'material-icons-outlined'} onClick={assignArchive} /></td> : null
          // let assignDisposeIcon = isAssign ? <td><Icon name='delete' color='tomato' id={sample.labid} status={sample.status} iconClass={sample.status === 'dispose' || sample.status === 'disposed' ? 'material-icons' : 'material-icons-outlined'} onClick={assignDispose} /></td> : null

          let assignArchiveIcon = isAssign ?
          <td>
            <Icon
              name='archive'
              color={sample.status === 'archive' || sample.status === 'archived' ? 'dodgerblue' : 'gray'}
              id={sample.labid}
              status={sample.status}
              iconClass={sample.status === 'archived' ? 'material-icons' : 'material-icons-outlined'}
              onClick={assignArchive}
            />
          </td> : null

          let assignDisposeIcon = isAssign ?
          <td>
            <Icon
              name='delete'
              color={sample.status === 'dispose' || sample.status === 'disposed' ? 'tomato' : 'gray'}
              id={sample.labid}
              status={sample.status}
              iconClass={sample.status === 'disposed' ? 'material-icons' : 'material-icons-outlined'}
              onClick={assignDispose}
            />
          </td> : null

          let archiveOrDispose =
          !isAssign && (sample.status === 'archive' || sample.status === 'archived') ?
          <td><Icon name='archive' iconClass={sample.status === 'archived' ? 'material-icons' : 'material-icons-outlined'} id={sample.labid} onClick={showStatusInfo} /></td> :
          !isAssign && (sample.status === 'dispose' || sample.status === 'disposed') ?
          <td><Icon name='delete' color='tomato' iconClass={sample.status === 'disposed' ? 'material-icons' : 'material-icons-outlined'} id={sample.labid} onClick={showStatusInfo} /></td> :
          <td></td>

          let style = {
            width: sample.phaseid === 'P' ? 50 : 100
          }

          let styleType = {
            color: 'white',
            backgroundColor:
              sample.sampletype === 'bulk' ? 'dodgerblue' :
              sample.sampletype === 'ca' ? 'orange':
              sample.sampletype === 'spt' ? 'mediumseagreen' :
              sample.sampletype === 'smbag' ? 'slateblue' :
              sample.sampletype === 'ac' ? 'gray' :
              sample.sampletype === 'shelby' ? 'violet' : 'tomato',
            width: sample.phaseid === 'P' ? 50 : 100
          }

          let rowListOther = (
            <>
              <td>
                <AssignLab
                  key={sample.labid.toString()}
                  who='assign'
                  sample={sample}
                  view={props.filter.view}
                  onClick={isAssign ? assignLab : showDueDate}
                  onClickShearOrConsol={isAssign ? openAssignModal : showDueDate}
                />
              </td>
              {
                sample.comments === null || sample.comments === '' ?
                <td><Icon name='add_comment' onClick={openAssignModal} id={sample.labid} field='comments' /></td> :
                <td onClick={openAssignModal} data-id={sample.labid} data-field='comments' data-comments={sample.comments}>{sample.comments}</td>

              }
            </>
          )

          let rowList = sample.phaseid === 'P' ?
            (
              <tr key={sample.labid.toString()}>
                {archiveOrDispose}
                <td><Icon name='local_shipping' color={sample.deliveredtime !== '' && sample.deliveredtime !== null ? 'dodgerblue' : 'tomato'} id={sample.labid} onClick={showDeliveredInfo} /></td>
                <td style={style}>{sample.location}</td>
                <td style={style}>{sample.depth}</td>
                <td style={styleType}>{sample.sampletype}</td>
                {assignDisposeIcon}
                {assignArchiveIcon}
                {rowListOther}
              </tr>
            ) :
            sample.phaseid === 'C' ?
            (
              <tr key={sample.labid.toString()}>
                {archiveOrDispose}
                <td><Icon name='local_shipping' color={sample.deliveredtime !== '' && sample.deliveredtime !== null ? 'dodgerblue' : 'tomato'} id={sample.labid} onClick={showDeliveredInfo} /></td>
                <td style={style}>{sample.sampleno}</td>
                <td style={style}>{sample.location}</td>
                <td style={style}>{sample.represents}</td>
                <td style={style}>{sample.purpose}</td>
                <td style={styleType}>{sample.sampletype}</td>
                {assignDisposeIcon}
                {assignArchiveIcon}
                {rowListOther}
              </tr>
            ) : null

          return (
            rowList
          )
        }
      })

      let sampleList = phase === 'P' ?
        (
          <table>
            <thead>
              <tr className='tableHeader'>
                <th></th>
                <th></th>
                <th>Location</th>
                <th>Depth</th>
                <th>Type</th>
                {disposeJob}
                {archiveJob}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {samples}
            </tbody>
          </table>
        ) :
        (
          <table>
            <thead>
              <tr className='tableHeader'>
                <th></th>
                <th></th>
                <th>SN</th>
                <th>Location</th>
                <th>Represents</th>
                <th>Purpose</th>
                <th>Type</th>
                {disposeJob}
                {archiveJob}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {samples}
            </tbody>
          </table>
        )

      let foundSamples = countSamples > 0 ?
        (
          <div style={{marginLeft: 25}}>
            <div className='jobHeader'>
              <h3 style={{display: 'inline-block', margin: '10px 0px 0px'}}>{job.jobnumber} {job.company} - {job.city}</h3>
            </div>
            {sampleList}
          </div>
        ) : null

      return (
        foundSamples
      )

    })

    return (
      <div>
        <div className='phaseHeader'>
          <h2 style={{margin: '10px 0px 0px'}}>{phase === 'P' ? 'Investigation' : 'Construction'}</h2>
        </div>
        {jobs}
      </div>
    )

  })

  //let iconNameAssign = isAssign ? 'brightness_1' : 'panorama_fish_eye'
  // let iconNameArchive = isArchive ? 'brightness_1' : 'panorama_fish_eye'
  // let iconNameDisposeOf = isDisposeOf ? 'brightness_1' : 'panorama_fish_eye'

  //let iconNamePriority = isAssign && isPriority ? 'error' : 'error_outline'
  // let iconPriorityAndDueDate = isAssign ?
  //   (
  //     <div style={{display: 'flex', alignItems: 'center'}}>
  //       <Icon name={iconNamePriority} onClick={selectPriority} color='tomato' /><span style={{marginRight: 10}}>Priority</span>
  //       <input type="date" id="dueDate" style={{margin: '3px 6px 3px 3px', borderRadius: 5, height: 30}} /><label>Due Date</label>
  //     </div>
  //   ) : null

  let modalStyle = {
    position: 'fixed',
    zIndex: 4,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }

  let modalContentStyle = {
    borderRadius: 10,
    border: '1px solid #d3d3d3',
    backgroundColor: 'white',
    margin: '5px auto',
    width: 600
  }

  // field === 'statusds' ? undistremold, satfieldm, dsload1, dsload2, dsload3, rate, reshear : null

  let inputStyle = {
    border: '1px solid #d3d3d3',
    borderRadius: 5,
    display: 'inline-block',
    height: 40,
    margin: 10,
    width: 50
  }

  let textAreaStyle = {
    border: '1px solid #d3d3d3',
    borderRadius: 5,
    display: 'inline-block',
    height: 100,
    margin: 10,
    width: '100%'
  }
  //unassignConsolOrShear
  let unassign = !isAssignModal.isComments && isAssignModal.testStatus !== null && isAssignModal.testStatus !== '' ? (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span>Unassign</span>
      <Icon
        name='remove_circle'
        color='tomato'
        id={isAssignModal.id}
        field={isAssignModal.field}
        status={isAssignModal.testStatus}
        btn={isAssignModal.isDs ? 'deleteDs' : isAssignModal.isConsol ? 'deleteConsol' : null}
        onClick={isAssignModal.isDs || isAssignModal.isConsol ? assignLab : null}
      />
    </div>
  ) : null

  let modalContent =
    fetchedConsolOrShear.length > 0 && isAssignModal.isDs ?
      (
        <div>
          <label>Undist/Remold
            <select id="undistremold" className="select" defaultValue={fetchedConsolOrShear[0].undistremold}>
              <option value="r">Remold</option>
              <option value="u">Undist</option>
            </select>
          </label><br />
          <label>Sat/Field Moist
            <select id="satfieldm" className="select" defaultValue={fetchedConsolOrShear[0].satfieldm}>
              <option value="s">Sat</option>
              <option value="f">Field</option>
            </select>
          </label><br />
          <label>Load (KSF)
            <input style={inputStyle} id="dsload1" type="text" pattern="[0-9]{1}" defaultValue={fetchedConsolOrShear[0].dsload1} />
            <input style={inputStyle} id="dsload2" type="text" pattern="[0-9]{1}" defaultValue={fetchedConsolOrShear[0].dsload2} />
            <input style={inputStyle} id="dsload3" type="text" pattern="[0-9]{1}" defaultValue={fetchedConsolOrShear[0].dsload3} />
          </label><br />
          <label>Rate (in/min)
            <input style={inputStyle} id="rate" type="text" pattern="[0]+\.[0-9]{1,}" defaultValue={fetchedConsolOrShear[0].rate} />
          </label><br />
          <label>No. of Reshears
            <input style={inputStyle} id="reshear" type="text" pattern="[0-9]" defaultValue={fetchedConsolOrShear[0].reshear} />
          </label>
        </div>
      ) :
    fetchedConsolOrShear.length > 0 && isAssignModal.isConsol ?
      (
        <div>
          <label>Saturation<input style={inputStyle} id="saturation" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].saturation} /></label><br />
          <label>Load To<input style={inputStyle} id="cload" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].cload} /></label><br />
          <label>Unload To<input style={inputStyle} id="unload" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].unload} /></label><br />
          <label>Reload To<input style={inputStyle} id="reload" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].reload} /></label><br />
          <label>Unload To<input style={inputStyle} id="unload2" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].unload2} /></label><br />
          <label>Time Reading<input style={inputStyle} id="timereading" type="text" pattern="\d{1,2}(\.\d{1})?" defaultValue={fetchedConsolOrShear[0].timereading} /></label>
        </div>
      ) :
    fetchedComment.length > 0 && isAssignModal.isComments ?
      (
        <div>
          <label>Comments</label><br/>
          <textarea id="comments" style={textAreaStyle}>{fetchedComment[0].comments}</textarea>
        </div>
      ) : null

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 120
  }

  let styleBtn2 = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid gray',
    margin: 10,
    width: 120
  }

  let styleBtn3 = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  return (
    <>
      {isAssignModal.isDs || isAssignModal.isConsol || isAssignModal.isComments ?
        <Modal
          content={
            <div style={{display: 'inline-block', textAlign: 'right'}}>
              {modalContent}
              <Icon
                name='check_circle'
                color='dodgerblue'
                id={isAssignModal.id}
                field={isAssignModal.field}
                status={isAssignModal.testStatus}
                btn={isAssignModal.isDs ? 'updateDs' : isAssignModal.isConsol ? 'updateConsol' : null}
                onClick={isAssignModal.isDs || isAssignModal.isConsol ? assignLab : isAssignModal.isComments ? addComment : null}
              />
              {unassign}
            </div>
          }
          closeModal={closeModal}
        /> : null
      }
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div style={{display: 'flex', alignItems: 'center'}}>                

                {props.user.assign > 1 ?
                  <>                   
                    <div style={isAssign ? styleBtn : styleBtn2} onClick={selectAssign}>
                      <Icon name='add_task' color={isAssign ? 'dodgerblue' : 'gray'} outline={true} />
                      <span style={{marginRight: 10, textDecoration: isAssign ? 'none' : 'line-through', color: isAssign ? 'black' : 'gray'}}><b>Assign</b></span>
                    </div>

                    {isAssign ?
                      <div style={{display: 'flex', alignItems: 'center'}}>

                        <div style={isPriority ? styleBtn : styleBtn2} onClick={selectPriority}>
                          <Icon name='error' color={isPriority ? 'tomato' : 'gray'} outline={true} />
                          <span style={{marginRight: 10, textDecoration: isPriority ? 'none' : 'line-through', color: isPriority ? 'black' : 'gray'}}><b>Priority</b></span>
                        </div>

                        <div style={styleBtn3}>
                          <label style={{margin: 10}}><b>Due Date</b></label><input type="date" id="dueDate" style={{margin: '3px 6px 3px 3px', borderRadius: 5, height: 30}} />
                        </div>                        
                        
                      </div> : null
                    }
                    
                  </> : null
                }

                <div style={styleBtn} onClick={fetchData}>
                  <Icon name='refresh' />
                  <span style={{marginRight: 10}}><b>Refresh</b></span>
                </div>

              </div>

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                {isSelectedJobRequired ? <h3>Please select a jobnumber if phase and status are set to All</h3> : phases}

              </div>

            </div>

          </div>

        </div> :
        <p style={{margin: 10}}>Loading...</p>
      }

    </>
  )
}

export default Assign
