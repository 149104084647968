import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import ScheduleInput from 'components/input/ScheduleInput'
import ScheduleTimeline from 'components/main/ScheduleTimeline'
import ScheduleCalendar from 'components/main/ScheduleCalendar'

import { formatDateYMD, formatDateTime, formatTime, getLocation, catchError, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

import 'styles/scheduleTimeline.css'

//import { showCalendar } from 'scripts/schedule'

const Schedule = (props) => {

  const canvasRef = useRef(null)
  const chartRef = useRef(null)

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const mapPosition = useRef({
    center: null,
    zoom: null
  })

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const [calendarDate, setCalendarDate] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    monthName: months[new Date().getMonth()],
    contentYear: null,
    contentTimeline: null,
    period: 'Week',
    startDate: null,
    startTime: null,
    user: ''
  })

  const [fetchedData, setFetchedData] = useState([])
  
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    startDate: null,
    google: true
  })

  const [display, setDisplay] = useState({
    table: false,
    calendar: false,
    timeline: true
  })
  
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Tests').then(res => {
        setFetchedData(res.sort((a, b) => Number(b.testno) - Number(a.testno)))
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectSchedule', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter,
          calendarDate: calendarDate
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          // console.log(`
          //   result: ${JSON.stringify(result)}
          // `)

          setFetchedData(result.map((data, i) => {

            // testdate: formatDateYMD(data.testdate)

            //let formatTime = new Date(`${data.startDate} ${data.startTime}`)

            //console.log(`time: ${data.startDate} ${data.startTime}`)

            return {...data,
              time: data.startTime.slice(0,2),
              startDate: formatDateYMD(data.startDate),                            
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime)
            }            

          }))

          setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectSchedule', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  useEffect(() => {
    // if (
    //   (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
    //   (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    // ) fetchData()
    fetchData()
  }, [props.filter, calendarDate])

  useEffect(() => {    

    // day

    let date = new Date(calendarDate.year, calendarDate.month, 1)
    let days = []
    //let timelineDays = []
    let day

    // console.log(`
    // date.getMonth(): ${date.getMonth()}
    // calendarDate.month: ${calendarDate.month}
    // `)

    //let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //let d = new Date(`${calendarDate.month+1}-${calendarDate.day}-${calendarDate.year}`);
    //let dayName = dayNames[d.getDay()];

    while (Number(date.getMonth()) === Number(calendarDate.month)) {

      day = new Date(date).getDate()      

      days.push(
        <tr data-id={day} onClick={selectDay}>
          <td>{day}</td>                      
        </tr>
      )

      //d = new Date(`${months[calendarDate.month]}-${day}-${calendarDate.year}`);
      //dayName = dayNames[d.getDay()];

      // console.log(`
      // d: ${d}
      // getDay: ${d.getDay()}
      // name: ${dayName}
      // date: ${months[calendarDate.month]}-${calendarDate.day}-${calendarDate.year}
      // `)

      // timelineDays.push(
      //   <div className={`headerDay ${calendarDate.day === day ? 'selectedDay' : dayName === 'Sat' || dayName === 'Sun' ? 'weekend' : 'weekDay'}`} data-id={day} onClick={selectDay}>
      //     {dayName} <br />
      //     <b>{day}</b>          
      //   </div>
      // )

      date.setDate(date.getDate() + 1)

      //console.log(`day: ${day}`)

    }

    let contentDay = (
      <table style={{width: '100%', height: '100%'}}>

          <thead>
            <tr>
              <th>Day</th>            
            </tr>
          </thead>

          <tbody>
            {days}          
          </tbody>

        </table>
    )
    
    // let contentTimeline = (
    //   <>
    //     {timelineDays}
    //   </>
    // )

    setCalendarDate(prevState => ({...prevState, contentDay: contentDay}))

  }, [calendarDate.month, calendarDate.year])

  useEffect(() => {

    // year

    let startYear = 2023

    let currentYear = new Date().getFullYear()

    let yearDiff = currentYear - startYear

    let listOfYears = []

    for (let i=0; i <= yearDiff; i++) {

      let year = startYear + i

      listOfYears.push(
        <tr data-id={year} onClick={selectYear}>
          <td>{year}</td>                      
        </tr>
      )

    }    

    let contentYear = (
      <table style={{width: '100%', height: '100%'}}>

          <thead>
            <tr>
              <th>Year</th>            
            </tr>
          </thead>

          <tbody>
            {listOfYears}          
          </tbody>

        </table>
    )    

    setCalendarDate(prevState => ({...prevState, contentYear: contentYear}))

    // set google if Mobile

    setIsModal(prevState => ({...prevState, google: props.user.device === 'mobile' ? false : true}) )

  }, [])

  //============== BEGIN GOOGLE MAP ======================

  useEffect(() => {
    if (isModal.google) initMap()
  }, [isModal.google])

  useEffect(() => {
    if (isModal.google) addMarkers()
  }, [isModal.google, props.filter, searchValue, fetchedData])

  // useEffect(() => {
  //   if (isModal.google) updateMap()
  // }, [searchValue])
  

  // Sets the map on all markers in the array.
  const highlightMarker = (e) => {    

    let id = parseInt(e.target.getAttribute('data-id'))
    let by

    console.log(`id: ${id}`)

    for (let i = 0; i < markerRef.current.length; i++) {

      by = !markerRef.current[i].get("user") ? '?' : markerRef.current[i].user

      if (markerRef.current[i].get("id") === id) {

        //console.log('yes')

        // markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        //markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)

        let icon = {
            url: `http://maps.google.com/mapfiles/ms/icons/yellow.png`,
            scaledSize: new window.google.maps.Size(40, 40), // scaled size
            labelOrigin: new window.google.maps.Point(19, 12)
        }

        markerRef.current[i].setIcon(icon)

      } else {

        //console.log('no')

        //markerRef.current[i].setAnimation(null)
        
        let status = by === '?' ? 'red' : markerRef.current[i].get("status") === 0 ? 'blue' : 'orange'

        let icon = {
          url: `http://maps.google.com/mapfiles/ms/icons/${status}.png`,
          scaledSize: new window.google.maps.Size(40, 40), // scaled size
          labelOrigin: new window.google.maps.Point(19, 12)
      }

      markerRef.current[i].setIcon(icon)
        
      }

      //markerRef.current[i].setMap(mapRef.current);
    }
  }  

  const initMap = () => {

    mapRef.current = new window.google.maps.Map(googleRef.current, {
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      zoom: 9,
      center: {lat: 33.847827, lng: -117.862286}
    });

    console.log('chart?')

    addMarkers()

  }

  const addMarkers = () => {

    let data = fetchedData.filter(data => {

      if (data.lat !== '' && data.lat !== null && data.lng !== '' && data.lng !== null) {

          // let jn = data.jobnumber === null ? '' : data.jobnumber
          // let gd = data.phase === null ? '' : data.phase === 'RG' ? 'Grading' : data.phase === 'PG' ? 'Post' : data.phase
          // let description = data.description === null ? '' : data.description                 

        return data

      }


    })

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, visible, imageName
    let imagePath = 'http://labs.google.com/ridefinder/images/'
    let jn, gd, description, tbl, by, time

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds()

    // clear markers

    for (i = 0; i < markerRef.current.length; i++) {
      markerRef.current[i].setMap(null);
    }

    // add markers

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      gd = data[i].gradeId === null ? '' : data[i].gradeId === 'RG' ? 'Grading' : data[i].gradeId === 'PG' ? 'Post' : data[i].gradeId
      description = data[i].description === null ? '' : data[i].description      
      by = !data[i].user ? '?' : data[i].user      
      visible = filterData(data[i], searchValue)

      // console.log(`
      // jn: ${jn}
      // by: ${by}
      // `)

      //console.log(`${visible}`)

      // imageName =
      // tbl === 'nukeIn' ? 'mm_20_green.png' :
      // tbl === 'nukeOut' ? 'mm_20_red.png' :
      // tbl === 'status' ? 'mm_20_blue.png' :
      // tbl === 'test' ? 'mm_20_orange.png' :
      // tbl === 'lab' ? 'mm_20_brown.png' :
      // tbl === 'daily' ? 'mm_20_gray.png' :
      // tbl === 'drawing' ? 'mm_20_purple.png' : 'mm_20_white.png'

      let status = by === '?' ? 'red' : data[i].status === 0 ? 'blue' : 'orange'

      let icon = {
          url: `http://maps.google.com/mapfiles/ms/icons/${status}.png`,
          scaledSize: new window.google.maps.Size(40, 40), // scaled size
          labelOrigin: new window.google.maps.Point(19, 12)
      }

      // icon: {
      //   url: `${imagePath}${imageName}`
      // },

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].lat, data[i].lng),
        id: data[i].id,
        jn: jn,
        gd: gd,
        client: data[i].client,
        city: data[i].city,
        startTime: data[i].startTime,
        duration: data[i].duration,
        description: description,        
        by: by,        
        map: mapRef.current,        
        icon: icon,
        label: { color: 'black', fontWeight: 'bold', fontSize: '10px', text: `${by !== '?' ? by.substring(0, 2).toUpperCase() : by}`},        
        visible: visible
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].lat, data[i].lng);

      bounds.extend(latLng);      

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent(`
          <div>
            <p>${this.jn} ${this.client} (${this.city})</p>
            <p>${this.by}</p>
            <p>start: ${this.startTime} => ${this.duration} hrs</p>
            <p>${this.description}</p>
          </div>
          `);
        infoWindow.open(mapRef.current, this);
      });

    }    

  }

  //============== END GOOGLE MAP ======================

  const selectRow = (e) => {

    let tr = e.currentTarget        
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i')

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    // } else if (fetchedData[i].syncedID === false) {
    //   alert('ID not synced. Please refresh the data.')
    } else {

      // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      // let today = new Date()
      // let entry = new Date(fetchedData[i].entrytime)
      // let timeDiff = Math.abs(entry.getTime() - today.getTime())
      // let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      // if (props.user.test < 2) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username !== fetchedData[i].entryby) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      // } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

      //   setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      // } else if (props.user.test === 4) {

      //   setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      // } else {

      //   setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      // }

      // // this is for removed, if returns undefined, then not removed
      // let removed =  fetchedData.find(test => test.testno === fetchedData[i].testno && test.suf === 'R')
      // let reTest = false

      // if (!removed && fetchedData[i].passfail === 'F') {

      //   // this is for retest, if returns undefined, then not found, so retest needed
      //   reTest = fetchedData.find(test => test.testno === fetchedData[i].testno && test.passfail === 'P')
      //   //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)

      // }

      // // used to dynamically update relComp and passFail during input
      // updatePassFail.current = {
      //   dryDens: fetchedData[i].drydens,
      //   optD: fetchedData[i].optd,
      //   reqComp: fetchedData[i].reqcomp
      // }

      // setIsValidated(prevState => ({...prevState,
      //   entryby: fetchedData[i].entryby,
      //   entrytime: fetchedData[i].entrytime,
      //   entrylat: fetchedData[i].entrylat,
      //   entrylng: fetchedData[i].entrylng,
      //   entrydevice: fetchedData[i].entrydevice,
      //   modby: fetchedData[i].modby,
      //   modtime: fetchedData[i].modtime,
      //   modlat: fetchedData[i].modlat,
      //   modlng: fetchedData[i].modlng,
      //   moddevice: fetchedData[i].moddevice,
      //   id: fetchedData[i].id,
      //   reTest: reTest === undefined ? true : false,
      //   showRemoved: removed === undefined ? true : false,
      //   removed: removed === undefined ? false : true,
      //   nukeId: fetchedData[i].nukeId,
      //   testDate: fetchedData[i].testdate,
      //   pre: fetchedData[i].pre,
      //   testNo: fetchedData[i].testno,
      //   suf: fetchedData[i].suf,
      //   testType: fetchedData[i].testtype,
      //   north: fetchedData[i].north,
      //   east: fetchedData[i].east,
      //   elevD: fetchedData[i].elevd,
      //   moistCont: fetchedData[i].moistcont,
      //   dryDens: fetchedData[i].drydens,
      //   maxId: fetchedData[i].maxId,
      //   curve: fetchedData[i].curve,
      //   optD: fetchedData[i].optd,
      //   optM: fetchedData[i].optm,
      //   reqComp: fetchedData[i].reqcomp,
      //   relComp: fetchedData[i].relcomp,
      //   passFail: fetchedData[i].passfail,        
      //   sandInitialWt: fetchedData[i].sandInitialWt, 
      //   sandFinalWt: fetchedData[i].sandFinalWt, 
      //   sandExtraWt: fetchedData[i].sandExtraWt, 
      //   sandDensity: fetchedData[i].sandDensity, 
      //   tubeWetWt: fetchedData[i].tubeWetWt, 
      //   tubeTareWt: fetchedData[i].tubeTareWt, 
      //   tubeFactor: fetchedData[i].tubeFactor, 
      //   moistWetWt: fetchedData[i].moistWetWt, 
      //   moistDryWt: fetchedData[i].moistDryWt, 
      //   moistTareWt: fetchedData[i].moistTareWt
      // }))
      openEdit(i)

    }

  }

  const selectPeriod = (e) => {

    let tr = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let id = tr.getAttribute('data-id')

    setCalendarDate(prevState => ({...prevState, period: id}))

    closePeriod()

  }

  const selectDay = (e) => {

    let tr = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let id = tr.getAttribute('data-id')

    setCalendarDate(prevState => ({...prevState, day: id}))

    closeDay()

  }

  const selectMonth = (e) => {

    let tr = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let id = tr.getAttribute('data-id')

    setCalendarDate(prevState => ({...prevState, 
      month: id,
      monthName: months[id]
    }))

    closeMonth()

  }

  const selectYear = (e) => {

    let tr = e.currentTarget        
    //let td = tr.getElementsByTagName('td')
    let id = tr.getAttribute('data-id')

    setCalendarDate(prevState => ({...prevState, year: id}))

    closeYear()

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  } 

  const openAdd = (user, time) => {

    //console.log(`startDate1: ${startDate}`)

    if (props.user.schedule < 2) {

      alert('You do not have the required permission. Contact an admin.')

    // if (isModal.warning) {
    //
    //   alert('You do not have the required permission. Contact an admin.')

    // } else if (props.filter.jobNumber === '') {

    //   alert('Please select a JN.')

    } else {

      // let testNo = fetchedData.length < 1 ? 1 : Math.max(...fetchedData.map(o => o.testno)) + 1

      //if (origin === 'calendar') setIsValidated(prevState => ({...prevState, startDate: new Date(startDate)}))

      //console.log(`startDate2: ${new Date(startDate)}`)
      //console.log(`startDate3: ${formatDateYMD(new Date(startDate))}`)

      if (time !== undefined) {        

        //formatDateYMD(new Date(startDate))

        setIsModal(prevState => ({...prevState, add: true, user: user, startTime: time}))        

      } else {

        setIsModal(prevState => ({...prevState, add: true}))

      }      

    }

  }

  const openEdit = (i) => setIsModal(prevState => ({...prevState, edit: true, i: i}))

  const closeModal = () => setIsModal(prevState => ({...prevState, add: false, edit: false, saving: false, startDate: null}))
  
  const openPeriod = () => setIsModal(prevState => ({...prevState, period: true}))
  const openDay = () => setIsModal(prevState => ({...prevState, day: true}))
  const openMonth = () => setIsModal(prevState => ({...prevState, month: true}))
  const openYear = () => setIsModal(prevState => ({...prevState, year: true}))

  const closePeriod = () => setIsModal(prevState => ({...prevState, period: false}))
  const closeDay = () => setIsModal(prevState => ({...prevState, day: false}))
  const closeMonth = () => setIsModal(prevState => ({...prevState, month: false}))
  const closeYear = () => setIsModal(prevState => ({...prevState, year: false}))

  // const toggleTimeFrame = () => {
  //   // console.log(`display: 
  //   // week: ${display.week}
  //   // month: ${display.month}
  //   // `)
  //   setDisplay(prevState => ({...prevState, week: display.week ? false : true, month: display.month ? false : true}))
  // }

  const toggleTimeline = () => setDisplay(prevState => ({...prevState, timeline: display.timeline ? false : true}))

  const toggleCalendar = () => setDisplay(prevState => ({...prevState, calendar: display.calendar ? false : true}))

  const toggleTable = () => setDisplay(prevState => ({...prevState, table: display.table ? false : true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const updateFetchedData = (data) => setFetchedData(data)
  
  let recordCount = 0

  let listOfData = fetchedData.map((data, i) => {

    // let pre = data.pre === null ? '' : data.pre
    // let number = data.testno === null ? '' : data.testno
    // let suf = data.suf === null ? '' : data.suf
    // let testNo = `${pre} ${number} ${suf}`
    // let dryDens = data.drydens === null ? '' : data.drydens
    // let moistCont = data.moistcont === null ? '' : data.moistcont
    // let md = `${dryDens} @ ${moistCont}`
    // let optD = data.optd === null ? '' : data.optd
    // let optM = data.optm === null ? '' : data.optm
    // let curve = data.curve === null ? '' : data.curve
    // let max = `${curve} - ${optD} @ ${optM}`
    // let reqComp = data.reqcomp === null ? '' : data.reqcomp
    // let elevD = data.elevd === null ? '' : data.elevd
    // let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    // let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''   

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    if (filter) {      

      recordCount += 1

      // <th>Date</th>
      // <th>JN</th>
      // <th>Time</th>
      // <th>Hours</th>
      // <th>Desc</th> 

      return (
        <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={selectRow}>
          <td>{data.startDate}</td>
          <td>{data.jobNumber}</td>
          <td>{data.startTime ===  null ? '' :data.startTime.substring(0,5)}</td>
          <td>{data.duration}</td>
          <td>{data.description}</td>
          <td>{data.tech}</td>   
        </tr>
      )

    }

  })
  
  let contentPeriod = (
    <table style={{width: '100%', height: '100%'}}>

        <thead>
          <tr>
            <th></th>            
          </tr>
        </thead>

        <tbody>
          <tr data-id={'Today'} onClick={selectPeriod}>
            <td>Today</td>                      
          </tr>
          <tr data-id={'1 day'} onClick={selectPeriod}>            
            <td>1 day</td>                      
          </tr>
          <tr data-id={'2 days'} onClick={selectPeriod}>            
            <td>2 days</td>                      
          </tr>
          <tr data-id={'3 days'} onClick={selectPeriod}>            
            <td>3 days</td>                      
          </tr>
          <tr data-id={'5 days'} onClick={selectPeriod}>            
            <td>5 days</td>        
          </tr>
          <tr data-id={'Week'} onClick={selectPeriod}>            
            <td>Week</td>                      
          </tr>
          <tr data-id={'Month'} onClick={selectPeriod}>            
            <td>Month</td>                      
          </tr>                    
        </tbody>

      </table>
  )

  let contentMonth = (
    <table style={{width: '100%', height: '100%'}}>

        <thead>
          <tr>
            <th>Month</th>            
          </tr>
        </thead>

        <tbody>
          <tr data-id={0} onClick={selectMonth}>
            <td>January</td>                      
          </tr>
          <tr data-id={1} onClick={selectMonth}>            
            <td>February</td>                      
          </tr>
          <tr data-id={2} onClick={selectMonth}>            
            <td>March</td>                      
          </tr>
          <tr data-id={3} onClick={selectMonth}>            
            <td>April</td>        
          </tr>
          <tr data-id={4} onClick={selectMonth}>            
            <td>May</td>                      
          </tr>
          <tr data-id={5} onClick={selectMonth}>            
            <td>June</td>                      
          </tr>
          <tr data-id={6} onClick={selectMonth}>            
            <td>July</td>                      
          </tr>
          <tr data-id={7} onClick={selectMonth}>            
            <td>August</td>                      
          </tr>
          <tr data-id={8} onClick={selectMonth}>            
            <td>September</td>                      
          </tr>
          <tr data-id={9} onClick={selectMonth}>            
            <td>October</td>                     
          </tr>
          <tr data-id={10} onClick={selectMonth}>            
            <td>November</td>                      
          </tr>
          <tr data-id={11} onClick={selectMonth}>            
            <td>December</td>          
          </tr>
        </tbody>

      </table>
  )
  
  // <div style={{display: 'inline-block', margin: 10}}>
  //   <small style={{margin: 10}}>Month</small>
  //   <label className='switch'>
  //     <input type='checkbox' onClick={toggleTimeFrame} defaultChecked={true} />
  //     <span className='slider round'></span>
  //   </label>
  //   <small style={{margin: 10}}>Week</small>
  // </div>

  // <Icon name='view_timeline' title='View Timeline' onClick={toggleTimeline} outline={true} />
  // <Icon name='calendar_month' title='View Calendar' onClick={toggleCalendar} />
  // <Icon name='table_rows' title='View List' outline={true} onClick={toggleTable} />

  let content = (
    <>
      {isModal.add || isModal.edit ?
        <ScheduleInput                
          close={closeModal} 
          fetchedData={fetchedData}
          updateFetchedData={updateFetchedData}              
          isModal={isModal}
          user={props.user}
          filter={props.filter}
          component={props.component}
        /> : null
      }
      {isModal.period ? <Modal content={contentPeriod} closeModal={closePeriod} /> : null}
      {isModal.day ? <Modal content={calendarDate.contentDay} closeModal={closeDay} /> : null}
      {isModal.month ? <Modal content={contentMonth} closeModal={closeMonth} /> : null}
      {isModal.year ? <Modal content={calendarDate.contentYear} closeModal={closeYear} /> : null}         
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          {props.user.device !== 'mobile' ?
          
            <div style={{margin: 10, flex: '0 1 30%'}}>

              <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

                <div style={{width: '100%'}}>                  

                  {props.user.schedule < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}

                  <Icon name='refresh' title='Refresh' onClick={fetchData} />             

                  {isModal.derp?

                    <div className='dateSelectorBlue' onClick={openPeriod}>        
                      <span style={{marginRight: 10}}><b>{calendarDate.period}</b></span>
                    </div> : null

                  }

                  <div className='dateSelectorBlue' onClick={openMonth}>        
                    <span style={{marginRight: 10}}><b>{calendarDate.monthName}</b></span>
                  </div>

                  <div className='dateSelectorBlue' onClick={openDay}>        
                    <span style={{marginRight: 10}}><b>{calendarDate.day}</b></span>
                  </div>

                  <div className='dateSelectorBlue' onClick={openYear}>        
                    <span style={{marginRight: 10}}><b>{calendarDate.year}</b></span>
                  </div>                

                  {isModal.derp ?

                  <>
                    <Icon name='arrow_circle_left' onClick={null} outline={true} />
                    <Icon name='arrow_circle_right' onClick={null} outline={true} />
                  </> : null

                  }

                </div>

                <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />                            

                {display.timeline && props.user.device !== 'mobile' ?
                  <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
                    <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
                  </div> : null
                }

                {display.calendar ? 
                  <ScheduleCalendar 
                    display={display}                 
                    openAdd={openAdd}
                    openEdit={openEdit}
                    close={closeModal} 
                    fetchedData={fetchedData}
                    updateFetchedData={updateFetchedData}              
                    isModal={isModal}
                    user={props.user}
                    filter={props.filter}
                    component={props.component}                
                    calendarDate={calendarDate}
                  /> : null
                }

                {display.table && fetchedData.length > 0 ?

                  <>

                    <small style={{margin: 10}}>Limits to past 100 entries</small>
                      
                    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                      <table className='hover'>

                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>JN</th>
                            <th>Time</th>
                            <th>Hours</th>
                            <th>Desc</th>
                            <th>Tech</th>                        
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>

                      </table>                      

                    </div>                

                  </> : null

                }            

              </div>

            </div> : null

          }

          <div id='timeline' style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              {props.user.device === 'mobile' ?

                <>

                  <div style={{width: '100%'}}>

                    {props.user.schedule < 2 ? null : <AddButton onClick={openAdd} />}

                    <Icon name='refresh' title='Refresh' onClick={fetchData} />

                    <Icon name='location_on' title='Toggle Map' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />             

                    {isModal.derp?

                      <div className='dateSelectorBlue' onClick={openPeriod}>        
                        <span style={{marginRight: 10}}><b>{calendarDate.period}</b></span>
                      </div> : null

                    }

                    <div className='dateSelectorBlue' onClick={openMonth}>        
                      <span style={{marginRight: 10}}><b>{calendarDate.monthName}</b></span>
                    </div>

                    <div className='dateSelectorBlue' onClick={openDay}>        
                      <span style={{marginRight: 10}}><b>{calendarDate.day}</b></span>
                    </div>

                    <div className='dateSelectorBlue' onClick={openYear}>        
                      <span style={{marginRight: 10}}><b>{calendarDate.year}</b></span>
                    </div>

                  </div>

                  <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

                </> : null

              }

              {isModal.chart ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
                  <canvas ref={canvasRef}></canvas>
                </div> : null
              }

              {props.user.device !== 'mobile' || (!isModal.google && props.user.device === 'mobile') ?

                <ScheduleTimeline 
                  display={display}                 
                  openAdd={openAdd}
                  openEdit={openEdit}
                  close={closeModal} 
                  fetchedData={fetchedData}
                  updateFetchedData={updateFetchedData}              
                  isModal={isModal}
                  user={props.user}
                  filter={props.filter}
                  component={props.component}                
                  calendarDate={calendarDate}
                  selectDay={selectDay}
                  highlightMarker={highlightMarker}
                /> : null
                
              }

              {isModal.google && props.user.device === 'mobile' ?
                <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
                  <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
                </div> : null
              }

            </div>

          </div>

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }
    </>
  )
  
  return props.modal ? <Modal content={content} closeModal={props.close} zIndex={props.zIndex} /> :  content  

}

export default Schedule
