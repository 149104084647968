import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'

import { formatDateTime, getLocation, catchError, replaceStr } from 'scripts/common'

import 'styles/sign.css'

import SignaturePad from 'signature_pad'

const Sign = (props) => {

  const canvasSign = useRef(null)
  const signaturePad = useRef(null)
  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    receivedBy: props.user.userlevel === 'guest' ? props.user.fullname : ''
  })

  // const clearIsValidated = () => setIsValidated({
  //   receivedBy: ''
  // })

  useEffect(() => {

    // On mobile devices it might make more sense to listen to orientation change,
    // rather than window resize events.

    signaturePad.current = new SignaturePad(canvasSign.current, {
      backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    })

    window.onresize = resizecanvasSign;
    resizecanvasSign() // necessary to allow signing


  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
     let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const resizecanvasSign = () => {

    if (document.activeElement.tagName !== 'INPUT') {

      let ratio =  Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      canvasSign.current.width = canvasSign.current.offsetWidth * ratio;
      canvasSign.current.height = canvasSign.current.offsetHeight * ratio;
      canvasSign.current.getContext("2d").scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      signaturePad.current.clear()

    }

  }

  const addSignature = () => {

    let signature = signaturePad.current.toDataURL('image/jpeg');

    if (signature === null || signature === '') {

        alert("Signature is blank.");

    } else {

      // signature = signature.replace('data:image/jpeg;base64,', '');
	    // signature = signature.replace(' ', '+');

      getLocation(function(latlng){

        fetch('/api/addSignature', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: props.user.id,
            signature: signature,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            //signaturePad.current.clear()
            props.update(signature)
            props.close()
          },
          (error) => {
            catchError('', '', '', 'addSignature', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const undoSignature = () => signaturePad.current.clear()
  // add={signDaily}


  const sign = () => {

    if (signaturePad.current.isEmpty()) {
      alert('Please provide a signature.')
    } else if (props.parent === 'daily' && (isValidated.receivedBy === null || isValidated.receivedBy === '')) {
      alert('Please provide your full name.')
    } else {

      if (props.parent === 'daily') {
        props.signDaily(signaturePad.current.toDataURL('image/jpeg'), isValidated.receivedBy)
      } else {
        addSignature()
      }

    }

  }

  return (

    <Modal
      add={sign}
      undo={undoSignature}
      content={
        <div>
          {props.parent === 'daily' ?
            <input type="text" className="signature-pad--full-name" placeholder="Please provide full name here" pattern=".{1,}" name='receivedBy' onInput={validate} onChange={changedData} defaultValue={props.user.fullname} required /> : null
          }
          <div className="signature-pad">

            <div className="signature-pad--body">
              <div className="signature-pad--body-x">X</div>
              <div className="signature-pad--body-note">Sign above</div>
              <canvas ref={canvasSign}></canvas>
            </div>

          </div>
        </div>
      }
      closeModal={props.close}
      height='none'
    />

  )
}

export default Sign
